import React, { useState } from 'react';
import {
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    makeStyles,
} from '@material-ui/core';
import { ImageType } from 'react-images-uploading';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { FullscreenOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    closeGalleryButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: '#ffffff',
    },
    closeGalleryBackground: {
        height: '48px',
        display: 'flex',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
    },
}));

interface Props {
    respondentPhotoId?: any;
}

export const PhotoId: React.FC<Props> = (props: Props) => {

    const [open, setOpen] = useState(false);
    const [selectedTile, setSelectedTile] = useState<ImageType | null>(null);
    const classes = useStyles();

    const handleClickOpen = (image: ImageType) => {
        setOpen(true);
        setSelectedTile(image);
    };

    return (
        <Grid container={true} direction={'row'} item={true} xs={12}>
            <Grid item={true} xs={12}>
                <GridList
                    cellHeight={180}
                    cols={3}
                >
                    {props.respondentPhotoId && (props.respondentPhotoId.map((image: ImageType, index: number) => (
                        <GridListTile key={index}>
                            <img src={image.public_path}/>
                            <GridListTileBar
                                title={'image'}
                                actionIcon={(
                                    <>
                                        <IconButton
                                            style={{color: '#fff'}}
                                            edge={'end'}
                                            size={'small'}
                                            onClick={() => {
                                                handleClickOpen(image)
                                            }}
                                        >
                                            <FullscreenOutlined/>
                                        </IconButton>
                                    </>
                                )}
                            />
                        </GridListTile>
                    )))}
                </GridList>
                <Dialog
                    open={open}
                    maxWidth={'lg'}
                >
                    {selectedTile && (
                        <>
                            <div className={classes.closeGalleryBackground}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    className={classes.closeGalleryButton}
                                    onClick={() => setOpen(false)}
                                    aria-label="close"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                            <img src={selectedTile.public_path}/>
                        </>
                    )}
                </Dialog>
            </Grid>
        </Grid>
    );
}
