import { Role, RolesAction, RolesActionType } from './constants';

export interface RolesState {
    loading: boolean;
    success: boolean;
    error: boolean;
    roles: Role[];
}

const initialState: RolesState = {
    loading: false,
    success: false,
    error: false,
    roles: [],
};

export const rolesReducer = (state: RolesState = initialState, action: RolesAction) => {
    switch (action.type) {
        case RolesActionType.ROLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RolesActionType.ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                roles: action.roles,
            };
        case RolesActionType.ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
