import { RespondentsListUpdateAction } from '../actions';
import { RespondentsList, RespondentsListActionType } from '../constants';

export interface RespondentsListUpdateState {
    loading: boolean;
    success: boolean;
    error: boolean;
    respondentsList: RespondentsList;
}

const initialState: RespondentsListUpdateState = {
    loading: false,
    success: false,
    error: false,
    respondentsList: {} as RespondentsList,
};

export const updateReducer = (state: RespondentsListUpdateState = initialState, action: RespondentsListUpdateAction) => {
    switch (action.type) {
        case RespondentsListActionType.RESPONDENTS_LIST_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                respondentsList: action.respondentsList,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_UPDATE_FAILURE:
            return {
                ...state,
                success: false,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
