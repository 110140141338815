import React from 'react';
import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logo from '../../assets/prc_logo.jpg'
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme =>
    ({
        logo: {
            backgroundImage: `url(${Logo})`,
            backgroundSize: '70px 30px',
            backgroundRepeat: 'no-repeat',
            minWidth: '70px',
            minHeight: '30px',
        },
        progress: {
            width: 70,
            marginTop: 8,
        },
    }));

export const LoadingPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            flexDirection={'column'}
        >
            <div className={classes.logo} />
            <LinearProgress className={classes.progress} />
        </Box>
    );
};
