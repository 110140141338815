import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { createGetCheckBoxProps, createGetAutocompleteProps } from '../../../helpers/formBuilder';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Electronics: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;

    const classes = useStyles();

    const cableProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'cable_provider'));
    const videoConsoleType = useSelector((state: AppState) => selectDictionaryGroup(state, 'video_console_type', true));

    const [values, setValues] = useState(respondent);

    const getCheckBoxProps = createGetCheckBoxProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Cable TV provider and/or Streaming Service'} {...params} />
                                        )}
                                    {...getAutocompleteProps('cable_provider', cableProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Video Game System at Home
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            {
                                videoConsoleType.map(
                                    (item: Dictionary, index: number) =>
                                        (
                                            <Grid key={ index } item={ true } xs={ 12 } className={ classes.grid }>
                                                <FormControlLabel
                                                    { ...getCheckBoxProps('video_game_system', videoConsoleType, index) }
                                                    control={ <Checkbox color="primary" /> }
                                                />
                                            </Grid>
                                        )
                                )
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
