import React, { useEffect } from 'react';
import { ProjectAddContainer } from '../../containers';
import { useDispatch } from 'react-redux';
import { projectsCreateResetState } from '../../modules/projects';
import { respondentsListListResetState, respondentsListRespondentsShowClearState } from '../../modules/respondentsList';
import { filterValuesResetState } from '../../modules/filterValue';
import { respondentsListResetState } from '../../modules/respondents';

export const ProjectsAddPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(projectsCreateResetState());
        dispatch(filterValuesResetState());
        dispatch(respondentsListListResetState());
        dispatch(respondentsListRespondentsShowClearState());
        dispatch(respondentsListResetState());
    }, []);

    return (
        <>
            <ProjectAddContainer/>
        </>
    );
};
