import React from 'react';
import { LoginPageWrapper } from '../../components/LoginPageWrapper';
import { LoginContainer } from '../../containers/LoginContainer';

export const LoginPage: React.FC = () =>

    (
        <LoginPageWrapper title={'Sign in'}>
            <LoginContainer/>
        </LoginPageWrapper>
    );
