import React from 'react';

type PermissionContextType = {
    isAllowedTo: (permission: string) => boolean;
}

const defaultBehaviour: PermissionContextType = {
    isAllowedTo: () => false,
};

export const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour);

interface AbilityContextProviderProps {
    children?: React.ReactNode;
    permissions: string[];
}

export const PermissionContextProvider: React.FC<AbilityContextProviderProps> = (props: AbilityContextProviderProps) => {
    const {children, permissions} = props;

    const isAllowedTo = (permission: string) => permissions.includes(permission);

    return (
        <>
            <PermissionContext.Provider
                value={{isAllowedTo}}>
                {children}
            </PermissionContext.Provider>
        </>
    );
};
