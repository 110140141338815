import React, { MutableRefObject, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';

interface RespondentIdCopyToClipboardComponentProps {
    id?: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            minWidth: 320,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    })
);

export const RespondentIdCopyToClipboardComponent: React.FC<RespondentIdCopyToClipboardComponentProps> = (props: RespondentIdCopyToClipboardComponentProps) => {
    const classes = useStyles();
    const inputElem = useRef() as MutableRefObject<HTMLInputElement>;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inputElem.current.value)
    };

    return (
        <div className={classes.root}>
            <InputLabel
                id={'respondent-copy-to-clipboard-label'}
                style={{color: '#f00', fontSize: 22, paddingRight: 6}}
            >
                ID
            </InputLabel>
            <Paper variant={'outlined'} className={classes.root} component="form">
                <InputBase
                    className={classes.input}
                    inputProps={{'aria-readonly': true}}
                    value={props.id}
                    inputRef={inputElem}
                />
                <Divider className={classes.divider} orientation="vertical"/>
                <Tooltip title={'copy to clipboard'} placement="top">
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        aria-label="copy"
                        onClick={() => copyToClipboard()}
                    >
                        <FileCopyIcon/>
                    </IconButton>
                </Tooltip>
            </Paper>
        </div>
    );
};
