import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Box, Button,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Project as Values } from '../../../modules/projects';
import { useFormik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import MomentUtils from '@material-ui/pickers/adapter/moment';
import { DateRangePicker, DateRange, DateRangeDelimiter, LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment/moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: 5,
            padding: 3,
            backgroundColor: '#dfe7fe',
            borderRadius: '15%',
            '&:hover': {
                borderRadius: '15%',
                padding: 3,
            },
        },
        inputLabel: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& label': {
                color: '#124c95',
            },
        },
        label: {
            backgroundColor: '#f2f2f2',
            borderRadius: 4,
            color: '#4f4f4f',
        },
        input: {
            maxWidth: 500,
        },
        typography: {
            color: '#82a1fb',
            fontSize: 13,
        },
    })
);

interface ErrorValues {
    project_number?: string,
    project_name?: string,
    contact?: string,
    project_company_name?: string,
    topic?: string,
    project_key_words?: string,
    fielding_date_at?: string,
    fielding_date_to?: string,
}

interface Props {
    project: Values;
    loading: boolean;
    onSubmit: (values: Values) => void;
    apiErrors: any;
}

const initialErrorValues: ErrorValues = {};

export const ProjectForm: React.FC<Props> = (props: Props) => {
    const {project, loading, onSubmit , apiErrors } = props;
    const [editMode, setEditMode] = useState<boolean>(false);
    const [keywordsLabels, setKeywordsLabels] = useState(project.project_key_words || []);
    const [clientInternalLabels, setClientInternalLabels] = useState(project.project_client_internal || []);
    const [geographicalAreaLabels, setGeographicalAreaLabels] = useState(project.project_geographical_area || []);

    const [fieldingDate, setFieldingDate] = React.useState<DateRange<Date>>([moment(project.fielding_date_at).toDate() ?? null, moment(project.fielding_date_to).toDate() ?? null]);

    const classes = useStyles();

    const validate = (values: Values) => {
        const errors: ErrorValues = {...initialErrorValues};

        if (!values.project_number) {
            errors.project_number = 'Required'
        }
        if (values.project_number && values.project_number.length < 8) {
            errors.project_number = 'Project number must be at least 8 characters'
        }
        if (values.project_number && values.project_number.length > 15) {
            errors.project_number = 'Project number must be maximum 15 characters'
        }
        if (!values.project_name) {
            errors.project_name = 'Required'
        }
        if (!values.contact) {
            errors.contact = 'Required'
        }
        if (!values.project_company_name) {
            errors.project_company_name = 'Required'
        }
        if (!values.topic) {
            errors.topic = 'Required'
        }
        if (keywordsLabels.length === 0) {
            errors.project_key_words = 'Required'
        }

        return errors;
    };

    const onSubmitForm = (values: Values) => {
        let newValues: Values = {
            ...values,
        };

        if (keywordsLabels.length) {
            newValues = {...newValues, project_key_words: keywordsLabels}
        }

        newValues = {...newValues, project_client_internal: clientInternalLabels}
        newValues = {...newValues, project_geographical_area: geographicalAreaLabels}

        if (fieldingDate[0] && fieldingDate[1]){
            newValues = {...newValues, fielding_date_at: fieldingDate[0].toISOString()}
            newValues = {...newValues, fielding_date_to: fieldingDate[1].toISOString()}
        }

        onSubmit(newValues);
        setEditMode(!editMode);
    };

    const dateChange = (newValue: DateRange<Date>) => {
        setFieldingDate(newValue)
    };

    const handleLabelsDelete = (index: number, labels: string[], setLabels: Dispatch<SetStateAction<string[]>>) => {
        const newLabels = [...labels];
        newLabels.splice(index, 1);
        setLabels(newLabels);
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        labels: string[],
        setLabels: Dispatch<SetStateAction<string[]>>
    ) => {
        if (e.key === 'Enter') {
            const element = e.target as HTMLInputElement;
            const newLabels = [...labels];
            newLabels.push(element.value);
            setLabels(newLabels);
            element.value = '';
        }
    };

    const focusLost = (
        e: React.FocusEvent<HTMLInputElement>,
        labels: string[],
        setLabels: Dispatch<SetStateAction<string[]>>
    ) => {
        const element = e.target as HTMLInputElement;
        const newLabels = [...labels];
        if (element.value){
            newLabels.push(element.value);
            setLabels(newLabels);
            element.value = '';
        }
    };

    const formik = useFormik({
        isInitialValid: false,
        initialValues: project,
        enableReinitialize: true,
        onSubmit: onSubmitForm,
        validate,
    });

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6}>
                    <Grid container={true} spacing={2}>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-project-number-label">Project #:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Project Number"
                                fullWidth={true}
                                {...formik.getFieldProps('project_number')}
                                error={ Boolean(apiErrors['project.project_number']) ||
                                (formik.touched.project_number &&
                                    Boolean(formik.errors.project_number))
                                }
                                helperText={apiErrors['project.project_number']?.[0] || (formik.touched.project_number && formik.errors.project_number)}
                            />
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-project-name-label">Project Name:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Project Name"
                                fullWidth={true}
                                {...formik.getFieldProps('project_name')}
                                error={ Boolean(apiErrors['project.project_name']) || (formik.touched.project_name &&
                                    Boolean(formik.errors.project_name))
                                }
                                helperText={apiErrors['project.project_name']?.[0] || (formik.touched.project_name && formik.errors.project_name)}
                            />
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-client-name-label">Contact Name:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Contact Name"
                                fullWidth={true}
                                {...formik.getFieldProps('contact')}
                                error={
                                    formik.touched.contact &&
                                    Boolean(formik.errors.contact)
                                }
                                helperText={(formik.touched.contact && formik.errors.contact)}
                            />
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-company-name-label">Company Name:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Company Name"
                                fullWidth={true}
                                {...formik.getFieldProps('project_company_name')}
                                error={
                                    formik.touched.project_company_name &&
                                    Boolean(formik.errors.project_company_name)
                                }
                                helperText={(formik.touched.project_company_name && formik.errors.project_company_name)}
                            />
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-topic-label">Topic:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Topic"
                                fullWidth={true}
                                {...formik.getFieldProps('topic')}
                                error={
                                    formik.touched.topic &&
                                    Boolean(formik.errors.topic)
                                }
                                helperText={(formik.touched.topic && formik.errors.topic)}
                            />
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-target-to-book-label">Target to Book:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                className={classes.input}
                                label="Target to Book"
                                fullWidth={true}
                                type={'number'}
                                InputProps={{ inputProps: {
                                    min: 0,
                                    onInput: (event: any) => {
                                        event.target.value = event.target.value.replace(/\D/g, '')
                                    }},
                                }}
                                {...formik.getFieldProps('target_to_book')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden xsDown={true}>
                    <Divider style={{marginRight: -2}} orientation="vertical" flexItem={true}/>
                </Hidden>
                <Grid item={true} xs={12} sm={6}>
                    <Grid container={true} spacing={2}>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-key-words-label">Key words:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="SUV's or Lexus or Toyota"
                                fullWidth={true}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                    handleKeyDown(e, keywordsLabels, setKeywordsLabels)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    focusLost(e, keywordsLabels, setKeywordsLabels)}
                                name={'project_key_words'}
                                error={
                                    formik.touched.project_key_words &&
                                    Boolean(formik.errors.project_key_words)
                                }
                                helperText={(formik.touched.project_key_words && formik.errors.project_key_words)}
                            />
                            {keywordsLabels.map((label: string, key: number) => (
                                <Box display="inline-block" key={key} mt={1} mr={1}>
                                    <Chip
                                        size="small"
                                        label={label}
                                        clickable={true}
                                        className={classes.label}
                                        onDelete={
                                            () => handleLabelsDelete(
                                                key,
                                                keywordsLabels,
                                                setKeywordsLabels
                                            )
                                        }
                                        deleteIcon={<CloseIcon/>}
                                    />
                                </Box>
                            ))}
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-client-internal-label">Client Reference #:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="Project # or PO # if applicable"
                                fullWidth={true}
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    focusLost(e, clientInternalLabels, setClientInternalLabels)}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                    handleKeyDown(e, clientInternalLabels, setClientInternalLabels)}
                                name="project_client_internal"
                            />
                            {clientInternalLabels.map((label: string, key: number) => (
                                <Box display="inline-block" key={key} mt={1} mr={1}>
                                    <Chip
                                        size="small"
                                        label={label}
                                        clickable={true}
                                        className={classes.label}
                                        onDelete={
                                            () => handleLabelsDelete(
                                                key,
                                                clientInternalLabels,
                                                setClientInternalLabels
                                            )
                                        }
                                        deleteIcon={<CloseIcon/>}
                                    />
                                </Box>
                            ))}
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel id="project-add-geographical-area-label">Project Geographical Area:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="Nationwide or city"
                                fullWidth={true}
                                onBlur={(e: any) =>
                                    focusLost(e, geographicalAreaLabels, setGeographicalAreaLabels)}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                    handleKeyDown(e, geographicalAreaLabels, setGeographicalAreaLabels)}
                                name="project_geographical_area"
                            />
                            {geographicalAreaLabels.map((label: string, key: number) => (
                                <Box display="inline-block" key={key} mt={1} mr={1}>
                                    <Chip
                                        size="small"
                                        label={label}
                                        clickable={true}
                                        className={classes.label}
                                        onDelete={
                                            () => handleLabelsDelete(
                                                key,
                                                geographicalAreaLabels,
                                                setGeographicalAreaLabels
                                            )
                                        }
                                        deleteIcon={<CloseIcon/>}
                                    />
                                </Box>
                            ))}
                        </Grid>
                        <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                            <InputLabel>Fielding Dates:</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <DateRangePicker
                                    startText="At"
                                    endText="To"
                                    clearable={true}
                                    value={fieldingDate}
                                    onChange={(newValue: DateRange<Date>) => dateChange(newValue)}
                                    renderInput={(startProps, endProps) => (
                                        <React.Fragment>
                                            <TextField  {...startProps} helperText={''} />
                                            <DateRangeDelimiter> to </DateRangeDelimiter>
                                            <TextField {...endProps} helperText={''} />
                                        </React.Fragment>
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {project.state === 1 && (
                            <>
                                <Grid className={classes.inputLabel} item={true} xs={12} md={3}>
                                    <InputLabel>Archived:</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <FormControlLabel
                                        name="state"
                                        control={<Checkbox color="secondary"/>}
                                        onChange={(e: any) => {
                                            formik.setFieldValue('state', e.target.checked ? 1 : 2)
                                        }}
                                        label="Archived"
                                        checked={ formik.getFieldProps('state').value === 1 }
                                    />
                                </Grid>
                            </>
                        )}

                    </Grid>
                </Grid>
                <Box mt={3} mr={1.5} width={1}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} sm={12}>
                            <Typography className={classes.typography}>
                                Project Description/Specs:
                            </Typography>
                            <TextField
                                label="Project Description/Specs"
                                {...formik.getFieldProps('project_description')}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <Typography className={classes.typography}>
                                Miscellaneous Costs/Incentives:
                            </Typography>
                            <TextField
                                label="Miscellaneous Costs/Incentives"
                                {...formik.getFieldProps('project_incentives')}
                            />
                        </Grid>

                        <Box width={1} display="flex" justifyContent="flex-end" mt={3} mr={1.5}
                             bgcolor="background.paper">
                            <Button
                                type="submit"
                                variant="contained"
                                size={'large'}
                                color="primary"
                                disabled={loading}
                                onClick={() => formik.handleSubmit()}
                            >
                                {loading && <CircularProgress size={24}/>}
                                {
                                    !loading && (
                                        <Typography>
                                            Save Project
                                        </Typography>
                                    )
                                }
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};

