import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_POST, METHOD_PUT, requestBuilder } from '../../../helpers';
import {
    dictionariesSuccess,
    dictionaryCreateFailure,
    DictionaryCreateRequest,
    dictionaryCreateSuccess, dictionaryUpdateFailure, DictionaryUpdateRequest, dictionaryUpdateSuccess
} from '../actions';
import { DictionariesActionType } from '../constants';
import { handleApiErrors } from '../../../helpers/api-errors';

function* createDictionary(action: DictionaryCreateRequest) {
    try {
        const {dictionary} = action;
        const {data} = yield call(
            requestBuilder,
            'api/dictionaries',
            METHOD_POST,
            {
                dictionary,
            }
        );

        yield put(dictionaryCreateSuccess());
        yield put(dictionariesSuccess(data));
    } catch (error) {
        yield put(dictionaryCreateFailure(handleApiErrors(error)));
    }
}

function* updateDictionary(action: DictionaryUpdateRequest) {
    try {
        const {dictionary} = action;
        const {data} = yield call(
            requestBuilder,
            `api/dictionaries/${dictionary._id}`,
            METHOD_PUT,
            {
                dictionary,
            }
        );

        yield put(dictionaryUpdateSuccess());
        yield put(dictionariesSuccess(data));
    } catch (error) {
        yield put(dictionaryUpdateFailure(handleApiErrors(error)));
    }
}

export function* rootDictionarySaga() {
    yield all([
        takeLatest(DictionariesActionType.DICTIONARY_UPDATE_REQUEST, updateDictionary),
        takeLatest(DictionariesActionType.DICTIONARY_CREATE_REQUEST, createDictionary),
    ]);
}
