import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { takeEvery, takeLatest, fork } from 'redux-saga/effects';
import {
    RespondentsListRequestSaga,
    RespondentsState,
    respondentsReducer,
    RespondentActionType,
    RespondentsShowRequestSaga,
    RespondentsDuplicateRequestSaga,
    RespondentsDeleteRequestSaga,
    UpdateRespondentRlFieldsSaga,
    RespondentsBulkDeleteRequestSaga,
} from './respondents';
import { filterValuesReducer, FilterValuesState } from './filterValue';
import {
    ExportToFileState,
    exportToFileReducer,
    EXPORT_TO_FILE_REQUEST,
    EXPORT_TO_FILE_DOWNLOAD,
    ExportToFileSaga, downloadExport,
} from './export';

import {
    LoginState,
    authReducer,
    loginRoot, LogoutActionType, logout,
} from './auth';
import { UserActionType, UserChangePasswordRequestSaga, usersReducer, UsersRefreshSaga, UsersState } from './user';
import { UserRequestSaga } from './user/sagas';
import {
    ResetPasswordActionType,
    resetPasswordReducer,
    ResetPasswordRequestSaga,
    ResetPasswordState,
    ChangePasswordRequestSaga
} from './resetPassword';
import {
    dictionariesReducer,
    DictionariesState
} from './dictionaries';
import { RolesActionType, rolesReducer, RolesSaga, RolesState } from './roles';
import { projectsReducer, ProjectsState } from './projects/reducers';
import { ProjectActionType, ProjectsListRequestSaga, ProjectsRequestSaga, ProjectsShowRequestSaga } from './projects';
import { rootProjectsSaga } from './projects/sagas/projects';
import { commentsReducer, CommentsState } from './comments/reducers/comments';
import { CommentsRequest, rootCommentsSaga } from './comments/sagas/comments';
import { CommentsActionType } from './comments';
import { createRespondent, rootUpdateRespondentsSaga } from './respondents/sagas/respondents';
import { rootRespondentsListSaga } from './respondentsList/sagas/respondentsList';
import rootUsersSaga from './user/sagas/users';
import { notificationsReducer, NotificationsState } from './notifications';
import { FileUploadActionType, fileUploadReducer, FileUploadState } from './fileUpload';
import uploadFile from './fileUpload/saga';
import { respondentsListReducer, RespondentsListState } from './respondentsList/reducers';
import awaitRespondentListShowSaga from './respondentsList/sagas/awaitRespondentListShow';
import { RespondentsListActionType } from './respondentsList';
import { exportToFileDoneReducer, ExportToFileDoneState, watchExportDone } from './exportDone';
import { chartsReducer, ChartsRequestSaga, ChartsState, ChartsType } from './charts';
import projectsRefreshSaga from './projects/sagas/refresh';
import dictionariesRootSaga from './dictionaries/sagas';
import { rootDictionarySaga } from './dictionaries/sagas/dictionary';
import respondentJoinProjectSaga from './respondents/sagas/join_project';

export * from './auth';
export * from './user';
export * from './respondents';
export * from './filterValue';
export * from './export';
export * from './resetPassword';

declare global {
    // tslint:disable-next-line:no-any
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

// app state interface
export interface AppState {
    auth: LoginState;
    user: UsersState;
    roles: RolesState;
    respondents: RespondentsState;
    respondentsList: RespondentsListState;
    comments: CommentsState;
    projects: ProjectsState;
    filterValues: FilterValuesState;
    export: ExportToFileState;
    exportDone: ExportToFileDoneState;
    resetPassword: ResetPasswordState;
    dictionaries: DictionariesState;
    notifications: NotificationsState;
    import: FileUploadState;
    charts: ChartsState;
}

// preparing app authReducer
const appReducer = combineReducers({
    auth: authReducer,
    user: usersReducer,
    roles: rolesReducer,
    respondents: respondentsReducer,
    respondentsList: respondentsListReducer,
    comments: commentsReducer,
    projects: projectsReducer,
    filterValues: filterValuesReducer,
    export: exportToFileReducer,
    exportDone: exportToFileDoneReducer,
    resetPassword: resetPasswordReducer,
    dictionaries: dictionariesReducer,
    notifications: notificationsReducer,
    import: fileUploadReducer,
    charts: chartsReducer,
});

// preparing sagas
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function* appSaga() {
    yield fork(loginRoot);
    yield fork(rootProjectsSaga);
    yield fork(rootUpdateRespondentsSaga);
    yield fork(rootCommentsSaga);
    yield fork(rootUsersSaga);
    yield fork(rootRespondentsListSaga);
    yield fork(RespondentsDuplicateRequestSaga);
    yield fork(dictionariesRootSaga);
    yield fork(rootDictionarySaga);
    yield takeEvery(RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_REQUEST, UpdateRespondentRlFieldsSaga);
    yield takeEvery(RespondentActionType.RESPONDENT_JOIN_PROJECT_REQUEST, respondentJoinProjectSaga);
    yield takeEvery(UserActionType.USER_REQUEST, UserRequestSaga);
    yield takeEvery(LogoutActionType.LOGOUT_REQUESTING, logout);
    yield takeEvery(RolesActionType.ROLES_REQUEST, RolesSaga);
    yield takeEvery(UserActionType.USER_CHANGE_PASSWORD_REQUEST, UserChangePasswordRequestSaga);
    yield takeEvery(RespondentActionType.RESPONDENTS_LIST_REQUEST, RespondentsListRequestSaga);
    yield takeEvery(RespondentActionType.RESPONDENTS_SHOW_REQUEST, RespondentsShowRequestSaga);
    yield takeEvery(RespondentActionType.RESPONDENTS_DELETE_REQUEST, RespondentsDeleteRequestSaga);
    yield takeEvery(RespondentActionType.RESPONDENTS_BULK_DELETE_REQUEST, RespondentsBulkDeleteRequestSaga);
    yield takeEvery(RespondentActionType.RESPONDENTS_CREATE_REQUEST, createRespondent);
    yield takeEvery(ProjectActionType.PROJECTS_LIST_REQUEST, ProjectsListRequestSaga);
    yield takeEvery(ProjectActionType.PROJECTS_SHOW_REQUEST, ProjectsShowRequestSaga);
    yield takeEvery(ProjectActionType.PROJECTS_REQUEST, ProjectsRequestSaga);
    yield takeEvery(CommentsActionType.COMMENTS_LIST_REQUEST, CommentsRequest);
    yield takeLatest(ChartsType.CHARTS_REQUEST, ChartsRequestSaga);
    yield takeEvery(EXPORT_TO_FILE_REQUEST, ExportToFileSaga);
    yield takeEvery(EXPORT_TO_FILE_REQUEST, watchExportDone);
    yield takeEvery(EXPORT_TO_FILE_DOWNLOAD, downloadExport);
    yield takeEvery(ResetPasswordActionType.RESET_PASSWORD_REQUESTING, ResetPasswordRequestSaga);
    yield takeEvery(ResetPasswordActionType.CHANGE_PASSWORD_REQUESTING, ChangePasswordRequestSaga);
    yield takeEvery(FileUploadActionType.FILE_UPLOAD_REQUEST, uploadFile);
    yield takeEvery(UserActionType.USERS_REFRESH, UsersRefreshSaga);
    yield takeEvery(ProjectActionType.PROJECTS_REFRESH, projectsRefreshSaga);
    yield takeEvery(RespondentsListActionType.RESPONDENTS_LIST_SHOW_AWAIT, awaitRespondentListShowSaga);
}

const sagaMiddleware = createSagaMiddleware();

// creating store
export const store = createStore(
    appReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

// running all sagas
sagaMiddleware.run(appSaga);

// export default store;
