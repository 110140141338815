import { NotificationsAction } from './actions';
import { NotificationActionType } from './constants';

export interface NotificationsState {
    toggleSnackbar: boolean;
    message: any;
    level: 'error' | 'warning' | 'info' | 'success';
}

const initialState: NotificationsState = {
    toggleSnackbar: false,
    message: 'Success',
    level: 'success',
};

export const notificationsReducer = (state: NotificationsState = initialState, action: NotificationsAction) => {
    switch (action.type) {
        case NotificationActionType.TOGGLE_SNACKBAR_OPEN: {
            return {
                ...state,
                toggleSnackbar: true,
                message: action.message,
                level: action.level,
            };
        }
        case NotificationActionType.TOGGLE_SNACKBAR_CLOSE: {
            return {
                ...state,
                toggleSnackbar: false,
                message: null,
                level: action.level,
            };
        }
        default: {
            return state;
        }
    }
};
