import React, { useState } from 'react';
import { RespondentInnerComponentProps } from '../index';
import { createGetInputProps } from '../../../helpers/formBuilder';
import TextField from '@material-ui/core/TextField';

export const Npi: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);

    return (
        <>
            <TextField
                inputProps={{maxLength: 10}}
                label={'NPI #'}
                {...getInputProps('npi')}
            />
        </>

    );
};
