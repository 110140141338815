import { NotificationActionType } from './constants';

export interface ToggleSnackbarOpen {
    type: NotificationActionType.TOGGLE_SNACKBAR_OPEN;
    message: any;
    level?: string;
}

export interface ToggleSnackbarClose {
    type: NotificationActionType.TOGGLE_SNACKBAR_CLOSE;
    level?: string;
}

export const toggleSnackbarOpen = (message: any, level?: string): ToggleSnackbarOpen => ({
    type: NotificationActionType.TOGGLE_SNACKBAR_OPEN,
    message,
    level,
});

export const toggleSnackbarClose = (level?: string): ToggleSnackbarClose => ({
    type: NotificationActionType.TOGGLE_SNACKBAR_CLOSE,
    level,
});

export type NotificationsAction = ToggleSnackbarOpen | ToggleSnackbarClose ;
