import React from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Dictionary } from '../../../modules/dictionaries';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() =>
    createStyles({
        grid: {
            maxHeight: '500px',
            width: '100%',
        },
        root: {
            flexGrow: 1,
        },
    })
);

interface MedicalProps {
    respondent: any;
    dictionaries: any;
}

export const Medical: React.FC<MedicalProps> = (props: MedicalProps) => {
    const {respondent, dictionaries} = props;
    const classes = useStyles();

    return (
        <div className={ classes.root }>
            <Grid container={ true } direction={ 'column' } className={ classes.grid }>
                {
                    dictionaries.length && respondent.diseases && dictionaries.map(
                        (item: Dictionary, index: number) => respondent.diseases.includes(item.value) ?
                            (
                                <Grid key={ index } item={ true } xs={ 12 }>
                                    <Checkbox
                                        disabled={ true }
                                        key={ index }
                                        name={ item.value }
                                        color={ 'primary' }
                                        checked={ true }
                                    />
                                    { item.text }
                                </Grid>
                            ) : ('')
                    )
                }
            </Grid>
        </div>
    );
};
