import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListComponent } from '../../components';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
    Project,
    projectsRefresh,
    projectsRequest,
    projectsUpdateRequest,
    selectActiveProjects,
    selectArchivedProjects,
    selectProjectsActiveTotal,
    selectProjectsArchivedTotal,
    selectProjectsPageHeaders,
    selectProjectsRequested,
} from '../../modules/projects';
import { FilterSet } from '../RespondentsSearchContainer';

const useStyles = makeStyles(theme =>
    ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
        },
        heading: {
            color: '#a9130f',
            fontSize: theme.typography.pxToRem(28),
            fontWeight: 600,
        },
    }));

export const ProjectsContainer: React.FC = () => {
    const dispatch = useDispatch();
    const active = useSelector(selectActiveProjects);
    const archived = useSelector(selectArchivedProjects);
    const [skipActive, setSkipActive] = useState(0);
    const [activeOrderBy, setActiveOrderBy] = useState('_id');
    const [activeOrderDirection, setActiveOrderDirection] = useState('desc');

    const activeTotal = useSelector(selectProjectsActiveTotal);
    const archivedTotal = useSelector(selectProjectsArchivedTotal);
    const [skipArchived, setSkipArchived] = useState(0);
    const [archivedOrderBy, setArchivedOrderBy] = useState('_id');
    const [archivedOrderDirection, setArchivedOrderDirection] = useState('desc');

    const headers = useSelector(selectProjectsPageHeaders);
    const loading = useSelector(selectProjectsRequested);
    const classes = useStyles();

    const handleArchive = (row: Project) => {
        row.state = 1;
        dispatch(projectsUpdateRequest(row));
        dispatch(projectsRefresh(activeOrderBy, activeOrderDirection, archivedOrderBy, archivedOrderDirection, skipActive, skipArchived));
    };

    const pageChangeActive = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => {
        setSkipActive(skip);
        setActiveOrderBy(orderBy || '')
        setActiveOrderDirection(orderDirection || '');
        dispatch(projectsRequest(orderBy, orderDirection, archivedOrderBy, archivedOrderDirection, skip, skipArchived))
    };

    const pageChangeArchived = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => {
        setSkipArchived(skip);
        setArchivedOrderBy(orderBy || '');
        setArchivedOrderDirection(orderDirection || '');
        dispatch(projectsRequest(activeOrderBy, activeOrderDirection, orderBy, orderDirection, skipActive, skip))
    };

    useEffect(
        () => {
            dispatch(projectsRequest(activeOrderBy, activeOrderDirection, archivedOrderBy, archivedOrderDirection, skipActive, skipArchived))
        },
        []
    );

    return (
        <div className={classes.root}>

            <Typography className={classes.heading}>Active Projects</Typography>

            <Paper className={classes.paper} variant="outlined">
                <ListComponent
                    rows={active.map(item => ({...item, id: item._id.$oid}))}
                    query={{}}
                    total={activeTotal}
                    returnLink=""
                    headers={headers}
                    pageChange={pageChangeActive}
                    actionArchive={true}
                    onHandleArchive={handleArchive}
                    headBackgroundColor="#ffe2e1"
                    headTextColor="#a9130f"
                    rowsPerPage={500}
                    sortable={true}
                    loading={loading}
                    key={'active'}
                />
            </Paper>

            <Typography className={classes.heading}>Archived Projects</Typography>

            <Paper className={classes.paper} variant="outlined">
                <ListComponent
                    rows={archived.map(item => ({...item, id: item._id.$oid}))}
                    query={{}}
                    total={archivedTotal}
                    returnLink=""
                    headers={headers}
                    pageChange={pageChangeArchived}
                    actionArchive={true}
                    onHandleArchive={handleArchive}
                    headBackgroundColor="#ffe2e1"
                    headTextColor="#a9130f"
                    rowsPerPage={500}
                    sortable={true}
                    loading={loading}
                    key={'archived'}
                />
            </Paper>
        </div>
    );
}
