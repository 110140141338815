import { useEffect } from 'react';

/**
 * Delete empty fields from object
 * @param values
 */
export const clearEmptyValues = (values: any) => {
    const newValuesState = {} as any;

    Object.keys(values).forEach((key: string) => {

        if (typeof values[key] === 'string'){
            values[key] = values[key].trim();
        }

        if (values[key] !== '') {
            if (!Array.isArray(values[key])) {
                newValuesState[key] = values[key];
            } else {
                values[key] = clearEmptyArrays(values[key])
                if (values[key].length) {
                    newValuesState[key] = values[key];
                }
            }
        }
    });

    return newValuesState;
};

/**
 * Filter empty arrays from object
 * @param values
 */
export const clearEmptyArrays = (values: any) => values.filter((item: any) =>
    typeof item !== 'object' || Object.values(item).some(x => (x !== null && x !== '' && x !== undefined))
);

/**
 * Filter empty arrays from object
 * @param values
 */
export const clearEmptyArraysValues = (values: any) => {
    Object.keys(values).forEach((key: string) => {
        if (Array.isArray(values[key])){
            values[key] = values[key].filter((item: any) =>
                typeof item !== 'object' || Object.values(item).some(x => (x !== null && x !== '' && x !== undefined)))
        }
    });

    return values;
};

/**
 * Add handler for press Enter key
 * @param eventHandler
 */
export const addOnEnterKeyPressHandler = (eventHandler: () => any) => {
    const listener = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            event.preventDefault();
            eventHandler();
        }
    };

    document.addEventListener('keydown', listener);

    return () => {
        document.removeEventListener('keydown', listener);
    };
}
