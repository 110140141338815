import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects';

import {
    METHOD_GET,
    METHOD_POST,
    requestBuilder,
} from '../../../helpers';
import { LoginActionType, LogoutActionType } from '../constants';

import history from '../../../history';
import { UserActionType } from '../../user';
import { toggleSnackbarOpen } from '../../notifications';

export function* logout() {
    try {
        const data = yield call(
            requestBuilder,
            'api/logout',
            METHOD_POST,
            {}
        );

        yield put({ type: LogoutActionType.LOGOUT_SUCCESS, data });

        history.push('/login');
    } catch (error) {
        yield put({ type: LogoutActionType.LOGOUT_ERROR, error });
    } finally {
        // if (yield cancelled()) {
        //     history.push('/login');
        // }
    }
}

function* loginFlow(email: string, password: string) {
    try {
        yield call(
            requestBuilder,
            'sanctum/csrf-cookie',
            METHOD_GET,
            {}
        );

        const data = yield call(
            requestBuilder,
            'api/login',
            METHOD_POST,
            {
                email,
                password,
            }
        );

        if (!data.errors) {
            yield put({ type: LoginActionType.LOGIN_SUCCESS, data });
            history.push('/dashboard');
        }
    } catch (error) {
        yield put({ type: LoginActionType.LOGIN_ERROR, error });
    } finally {
        if (yield cancelled()) {
            history.push('/login');
        }
    }
}

function* loginWatcher() {

    while (true) {

        const { email, password } = yield take(LoginActionType.LOGIN_REQUESTING);

        const task = yield fork(loginFlow, email, password);

        const action = yield take([UserActionType.USER_FAILURE, LoginActionType.LOGIN_ERROR, LogoutActionType.LOGOUT_SUCCESS]);

        if (action.type === UserActionType.USER_FAILURE || action.type === LogoutActionType.LOGOUT_SUCCESS) {
            yield cancel(task);
        }

        // yield call(logout);
    }
}

export function* loginRoot () {
    yield fork(loginWatcher)
}
