import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    selectExportDoneLink,
    selectExportDoneLoading,
    selectExportDoneSuccess
} from '../../../modules/exportDone/selectors';
import DoneIcon from '@material-ui/icons/Done';
import { Button } from '@material-ui/core';
import { exportToFileDownload, exportToFileResetState } from '../../../modules';

interface Props {
    open: boolean;
};

export const ExportProgress: React.FC<Props> = (props: Props) => {
    const {open} = props;
    const dispatch = useDispatch();
    const loading: boolean = useSelector(selectExportDoneLoading);
    const success: boolean = useSelector(selectExportDoneSuccess);
    const link: string = useSelector(selectExportDoneLink);

    const handleClose = () => {
        dispatch(exportToFileResetState());
        dispatch(exportToFileDownload(link));
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            // onClose={handleClose}
            message={(
                <Box display={'flex'}>
                    {loading && (
                        <>
                            <CircularProgress style={{marginRight: 10}} size={24}/>
                            <Typography component={'span'}>Preparing Export...</Typography>
                        </>
                    )}
                    {success && (
                        <>
                            <DoneIcon style={{color: 'green'}}/>
                        </>
                    )}
                </Box>
            )}
            action={(
                <>
                    {success && (
                        <Button color="secondary" size="small" onClick={handleClose}>
                            <Typography component={'span'}>
                                Export
                            </Typography>
                        </Button>
                    )}
                </>
            )}
        />
    );
}
