import moment from 'moment';
import { Dictionary } from '../modules/dictionaries';
import { Respondent } from '../modules';
import { ChangeEvent } from 'react';
import { AutocompleteInputChangeReason } from '@material-ui/lab';

export const createGetInputProps = (values: any, setValues: any, onChangeAction: any) => (field: keyof Respondent, valid?: any) => ({
    name: field,
    id: field,
    value: (() => {
        // if date - convert to date format
        if (/[\d]{4}-[\d]{2}-[\d]{2}/.test(values[field])) {
            return moment(values[field]).format('YYYY-MM-DD')
        } else {
            return (typeof values[field] === 'boolean') ? values[field].toString() : (values[field] ?? '')
        }
    })(),
    onChange: (event: any) => {
        let val = event.target.value;

        if (valid){
            val = valid(val);
        }

        if (val === 'true' || val === 'false') {
            val = JSON.parse(val);
        }
        const newValuesState = {
            ...values, [event.target.name]:
            val,
        };

        setValues(newValuesState);

        if (onChangeAction) {
            onChangeAction(field, val);
        }
    },
});

export const createGetCheckBoxProps = (values: any, setValues: any, onChangeAction: any) => (field: keyof Respondent, dicts: any, index: number) => ({
    name: field,
    id: field,
    label: dicts[index].text,
    value: dicts[index].value,
    checked: (values[field]) ? (values[field].includes(dicts[index].value) || false) : false,
    onChange: (event: any) => {
        const fieldProps = values[field] || [];

        const index = fieldProps.findIndex((f: any) => f === Number(event.target.value));
        index === -1 ? fieldProps.push(Number(event.target.value)) : fieldProps.splice(index, 1);

        const newValuesState = {
            ...values, [event.target.name]:
            fieldProps,
        };

        setValues(newValuesState);

        if (onChangeAction) {
            onChangeAction(field, fieldProps);
        }
    },
});

export const createGetAutocompleteProps = (values: any, setValues: any, onChangeAction: any) => (field: keyof Respondent, dicts: any) => ({
    name: field,
    id: field,
    options: dicts,
    getOptionLabel: ((option: Dictionary) => option.text),
    getOptionSelected: (option: Dictionary, value: Dictionary) => option.text === value.text,
    defaultValue: dicts.find((option : Dictionary) => option.value === values[field]),
    onChange: (event: any) => {
        const selectedOcc = dicts.find((option: Dictionary) => option.text === event.target.innerText);
        const newValuesState = {
            ...values, field:
                selectedOcc ? selectedOcc.value : '',
        };

        setValues(newValuesState);

        if (onChangeAction) {
            onChangeAction(field, selectedOcc ? selectedOcc.value : '');
        }
    },
});

export const createMultipleAutocompleteProps = (values: any, setValues: any, onChangeAction: any) => (field: keyof Respondent, dicts: any) => ({
    name: field,
    id: field,
    options: dicts,
    getOptionLabel: ((option: Dictionary) => option.text),
    defaultValue: dicts.filter((option: Dictionary) => values[field]?.includes(option.value)),
    disableCloseOnSelect: true,
    multiple: true,
    onChange: (event: any, value: any) => {
        const selectedOcc = value.map((occ: Dictionary) => occ.value);
        const newValues = {...values, field: selectedOcc};
        setValues(newValues);

        if (onChangeAction) {
            onChangeAction(field, selectedOcc);
        }
    },
    onInputChange: (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        if (event && event.type === 'blur') {
            return '';
        } else if (reason !== 'reset') {
            return value;
        }
    },
});

export const createGetSelectProps = (values: any, setValues: any, onChangeAction: any) => (field: keyof Respondent, optionsValues: any) => ({
    name: field,
    id: field,
    options : optionsValues,
    getOptionLabel: ((option: any) => option.text),
    // filterOptions: (options: any, state: any) => options,
    getOptionSelected: (option: any, value: any) => option.text === value.text,
    defaultValue:   values[field] ? optionsValues.find((option : any) => option.value === values[field].toString()) : optionsValues[0],
    onChange: (event: any, newValue: any) => {
            const newValuesState = {
                ...values, field:
                    newValue ? newValue.value : '',
            };

            setValues(newValuesState);

            if (onChangeAction) {
                onChangeAction(field,  newValue ? newValue.value : '');
            }
        },
});
