import React, { useState } from 'react';
import {
    Button, ButtonGroup,
    createStyles,
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    makeStyles,
    Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { CloudUploadOutlined, DeleteOutlined, DeleteSweepOutlined, UpdateOutlined } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectRespondentUpdateLoading } from '../../../modules';
import Box from '@material-ui/core/Box';

interface Props {
    setFilterValues: (prop: string, value: any) => void;
    searchBtnDisabled?: boolean;
    onSubmit: () => void;
    uploadIds: ImageListType;
}

const useStyles = makeStyles(() =>
    createStyles({
        imageList: {
            flexWrap: 'wrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
    })
);


export const UploadPhotoId: React.FC<Props> = (props: Props) => {

    const respondentUpdateLoading = useSelector(selectRespondentUpdateLoading);
    const {setFilterValues, uploadIds} = props;

    const [photoIds, setPhotoIds] = useState(uploadIds);

    const maxNumber = 69;

    const onChange = (imageList: ImageListType) => {
        setPhotoIds(imageList as never[]);

        setFilterValues('files', imageList);
    };

    const classes = useStyles();

    return (
        <Grid container={true} direction={'row'} item={true} xs={12}>
            <ImageUploading
                multiple={true}
                value={photoIds}
                acceptType={['jpg', 'png']}
                maxFileSize={851 * 1024}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey={'public_path'}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                  }) => (
                    <>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} md={10}>
                                <ButtonGroup disableElevation={true} variant="contained" color="primary">
                                    <Button
                                        style={isDragging ? {color: 'red'} : undefined}
                                        onClick={onImageUpload}
                                        color={'primary'}
                                        variant={'contained'}
                                        startIcon={<CloudUploadOutlined/>}
                                        {...dragProps}
                                    >Select Files</Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        startIcon={<DeleteSweepOutlined/>}
                                        onClick={onImageRemoveAll}
                                    >Delete All</Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item={true} xs={12} md={2}>
                                <Box display={'flex'} justifyContent={'center'} mb={4}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size={'large'}
                                        color="primary"
                                        disabled={props.searchBtnDisabled}
                                        onClick={() => props.onSubmit()}
                                    >
                                        {respondentUpdateLoading ?
                                            (
                                                <CircularProgress style={{color: '#fff'}} size={24}/>
                                            )
                                            : (
                                                <Typography>
                                                    Save
                                                </Typography>
                                            )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <GridList
                                cellHeight={180}
                                className={classes.imageList}
                                cols={3}
                            >
                                {imageList && (imageList.map((image: ImageType, index: number) => (
                                    <GridListTile key={index}>
                                        <img src={image.public_path}/>
                                        <GridListTileBar
                                            title={'image'}
                                            actionIcon={(
                                                <>
                                                    <IconButton
                                                        onClick={() => onImageUpdate(index)}
                                                        style={{color: '#fff'}}
                                                        edge={'end'}
                                                        size={'small'}>
                                                        <UpdateOutlined/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => onImageRemove(index)}
                                                        style={{color: '#fff'}}
                                                        edge={'end'}
                                                        size={'small'}>
                                                        <DeleteOutlined/>
                                                    </IconButton>
                                                </>
                                            )}
                                        />
                                    </GridListTile>
                                )))}
                                {errors && (
                                    <div>
                                        {errors && (
                                            <Alert severity="error">
                                                <AlertTitle>Error</AlertTitle>
                                                {errors.acceptType && (
                                                    <span>Your selected file type is not allow</span>)}
                                                {errors.maxFileSize && (
                                                    <span>Selected file size exceed max File Size</span>)}
                                            </Alert>
                                        )}
                                    </div>
                                )}
                            </GridList>
                        </Grid>
                    </>
                )}
            </ImageUploading>
        </Grid>

    );
};
