import { ProjectAction } from '../actions';
import { ProjectActionType } from '../constants';
import {ListComponentHeader} from "../../../interfaces";

export interface ProjectsListState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: any[];
    total: number;
    headers: ListComponentHeader[];
}

const initialState: ProjectsListState = {
    loading: false,
    success: false,
    error: false,
    data: [],
    total: 0,
    headers: [],
};

export const listReducer = (state: ProjectsListState = initialState, action: ProjectAction) => {
    switch (action.type) {
        case ProjectActionType.PROJECTS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                total: 0,
            };
        case ProjectActionType.PROJECTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
                total: action.total,
                headers: action.headers,
            };
        case ProjectActionType.PROJECTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
