import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_POST, METHOD_GET, requestBuilder, METHOD_PUT, METHOD_DELETE } from '../../../helpers';
import {
    respondentsListCreateFailure,
    RespondentsListCreateRequest,
    respondentsListCreateSuccess,
    respondentsListRespondentsShowSuccess,
    RespondentsListRespondentsShowRequest,
    RespondentsListUpdateRequest,
    respondentsListUpdateSuccess,
    respondentsListShowSuccess,
    respondentsListShowFailure,
    RespondentsListShowRequest,
    respondentsListUpdateFailure,
    RespondentsListDeleteRequest, respondentsListDeleteSuccess, respondentsListDeleteFailure, respondentsListListSuccess
} from '../actions';
import { RespondentsListActionType } from '../constants';
import { respondentsShowFailure } from '../../respondents';

function* showRespondentsListRespondents(action: RespondentsListRespondentsShowRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/respondents-list/respondents/${action._id}?skip=${action.skip}`,
            METHOD_GET,
            {}
        );

        yield put(respondentsListRespondentsShowSuccess(data.respondents, data.total, data.headers));

    } catch (error) {
        yield put(respondentsShowFailure());
    }
}

function* showRespondentsList(action: RespondentsListShowRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/respondents-list/${action.id}`,
            METHOD_GET,
            {}
        );

        yield put(respondentsListShowSuccess(data.list));
    } catch (error) {
        yield put(respondentsListShowFailure());
    }
}

function* createRespondentsList(action: RespondentsListCreateRequest) {
    try {
        const {respondentsList} = action;
        const {data} = yield call(
            requestBuilder,
            'api/respondents-list',
            METHOD_POST,
            {
                'respondentsList': respondentsList,
            }
        );
        yield put(respondentsListCreateSuccess(data.lists));
    } catch (error) {
        yield put(respondentsListCreateFailure());
    }
}

function* deleteRespondentsList(action: RespondentsListDeleteRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/respondents-list/${action.id}`,
            METHOD_DELETE,
            {}
        );
        yield put(respondentsListDeleteSuccess(data.list));
        yield put(respondentsListListSuccess(data.list));
    } catch (error) {
        yield put(respondentsListDeleteFailure());
    }
}

function* updateRespondentsList(action: RespondentsListUpdateRequest) {
    try {
        const {respondentsList} = action;
        const {data} = yield call(
            requestBuilder,
            `api/respondents-list/${respondentsList._id}`,
            METHOD_PUT,
            {
                'respondentsList': respondentsList,
            }
        );
        yield put(respondentsListUpdateSuccess(data.respondentsList));
    } catch (error) {
        yield put(respondentsListUpdateFailure());
    }
}

export function* rootRespondentsListSaga() {
    yield all([
        takeLatest(RespondentsListActionType.RESPONDENTS_LIST_CREATE_REQUEST, createRespondentsList),
        takeLatest(RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_REQUEST, showRespondentsListRespondents),
        takeLatest(RespondentsListActionType.RESPONDENTS_LIST_SHOW_REQUEST, showRespondentsList),
        takeLatest(RespondentsListActionType.RESPONDENTS_LIST_UPDATE_REQUEST, updateRespondentsList),
        takeLatest(RespondentsListActionType.RESPONDENTS_LIST_DELETE_REQUEST, deleteRespondentsList),
    ]);
}
