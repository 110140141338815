import { AppState, Respondent } from '..';
import { RespondentsList } from './constants';
import { ListComponentHeader } from '../../interfaces';

export const selectRespondentsList = (state: AppState): RespondentsList[] => state.respondentsList.respondentsListRespondents.list;
export const selectRespondentsListRespondents = (state: AppState): Respondent[] => state.respondentsList.respondentsListRespondents.respondents;
export const selectRespondentsListRespondentsTotal = (state: AppState): number => state.respondentsList.respondentsListRespondents.total;
export const selectRespondentsListRespondentsHeaders = (state: AppState): ListComponentHeader[] => state.respondentsList.respondentsListRespondents.headers;

// respondents list respondents
export const selectRespondentsListRespondentsLoading = (state: AppState): boolean => state.respondentsList.respondentsListRespondents.loading;
export const selectRespondentsListRespondentsSuccess = (state: AppState): boolean => state.respondentsList.respondentsListRespondents.success;

// show
export const selectRespondentsListShowLoading = (state: AppState): boolean => state.respondentsList.show.loading;

// create
export const selectRespondentsListCreateLoading = (state: AppState): boolean => state.respondentsList.create.loading;

// delete
export const selectRespondentsListDeleteLoading = (state: AppState): boolean => state.respondentsList.delete.loading;

// update
export const selectRespondentsListUpdateLoading = (state: AppState): boolean => state.respondentsList.update.loading;
