import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { respondentsListFailure, RespondentsListRequest, respondentsListSuccess } from '../actions';

export default function* ListRequestSaga(action: RespondentsListRequest) {
    try {
        const { data } = yield call(
            requestBuilder,
            'api/respondents/search',
            METHOD_POST,
            {
                filter: action.filter,
                fromFile: action.fromFile,
                skip: action.skip,
                orderBy: action.orderBy,
                orderDirection: action.orderDirection,
            }
        );

        yield put(respondentsListSuccess(data.data, data.total, data.headers));
    } catch (error) {
        yield put(respondentsListFailure());
    }
}
