import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListComponent } from '../../components';
import {
    selectRespondents,
    selectFilterValues,
    selectTotal,
    respondentsListRequest,
    exportToFileRequest,
    selectHeaders,
    selectRespondentsRequested,
    respondentsBulkDeleteRequest,
    selectRespondentBulkDeleteLoading,
    selectRespondentBulkDeleteSuccess, respondentsDeleteRequest, selectFromFileValues
} from '../../modules';
import { FilterSet } from '../RespondentsSearchContainer';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { selectExportLoading } from '../../modules/export/selectors';
import { ConfirmDialog } from '../../components/common/ConfirmDialog';
import { selectExportDoneLoading, selectExportDoneSuccess } from '../../modules/exportDone/selectors';

const useStyles = makeStyles(theme =>
    ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(28),
            fontWeight: 600,
        },
        exportButton: {
            border: '2px solid #5680f9',
        },
    }));

export const RespondentsListContainer: React.FC = () => {
    const dispatch = useDispatch();
    const respondents = useSelector(selectRespondents);
    const exportLoading = useSelector(selectExportLoading);
    const total = useSelector(selectTotal);
    const filterValues = useSelector(selectFilterValues);
    const fromFile = useSelector(selectFromFileValues);
    const headers = useSelector(selectHeaders);
    const loading = useSelector(selectRespondentsRequested);
    const bulkDeleteLoading = useSelector(selectRespondentBulkDeleteLoading);
    const bulkDeleteSuccess = useSelector(selectRespondentBulkDeleteSuccess);
    const exportDoneLoading = useSelector(selectExportDoneLoading);
    const exportDoneSuccess = useSelector(selectExportDoneSuccess);
    const classes = useStyles();

    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [orderDirection, setOrderDirection] = useState<string | undefined>(undefined);
    const [ids, setIds] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const pageChange = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => (
        dispatch(respondentsListRequest(filterValues, skip, orderBy, orderDirection, fromFile))
    );

    const handleExport = () => {
        dispatch(exportToFileRequest(filterValues, 'respondents', fromFile));
    };

    const handleBulkDelete = (ids: string[], skip: number, orderBy?: string, orderDirection?: string) => {
        setIds(ids);
        setSkip(skip);
        if (orderBy?.length) {
            setOrderBy(orderBy);
        }
        setOrderDirection(orderDirection);
        setOpen(true);
    };

    const handleDelete = () => {
        handleClose();
        dispatch(respondentsBulkDeleteRequest(ids));
        setIds([])
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(
        () => {
            dispatch(respondentsListRequest(filterValues, skip, orderBy, orderDirection, fromFile));
        },
        [filterValues, bulkDeleteSuccess]
    );

    return (
        <div className={classes.root}>

            <Typography className={classes.heading}>Respondent Search Result</Typography>

            <Paper className={classes.paper} variant="outlined">
                <ListComponent
                    rows={respondents.map(item => ({...item, id: item._id.$oid}))}
                    query={filterValues}
                    total={total}
                    returnLink="/search"
                    headers={headers}
                    exportAbilities={true}
                    handleExport={handleExport}
                    exportLoading={exportLoading}
                    onHandleBulkDelete={handleBulkDelete}
                    bulkDeleteLoading={bulkDeleteLoading}
                    pageChange={pageChange}
                    loading={loading}
                    listOf={'Respondent'}
                    sortable={true}
                    hideHeadings={['Contact', 'Status', 'Notes']}
                    showEdit={true}
                    exportDoneLoading={exportDoneLoading}
                    exportDoneSuccess={exportDoneSuccess}
                />
            </Paper>
            <ConfirmDialog
                confirmMessage={
                    'Are you sure you want to erase this respondents?\n' +
                    'This action will remove them from your database.'
                }
                open={open}
                handleDelete={handleDelete}
                handleClose={handleClose}
            />
        </div>
    );
};
