import { ExportToFileAction } from './actions';
import { EXPORT_TO_FILE_FAILURE, EXPORT_TO_FILE_REQUEST, EXPORT_TO_FILE_SUCCESS, EXPORT_TO_FILE_RESET_STATE } from './constants';

export interface ExportToFileState {
    loading: boolean;
    success: boolean;
    error: boolean;
    id: string | null;
}

const initialState: ExportToFileState = {
    loading: false,
    success: false,
    error: false,
    id: null,
};

export const exportToFileReducer = (state: ExportToFileState = initialState, action: ExportToFileAction) => {
    switch (action.type) {
        case EXPORT_TO_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case EXPORT_TO_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                id: action.id,
            };
        case EXPORT_TO_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case EXPORT_TO_FILE_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
