import { RespondentsListDeleteAction } from '../actions';
import { RespondentsList, RespondentsListActionType } from '../constants';

export interface RespondentsListDeleteState {
    loading: boolean;
    success: boolean;
    error: boolean;
    list: RespondentsList[];
}

const initialState: RespondentsListDeleteState = {
    loading: false,
    success: false,
    error: false,
    list: [],
};

export const deleteReducer = (state: RespondentsListDeleteState = initialState, action: RespondentsListDeleteAction) => {
    switch (action.type) {
        case RespondentsListActionType.RESPONDENTS_LIST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                list: action.list,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_DELETE_FAILURE:
            return {
                ...state,
                success: false,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
