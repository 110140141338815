import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Comment } from '../../modules/comments';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { selectUser } from '../../modules';

interface CommentsProps {
    comments: Comment[],
    commentsCount: number,
    loading: boolean,
    postCommentLoading: boolean,
    postComment: (comment: string) => void,
    onHandleExpandMore: () => void,
    onHandleDeleteComment: (id: string) => void,
    onHandleCommentTextChange: (comment: Comment) => void
}

const useStyles = makeStyles(theme =>
    ({
            button: {
                maxWidth: 100,
                marginLeft: 24,
                minWidth: 100,
                backgroundColor: '#bdbdbd',
            },
            commentButton: {
                backgroundColor: '#f2f2f2',
                padding: 2,
                minWidth: 50,
                maxHeight: 25,
                '& span': {
                    color: '#4f4f4f',
                },
            },
            commentUser: {
                fontSize: 15,
                fontWeight: 'bold',
                color: '#4f4f4f',
                marginRight: theme.spacing(4),
            },
            commentDate: {
                fontSize: 12,
                color: '#828282',
            },
            message: {
                position: 'relative',
                marginLeft: '16px',
                padding: '10px',
                width: '80%',
                color: '#4f4f4f',
                borderRadius: '4px',
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '12',
                    height: '0',
                    borderLeft: '15px solid transparent',
                    borderRight: '15px solid transparent',
                    top: '0',
                    left: '-15px',
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: '0',
                    height: '0',
                    borderLeft: '16px solid transparent',
                    borderRight: '16px solid transparent',
                    top: '-1px',
                    left: '-17px',
                },
            },
            messageBlue: {
                border: '1px solid #97C6E3',
                backgroundColor: '#A8DDFD',
                '&:after': {
                    borderTop: '15px solid #A8DDFD',
                },
                '&:before': {
                    borderTop: '17px solid #97C6E3',
                },
            },
            messageOrange: {
                backgroundColor: '#fff59d',
                border: '1px solid #dfd087',
                '&:after': {
                    borderTop: '15px solid #fff59d',
                },
                '&:before': {
                    borderTop: '17px solid #dfd087',
                },
            },
        }
    ));

export const Comments: React.FC<CommentsProps> = (props: CommentsProps) => {
    const {
        comments,
        commentsCount,
        loading,
        postCommentLoading,
        postComment,
        onHandleExpandMore,
        onHandleDeleteComment,
        onHandleCommentTextChange,
    } = props;
    const [comment, setComment] = useState<string>('');
    const [editedCommentText, setEditedCommentText] = useState<string>('');
    const [expanded, setExpanded] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<number>(999999);
    const user = useSelector(selectUser);

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setComment(event.target.value);
    };

    const handlePostComment = () => {
        postComment(comment);
        setComment('');
        setEditedCommentText('');
        setEditMode(999999);
    };

    const handleEditMode = (key: number) => {
        setEditedCommentText(comments[key].text);
        setEditMode(key);
    };

    const handleEditComment = (key: number) => {
        setEditMode(999999);
        if (comments[key].text !== editedCommentText) {
            comments[key].text = editedCommentText;
            onHandleCommentTextChange(comments[key]);
        }
    };

    const handleEditCancel = () => {
        setEditedCommentText('');
        setEditMode(999999);
    };

    const handleCommentTextChange = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
        setEditedCommentText(event.target.value);
    };

    const handleDeleteComment = (id: string) => {
        onHandleDeleteComment(id)
    };

    const handleExpandMore = () => {
        setExpanded(!expanded);
        onHandleExpandMore();
    };

    return (
        <>
            <Box
                display={'flex'}
                flexGrow={1}
                justifyContent={'space-between'}
            >
                <TextField
                    id="comment"
                    label="Write a comment..."
                    value={comment}
                    onChange={handleChange}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                        handleEditCancel()}
                />
                <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    onClick={handlePostComment}
                >
                    {postCommentLoading && <CircularProgress size={24}/>}
                    {
                        !postCommentLoading && (
                            <Typography>Post</Typography>
                        )
                    }
                </Button>
            </Box>

            {comments && (
                <List>
                    {
                        comments.map((comment: Comment, key: number) =>
                            (
                                <ListItem
                                    key={comment._id}
                                    style={{paddingLeft: 0, paddingRight: 0}}
                                    alignItems="flex-start"
                                >
                                    <ListItemAvatar>
                                        <AccountCircle color={'primary'} fontSize={'large'}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={(
                                            <>
                                                <Typography component={'span'} className={classes.commentUser}>
                                                    {comment.user._id === user._id ? ('Me') : (`${comment.user.first_name} ${comment.user.last_name}`)}
                                                </Typography>
                                                <Typography className={classes.commentDate} component={'span'}>
                                                    {comment.created_at}
                                                </Typography>
                                            </>
                                        )}
                                        secondary={(
                                            <Box
                                                width={1}
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                            >
                                                {key === editMode ? (
                                                    <TextField
                                                        onChange={
                                                            (event: React.ChangeEvent<HTMLInputElement>) =>
                                                            {handleCommentTextChange(event, key)}
                                                        }
                                                        value={editedCommentText}
                                                    />
                                                ) : (
                                                    <div className={`${classes.message} ${comment.user._id === user._id ? classes.messageOrange : classes.messageBlue}`}>
                                                        <Typography>
                                                            {comment.text}
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'space-between'}
                                                    style={{minWidth: 110}}
                                                    ml={2}
                                                >
                                                    {key === editMode ? (
                                                        <>
                                                            <Button
                                                                onClick={() => handleEditComment(key)}
                                                                className={classes.commentButton}
                                                            ><DoneIcon/></Button>
                                                            <Button
                                                                onClick={() => handleEditCancel()}
                                                                className={classes.commentButton}
                                                            ><CloseIcon/></Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                onClick={() => handleEditMode(key)}
                                                                className={classes.commentButton}
                                                            >Edit</Button>
                                                            <Button
                                                                onClick={() => handleDeleteComment(comment._id)}
                                                                className={classes.commentButton}
                                                            >Delete</Button>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </ListItem>
                            )
                        )
                    }
                </List>
            )}

            <Box width={1} display={'flex'} justifyContent={'center'}>
                {(commentsCount > 2 && !loading) && (
                    <IconButton>
                        {!expanded && (
                            <ExpandMoreIcon onClick={handleExpandMore} color={'primary'} fontSize={'large'}/>
                        )}
                        {!loading && expanded && (
                            <ExpandLessIcon onClick={() => setExpanded(false)} color={'primary'} fontSize={'large'}/>
                        )}
                    </IconButton>
                )}
            </Box>

            {loading && (
                <Box width={1} display={'flex'} justifyContent={'center'}>
                    <CircularProgress size={24}/>
                </Box>
            )}
        </>
    )
};
