import React, { ChangeEvent, useEffect, useState } from 'react';
import { UploadFileComponent } from '../../components/UploadFileComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadRequest } from '../../modules/fileUpload';
import { selectImportLoading, selectImportSuccess } from '../../modules/fileUpload/selectors';
import { toggleSnackbarOpen } from '../../modules/notifications';

export const RespondentsImportContainer: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File>({} as File);
    const loading = useSelector(selectImportLoading);
    const success = useSelector(selectImportSuccess);
    const dispatch = useDispatch();

    const handleCapture = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        // @ts-ignore
        setSelectedFile(element.files[0]);
    };

    useEffect(() => {
        if (success) {
            dispatch(toggleSnackbarOpen('File uploaded. We will notify you when it will be processed by email.', 'success'))
        }
    }, [success]);

    const handleSubmit = () => {
        dispatch(fileUploadRequest(selectedFile, 'api/respondents/import'));
    };

    return (
        <>
            <UploadFileComponent
                handleSubmit={handleSubmit}
                handleCapture={handleCapture}
                selectedFileName={selectedFile ? selectedFile.name : ''}
                loading={loading}
                title={'Select xlsx. File to import into Respondent Database.'}
            />
        </>
    );
};
