import { LoginAction, LoginActionType, LoginState, LogoutActionType } from './constants';

export const initialLoginState: LoginState = {
    requesting: false,
    loggedIn: false,
    errors: {},
};

export const authReducer = (state = initialLoginState, action: LoginAction) => {
    switch (action.type) {
        // Set the requesting flag and append a message to be shown
        case LoginActionType.LOGIN_REQUESTING:
            return {
                ...state,
                requesting: true,
            };

        // Successful?  Reset the login state.
        case LoginActionType.LOGIN_SUCCESS:
            return {
                ...state,
                errors: {},
                requesting: false,
                loggedIn: true,
            };

        // Append the error returned from our api
        // set the success and requesting flags to false
        case LoginActionType.LOGIN_ERROR:
            return {
                ...state,
                errors: action.error,
                requesting: false,
                loggedIn: false,
            };
        case LogoutActionType.LOGOUT_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case LogoutActionType.LOGOUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                loggedIn: false,
            };

        default:
            return state;
    }
};
