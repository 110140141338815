import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import TextField from '@material-ui/core/TextField';
import { createGetInputProps, createGetCheckBoxProps, createGetAutocompleteProps } from '../../../helpers/formBuilder';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
    })
);

export const Personal: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const carInsuranceProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'car_insurance_provider'));
    const healthInsuranceProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'health_insurance_provider'));
    const lifeInsuranceProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'life_insurance_provider'));
    const personalIncome = useSelector((state: AppState) => selectDictionaryGroup(state, 'personal_income'));
    const homeInsuranceProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'home_insurance_provider'));
    const countries = useSelector((state: AppState) => selectDictionaryGroup(state, 'countries'));
    const primaryLanguage = useSelector((state: AppState) => selectDictionaryGroup(state, 'primary_language'));
    const sexualOrientation = useSelector((state: AppState) => selectDictionaryGroup(state, 'sexual_orientation'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);
    const getCheckBoxProps = createGetCheckBoxProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Car Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('car_insurance')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Car Insurance Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('car_insurance_provider', carInsuranceProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Caregiver for Anyone?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('car_give_for_anyone')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Health Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('health_insurance')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Health Insurance Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('health_insurance_provider', healthInsuranceProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                            <Grid xs={ 12 } item={ true }>
                                <TextField
                                    label="Health Insurance Provider (other)"
                                    { ...getInputProps('health_insurance_provider_other') }
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Life Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('life_insurance')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Life Insurance Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('life_insurance_provider', lifeInsuranceProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Household Income'} {...params} />
                                        )}
                                    {...getAutocompleteProps('household_income', personalIncome)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Home Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('home_insurance')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Home Insurance Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('home_insurance_provider', homeInsuranceProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Personal Income'} {...params} />
                                        )}
                                    {...getAutocompleteProps('personal_income', personalIncome)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Place of Birth'} {...params} />
                                        )}
                                    {...getAutocompleteProps('place_of_birth', countries)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Primary Language'} {...params} />
                                        )}
                                    {...getAutocompleteProps('primary_language', primaryLanguage)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Sexual Orientation'} {...params} />
                                        )}
                                    {...getAutocompleteProps('sexual_orientation', sexualOrientation)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Speak Spanish?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('speak_spanish')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Languages Spoken in Household (check all that apply)
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            {
                                primaryLanguage.map(
                                    (item: Dictionary, index: number) =>
                                        (
                                            <Grid key={ index } item={ true } xs={ 12 } className={ classes.grid }>
                                                <FormControlLabel
                                                    { ...getCheckBoxProps('languages_spoken_in_hh', primaryLanguage, index) }
                                                    control={ <Checkbox color="primary" /> }
                                                />
                                            </Grid>
                                        )
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Languages Spoken Outside of Household (check all that apply)
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            {
                                primaryLanguage.map(
                                    (item: Dictionary, index: number) =>
                                        (
                                            <Grid key={ index } item={ true } xs={ 12 } className={ classes.grid }>
                                                <FormControlLabel
                                                    { ...getCheckBoxProps('languages_spoken_outside_hh', primaryLanguage, index) }
                                                    control={ <Checkbox color="primary" /> }
                                                />
                                            </Grid>
                                        )
                                )
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
