import React, { ChangeEvent, useRef } from 'react';
import {
    Button,
    makeStyles,
    Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    faceImage: {
        color: theme.palette.primary.light,
    },
    paper: {
        padding: theme.spacing(6),
    },
}));

interface Props {
    handleSubmit: () => void;
    handleCapture: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedFileName: string;
    loading: boolean;
    title: string;
}

export const UploadFileComponent: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const fileInput = useRef<HTMLInputElement | null>(null);

    const {handleSubmit, handleCapture, selectedFileName, loading, title} = props;

    return (
        <Paper variant={'outlined'} className={classes.paper}>
            <Grid container={true}>
                <Grid item={true} xs={10}>
                    <Typography>
                        {title}
                    </Typography>
                </Grid>
                <Grid item={true} xs={2}>
                    <Button
                        variant="contained"
                        size={'large'}
                        color="primary"
                        onClick={() => fileInput.current?.click()}
                    >
                        <Typography>
                            Browse
                        </Typography>
                    </Button>
                </Grid>
                {selectedFileName && (
                    <Grid container={true}>
                        <Grid item={true} xs={5}>
                            <Typography>{selectedFileName}</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Button
                                variant="contained"
                                size={'large'}
                                onClick={() => handleSubmit()}
                            >
                                {loading ?
                                    (
                                        <CircularProgress size={24}/>
                                    )
                                    : (
                                        <Typography>
                                            Upload
                                        </Typography>
                                    )}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                className={classes.input}
                id="faceImage"
                ref={fileInput}
                type="file"
                hidden={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleCapture(e)}
            />
            {/*<Tooltip title="Select Image">*/}
            {/*    <label htmlFor="faceImage">*/}
            {/*        <IconButton*/}
            {/*            className={classes.faceImage}*/}
            {/*            color="primary"*/}
            {/*            aria-label="upload picture"*/}
            {/*            component="span"*/}
            {/*        >*/}
            {/*            <PhotoCamera fontSize="large"/>*/}
            {/*        </IconButton>*/}
            {/*    </label>*/}
            {/*</Tooltip>*/}
            {/*<label>{selectedFileName ? selectedFileName : 'Select Image'}</label>*/}
            {/*<Button onClick={() => handleSubmit()} color="primary">*/}
            {/*    Save*/}
            {/*</Button>*/}
        </Paper>
    );
};
