import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined } from '@material-ui/icons';
import {
    DateRange,
    DateRangeDelimiter,
    DateRangePicker,
    LocalizationProvider
} from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            left: '25px',
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        paper: {
            padding: theme.spacing(5),
        },
        input: {
            maxWidth: 500,
            width: '100%',
        },
        typography: {
            color: '#124c95',
        },
        inputLabel: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    })
);

export interface ProjectsComponentProps {
    onSubmit: (values: Values) => void;
}

export interface Values {
    project_number?: string,
    project_name?: string,
    contact?: string,
    project_company_name?: string,
    topic?: string,
    project_key_words?: string,
    project_client_internal?: string,
    project_geographical_area?: string,
    project_description?: string,
    project_incentives?: string,
    fielding_date_at?: string,
    fielding_date_to?: string,
    state?: string,
}

export const initialValues: Values = {
    state: 'false',
};

export const ProjectsSearchComponent: React.FC<ProjectsComponentProps> = (props: ProjectsComponentProps) => {
    const classes = useStyles();

    const onSubmitForm = (values: Values) => {
        if (fieldingDate[0] && fieldingDate[1]){
            values = {...values, fielding_date_at: fieldingDate[0].toISOString()}
            values = {...values, fielding_date_to: fieldingDate[1].toISOString()}
        }
        props.onSubmit(values)
    };

    const [fieldingDate, setFieldingDate] = React.useState<DateRange<Date>>([null, null]);

    const dateChange = (newValue: DateRange<Date>) => {
        setFieldingDate(newValue)
    }

    const formik = useFormik({
        isInitialValid: false,
        initialValues,
        onSubmit: onSubmitForm,
    });

    return (
        <>
            <Box display={'flex'} flexGrow={1} mb={5}>
                <Grid item={true} md={10} xs={12}>
                    <TextField
                        style={{backgroundColor: '#fff'}}
                        size={'small'}
                        {...formik.getFieldProps('keywords')}
                        type={'search'}
                        fullWidth={true}
                        id="outlined-basic"
                        placeholder="Search by Keyword"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined color={'disabled'}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item={true} xs={12} md={2}>
                    <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'}>
                        <Button
                            type="submit"
                            size={'large'}
                            color={'primary'}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                        >
                            <Typography color={'inherit'}>
                                Search
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Box>

            <Typography className={classes.heading}>Project Search</Typography>

            <Paper className={classes.paper} variant="outlined">
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Project#:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Project Number"
                                {...formik.getFieldProps('project_number')}
                            />
                        </Box>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Project Name:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Project Name"
                                {...formik.getFieldProps('project_name')}
                            />
                        </Box>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Contact Name:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Contact Name"
                                {...formik.getFieldProps('contact')}
                            />
                        </Box>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Company Name:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Company Name"
                                {...formik.getFieldProps('project_company_name')}
                            />
                        </Box>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Topic:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Topic"
                                {...formik.getFieldProps('topic')}
                            />
                        </Box>
                        <Box my={2} mr={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                State:
                            </Typography>
                            <Select
                                label={'State'}
                                className={classes.input}
                                IconComponent={KeyboardArrowDownIcon}
                                {...formik.getFieldProps('state')}
                            >
                                <MenuItem key={3}
                                          value={'false'}>All</MenuItem>
                                <MenuItem key={2}
                                          value={2}>Active</MenuItem>
                                <MenuItem key={1}
                                          value={1}>Archived</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                    <Hidden xsDown={true}>
                        <Divider style={{marginRight: -2}} orientation="vertical" flexItem={true}/>
                    </Hidden>
                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Key words:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Key words"
                                {...formik.getFieldProps('project_key_words')}
                            />
                        </Box>
                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Client Reference #:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Client Reference #"
                                {...formik.getFieldProps('project_client_internal')}
                            />
                        </Box>
                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Project Geographical Area:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Project Geographical Area"
                                {...formik.getFieldProps('project_geographical_area')}
                            />
                        </Box>
                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Project Description/Specs:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Project Description/Specs"
                                {...formik.getFieldProps('project_description')}
                            />
                        </Box>
                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Miscellaneous Costs/Incentives:
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Miscellaneous Costs/Incentives"
                                {...formik.getFieldProps('project_incentives')}
                            />
                        </Box>

                        <Box my={2} ml={2} className={classes.inputLabel}>
                            <Typography className={classes.typography}>
                                Fielding Dates
                            </Typography>
                            <Grid  className={classes.input} item={true} md={10} xs={12}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <DateRangePicker
                                        startText="At"
                                        endText="To"
                                        clearable={true}
                                        value={fieldingDate}
                                        onChange={(newValue: DateRange<Date>) => dateChange(newValue)}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField
                                                    {...startProps}
                                                    fullWidth={false}
                                                    helperText={''}
                                                />
                                                <DateRangeDelimiter> to </DateRangeDelimiter>
                                                <TextField {...endProps} fullWidth={false} helperText={''} />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Box>
                    </Grid>
                    <Box width={1} display="flex" justifyContent="flex-end" mt={3} mr={1.5}
                         bgcolor="background.paper">
                        <Button
                            type="submit"
                            variant="contained"
                            size={'large'}
                            color="primary"
                            onClick={() => formik.handleSubmit()}
                        >
                            <Typography>
                                Search
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </>
    );
}
