import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Header } from '../Header';
import { AppNav } from '../Navigation';

import './Layout.css';
import { makeStyles } from '@material-ui/core/styles';
import { Notification } from '../Notification';
import { ExportProgress } from '../ExportProgress';
import { useSelector } from 'react-redux';
import { selectExportSuccess } from '../../../modules/export/selectors';

interface LayoutProps {
    children?: React.ReactNode;
}

const useStyles = makeStyles({
    root: {
        color: '#0000fe',
        maxWidth: '1680px',
    },
});

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { children } = props;
    const classes = useStyles();
    const success: boolean = useSelector(selectExportSuccess);

    return (
        <>
            <Header/>
            <Container className={classes.root}>
                <Box mt={12}>
                    <AppNav/>
                </Box>
                <Notification/>
                <ExportProgress
                    open={success}
                />
                {children}
            </Container>
        </>
    );
};
