import { RespondentsListAction } from '../actions';
import { RespondentsList, RespondentsListActionType } from '../constants';

export interface RespondentsListShowState {
    loading: boolean;
    success: boolean;
    error: boolean;
    list: RespondentsList;
}

const initialState: RespondentsListShowState = {
    loading: false,
    success: false,
    error: false,
    list: {} as RespondentsList,
};

export const showReducer = (state: RespondentsListShowState = initialState, action: RespondentsListAction) => {
    switch (action.type) {
        case RespondentsListActionType.RESPONDENTS_LIST_SHOW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_SHOW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                list: action.respondentsList,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_SHOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
