import React, { useEffect, useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { RespondentsList as Values } from '../../../modules/respondentsList/constants';
import { useFormik } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    RespondentsList,
    respondentsListRespondentsShowClearState,
    respondentsListRespondentsShowRequest,
    selectRespondentsListRespondents,
    selectRespondentsListRespondentsHeaders,
    selectRespondentsListRespondentsTotal,
    respondentsListShowRequest,
    // selectRespondentsListShowList,
    respondentsListShowAwait,
    selectRespondentsListRespondentsLoading,
    selectRespondentsListRespondentsSuccess,
    selectRespondentsListCreateLoading,
    respondentsListDeleteRequest,
    selectRespondentsListDeleteLoading, selectRespondentsListUpdateLoading
} from '../../../modules/respondentsList';
import { RespondentsSearchComponent } from '../../RespondentsSearchComponent';
import { FilterSet } from '../../../containers';
import { useDispatch, useSelector } from 'react-redux';
import {
    Respondent,
    respondentsListRequest,
    selectHeaders,
    selectRespondents,
    selectRespondentsRequested,
    selectTotal,
    filterValuesSet,
    respondentUpdateRlFieldsRequest,
    selectFilterValues
} from '../../../modules';
import { ListComponent } from '../../ListComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EditOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        listItem: {
            color: '#005001',
            '& span': {
                fontSize: theme.typography.pxToRem(17),
            },
        },
        button: {
            margin: 5,
            padding: 3,
            backgroundColor: '#f5f5f5',
            borderRadius: '15%',
            '&:hover': {
                borderRadius: '15%',
                padding: 3,
            },
        },
        buttonRespondentList: {
            margin: 3,
            padding: 0,
            color: '#fff',
            backgroundColor: '#5680F9',
            borderRadius: '15%',
            '&:hover': {
                borderRadius: '15%',
                padding: 0,
            },
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        paper: {
            padding: theme.spacing(5),
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        iconButton: {
            padding: 10,
        },
        typography: {
            color: '#124c95',
        },
        inputLabel: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        actionButton: {
            display: 'block',
            color: '#BDBDBD',
            cursor: 'pointer',
        },
        rlName: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(27.8),
            fontWeight: 600,
        },
    })
);

const initialValues: Values = {
    _id: '',
    name: '',
    respondents_list_project: '',
    filter: [],
};

interface ProjectRespondentsListProps {
    list?: RespondentsList[];
    projectCreated: boolean;
    onSubmit: (values: Values) => void;
}

export const ProjectRespondentListComponent: React.FC<ProjectRespondentsListProps> =
    (props: ProjectRespondentsListProps) => {
        const classes = useStyles();
        const dispatch = useDispatch();

        const filterValues = useSelector(selectFilterValues);
        const respondents = useSelector(selectRespondents);
        const respondentsListRespondents = useSelector(selectRespondentsListRespondents);
        const respondentsListRespondentsLoading = useSelector(selectRespondentsListRespondentsLoading);
        const respondentsListRespondentsSuccess = useSelector(selectRespondentsListRespondentsSuccess);
        const respondentsListRespondentsTotal = useSelector(selectRespondentsListRespondentsTotal);
        const respondentsListCreateLoading = useSelector(selectRespondentsListCreateLoading);
        const respondentsListUpdateLoading = useSelector(selectRespondentsListUpdateLoading);
        const respondentsListDeleteLoading = useSelector(selectRespondentsListDeleteLoading);
        const respondentsListRespondentsHeaders = useSelector(selectRespondentsListRespondentsHeaders);
        const respondentsLoading = useSelector(selectRespondentsRequested);
        const total = useSelector(selectTotal);
        const headers = useSelector(selectHeaders);
        // const respondentsListShowList = useSelector(selectRespondentsListShowList);

        const getInitialFormData = () =>
            ({f_id: (0 | Math.random() * 9e6).toString(36), type: false});

        const [enterListName, setEnterListName] = useState<boolean>(true);
        const [respondentsListName, setRespondentsListName] = useState<string>('');
        const [currentListId, setCurrentListId] = useState<string>('');
        const [mode, setMode] = useState<'result' | 'show' | 'search'>('search');
        const [deleteKey, setDeleteKey] = useState('');
        const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);

        const [formData, setFormData] = useState<any>([getInitialFormData()]);

        const {list, projectCreated, onSubmit} = props;


        const handleShowRespondentsList = (id: string, name: string) => {
            setCurrentListId(id);
            setEnterListName(false);
            setSearchBtnDisabled(false);
            setRespondentsListName(name);
            setMode('show');
            dispatch(respondentsListRespondentsShowRequest(id, 0));
        };

        useEffect(() => {
            setFormData(filterValues);
        }, [filterValues]);

        useEffect(() => {
            setFormData([getInitialFormData()]);
            setRespondentsListName('');
            setMode('search');
        }, [projectCreated]);

        const handleEditRespondentsList = (id: string, name: string) => {
            setCurrentListId(id);
            setEnterListName(false);
            setSearchBtnDisabled(false);
            setRespondentsListName(name);
            formik.setFieldValue('name', name);
            setFormData([getInitialFormData()]);
            setMode('search');
            dispatch(respondentsListRespondentsShowClearState());
            dispatch(respondentsListShowRequest(id));
            dispatch(respondentsListShowAwait());
        };

        const handleExportRespondentsList = (id: string) => {
            // console.log(id);
            // dispatch(respondentsListRespondentsShowRequest(id, 0));
        };

        const handleDeleteRespondentsList = (id: string) => {
            setDeleteKey(id);
            dispatch(respondentsListDeleteRequest(id));
        };

        const setFilterValuesAll = (values: Respondent, index: number) => {
            formData[index] = values;
        };

        const addForm = () => {
            const newForm = [...formData];
            newForm.push(getInitialFormData());
            setFormData(newForm)
        };

        const showFormValues = () => {
            setMode('result');
            dispatch(filterValuesSet(formData));
            dispatch(respondentsListRequest(formData));
        };

        const onSubmitForm = (values: Values) => {
            values.filter = formData;
            values._id = currentListId;
            onSubmit(values);
            setEnterListName(false);
            setSearchBtnDisabled(false);
            setRespondentsListName('');
        };

        const formik = useFormik({
            initialValues,
            onSubmit: onSubmitForm,
        });

        const handleClick = () => {
            formik.setFieldValue('name', '');
            setRespondentsListName('');
        };

        const handleEnterRespondentsListName = () => {
            if (formik.values.name === '') {
                return false;
            }
            setMode('search');
            dispatch(respondentsListRespondentsShowClearState());
            setRespondentsListName(formik.values.name);
            setEnterListName(false);
            setSearchBtnDisabled(false);
        };

        const handleCreateRespondentsListClick = () => {
            setMode('search');
            setEnterListName(true);
            setSearchBtnDisabled(true);
            setCurrentListId('');
            dispatch(respondentsListRespondentsShowClearState());
            setRespondentsListName('');
        };

        const handleEditRespondentsListName = () => {
            setMode('search');
            formik.setFieldValue('name', respondentsListName);
            setEnterListName(true);
            setSearchBtnDisabled(true);
            dispatch(respondentsListRespondentsShowClearState());
        };

        const pageChange = (query: FilterSet, skip: number) => (
            dispatch(respondentsListRequest(filterValues, skip))
        );

        const respondentsListShowPageChange = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => (
            dispatch(respondentsListRespondentsShowRequest(currentListId, skip))
        );

        const onChange = (e: React.ChangeEvent<{ name?: any; value: unknown; }>, id: string, respondent: Respondent, page: number) => {
            const skip = page * 100;
            dispatch(respondentUpdateRlFieldsRequest(id, {'type': e.target.name, 'value': e.target.value}, currentListId, skip))
        };

        return (
            <>
                {(respondentsListName && !enterListName) ? (
                    <Box width={1} display={'flex'} justifyContent={'center'}>
                        <Typography className={classes.rlName}>{respondentsListName}</Typography>
                        <Box
                            display={'inline-block'}
                            ml={1}
                        >
                            <EditOutlined
                                className={classes.actionButton}
                                fontSize={'small'}
                                onClick={() => handleEditRespondentsListName()}
                            />
                            <Typography
                                variant={'caption'}
                                className={classes.actionButton}>Edit</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography className={classes.rlName}>Step 2: Define Respondents list</Typography>
                )}

                {projectCreated && mode === 'search' && enterListName && (
                    <Grid item={true} xs={12} sm={6}>
                        <Box mr={0.5} className={classes.inputLabel}>
                            <Typography style={{color: '#4d4d4d'}}>
                                Respondents List Name
                            </Typography>
                            <TextField
                                style={{maxWidth: 550}}
                                id="outlined-basic"
                                placeholder="Respondents List Name"
                                {...formik.getFieldProps('name')}
                            />
                        </Box>
                        <Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                            <IconButton onClick={handleEnterRespondentsListName}
                                        className={classes.buttonRespondentList}
                                        edge="end"
                                        aria-label="edit">
                                <CheckIcon/>
                            </IconButton>
                            <IconButton onClick={handleClick}
                                        className={classes.buttonRespondentList}
                                        edge="end"
                                        aria-label="clear">
                                <ClearIcon/>
                            </IconButton>
                        </Box>
                    </Grid>
                )}
                <Paper className={classes.paper} variant="outlined">
                    {!projectCreated && (
                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            You should save Project first
                        </Alert>
                    )}
                    {mode === 'result' && total >= 5000 && (
                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            Please further refine your recruiting list or create a second respondent list. Matching respondents for your current search exceeds 5000.
                        </Alert>
                    )}
                    {list && (
                        <Grid item={true} xs={12} sm={4}>
                            <List>
                                {list.map((item: RespondentsList, key: number) => {
                                        const labelId = `checkbox-list-label-${key}`;
                                        return (
                                            <ListItem key={item._id} role={undefined} dense={true}>
                                                <ListItemText className={classes.listItem} id={labelId}
                                                              primary={`${key + 1}. ${item.name}`}/>
                                                <ListItemSecondaryAction>
                                                    <IconButton className={classes.button} edge="end"
                                                                onClick={() => handleShowRespondentsList(item._id, item.name)}
                                                                aria-label="view">
                                                        <VisibilityOutlinedIcon/>
                                                    </IconButton>
                                                    <IconButton className={classes.button} edge="end"
                                                                onClick={() => handleEditRespondentsList(item._id, item.name)}
                                                                aria-label="edit">
                                                        <CreateOutlinedIcon/>
                                                    </IconButton>
                                                    {/*<IconButton className={classes.button} edge="end"*/}
                                                    {/*            onClick={() => handleExportRespondentsList(item._id)}*/}
                                                    {/*            aria-label="export">*/}
                                                    {/*    <ExitToAppIcon/>*/}
                                                    {/*</IconButton>*/}
                                                    <IconButton className={classes.button} edge="end"
                                                                onClick={() => handleDeleteRespondentsList(item._id)}
                                                                aria-label="delete">
                                                        {respondentsListDeleteLoading && deleteKey === item._id ? (
                                                            <CircularProgress size={27}/>
                                                        ) : (
                                                            <DeleteOutlineIcon/>
                                                        )}
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    }
                                )}
                            </List>
                        </Grid>
                    )}

                    {projectCreated && (
                        <>
                            {mode === 'search' && formData.length && (
                                formData.map((form: any, index: number) => (
                                    <Grid container={true} key={form.f_id} style={{marginBottom: 40}}>
                                        <RespondentsSearchComponent
                                            formData={formData}
                                            formValues={form}
                                            index={index}
                                            addFrom={addForm}
                                            onSubmit={() => {
                                                showFormValues()
                                            }}
                                            setFilterValues={(values: Respondent) => setFilterValuesAll(values, index)}
                                            searchBtnDisabled={searchBtnDisabled}
                                        />
                                    </Grid>
                                ))
                            )}

                            {['result', 'show'].includes(mode) && !respondentsLoading && !respondentsListRespondentsLoading && (
                                <Box display={'flex'} justifyContent={'flex-end'}>
                                    <Typography
                                        onClick={handleCreateRespondentsListClick}
                                        className={classes.typography}>
                                        +Create Respondents List
                                    </Typography>
                                </Box>
                            )}

                            {(mode === 'result' && respondents) && (
                                respondentsLoading ? <LinearProgress/> : (
                                    <>
                                        <ListComponent
                                            rows={respondents.map(item => ({...item, id: item._id.$oid}))}
                                            query={filterValues}
                                            total={total}
                                            listOf={'Respondent'}
                                            returnLink="/search"
                                            pageChange={pageChange}
                                            headers={headers}
                                            hideHeadings={['Contact', 'Status', 'Notes']}
                                            targetBlank={true}
                                        />
                                        {mode === 'result' && total >= 5000 && (
                                            <Alert severity="warning">
                                                <AlertTitle>Warning</AlertTitle>
                                                Please further refine your recruiting list or create a second respondent list.
                                                Matching respondents for your current search exceeds 5000.
                                            </Alert>
                                        )}
                                        {!respondentsListRespondentsSuccess && (
                                            <Box mt={5} mx={1.5} display={'flex'} justifyContent={'flex-end'}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    size={'large'}
                                                    color="primary"
                                                    disabled={(total >= 5000 || respondentsListCreateLoading || respondentsListUpdateLoading)}
                                                    onClick={() => formik.handleSubmit()}
                                                >
                                                    {(respondentsListCreateLoading || respondentsListUpdateLoading) ?
                                                        (
                                                            <CircularProgress size={24}/>
                                                        )
                                                        : (
                                                            <Typography>
                                                                Save Respondents List
                                                            </Typography>
                                                        )}
                                                </Button>
                                            </Box>
                                        )}
                                    </>
                                )
                            )}
                            {(mode === 'show' && respondentsListRespondents) && (
                                <ListComponent
                                    rows={respondentsListRespondents.map(item => ({...item, id: item._id}))}
                                    query={filterValues}
                                    loading={respondentsListRespondentsLoading}
                                    total={respondentsListRespondentsTotal}
                                    returnLink="/search"
                                    listOf={'Respondent'}
                                    hideHeadings={['Age', 'Ethnicity', 'City', 'State']}
                                    rowChange={onChange}
                                    pageChange={respondentsListShowPageChange}
                                    headers={respondentsListRespondentsHeaders}
                                    targetBlank={true}
                                />
                            )}
                        </>
                    )}
                </Paper>
            </>
        );
    };
