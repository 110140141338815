import React from 'react';
import { Accordion, Grid, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

interface FilterSetProps {
    children: React.ReactNode;
    title: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
    })
);

export const AccordionContainer: React.FC<FilterSetProps> = (props: FilterSetProps) => {
    const classes = useStyles();
    const {children, title} = props;

    return (
        <Grid item={true} xs={12} md={12}>
            <Accordion
                variant={'outlined'}
                TransitionProps={{mountOnEnter: true}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
