import React from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getPropTextValue } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';
import { CheckBoxGroupField } from '../../common/Form/CheckBoxGroupField';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import { selectDictionaryGroup } from '../../../modules/dictionaries';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Lifestyle: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    const tobaccoUsage = useSelector((state: AppState) => selectDictionaryGroup(state, 'tobacco_usage'));

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Alcohol
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'alcohol') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Tobacco Dip User
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.tobaco_deep_users }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Tobacco Usage
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.tobaco_usage }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Smoker
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.smoker }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Brand of Cigarettes
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'cigarette') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Length of Cigarettes
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'length_of_cigarettes') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Type of Cigarettes
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'type_of_cigarettes') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Gym Membership
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.gym_membership }
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
