import React, { ChangeEvent, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Button, DialogActions, DialogContent, Divider, FormControlLabel, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Dictionary } from '../../modules/dictionaries';

interface Props {
    open: boolean;
    fields: Dictionary[];
    exportFields: string[];
    handleClose: () => void;
    updateExportFields: (fields: string[]) => void;
}

const useStyles = makeStyles(theme =>
    ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        paper: {
            padding: theme.spacing(5),
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(24),
            fontWeight: 600,
        },
        exportButton: {
            border: '2px solid #5680f9',
        },
    }));

export const ExportFields: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {
        open,
        fields,
        exportFields = [],
        handleClose,
        updateExportFields,
    } = props;

    const [userExportFields, setUserExportFields] = useState(exportFields);

    const handleInputChange = (name: string, checked: boolean) => {
        const fields = [...userExportFields];
        if (checked) {
            fields.push(name);
        } else {
            const index = fields.indexOf(name);
            fields.splice(index, 1);
        }
        setUserExportFields(fields);
    };

    const handleUpdateExportFields = () => {
        updateExportFields(userExportFields);
        handleClose();
    };

    const isChecked = (name: string) => {
        if (userExportFields) {
            return userExportFields.find((field: string) => field === name) !== undefined;
        }
        return false;
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'xl'}
            >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mr={4}>
                    <DialogTitle id="duplicates-dialog-title">
                        <Typography
                            className={classes.heading}
                        >Select Fields to Export</Typography>
                    </DialogTitle>
                    <IconButton edge={'end'} size={'small'} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                {/*<DialogContent>*/}
                {/*    <FormControlLabel*/}
                {/*        control={(*/}
                {/*            <Checkbox*/}
                {/*                name={'all'}*/}
                {/*                // onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                {/*                //     handleInputChange(e.target.name, e.target.checked)*/}
                {/*                // }}*/}
                {/*            />*/}
                {/*        )}*/}
                {/*        label={'All'}/>*/}
                {/*</DialogContent>*/}
                {/*<Divider/>*/}
                <DialogContent>
                    <div className={classes.root}>
                        {fields && fields.map((field: Dictionary) => (
                            <FormControlLabel
                                key={field.value}
                                control={(
                                    <Checkbox
                                        checked={isChecked(field.value)}
                                        name={field.value}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            handleInputChange(e.target.name, e.target.checked)
                                        }}
                                    />
                                )}
                                label={field.text}/>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        // className={classes.modifyButton}
                        variant="contained"
                        size={'large'}
                        color="primary"
                        onClick={handleUpdateExportFields}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};
