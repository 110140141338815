import { FilterSet } from '../../containers';

export enum RespondentsListActionType {
    // RESPONDENTS_LIST_FAILURE = 'RESPONDENTS_LIST/FAILURE',
    // RESPONDENTS_LIST_REQUEST = 'RESPONDENTS_LIST/REQUEST',
    // RESPONDENTS_LIST_SUCCESS = 'RESPONDENTS_LIST/SUCCESS',
    //
    // RESPONDENTS_LIST_UPDATE_FAILURE = 'RESPONDENTS_LIST/UPDATE/FAILURE',
    // RESPONDENTS_LIST_UPDATE_REQUEST = 'RESPONDENTS_LIST/UPDATE/REQUEST',
    // RESPONDENTS_LIST_UPDATE_SUCCESS = 'RESPONDENTS_LIST/UPDATE/SUCCESS',

    RESPONDENTS_LIST_CREATE_FAILURE = 'RESPONDENTS_LIST/CREATE/FAILURE',
    RESPONDENTS_LIST_CREATE_REQUEST = 'RESPONDENTS_LIST/CREATE/REQUEST',
    RESPONDENTS_LIST_CREATE_SUCCESS = 'RESPONDENTS_LIST/CREATE/SUCCESS',

    RESPONDENTS_LIST_RESPONDENTS_SHOW_FAILURE = 'RESPONDENTS_LIST/RESPONDENTS/SHOW/FAILURE',
    RESPONDENTS_LIST_RESPONDENTS_SHOW_REQUEST = 'RESPONDENTS_LIST/RESPONDENTS/SHOW/REQUEST',
    RESPONDENTS_LIST_RESPONDENTS_SHOW_SUCCESS = 'RESPONDENTS_LIST/RESPONDENTS/SHOW/SUCCESS',
    RESPONDENTS_LIST_RESPONDENTS_SHOW_RESET_STATE = 'RESPONDENTS_LIST/RESPONDENTS/SHOW/RESET/STATE',

    RESPONDENTS_LIST_SHOW_FAILURE = 'RESPONDENTS_LIST/SHOW/FAILURE',
    RESPONDENTS_LIST_SHOW_REQUEST = 'RESPONDENTS_LIST/SHOW/REQUEST',

    RESPONDENTS_LIST_SHOW_SUCCESS = 'RESPONDENTS_LIST/SHOW/SUCCESS',

    RESPONDENTS_LIST_SHOW_AWAIT = 'RESPONDENTS_LIST/SHOW/AWAIT',

    RESPONDENTS_LIST_UPDATE_FAILURE = 'RESPONDENTS_LIST/UPDATE/FAILURE',
    RESPONDENTS_LIST_UPDATE_REQUEST = 'RESPONDENTS_LIST/UPDATE/REQUEST',
    RESPONDENTS_LIST_UPDATE_SUCCESS = 'RESPONDENTS_LIST/UPDATE/SUCCESS',

    RESPONDENTS_LIST_DELETE_FAILURE = 'RESPONDENTS_LIST/DELETE/FAILURE',
    RESPONDENTS_LIST_DELETE_REQUEST = 'RESPONDENTS_LIST/DELETE/REQUEST',
    RESPONDENTS_LIST_DELETE_SUCCESS = 'RESPONDENTS_LIST/DELETE/SUCCESS',

    RESPONDENTS_LIST_LIST_FAILURE = 'RESPONDENTS_LIST/LIST/FAILURE',
    RESPONDENTS_LIST_LIST_REQUEST = 'RESPONDENTS_LIST/LIST/REQUEST',
    RESPONDENTS_LIST_LIST_SUCCESS = 'RESPONDENTS_LIST/LIST/SUCCESS',
    RESPONDENTS_LIST_LIST_RESET_STATE = 'RESPONDENTS_LIST/LIST/RESET/STATE',
}

export interface RespondentsList {
    _id: string,
    name: string,
    respondents_list_project?: string,
    filter: FilterSet,
}

