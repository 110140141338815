import { AppState } from '../';
import { User } from './reducers/user';
import { ListComponentHeader } from '../../interfaces';
import { ApiErrors } from '../../helpers/api-errors';

export const selectUser = (state: AppState): User => state.user.user.user;
export const selectUserSigned = (state: AppState): boolean => state.user.user.signed;
export const selectUserError = (state: AppState): boolean => state.user.user.error;

// Users
export const selectUsersActive = (state: AppState): User[] => state.user.users.active;
export const selectUsersArchived = (state: AppState): User[] => state.user.users.archived;
export const selectUsersHeaders = (state: AppState): ListComponentHeader[] => state.user.users.headers;
export const selectUsersTotal = (state: AppState): number => state.user.users.total;
export const selectUsersArchivedTotal = (state: AppState): number => state.user.users.archivedTotal;
export const selectUsersLoading = (state: AppState): boolean => state.user.users.loading;
// User Show
export const selectUserShowLoading = (state: AppState): boolean => state.user.show.loading;
export const selectUserShow = (state: AppState): User => state.user.show.user;
// User Update
export const selectUserUpdateLoading = (state: AppState): boolean => state.user.update.loading;
export const selectUserUpdateSuccess = (state: AppState): boolean => state.user.update.success;
export const selectUserUpdateError = (state: AppState): boolean => state.user.update.error;
export const selectUserUpdateErrors = (state: AppState): ApiErrors => state.user.update.errors;


// User Create
export const selectUserCreateUser = (state: AppState): User => state.user.create.user;
export const selectUserCreateLoading = (state: AppState): boolean => state.user.create.loading;
export const selectUserCreateSuccess = (state: AppState): boolean => state.user.create.success;
export const selectUserCreateError = (state: AppState): boolean => state.user.create.error;
export const selectUserCreateErrors = (state: AppState): ApiErrors => state.user.create.errors;

// Change Password
export const selectUserPasswordChangeRequested = (state: AppState): boolean => state.user.changePassword.loading;
export const selectUserPasswordChangeSuccess = (state: AppState): string => state.user.changePassword.message;
export const selectUserPasswordChangeFailure = (state: AppState): string => state.user.changePassword.error;


