import { RespondentDuplicateSearchAction } from '../actions';
import { Respondent, RespondentActionType } from '../constants';
import { ListComponentHeader } from '../../../interfaces';

export interface RespondentDuplicateSearchState {
    loading: boolean;
    success: boolean;
    error: boolean;
    duplicates: Respondent[];
    headers: ListComponentHeader[];
}

const initialState: RespondentDuplicateSearchState = {
    loading: false,
    success: false,
    error: false,
    duplicates: [],
    headers: [],
};

export const duplicatesReducer =
    (state: RespondentDuplicateSearchState = initialState, action: RespondentDuplicateSearchAction) => {
        switch (action.type) {
            case RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: false,
                    duplicates: [],
                };
            case RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    success: true,
                    duplicates: action.duplicates,
                    headers: action.headers,
                };
            case RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: true,
                };
            case RespondentActionType.RESPONDENT_DUPLICATE_RESET_STATE:
                return {
                    ...state,
                    ...initialState,
                };
            default:
                return state;
        }
    };
