import { RespondentsListAction } from '../actions';
import { RespondentsList, RespondentsListActionType } from '../constants';
import { Respondent } from '../../respondents';
import { ListComponentHeader } from '../../../interfaces';

export interface RespondentsListRespondentsState {
    loading: boolean;
    success: boolean;
    error: boolean;
    list: RespondentsList[];
    respondents: Respondent[];
    total: number;
    headers: ListComponentHeader[];
}

const initialState: RespondentsListRespondentsState = {
    loading: false,
    success: false,
    error: false,
    list: [],
    respondents: [],
    total: 0,
    headers: [],
};

export const respondentsListRespondentsReducer =
    (state: RespondentsListRespondentsState = initialState, action: RespondentsListAction) => {
    switch (action.type) {
        case RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_REQUEST:
            return {
                ...state,
                loading: true,
                total: 0,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_CREATE_SUCCESS:
        case RespondentsListActionType.RESPONDENTS_LIST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                list: action.list,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                respondents: action.respondents,
                total: action.total,
                headers: action.headers,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_RESET_STATE:
            return {
                ...state,
                respondents: [],
                loading: false,
                success: false,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_LIST_RESET_STATE:
            return {
                ...state,
                list: [],
            };
        default:
            return state;
    }
};
