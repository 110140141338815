import React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Respondent } from '../../modules';
import { RespondentEditComponent } from '../RespondentEditComponent';
import { FilterSet } from '../../containers/RespondentsSearchContainer';

export interface RespondentsSearchComponentProps {
    addFrom: () => void;
    onSubmit: () => void;
    setFilterValues: (values: Respondent) => void;
    formData: any;
    formValues?: FilterSet;
    index: any;
    searchBtnDisabled?: boolean;
}

export const RespondentsSearchComponent: React.FC<RespondentsSearchComponentProps> =
    (props: RespondentsSearchComponentProps) => {

        const respondent: Respondent = {type: 'false'};

        return (
            <>
                <Box display={'flex'} flexGrow={1} mt={2} mx={1.5} style={{marginTop: 30}}>
                    <RespondentEditComponent
                        mode={'search'}
                        searchBtnDisabled={props.searchBtnDisabled}
                        respondent={props.formValues || respondent}
                        onSubmit={() => {
                            props.onSubmit()
                        }}
                        setFilterValues={props.setFilterValues}
                    />
                </Box>

                <Box mt={2} mx={1.5} width={1} display={'flex'} justifyContent={'flex-end'} style={{marginTop: 30}}>
                    {
                        props.formData.length === (props.index + 1) && (
                            <Button
                                type="submit"
                                variant="contained"
                                size={'large'}
                                color="primary"
                                onClick={() => props.addFrom()}
                                disabled={props.searchBtnDisabled}
                                style={{marginRight: 20}}
                            >
                                <Typography>
                                    And
                                </Typography>
                            </Button>
                        )

                    }

                    <Button
                        type="submit"
                        variant="contained"
                        size={'large'}
                        color="primary"
                        disabled={props.searchBtnDisabled}
                        onClick={() => props.onSubmit()}
                    >
                        <Typography>
                            Search
                        </Typography>
                    </Button>

                </Box>

            </>
        )
    };
