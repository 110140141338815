import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, TextField, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectSignInLoading, selectSignInFailure } from '../../modules/auth';
import Alert from '@material-ui/lab/Alert';

export interface LoginComponentProps {
    onSubmit: (
        email: string,
        password: string
    ) => void;
}

const useStyles = makeStyles(theme =>
    ({
        button: {
            backgroundColor: '#0000fe',
            margin: theme.spacing(3, 0, 2),
            textTransform: 'none',
            minWidth: 150,
        },
    }));

export const LoginComponent: React.FC<LoginComponentProps> = (props: LoginComponentProps) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const loading = useSelector(selectSignInLoading);
    const errors = useSelector(selectSignInFailure);
    const classes = useStyles();
    const onSubmit = () => props.onSubmit(
        email,
        password
    );

    return (
        <>
            {
                errors.message && (
                    <Alert severity={'error'}>
                        {errors.message}
                    </Alert>
                )
            }
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required={true}
                    helperText={errors.email}
                    id="email"
                    label="Email Address"
                    name="email"
                    size="small"
                    autoComplete="email"
                    autoFocus={true}
                    defaultValue={email}
                    error={!!errors.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    helperText={errors.password}
                    required={true}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={!!errors.password}
                    defaultValue={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}

                />

                <NavLink to="/password_reset">
                    <Typography variant={'subtitle2'} align={'right'}>Forgot password?</Typography>
                </NavLink>

                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={onSubmit}
                    className={classes.button}
                    disabled={loading}
                >
                    {loading && <CircularProgress size={24}/>}
                    {
                        !loading && (
                            <Typography variant={'button'}>
                                Sign In
                            </Typography>
                        )
                    }
                </Fab>

            </Box>
        </>
    );
};
