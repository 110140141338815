import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUserSigned,
    userChangePasswordRequest,
    UserChangePasswordRequestPayload,
    selectUser,
    selectUserPasswordChangeRequested, selectUserPasswordChangeSuccess, selectUserPasswordChangeFailure,
} from '../../../modules/user';
import { UserComponent } from '../../../components/User/UserComponent';
import { resetPasswordRequest } from '../../../modules/resetPassword';

export const UserContainer: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const user = useSelector(selectUser);
    const signed = useSelector(selectUserSigned);
    const loading = useSelector(selectUserPasswordChangeRequested);
    const message = useSelector(selectUserPasswordChangeSuccess);
    const error = useSelector(selectUserPasswordChangeFailure);
    const dispatch = useDispatch();

    const onSubmit = (payload: UserChangePasswordRequestPayload) => {
        dispatch(userChangePasswordRequest(payload))
    };

    const onPasswordResetSubmit = (email: string) => {
        dispatch(resetPasswordRequest(email));
    };

    useEffect(() => {
        setShow(true);

        const timeId = setTimeout(() => {
            setShow(false)
        }, 3000);

        return () => {
            clearTimeout(timeId)
        }
    }, [
        message, error,
    ]);

    return (
        <UserComponent
            user={user}
            signed={signed}
            loading={loading}
            message={message}
            error={error}
            show={show}
            onSubmit={onSubmit}
            onResetPasswordSubmit={onPasswordResetSubmit}
        />
    );
};
