import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, Theme } from '@material-ui/core';

interface RadioGroupEditFieldProps {
    values?: any;
    inputProps?: any;
    unknown?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const RadioGroupEditField: React.FC<RadioGroupEditFieldProps> = (props: RadioGroupEditFieldProps) => {
    const classes = useStyles();
    const { values = {'true' : 'Yes', 'false': 'No'}, unknown = true, inputProps} = props;

    return (
        <>
            {
                (
                    <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup
                            row={ true }
                            aria-label="position"
                            defaultValue="false"
                            {...inputProps}
                        >
                            {
                                Object.keys(values).map((key: any) => (
                                    <FormControlLabel
                                        key={key}
                                        value={ key }
                                        control={ <Radio color="primary"/> }
                                        label={values[key]}
                                        labelPlacement="start"
                                    />
                                ))
                            }
                            {
                                unknown && (
                                    <FormControlLabel
                                        key={ '' }
                                        value={ '' }
                                        control={ <Radio color="primary"/> }
                                        label={ 'Unknown' }
                                        labelPlacement="start"
                                    />
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                )
            }
        </>
    );
};
