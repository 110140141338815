import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Respondent,
    respondentsShowRequest,
    respondentUpdateRequest,
    selectRespondent,
    selectRespondentRequested,
    respondentUploadIdRequest,
    respondentUploadIdSuccess
} from '../../modules';
import { useParams } from 'react-router-dom';
import { RespondentEditComponent } from '../../components/RespondentEditComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { clearEmptyArraysValues } from '../../helpers/formHelper';


export const RespondentEditContainer: React.FC = () => {
    const dispatch = useDispatch();
    const respondent = useSelector(selectRespondent);
    const loading = useSelector(selectRespondentRequested);

    // @ts-ignore
    const {id} = useParams();

    const onSubmit = (values: Respondent, uploadFile: boolean) => {

        values = clearEmptyArraysValues(values);

        dispatch(respondentUpdateRequest(id, values));

        if (uploadFile) {
            dispatch(respondentUploadIdRequest(id, values.files));
        } else {
            dispatch(respondentUploadIdSuccess());
        }
    };

    useEffect(
        () => {
            dispatch(respondentsShowRequest(id));
            // tslint:disable-next-line:align
        }, []
    );

    return (
        <>
            {
                loading ? <LinearProgress/> : (
                    <RespondentEditComponent
                        mode={'edit'}
                        respondent={respondent}
                        onSubmit={onSubmit}
                        hideSearchByKeyword={true}
                    />
                )
            }
        </>
    );
};
