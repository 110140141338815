import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Project } from '../../../modules/projects';
import { ListComponent } from '../../ListComponent';
import {
    AppState,
    Respondent,
    respondentJoinProjectRequest,
    selectRespondentJoinProjectError, selectRespondentJoinProjectLoading,
    selectRespondentJoinProjectMessage, selectRespondentJoinProjectRlRevision, selectRespondentJoinProjectSuccess
} from '../../../modules';
import { FilterSet } from '../../../containers';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { toggleSnackbarOpen } from '../../../modules/notifications';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    projects: Project[];
    respondent: Respondent;
}

export const RespondentAttendanceComponent: React.FC<Props> = (props: Props) => {
    const {projects, respondent} = props;
    const respondentJoinProjectError = useSelector(selectRespondentJoinProjectError);
    const respondentJoinProjectSuccess = useSelector(selectRespondentJoinProjectSuccess);
    const respondentJoinProjectMessage = useSelector(selectRespondentJoinProjectMessage);
    const respondentJoinProjectLoading = useSelector(selectRespondentJoinProjectLoading);
    const respondentJoinProjectRlRevision = useSelector(selectRespondentJoinProjectRlRevision);

    const dispatch = useDispatch();

    const [projectId, setProjectId] = useState('');
    const [status, setStatus] = useState('');
    const [projectSelected, setProjectSelected] = useState(false);
    const [pushRlStatus, setPushRlStatus] = useState(false);

    const respondentStatus = useSelector((state: AppState) => selectDictionaryGroup(state, 'respondent_rl_status_column'));

    useEffect(() => {
        if (respondentJoinProjectError) {
            dispatch(toggleSnackbarOpen(respondentJoinProjectMessage, 'error'));
        }
    }, [respondentJoinProjectError]);

    useEffect(() => {
        if (respondentJoinProjectSuccess) {
            dispatch(toggleSnackbarOpen(respondentJoinProjectMessage, 'success'));
            setPushRlStatus(true)

            const timer = setTimeout(() => {
                setPushRlStatus(false);
            }, 1000);
            respondent.history = [respondentJoinProjectRlRevision, ...respondent.history]

            return () => clearTimeout(timer);
        }
    }, [respondentJoinProjectSuccess]);

    const setProject = (name: string, value: string) => {
        setProjectSelected(true);
        setProjectId(value);
    };

    const handleJoinProject = () => {
        dispatch(respondentJoinProjectRequest(projectId, status, respondent._id));
    };

    // tslint:disable-next-line:no-empty
    const pageChange = (query: FilterSet, skip: number) => {
    };

    return (
        <div style={{width: '100%'}}>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                    <FormControl size={'small'} variant="filled">
                        <InputLabel id="respondent-add-project-label">Select Project</InputLabel>
                        <Select
                            labelId="respondent-add-project-label"
                            id="respondent-add-project"
                            IconComponent={KeyboardArrowDownIcon}
                            value={projectId}
                            onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
                                setProject(e.target.name as string, e.target.value as string)
                            }}
                        >
                            {
                                projects.map((project: Project, index: number) => (
                                    <MenuItem key={index}
                                              value={project._id}>{project.display_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                    <FormControl size={'small'} variant="filled">
                        <InputLabel id="respondent-add-project-status-label">Select Status</InputLabel>
                        <Select
                            labelId="respondent-add-project-status-label"
                            id="respondent-add-project-status"
                            disabled={!projectSelected}
                            value={status}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
                                setStatus(e.target.value as string)
                            }}
                        >
                            {
                                respondentStatus.map((item: Dictionary, index: number) => (
                                    <MenuItem key={index}
                                              value={item.value}>{item.text}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                    <Button
                        size={'large'}
                        variant="contained"
                        disabled={!projectSelected || respondentJoinProjectLoading}
                        onClick={handleJoinProject}
                        color={'primary'}
                    >
                        {respondentJoinProjectLoading ? (
                            <CircularProgress size={24}/>
                        ) : (
                            <Typography>
                                Save
                            </Typography>
                        )}
                    </Button>
                </Grid>
            </Grid>
            <ListComponent
                class={'bordered'}
                rows={respondent.history}
                query={{}}
                total={respondent.history.length}
                listOf={'Respondent'}
                showActions={false}
                showPagination={false}
                headBackgroundColor={'#edf2ff'}
                headTextColor={'#5680f9'}
                showTotal={false}
                loading={pushRlStatus}
                pageChange={pageChange}
                headers={[
                    {
                        name: 'project_name_text',
                        label: 'Project Name',
                    },
                    {
                        name: 'project_number_text',
                        label: 'Project #',
                    },
                    {
                        name: 'project_company_text',
                        label: 'Company Name',
                    },
                    {
                        name: 'status_text',
                        label: 'Booked Status',
                    },
                    {
                        name: 'status_time_text',
                        label: 'Status Time',
                    },
                    {
                        name: 'project_fielding_date_text',
                        label: 'Fielding Date',
                    },
                ]}
            />
        </div>
    );
};
