import { take, put } from 'redux-saga/effects';
import {
    UsersRefresh,
    usersRequest,
} from '../actions';
import { UserActionType } from '../constants';

export default function* usersRefreshSaga(action: UsersRefresh) {
    yield take([UserActionType.USER_UPDATE_SUCCESS, UserActionType.USER_DELETE_SUCCESS]);
    yield put(usersRequest());
}
