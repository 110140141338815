import { User } from '../user/reducers/user';

export enum CommentsActionType {
    COMMENTS_UPDATE_FAILURE = 'COMMENTS/UPDATE/FAILURE',
    COMMENTS_UPDATE_REQUEST = 'COMMENTS/UPDATE/REQUEST',
    COMMENTS_UPDATE_SUCCESS = 'COMMENTS/UPDATE/SUCCESS',

    COMMENTS_CREATE_FAILURE = 'COMMENTS/CREATE/FAILURE',
    COMMENTS_CREATE_REQUEST = 'COMMENTS/CREATE/REQUEST',
    COMMENTS_CREATE_SUCCESS = 'COMMENTS/CREATE/SUCCESS',

    COMMENTS_DELETE_FAILURE = 'COMMENTS/DELETE/FAILURE',
    COMMENTS_DELETE_REQUEST = 'COMMENTS/DELETE/REQUEST',
    COMMENTS_DELETE_SUCCESS = 'COMMENTS/DELETE/SUCCESS',

    COMMENTS_LIST_FAILURE = 'COMMENTS/LIST/FAILURE',
    COMMENTS_LIST_REQUEST = 'COMMENTS/LIST/REQUEST',
    COMMENTS_LIST_SUCCESS = 'COMMENTS/LIST/SUCCESS',
}

export interface Comment {
    _id: string,
    text: string,
    created_at: string
    user: User,
}
