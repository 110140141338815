import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RespondentComponent } from '../../components';
import {
    selectRespondent,
    respondentsShowRequest,
    selectRespondentRequested,
    AppState,
    selectRespondentFailed, respondentsJoinProjectResetState
} from '../../modules';
import { useParams } from 'react-router-dom';
import { selectDictionaryGroup } from '../../modules/dictionaries';
import { LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export const RespondentContainer: React.FC = () => {
    const dispatch = useDispatch();
    const respondent = useSelector(selectRespondent);
    const loading = useSelector(selectRespondentRequested);
    const failed = useSelector(selectRespondentFailed);

    const dictionaries = useSelector((state: AppState) => selectDictionaryGroup(state, 'medical'));

    // @ts-ignore
    const {id} = useParams();

    useEffect(
        () => {
            dispatch(respondentsJoinProjectResetState())
            dispatch(respondentsShowRequest(id));
        },
        []
    );

    return (
        <>
            {loading ? (
                <LinearProgress/>
            ) : (
                <>
                {failed ? (
                    <Alert severity={'error'}>
                        Cannot load respondent
                    </Alert>
                ) : (
                    <RespondentComponent
                        respondent={respondent}
                        dictionaries={dictionaries}
                    />
                )}
                </>
            )}
        </>
    );
};
