import React from 'react';
import {
    Box,
    createStyles, Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableRow,
    Theme
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { Project as Values } from '../../modules/projects';
import TextField from '@material-ui/core/TextField';
import { Can } from '../common/Can';

export interface ProjectShowComponentProps {
    project: Values;
    booked: string;
}

const projectTitles: string[] = [
    'Company Name', 'Project #', 'Topic/Keyword', 'x of x booked', 'Fielding Dates',
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: 5,
            padding: 3,
            backgroundColor: '#dfe7fe',
            borderRadius: '15%',
            '&:hover': {
                borderRadius: '15%',
                padding: 3,
            },
        },
        typography: {
            color: '#82a1fb',
            fontSize: 13,
        },
        tableDetails: {
             '& tbody': {
                 '& td': {
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
                     maxWidth: 300,
                     '& p': {
                         overflow: 'hidden',
                         textOverflow: 'ellipsis',
                     },
                 },
             },
         },
    })
);

export const ProjectShowComponent: React.FC<ProjectShowComponentProps> = (props: ProjectShowComponentProps) => {
    const classes = useStyles();

    const {project, booked} = props;

    return (
        <>
            <Can to={'project.update'}>
                <Box mt={-4} width={1} display="flex" justifyContent="flex-end">
                    <Link to={`/projects/edit/${project._id}`}>
                        <Box display="flex" alignItems={'center'} flexDirection={'column'}>
                            <IconButton
                                className={classes.button}
                                color={'primary'}
                                aria-label="edit">
                                <CreateOutlinedIcon/>
                            </IconButton>
                            <Typography className={classes.typography}>
                                Edit
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            </Can>
            {props.project && (
                <>
                    <TableContainer component={(props: TableContainerProps) => (<Paper {...props} elevation={0}/>)} className={classes.tableDetails}>
                        <Table aria-label="project table">
                            <TableHead style={{backgroundColor: '#ffe2e1'}}>
                                <TableRow>
                                    {
                                        projectTitles.map(
                                            (key: string) =>
                                                (
                                                    <TableCell key={key}>
                                                        {key}
                                                    </TableCell>
                                                )
                                        )
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <>
                                        <TableCell>
                                            <Typography>{project.project_company_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{project.project_number}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{project.topic}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{booked}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{project.fielding_date_text}</Typography>
                                        </TableCell>
                                    </>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box width={1} mt={5}>
                        <Grid item={true} xs={12} sm={12}>
                            <>
                                <Typography className={classes.typography}>
                                    Project Description/Specs
                                </Typography>
                                <TextField
                                    margin="none"
                                    value={project.project_description}
                                    fullWidth={true}
                                    aria-readonly={true}
                                    hiddenLabel={true}
                                />
                            </>
                        </Grid>
                    </Box>
                    <Box width={1} mt={5}>
                        <Grid item={true} xs={12} sm={12}>
                            <>
                                <Typography className={classes.typography}>
                                    Miscellaneous Costs/Incentives:
                                </Typography>

                                <TextField
                                    margin="none"
                                    value={project.project_incentives}
                                    fullWidth={true}
                                    aria-readonly={true}
                                    hiddenLabel={true}
                                />
                            </>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
};
