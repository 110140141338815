import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { dictionariesListFailure, DictionariesListRequest, dictionariesListSuccess } from '../actions';

function* RequestSaga(action: DictionariesListRequest) {
    try {
        const { data } = yield call(
            requestBuilder,
            `api/dictionaries/search`,
            METHOD_POST,
            {
                filter: action.filter,
                skip: action.skip,
                orderBy: action.orderBy,
                orderDirection: action.orderDirection,
            }
        );

        yield put(dictionariesListSuccess(data.data, data.total, data.headers));
    } catch (error) {
        yield put(dictionariesListFailure());
    }
}

export const DictionariesListRequestSaga = RequestSaga;
