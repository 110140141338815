import { Role, RolesAction, RolesActionType } from './constants';

export const rolesRequest = (): RolesAction =>
    ({
        type: RolesActionType.ROLES_REQUEST,
    });

export const rolesSuccess = (roles: Role[]): RolesAction =>
    ({
        type: RolesActionType.ROLES_SUCCESS,
        roles,
    });

export const rolesFailure = (error: string): RolesAction =>
    ({
        type: RolesActionType.ROLES_FAILURE,
        error,
    });
