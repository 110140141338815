import { ExportToFileDoneType } from './constants';

export interface ExportToFileDoneFailure {
    type: typeof ExportToFileDoneType.EXPORT_TO_FILE_DONE_FAILURE;
}

export interface ExportToFileDoneRequest {
    type: typeof ExportToFileDoneType.EXPORT_TO_FILE_DONE_REQUEST;
}

export interface ExportToFileDoneSuccess {
    type: typeof ExportToFileDoneType.EXPORT_TO_FILE_DONE_SUCCESS,
    link: string,
}

export type ExportToFileDoneAction = ExportToFileDoneFailure | ExportToFileDoneRequest | ExportToFileDoneSuccess;

export const exportToFileDoneFailure = (): ExportToFileDoneFailure => ({
    type: ExportToFileDoneType.EXPORT_TO_FILE_DONE_FAILURE,
});

export const exportToFileDoneRequest = (): ExportToFileDoneRequest => ({
    type: ExportToFileDoneType.EXPORT_TO_FILE_DONE_REQUEST,
});

export const exportToFileDoneSuccess = (link: string): ExportToFileDoneSuccess => ({
    type: ExportToFileDoneType.EXPORT_TO_FILE_DONE_SUCCESS,
    link,
});

export interface ExportToFileCheckDoneStart {
    type: typeof ExportToFileDoneType.EXPORT_TO_FILE_CHECK_DONE_START;
}

export interface ExportToFileCheckDoneStop {
    type: typeof ExportToFileDoneType.EXPORT_TO_FILE_CHECK_DONE_STOP;
}

export type ExportToFileCheckDoneAction = ExportToFileCheckDoneStart | ExportToFileCheckDoneStop;

export const exportToFileCheckDoneStart = (): ExportToFileCheckDoneStart => ({
    type: ExportToFileDoneType.EXPORT_TO_FILE_CHECK_DONE_START,
});

export const exportToFileCheckDoneStop = (): ExportToFileCheckDoneStop => ({
    type: ExportToFileDoneType.EXPORT_TO_FILE_CHECK_DONE_STOP,
});
