import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListComponent } from '../../components';
import {
    selectFilterValues,
    exportToFileRequest
} from '../../modules';
import { FilterSet } from '../RespondentsSearchContainer';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
    projectsListRequest,
    selectProjectsList,
    selectProjectsListHeaders, selectProjectsListLoading,
    selectProjectsTotal
} from '../../modules/projects';
import { selectExportLoading } from '../../modules/export/selectors';

const useStyles = makeStyles(theme =>
    ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(28),
            fontWeight: 600,
        },
    }));

export const ProjectsListContainer: React.FC = () => {
    const dispatch = useDispatch();
    const projects = useSelector(selectProjectsList);
    const loading = useSelector(selectProjectsListLoading);
    const exportLoading = useSelector(selectExportLoading);
    const total = useSelector(selectProjectsTotal);
    const filterValues = useSelector(selectFilterValues);
    const headers = useSelector(selectProjectsListHeaders);
    const classes = useStyles();

    const handleExport = () => {
        dispatch(exportToFileRequest(filterValues, 'projects', false));
    };

    const pageChange = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => (
        dispatch(projectsListRequest(filterValues, skip, orderBy, orderDirection))
    );

    useEffect(
        () => {
            dispatch(projectsListRequest(filterValues));
        },
        [filterValues]
    );

    return (
        <div className={classes.root}>

            <Typography className={classes.heading}>Project Search Result</Typography>

            <Paper className={classes.paper} variant="outlined">
                <ListComponent
                    rows={projects}
                    query={filterValues}
                    total={total}
                    exportAbilities={true}
                    handleExport={handleExport}
                    exportLoading={exportLoading}
                    pageChange={pageChange}
                    returnLink="/projects/search"
                    headers={headers}
                    headBackgroundColor="#ffe2e1"
                    headTextColor="#a9130f"
                    loading={loading}
                    sortable={true}
                />
            </Paper>
        </div>
    );
};
