import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { resetPasswordFailure, ResetPasswordRequest, resetPasswordSuccess } from '../actions';
import { handleApiErrors } from '../../../helpers/api-errors';

export default function* ResetPasswordSaga(action: ResetPasswordRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            'api/forgot-password',
            METHOD_POST,
            {email: action.email}
        );

        yield put(resetPasswordSuccess(data.status));
    } catch (error) {
        yield put(resetPasswordFailure(error))
    }
}
