import { RespondentsListAction } from '../actions';
import { RespondentActionType } from '../constants';
import { ListComponentHeader } from '../../../interfaces';

export interface RespondentsListState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: any[];
    total: number;
    headers: ListComponentHeader[];
}

const initialState: RespondentsListState = {
    loading: false,
    success: false,
    error: false,
    data: [],
    total: 0,
    headers: [],
};

export const listReducer = (state: RespondentsListState = initialState, action: RespondentsListAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENTS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                total: 0,
            };
        case RespondentActionType.RESPONDENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
                total: action.total,
                headers: action.headers,
            };
        case RespondentActionType.RESPONDENTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case RespondentActionType.RESPONDENTS_LIST_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
