import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Respondent as Values } from '../../../modules/respondents';
import { useFormik } from 'formik';
import { InputPhoneMask } from '../../common/Form/InputPhoneMask';
import { addOnEnterKeyPressHandler, clearEmptyValues } from '../../../helpers/formHelper';

interface DashboardFormProps {
    onSubmit: () => void;
    setFilter: (filterValue: any) => void;
}

export const DashboardForm: React.FC<DashboardFormProps> = (props: DashboardFormProps) => {

    const initialValues: Values = {};

    const formikSubmit = (values: Values) => {
        values = clearEmptyValues(values);

        props.setFilter(values);
        props.onSubmit();
    };

    useEffect(() => addOnEnterKeyPressHandler(formik.handleSubmit), []);

    const formik = useFormik({
        initialValues,
        onSubmit: formikSubmit,
    });

    return (
        <>
            <Grid container={true} spacing={2} justify={'center'}>
                <Grid xs={12} sm={6} item={true}>
                    <Box pr={6}>
                        <TextField
                            margin="none"
                            id="first_name"
                            label="First Name"
                            {...formik.getFieldProps('first_name')}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} sm={6} item={true}>
                    <Box pl={6}>
                        <InputPhoneMask
                            name={'phone'}
                            label="Phone"
                            value={formik.getFieldProps('phone').value}
                            setPhone={(value: any) => {
                                formik.setFieldValue('phone', value);
                            }}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} sm={6} item={true}>
                    <Box pr={6}>
                        <TextField
                            margin="none"
                            id="last_name"
                            label="Last Name"
                            {...formik.getFieldProps('last_name')}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} sm={6} item={true}>
                    <Box pl={6}>
                        <TextField
                            margin="none"
                            id="email"
                            label="Email"
                            {...formik.getFieldProps('email')}
                            autoComplete="email"
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid xs={'auto'} item={true}>
                    <Box mt={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size={'large'}
                            onClick={() => formik.handleSubmit()}
                        >

                            <Typography variant={'button'}>
                                Search
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};
