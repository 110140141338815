import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_DELETE, METHOD_GET, METHOD_POST, METHOD_PUT, requestBuilder } from '../../../helpers';
import {
    commentsCreateFailure,
    CommentsCreateRequest,
    commentsCreateSuccess, commentsDeleteFailure,
    commentsDeleteSuccess,
    commentsListFailure,
    CommentsListRequest,
    commentsListSuccess, commentsUpdateFailure, CommentsUpdateRequest, commentsUpdateSuccess
} from '../actions';
import { CommentsActionType } from '../constants';

export function* CommentsRequest(action: CommentsListRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/projects/${action.projectId}/comments`,
            METHOD_GET,
            {}
        );

        yield put(commentsListSuccess(data.comments, data.commentsCount));
    } catch (error) {
        yield put(commentsListFailure());
    }
}

function* createComment(action: CommentsCreateRequest) {
    try {
        const {comment, project} = action;
        const response = yield call(
            requestBuilder,
            `api/projects/${project}/comments`,
            METHOD_POST,
            {
                'text': comment,
            }
        );
        yield put(commentsCreateSuccess(response.data.comments, response.data.commentsCount));
    } catch (error) {
        yield put(commentsCreateFailure());
    }
}

function* deleteComment(action: CommentsCreateRequest) {
    try {
        const {comment} = action;
        const response = yield call(
            requestBuilder,
            `api/comments/${comment}`,
            METHOD_DELETE,
            {}
        );
        yield put(commentsDeleteSuccess(response.data.comments, response.data.commentsCount));
    } catch (error) {
        yield put(commentsDeleteFailure());
    }
}

function* updateComment(action: CommentsUpdateRequest) {
    try {
        const {comment} = action;
        yield call(
            requestBuilder,
            `api/comments/${comment._id}`,
            METHOD_PUT,
            {
                text: comment.text,
            }
        );
        yield put(commentsUpdateSuccess());
    } catch (error) {
        yield put(commentsUpdateFailure());
    }
}

export function* rootCommentsSaga() {
    yield all([
        takeLatest(CommentsActionType.COMMENTS_CREATE_REQUEST, createComment),
        takeLatest(CommentsActionType.COMMENTS_UPDATE_REQUEST, updateComment),
        takeLatest(CommentsActionType.COMMENTS_DELETE_REQUEST, deleteComment),
    ]);
}
