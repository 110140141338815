import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { chartsRequest } from '../../modules/charts';
import { selectChart, selectChartAll, selectChartLoading } from '../../modules/charts/selectors';
import {
    Cell,
    Legend,
    PieLabelRenderProps,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid, XAxis, YAxis, Bar, BarChart
} from 'recharts';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import { Dictionary, selectDictionaryGroup } from '../../modules/dictionaries';
import { AppState } from '../../modules';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    heading: {
        color: '#5680F9',
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 600,
    },
    table: {
        maxWidth: 400,
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const COLORS = ['#cd69ff', '#ea3f5f', '#607dea', '#8fe5b2', '#3edf75', '#ff8038', '#a4a0a0', '#ffe528'];

const RADIAN = Math.PI / 180;
const textStyles: React.CSSProperties = {fontWeight: 'bold'};
const RenderCustomizedLabel = (
    props: PieLabelRenderProps
) => {
    const iRadius = Number(props.innerRadius) || 0;
    const oRadius = Number(props.outerRadius) || 0;
    const mAngle = Number(props.midAngle) || 0;
    const chartX = Number(props.cx) || 0;
    const chartY = Number(props.cy) || 0;
    const percentage = Number(props.percent) || 0;

    const radius = 25 + iRadius + (oRadius - iRadius);
    const x = chartX + radius * Math.cos(-mAngle * RADIAN);
    const y = chartY + radius * Math.sin(-mAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="#4f4f4f"
            textAnchor={x > chartX ? 'start' : 'end'}
            dominantBaseline="central"
            style={textStyles}
        >
            {props.name + ` ${(percentage * 100).toFixed(0)}%`}
        </text>
    );
};

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box width={1} display={'flex'}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function getProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const AnalyticsContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const chart = useSelector(selectChart);
    const loading = useSelector(selectChartLoading);
    const all = useSelector(selectChartAll);
    const tabs = useSelector((state: AppState) => selectDictionaryGroup(state, 'analytics_menu'));
    const [tab, setTab] = React.useState('age');
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setTab(tabs[newValue].value);
    };

    useEffect(() => {
        dispatch(chartsRequest(tab));
    }, [tab]);

    return (
        <div>

            <Typography className={classes.heading}>Analytics</Typography>

            <Paper variant={'outlined'}>
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant={'scrollable'}
                        value={value}
                        onChange={handleChange}
                        aria-label="Charts tabs"
                        className={classes.tabs}
                    >
                        {
                            tabs.map(
                                (tab: Dictionary, key: number) =>
                                    (
                                        <Tab key={key} label={tab.text} {...getProps(key)} />
                                    )
                            )
                        }
                    </Tabs>
                    {
                        tabs.map(
                            (tab: Dictionary, key: number) =>
                                (
                                    <TabPanel key={key} value={value} index={key}>
                                        {loading && (
                                            <Box
                                                width={700}
                                                height={550}
                                                display={'flex'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <CircularProgress size={50}/>
                                            </Box>
                                        )}
                                        {(!loading && chart) && (
                                            <ResponsiveContainer width={700} height={500}>
                                                <BarChart width={500} height={300} data={chart}>
                                                    <Tooltip />
                                                    <Legend
                                                        payload={
                                                            chart.map(
                                                                (item: any, index: number) => ({
                                                                    id: item.name,
                                                                    type: 'square',
                                                                    value: `${item.name}`,
                                                                    color: COLORS[index % COLORS.length],
                                                                })
                                                            )
                                                        }
                                                    />
                                                    <XAxis dataKey={'name'}/>
                                                    <YAxis />
                                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                    <Bar dataKey={'aggregate'} minPointSize={5}>
                                                        {chart.map((entry: any, index: number) => (
                                                            <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        )}
                                    </TabPanel>
                                )
                        )
                    }
                    {(chart && !loading) && (
                        <TableContainer className={classes.table}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                        <TableCell align="right">%</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {chart.map((segment: any, key: number) => (
                                        <TableRow key={key}>
                                            <TableCell component="th" scope="row">
                                                {segment.name}
                                            </TableCell>
                                            <TableCell align="right">{segment.aggregate}</TableCell>
                                            <TableCell
                                                align="right">{(segment.aggregate * 100 / all).toFixed(0)}%</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Paper>
        </div>
    )
};
