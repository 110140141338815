import React, { useEffect } from 'react';
import { DashboardContainer } from '../../containers/DashboardContainer';
import { useDispatch } from 'react-redux';
import { projectsDashboardResetState } from '../../modules/projects';

export const DashboardPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(projectsDashboardResetState())
    }, []);

    return (
        <DashboardContainer/>
    );
};
