import { call, put } from 'redux-saga/effects';
import {
    METHOD_GET,
    requestBuilder,
} from '../../../helpers';
import {
    UserRequest,
    userSuccess,
    userFailure,
} from '../actions';
import history from '../../../history';

export default function* userRequestSaga(action: UserRequest) {
    try {
        const data = yield call(
            requestBuilder,
            'api/me',
            METHOD_GET,
            {}
        );
        if (data.message === 'Unauthenticated') {
            history.push('/login');
            yield put(userFailure());
        } else {
            yield put(userSuccess(data.data, true));
        }

    } catch (error) {
        yield put(userFailure());
        history.push('/login');
    }
}
