import { take, put } from 'redux-saga/effects';
import {
    ProjectsRefresh,
    projectsRequest,
} from '../actions';
import { ProjectActionType } from '../constants';

export default function* projectsRefreshSaga(action: ProjectsRefresh) {
    const {activeOrderBy, activeOrderDirection, archivedOrderBy, archivedOrderDirection, skipActive, skipArchived} = action;
    yield take(ProjectActionType.PROJECTS_UPDATE_SUCCESS);
    yield put(projectsRequest(activeOrderBy, activeOrderDirection, archivedOrderBy, archivedOrderDirection, skipActive, skipArchived));
}
