import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import FormControl from '@material-ui/core/FormControl';
import { createGetAutocompleteProps, createGetInputProps } from '../../../helpers/formBuilder';
import { ChildSearchField } from '../../common/Form/ChildSearchField';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Household: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const internetProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'internet_provider'));
    const typeOfFlooring = useSelector((state: AppState) => selectDictionaryGroup(state, 'type_of_flooring'));
    const typeOfHome = useSelector((state: AppState) => selectDictionaryGroup(state, 'type_of_home', true));
    const poolType = useSelector((state: AppState) => selectDictionaryGroup(state, 'pool_type'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={true} spacing={3} justify={'center'}>
            <Grid xs={12} item={true}>
                <Box my={2}>
                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid xs={12} item={true}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Number in Household"
                                type={'number'}
                                InputProps={{ inputProps: {
                                        min: 0,
                                        onInput: (event: any) => {
                                            event.target.value = event.target.value.replace(/\D/g, '')
                                        }},
                                }}
                                { ...getInputProps('number_in_household')}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <ChildSearchField
                            values={ values }
                            setValues={ setValues }
                            setFilterValues={ setFilterValues }
                            mode={ props.mode }
                        />
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid xs={12} item={true}>
                            <FormControl size={'small'} variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Household Internet Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('hh_internet_provider', internetProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Home
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('home_ownership')}
                                values={ {'true' : 'Own', 'false': 'Rent'} }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid xs={12} item={true}>
                            <FormControl size={'small'} variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Type of Home'} {...params} />
                                        )}
                                    {...getAutocompleteProps('type_of_home', typeOfHome)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Pool
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('pool')}
                            />
                        </Grid>
                    </Grid>

                    {values.pool && (
                        <Grid container={true} spacing={1}>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        renderInput={params =>
                                            (
                                                <TextField label={'Type of Pool'} {...params} />
                                            )}
                                        {...getAutocompleteProps('pool_type', poolType)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Solar Power
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('solar_power')}
                                values={ {'Yes' : 'Yes', 'No': 'No', 'Interest': 'Interest'} }
                            />
                        </Grid>
                    </Grid>
                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid xs={12} item={true}>
                            <FormControl size={'small'} variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Type of Flooring'} {...params} />
                                        )}
                                    {...getAutocompleteProps('type_of_flooring', typeOfFlooring)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
