import { ProjectAction } from '../actions';
import { Project, ProjectActionType } from '../constants';
import { initialValues } from '../../../components/ProjectsSearchComponent';
import { ApiErrors } from '../../../helpers/api-errors';

export interface ProjectsCreateState {
    loading: boolean;
    success: boolean;
    error: boolean;
    project: Project;
    errors: ApiErrors;
}

const initialState: ProjectsCreateState = {
    loading: false,
    success: false,
    error: false,
    project: {} as Project,
    errors: {} as ApiErrors,
};

export const createReducer = (state: ProjectsCreateState = initialState, action: ProjectAction) => {
    switch (action.type) {
        case ProjectActionType.PROJECTS_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ProjectActionType.PROJECTS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                project: action.project,
            };
        case ProjectActionType.PROJECTS_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errors: action.errors,
            };
        case ProjectActionType.PROJECTS_CREATE_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
