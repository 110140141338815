import { ExportToFileDoneAction } from './actions';
import { ExportToFileDoneType } from './constants';

export interface ExportToFileDoneState {
    loading: boolean;
    success: boolean;
    error: boolean;
    link: string;
}

const initialState: ExportToFileDoneState = {
    loading: false,
    success: false,
    error: false,
    link: '',
};

export const exportToFileDoneReducer = (state: ExportToFileDoneState = initialState, action: ExportToFileDoneAction) => {
    switch (action.type) {
        case ExportToFileDoneType.EXPORT_TO_FILE_DONE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                link: '',
            };
        case ExportToFileDoneType.EXPORT_TO_FILE_DONE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                link: action.link,
            };
        case ExportToFileDoneType.EXPORT_TO_FILE_DONE_FAILURE:
            return {
                ...state,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};
