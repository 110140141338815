import { ApiErrors } from '../../helpers/api-errors';

export enum LoginActionType {
    LOGIN_REQUESTING = 'LOGIN_REQUESTING',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_ERROR = 'LOGIN_ERROR',
}

export enum LogoutActionType {
    LOGOUT_REQUESTING = 'LOGOUT_REQUESTING',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_ERROR = 'LOGOUT_ERROR',
}

export interface LoginResponse {
    name: string;
    email: string;
    _id: number;
}

export interface LoginRequestPayload {
    email: string;
    password: string;
}

export interface LoginErrorPayload {
    error: any;
}

export interface LogoutRequestAction {
    type: typeof LogoutActionType.LOGOUT_REQUESTING;
}

export interface LogoutRequestSuccess {
    type: typeof LogoutActionType.LOGOUT_SUCCESS;
}

export const logoutRequest = (): LogoutRequestAction => ({
    type: LogoutActionType.LOGOUT_REQUESTING,
});

export type LoginRequestAction = ActionWithPayload<LoginActionType.LOGIN_REQUESTING, LoginRequestPayload>;
export type LoginSuccessAction = ActionWithPayload<LoginActionType.LOGIN_SUCCESS, LoginResponse>;
export type LoginErrorAction = ActionWithPayload<LoginActionType.LOGIN_ERROR, LoginErrorPayload>;

export type LoginAction = LoginRequestAction | LoginSuccessAction | LoginErrorAction | LogoutRequestAction | LogoutRequestSuccess;

export interface LoginState {
    requesting: boolean;
    loggedIn: boolean;
    errors: ApiErrors;
}
