import { call, put } from 'redux-saga/effects';
import { METHOD_DELETE, requestBuilder } from '../../../helpers';
import {
    RespondentsBulkDeleteRequest,
    respondentsBulkDeleteFailure,
    respondentsBulkDeleteSuccess,
} from '../actions';
import { toggleSnackbarOpen } from '../../notifications';
// import history from '../../../history';

export default function* BulkDeleteRespondentSaga(action: RespondentsBulkDeleteRequest) {
    try {
        yield call(
            requestBuilder,
            'api/respondents',
            METHOD_DELETE,
            {
                ids: action.ids,
            }
        );

        yield put(respondentsBulkDeleteSuccess());
        // history.push('/search');
        yield put(toggleSnackbarOpen('Records Deleted', 'success'));

    } catch (error) {
        yield put(respondentsBulkDeleteFailure());
    }
}
