import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    projectsShowRequest, projectsShowResetState,
    selectProject, selectProjectShowLoading
} from '../../modules/projects';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Comments } from '../../components/Comments';
import {
    Comment,
    commentsCreateRequest,
    commentsDeleteRequest,
    commentsListRequest,
    commentsUpdateRequest,
    selectCommentPostRequested,
    selectComments,
    selectCommentsCount,
    selectCommentsRequested
} from '../../modules/comments';
import { ProjectRespondentListComponent } from '../../components/Project/ProjectRespondentListComponent';
import {
    respondentsListCreateRequest,
    respondentsListUpdateRequest,
    selectRespondentsList,
    RespondentsList
} from '../../modules/respondentsList';
import { ProjectShowComponent } from '../../components/ProjectShowComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { selectBookedText } from '../../modules/';
import history from '../../history';

const useStyles = makeStyles(theme =>
    ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
            marginBottom: theme.spacing(4),
        },
        heading: {
            color: '#a9130f',
            fontSize: 30,
        },
        typography: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(27.8),
            fontWeight: 600,
        },
    }));


export const ProjectShowContainer: React.FC = () => {
    const project = useSelector(selectProject);
    const projectLoading = useSelector(selectProjectShowLoading);
    const comments = useSelector(selectComments);
    const commentsLoading = useSelector(selectCommentsRequested);
    const postCommentLoading = useSelector(selectCommentPostRequested);
    const commentsCount = useSelector(selectCommentsCount);
    const respondentsList = useSelector(selectRespondentsList);
    const bookedText = useSelector(selectBookedText);
    const [booked, setBooked] = useState('')

    const dispatch = useDispatch();
    const classes = useStyles();

    const {id} = useParams<{ id: string }>();

    useEffect(
        () => {
            dispatch(projectsShowResetState());
            dispatch(projectsShowRequest(id));
        },
        [id]
    );

    useEffect(
        () => {
            setBooked(project.booked_text);
        },
        [project]
    );

    useEffect(
        () => {
            setBooked(bookedText);
        },
        [bookedText]
    )

    const postComment = (comment: string) => {
        dispatch(commentsCreateRequest(comment, id));
    };

    const onSubmitRespondentList = (values: RespondentsList) => {
        values.respondents_list_project = project._id;
        if (values._id) {
            dispatch(respondentsListUpdateRequest(values));
        } else {
            dispatch(respondentsListCreateRequest(values));
        }
        // history.push('/projects/');
    };

    const onHandleExpandMore = () => {
        dispatch(commentsListRequest(id))
    };

    const onHandleDeleteComment = (id: string) => {
        dispatch(commentsDeleteRequest(id))
    };

    const onHandleCommentTextChange = (comment: Comment) => {
        dispatch(commentsUpdateRequest(comment))
    };

    return (
        <>
            {
                projectLoading ? <LinearProgress/> : (
                    <>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Typography className={classes.heading}>
                                {project.project_name}
                            </Typography>
                        </Box>
                        <Paper className={classes.paper} variant="outlined">
                            <ProjectShowComponent
                                project={project}
                                booked={booked}
                            />
                        </Paper>

                        <Typography className={classes.typography}>Project Activity</Typography>
                        <Paper className={classes.paper} variant="outlined">
                            <Comments
                                comments={comments}
                                commentsCount={commentsCount}
                                loading={commentsLoading}
                                postCommentLoading={postCommentLoading}
                                postComment={postComment}
                                onHandleExpandMore={onHandleExpandMore}
                                onHandleDeleteComment={onHandleDeleteComment}
                                onHandleCommentTextChange={onHandleCommentTextChange}
                            />
                        </Paper>

                        <ProjectRespondentListComponent
                            list={respondentsList}
                            projectCreated={true}
                            onSubmit={onSubmitRespondentList}
                        />
                    </>
                )
            }
        </>
    )
};
