import { call, put, delay } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../helpers';
import { exportToFileFailure, ExportToFileRequest, ExportToFileSuccess, exportToFileSuccess } from './actions';
import { ExportToFileDoneSuccess } from '../exportDone';

export function* downloadExport(action: ExportToFileDoneSuccess) {
    const link = action.link;
    downloadFileFromURL(link, 'respondents.xlsx');
}

function downloadFileFromURL(url: string, filename: string = '') {
    const link = document.createElement('a');

    link.setAttribute('download', filename);
    link.setAttribute('type', 'hidden');
    link.setAttribute('href', url);

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        document.body.removeChild(link);
    },         100);
}

function* RequestSaga(action: ExportToFileRequest) {
    try {
        const {filter, entity, fromFile} = action;

        const {data} = yield call(
            requestBuilder,
            `api/${entity}/export`,
            METHOD_POST,
            {
                filter,
                fromFile
            }
        );
        yield put(exportToFileSuccess(data.export));
    } catch (error) {
        yield put(exportToFileFailure())
    }
}

export const ExportToFileSaga = RequestSaga;
