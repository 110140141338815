import React from 'react';
import { User } from '../../../modules/user/reducers/user';
import { ListComponent } from '../../ListComponent';
import { ListComponentHeader } from '../../../interfaces';
import { FilterSet } from '../../../containers/RespondentsSearchContainer';

interface UserRolesComponentProps {
    users: User[];
    total: number;
    headers: ListComponentHeader[];
    loading: boolean,
    pageChange: (skip: number, orderBy?: string, orderDirection?: string) => void,
    handleArchive: (row: User) => void,
    handleDelete: (row: User) => void,
    actionArchive?: boolean,
    actionDelete?: boolean,
    serverSide?: boolean,
}

export const UserRolesComponent: React.FC<UserRolesComponentProps> = (props: UserRolesComponentProps) => {

    const pageChange = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => {
        props.pageChange(skip, orderBy, orderDirection)
    };

    const {users, total, headers, loading, handleArchive, handleDelete, actionArchive = false, actionDelete = false, serverSide} = props;

    return (
        <>
            <ListComponent
                rows={users}
                query={{}}
                total={total}
                headers={headers}
                pageChange={pageChange}
                loading={loading}
                rowsPerPage={500}
                actionArchive={actionArchive}
                actionDelete={true}
                onHandleArchive={handleArchive}
                onHandleDelete={handleDelete}
                listOf={'User'}
                sortable={true}
                headBackgroundColor={'#edf2ff'}
                headTextColor={'#5680f9'}
                serverSide={serverSide}
            />
        </>
    );
};
