import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { respondentsDeleteRequest, selectRespondentDeleteLoading } from '../../../modules/respondents';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConfirmDialog } from '../../common/ConfirmDialog';

interface RespondentDeleteProps {
    id?: string;
}

export const RespondentDeleteComponent: React.FC<RespondentDeleteProps> = (props: RespondentDeleteProps) => {
    const dispatch = useDispatch();

    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);

    const respondentDeleteLoading = useSelector(selectRespondentDeleteLoading);

    const handleDelete = () => {
        dispatch(respondentsDeleteRequest(props.id));
        handleClose();
    };

    const handleClose = () => {
        setOpenConfirmDeleteDialog(false);
    };

    return (
        <>
            <Button
                type="submit"
                variant="contained"
                size={'large'}
                color="primary"
                style={{marginRight: 20, backgroundColor: '#f00'}}
                onClick={() => setOpenConfirmDeleteDialog(true)}
            >
                {respondentDeleteLoading ? (
                    <CircularProgress style={{color: '#fff'}} size={24}/>
                ) : (
                    <Typography>
                        Delete
                    </Typography>
                )}
            </Button>
            <ConfirmDialog
                confirmMessage={
                    'Are you sure you want to erase this respondent?\n' +
                    'This action will remove them from your database.'
                }
                open={openConfirmDeleteDialog}
                handleDelete={handleDelete}
                handleClose={handleClose}
            />
        </>
    );
};
