import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { createGetAutocompleteProps, createGetInputProps } from '../../../helpers/formBuilder';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },

        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Financial: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const checkingAccountProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'checking_account_provider'));
    const savingsAccountProvider = useSelector((state: AppState) => selectDictionaryGroup(state, 'savings_account_provider'));
    const liquidAssets = useSelector((state: AppState) => selectDictionaryGroup(state, 'personal_income'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Checking Account
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('checking_account')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Checking Account Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('checking_account_provider', checkingAccountProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Savings Account
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('savings_account')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Savings Account Provider'} {...params} />
                                        )}
                                    {...getAutocompleteProps('savings_account_provider', savingsAccountProvider)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Credit Cards
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('credit_card_ow')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Investment Account
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('investment_account')}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Amount in Liquid Assets'} {...params} />
                                        )}
                                    {...getAutocompleteProps('ammount_in_liquid_assets', liquidAssets)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
