import { FILTER_VALUE_RESET_STATE, FILTER_VALUE_SET } from './constants';
import { FilterSet } from '../../containers/RespondentsSearchContainer';

export interface FilterValuesSet {
    type: typeof FILTER_VALUE_SET;
    filter: FilterSet;
    fromFile?: Boolean;
}

export interface FilterValuesResetState {
    type: typeof FILTER_VALUE_RESET_STATE;
}

export type FilterValueAction = FilterValuesSet | FilterValuesResetState;

export const filterValuesSet = (filter: FilterSet, fromFile?: Boolean): FilterValuesSet => ({
    type: FILTER_VALUE_SET,
    filter,
    fromFile
});

export const filterValuesResetState = (): FilterValuesResetState => ({
    type: FILTER_VALUE_RESET_STATE,
});
