import { DictionariesAction } from '../actions';
import { DictionariesActionType, Dictionary } from '../constants';

export interface DictionariesAllState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: Dictionary[];
}

const initialState: DictionariesAllState = {
    loading: false,
    success: false,
    error: false,
    data: [],
};

export const allReducer = (state: DictionariesAllState = initialState, action: DictionariesAction) => {
    switch (action.type) {
        case DictionariesActionType.DICTIONARIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DictionariesActionType.DICTIONARIES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
            };
        case DictionariesActionType.DICTIONARIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
