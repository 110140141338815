import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, Theme } from '@material-ui/core';

interface RadioGroupFieldProps {
    value?: any;
    name?: string;
    values?: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
            '& label:first-child': {
                marginLeft: 0,
            },
        },
    })
);

export const RadioGroupField: React.FC<RadioGroupFieldProps> = (props: RadioGroupFieldProps) => {
    const classes = useStyles();
    const { values = {'true' : 'Yes', 'false' : 'No'}, value} = props;

    return (
        <>
            {
                (
                    <FormControl component="fieldset" className={ classes.formControl }>
                        <RadioGroup
                            row={ true }
                            name={ props.name }
                            aria-label="position"
                            value={typeof value === 'boolean' ? value.toString() : value ?? ''}
                            defaultValue="">
                            {
                                Object.keys(values).map((key: string) => (
                                    <FormControlLabel
                                        key={key}
                                        disabled={ (typeof value === 'boolean' ? key.toString() !== value.toString() : key !== value) }
                                        value={ key.toString() }
                                        control={ <Radio color="primary" /> }
                                        label={values[key]}
                                        labelPlacement="start"
                                    />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                )
            }
        </>
    );
};