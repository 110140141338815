import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import {
    RespondentDuplicateSearchRequest,
    respondentDuplicateSearchFailure,
    respondentDuplicateSearchSuccess,
    RespondentDuplicateCreateRequest,
    respondentDuplicateCreateSuccess,
    respondentDuplicateCreateFailure
} from '../actions';
import { RespondentActionType } from '../constants';
import { toggleSnackbarOpen } from '../../notifications';

function* duplicateSearch(action: RespondentDuplicateSearchRequest) {
    try {
        const { data } = yield call(
            requestBuilder,
            'api/respondents/duplicate-search',
            METHOD_POST,
            {
                phone: action.phone,
            }
        );

        yield put(respondentDuplicateSearchSuccess(data.duplicates, data.headers));
    } catch (error) {
        yield put(respondentDuplicateSearchFailure());
    }
}

function* duplicateCreate(action: RespondentDuplicateCreateRequest) {
    try {
        yield call(
            requestBuilder,
            'api/respondents/duplicate',
            METHOD_POST,
            {
                id: action.id,
                replace: action.replace,
            }
        );

        yield put(respondentDuplicateCreateSuccess());
        const actionWord = action.replace ? 'Replaced' : 'Duplicated';
        yield put(toggleSnackbarOpen('Record ' + actionWord, 'success'));
    } catch (error) {
        yield put(respondentDuplicateCreateFailure());
    }
}

export default function* rootDuplicateSaga() {
    yield all([
        takeLatest(RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_REQUEST, duplicateSearch),
        takeLatest(RespondentActionType.RESPONDENT_DUPLICATE_CREATE_REQUEST, duplicateCreate),
    ]);
}
