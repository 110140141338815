import { FileUploadAction, FileUploadActionType } from './constants';

export const fileUploadSuccess = (filter: any): FileUploadAction =>
    ({
        type: FileUploadActionType.FILE_UPLOAD_SUCCESS,
        filter,
    });

export const fileUploadRequest = (file: File, url: string): FileUploadAction =>
    ({
        type: FileUploadActionType.FILE_UPLOAD_REQUEST,
        file,
        url,
    });

export const fileUploadFailure = (error: string): FileUploadAction =>
    ({
        type: FileUploadActionType.FILE_UPLOAD_FAILURE,
        error,
    });

export const fileUploadDataReset = (): FileUploadAction =>
    ({
        type: FileUploadActionType.FILE_UPLOAD_DATA_RESET_STATE,
    });
