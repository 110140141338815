import { RespondentsDeleteAction } from '../actions';
import { RespondentActionType } from '../constants';

export interface RespondentsDeleteState {
    loading: boolean;
    success: boolean;
    error: boolean;
}

const initialState: RespondentsDeleteState = {
    loading: false,
    success: false,
    error: false,
};

export const deleteReducer = (state: RespondentsDeleteState = initialState, action: RespondentsDeleteAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENTS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case RespondentActionType.RESPONDENTS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RespondentActionType.RESPONDENTS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
            };
        default:
            return state;
    }
};
