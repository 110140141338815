import React, { useState } from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import FormControl from '@material-ui/core/FormControl';
import { createGetInputProps, createGetCheckBoxProps, createGetAutocompleteProps } from '../../../helpers/formBuilder';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
    })
);

export const Lifestyle: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const alcohol = useSelector((state: AppState) => selectDictionaryGroup(state, 'alcohol_consumption'));
    const brandCigs = useSelector((state: AppState) => selectDictionaryGroup(state, 'cigarette'));
    const lengthCigs = useSelector((state: AppState) => selectDictionaryGroup(state, 'length_of_cigarettes'));
    const typeCigs = useSelector((state: AppState) => selectDictionaryGroup(state, 'type_of_cigarettes'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    {
                        props.mode === 'search' && (
                            <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                                <Grid item={ true } xs={ 12 } sm={ 4 }>
                                    <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                        Has Children
                                    </Typography>
                                </Grid>
                                <Grid item={ true } xs={ 12 } sm={ 8 }>
                                    <RadioGroupEditField
                                        inputProps = {getInputProps('has_children')}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Alcohol'} {...params} />
                                        )}
                                    {...getAutocompleteProps('alcohol', alcohol)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Tobacco Dip User
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('tobaco_deep_users')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Tobacco Usage
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('tobaco_usage')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Smoker
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('smoker')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Brand of Cigarettes'} {...params} />
                                        )}
                                    {...getAutocompleteProps('cigarette', brandCigs)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Length of Cigarettes'} {...params} />
                                        )}
                                    {...getAutocompleteProps('length_of_cigarettes', lengthCigs)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Type of Cigarettes'} {...params} />
                                        )}
                                    {...getAutocompleteProps('type_of_cigarettes', typeCigs)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Gym Membership
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('gym_membership')}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
