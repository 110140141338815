import { RespondentJoinProjectAction } from '../actions';
import { RespondentActionType } from '../constants';

export interface RespondentsJoinProjectState {
    loading: boolean;
    success: boolean;
    error: boolean;
    message: string;
    rlRevision: any;
}

const initialState: RespondentsJoinProjectState = {
    loading: false,
    success: false,
    error: false,
    message: '',
    rlRevision: {},
};

export const joinProjectReducer = (state: RespondentsJoinProjectState = initialState, action: RespondentJoinProjectAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENT_JOIN_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorMessage: '',
                rlRevision: {},
            };
        case RespondentActionType.RESPONDENT_JOIN_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.message,
                rlRevision: action.rlRevision,
            };
        case RespondentActionType.RESPONDENT_JOIN_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.message,
            };
        case RespondentActionType.RESPONDENT_JOIN_PROJECT_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
