import React, { useState } from 'react';
import { Box, Typography, TextField, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import {
    resetPasswordError,
    resetPasswordRequesting,
    resetPasswordStatus,
    resetPasswordErrorMessage,
    resetPasswordStatusMessage
} from '../../../modules/resetPassword';

export interface ResetPasswordProps {
    onSubmit: (email: string) => void;
}

const useStyles = makeStyles(theme =>
    ({
        button: {
            backgroundColor: '#0000fe',
            margin: theme.spacing(3, 0, 2),
            textTransform: 'none',
            minWidth: 150,
        },
    }));

export const ResetPasswordComponent: React.FC<ResetPasswordProps> = (props: ResetPasswordProps) => {
    const [email, setEmail] = useState<string>('');
    const classes = useStyles();
    const onSubmit = () => props.onSubmit(email);
    const resetRequesting = useSelector(resetPasswordRequesting);
    const resetSuccess = useSelector(resetPasswordStatus);
    const resetSuccessMessage = useSelector(resetPasswordStatusMessage);
    const errors = useSelector(resetPasswordErrorMessage);

    return (
        <>
            {
                resetSuccess && (
                    <Alert severity={'success'}>
                        {resetSuccessMessage}
                    </Alert>
                )
            }
            {
                errors.message && (
                    <Alert severity={'error'}>
                        {errors.message}
                    </Alert>
                )
            }
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >
                <TextField
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="normal"
                    required={true}
                    id="email"
                    label="Email Address"
                    name="email"
                    size="small"
                    autoComplete="email"
                    autoFocus={true}
                    defaultValue={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />

                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={onSubmit}
                    className={classes.button}
                    disabled={resetRequesting || resetSuccess}
                >
                    {resetRequesting && <CircularProgress size={24}/>}
                    {!resetRequesting && (
                        <Typography variant={'button'}>
                            Send password reset email
                        </Typography>
                    )}
                </Fab>

            </Box>
        </>
    );
};
