import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

interface Props {
    confirmMessage: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    open: boolean;
    handleClose: () => void;
    handleDelete: () => void;
};

export const ConfirmDialog: React.FC<Props> = (props: Props) => {
    const {
        confirmMessage,
        cancelButtonText = 'Cancel',
        confirmButtonText = 'Delete',
        open = false,
    } = props;
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">
                    <Typography>
                        {confirmMessage}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        size={'large'}
                        color="primary"
                        onClick={props.handleClose}
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        onClick={props.handleDelete}
                        variant="contained"
                        size={'large'}
                        color="primary"
                        style={{backgroundColor: '#f00'}}
                        autoFocus={true}>
                        <Typography>{confirmButtonText}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
