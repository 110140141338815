import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';

export const prcTheme = createMuiTheme({
    typography: {
        fontSize: 16,
    },
    palette: {
        primary: {
            main: '#5680f9',
        },
        secondary: {
            main: '#ef7311',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                minWidth: 200,
            },
            label: {
                color: '#fff',
            },
        },
        MuiBadge: {
            colorSecondary: {
                color: '#fff',
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: 4,
            },
        },
        MuiTextField: {
            root: {
                borderRadius: 4,
                backgroundColor: '#edf2ff',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'inherit',
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: '#edf2ff',
            },
            selectMenu: {
                // minHeight: 34,
                borderRadius: 4,
                // padding: '5px 10px',
            },
        },
        MuiRadio: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiTableCell: {
            root: {
                padding: 0,
            },
        },
    },
    props: {
        MuiFormControl: {
            fullWidth: true,
        },
        MuiTextField: {
            fullWidth: true,
            variant: 'filled',
            InputProps: {
                disableUnderline: true,
            },
            size: 'small',
        },
        MuiSelect: {
            variant: 'filled',
            disableUnderline: true,
        },
        MuiInput: {
            disableUnderline: true,
        },
        MuiFilledInput: {
            disableUnderline: true,
        },
    },
});
