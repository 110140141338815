import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { UserChangePasswordAction } from '../constants';
import { userChangePasswordFailure, userChangePasswordSuccess } from '../actions';

export default function* changePasswordSaga(action: UserChangePasswordAction) {
    try {
        const response = yield call(
            requestBuilder,
            'api/users/change-password',
            METHOD_POST,
            action
        );
        if (response.data.error) {
            yield put(userChangePasswordFailure(response.data.error));
        } else {
            yield put(userChangePasswordSuccess(response.data.success));
        }

    } catch (error) {
        yield put(userChangePasswordFailure('Error'));
    }
}
