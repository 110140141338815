import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { getPropTextValue, responseFormatters } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';
import moment from 'moment';
import history from '../../../history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
        editBtn: {
            position: 'absolute',
            top: '30px',
            minWidth: 'auto',
            right: '20px',
            zIndex: 1,
        },

        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',

        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
            position: 'relative',

        },
    })
);

export const Demographic: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.heading}>Demographic</Typography>
            <Paper className={classes.paper} variant="outlined">

                <Button component={Link} to={`/respondents/edit/${respondent._id}${history.location.hash}`} variant="contained" color="primary"
                        className={classes.editBtn}>
                    Edit
                </Button>

                <Grid container={true} spacing={3} justify={'center'}>
                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography className={classes.typographyHeader}>
                                        First Name
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography className={classes.typography}>
                                        {getPropTextValue(respondent, 'first_name', true)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography className={classes.typographyHeader}>
                                        Last Name
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography className={classes.typography}>
                                        {getPropTextValue(respondent, 'last_name', true)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {respondent.email}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Home Phone
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {responseFormatters.phone(respondent.home_phone)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Business Phone
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {responseFormatters.phone(respondent.business_phone)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Cell Phone
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {responseFormatters.phone(respondent.cellphone)}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Street Address
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {respondent.street_address}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        City
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'city')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        State
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'state')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Zip
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {respondent.zip_code}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        County
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'county')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.fieldItem} container={true} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Rural Code
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'rural_code')}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container={true} spacing={3} justify={'center'}>
                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Age
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {respondent.age}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Sex at birth
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'sex_at_birth')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Birthday
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {respondent.dob && moment(respondent.dob).format('MM/DD/YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Ethnicity
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'ethnicity')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Marital status
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'marital_status')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Employment status
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        { getPropTextValue(respondent, 'employment_status') }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Occupation
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'occupation')}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Gender
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'gender')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Income
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'income')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Education level
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'education_level')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Role in company
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>

                                        {getPropTextValue(respondent, 'role_in_company')}

                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container={true} spacing={3} justify={'center'}>
                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid justify={'space-between'} container={true}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Vaccinated?
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <RadioGroupField
                                        value={respondent.vaccinated}
                                        name="vaccinated"
                                    />
                                </Grid>
                            </Grid>

                            <Grid justify={'space-between'} container={true}>
                                <Grid item={true} xs={12}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <RadioGroup row={true} aria-label="position" name="position" defaultValue="">
                                            <FormControlLabel
                                                disabled={true}
                                                value="Moderna"
                                                control={<Radio color="primary"/>}
                                                label="Moderna"
                                            />
                                            <FormControlLabel
                                                disabled={true}
                                                value="J&J"
                                                control={<Radio color="primary"/>}
                                                label="J&J"
                                            />
                                            <FormControlLabel
                                                disabled={true}
                                                value="Pfizer"
                                                control={<Radio color="primary"/>}
                                                label="Pfizer"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid className={classes.fieldItem} container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        DATE of that Vaccination
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <Typography style={{width: '100%'}} className={classes.typography}>
                                        {getPropTextValue(respondent, 'vaccine_date')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <Box my={2}>

                            <Grid justify={'space-between'} container={true}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Has confirmed antibodies
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <RadioGroupField
                                        value={respondent.covid_antibodies}
                                        name="covid_antibodies"
                                    />
                                </Grid>
                            </Grid>

                            <Grid justify={'space-between'} container={true}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                        Had Covid 19?
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} sm={8}>
                                    <RadioGroupField
                                        value={respondent.has_covid}
                                        name="has_covid"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Divider/>

                    <Grid item={true} xs={12}>
                        <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                            Comments
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Typography style={{width: '100%'}} className={classes.typography}>
                            {getPropTextValue(respondent, 'comments')}
                        </Typography>
                    </Grid>

                </Grid>

            </Paper>
        </>
    );
};
