import React, { ChangeEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { AccordionContainer } from '../common/AccordionContainer';
import { Respondent } from '../../modules';
import { Demographic } from './Demographic';
import { Education } from './Education';
import { Lifestyle } from './Lifestyle';
import { Electronics } from './Electronics';
import { Medical } from './Medical';
import { Financial } from './Financial';
import { Military } from './Military';
import { Political } from './Political';
import { Employment } from './Employment';
import { Household } from './Household';
import { Personal } from './Personal';
import { Religion } from './Religion';
import { Vehicle } from './Vehicle';
import { UploadPhotoId } from './UploadPhotoId';
import { Pets } from './Pets';
import { State } from './State';
import { RespondentIdCopyToClipboardComponent } from '../Respondent/RespondentIdCopyToClipboardComponent';
import { RespondentDeleteComponent } from '../Respondent/RespondentDeleteComponent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined } from '@material-ui/icons';
import history from '../../history';
import { Npi } from './Npi';

export interface RespondentsEditComponentProps {
    respondent: Respondent;
    onSubmit: (values: Respondent, uploadFile: boolean) => void;
    setFilterValues?: (values: Respondent) => void;
    mode: string;
    searchBtnDisabled?: boolean;
    hideSearchByKeyword?: boolean;
}

export interface RespondentInnerComponentProps {
    respondent: Respondent;
    setFilterValues: (prop: string, value: any) => void;
    mode?: string;
}

export const RespondentEditComponent: React.FC<RespondentsEditComponentProps> = (props: RespondentsEditComponentProps) => {
    const formValues = {...props.respondent};
    const [uploadFile, setUploadFile] = useState(false);
    const [values, setValues] = useState(formValues);

    const setFilterValues = (prop: string, value: any) => {

        if (prop === 'files') {
            setUploadFile(true);
        }

        formValues[prop as keyof Respondent] = value;

        if (props.mode === 'search' && (value === '' || value.length === 0)) {
            delete formValues[prop as keyof Respondent];
        }

        setValues(formValues);

        if (props.setFilterValues) {
            props.setFilterValues(formValues);
        }
    };

    return (
        <Grid container={true} spacing={3} style={{marginBottom: 40}}>
            <Grid item={true} xs={12}>
                {
                    !props.hideSearchByKeyword && (
                        <Grid container={true}>
                            <Grid item={true} md={10} xs={12}>
                                <TextField
                                    style={{backgroundColor: '#fff'}}
                                    size={'small'}
                                    type={'search'}
                                    fullWidth={true}
                                    id="outlined-basic"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFilterValues('keywords', e.target.value)}
                                    placeholder="Search by Keyword"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchOutlined color={'disabled'}/>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </Grid>
                            <Grid item={true} xs={12} md={2}>
                                <Box display={'flex'} justifyContent={'flex-end'}>
                                    <Button
                                        type="submit"
                                        size={'large'}
                                        variant="contained"
                                        color={'primary'}
                                        disabled={props.searchBtnDisabled}
                                        onClick={() => props.onSubmit(values, uploadFile)}
                                    >
                                        <Typography color={'inherit'}>
                                            Search
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                }

                <Box display={'flex'}
                     justifyContent={history.location.hash !== '#view' && props.mode === 'edit' ? 'space-between' : 'flex-end'}
                     my={2}
                     mb={3}
                >
                    {
                        props.mode === 'edit' && (
                            <>
                                {
                                    history.location.hash !== '#view' && (
                                        <NavLink to="/respondents/list" color="primary">
                                            Return to list
                                        </NavLink>
                                    )
                                }
                            </>
                        )}

                    <Grid container={true} spacing={2} style={{justifyContent: 'end'}}>
                        <Grid item={true}>
                            <Npi
                                respondent={props.respondent}
                                setFilterValues={setFilterValues}
                            />
                        </Grid>

                        {props.mode === 'edit' && (
                            <Grid item={true}>
                                <RespondentIdCopyToClipboardComponent
                                    id={props.respondent._id}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>


                <State
                    respondent={props.respondent}
                    setFilterValues={setFilterValues}
                    mode={props.mode}
                />
                <Demographic
                    respondent={props.respondent}
                    onSubmit={() => props.onSubmit(values, uploadFile)}
                    setFilterValues={setFilterValues}
                    mode={props.mode}
                    searchBtnDisabled={props.searchBtnDisabled}
                />
            </Grid>
            {props.mode !== 'search' && (
                <Grid item={true} xs={12}>
                    <AccordionContainer
                        title={'Upload photo ID'}
                    >
                        <UploadPhotoId
                            onSubmit={() => props.onSubmit(values, uploadFile)}
                            setFilterValues={setFilterValues}
                            searchBtnDisabled={props.searchBtnDisabled}
                            uploadIds={props.respondent.upload_ids}
                        />
                    </AccordionContainer>
                </Grid>
            )}
            <Grid item={true} xs={12}>
                <AccordionContainer
                    title={'Medical'}
                >
                    <Medical
                        respondent={props.respondent}
                        setFilterValues={setFilterValues}
                    />
                </AccordionContainer>
            </Grid>

            <Box display={'flex'} flexGrow={1} mt={2} mx={1.5}>
                <Grid spacing={3} container={true}>
                    <Grid item={true} xs={12} md={6}>
                        <Grid spacing={3} container={true}>
                            <AccordionContainer
                                title={'Education'}
                            >
                                <Education
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Employment'}
                            >
                                <Employment
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Household'}
                            >
                                <Household
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                    mode={props.mode}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Personal'}
                            >
                                <Personal
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Religion'}
                            >
                                <Religion
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                    mode={props.mode}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Vehicle Ownership'}
                            >
                                <Vehicle
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <Grid spacing={3} container={true}>
                            <AccordionContainer
                                title={'Electronics'}
                            >
                                <Electronics
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Financial'}
                            >
                                <Financial
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Lifestyle'}
                            >
                                <Lifestyle
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                    mode={props.mode}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Military'}
                            >
                                <Military
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Political'}
                            >
                                <Political
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                            <AccordionContainer
                                title={'Pets'}
                            >
                                <Pets
                                    respondent={props.respondent}
                                    setFilterValues={setFilterValues}
                                />
                            </AccordionContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {
                (props.mode === 'edit' || props.mode === 'add') && (
                    <Box mt={2} mx={1.5} width={1} display={'flex'} justifyContent={'flex-end'}>
                        {props.mode === 'edit' && (
                            <RespondentDeleteComponent
                                id={props.respondent._id}
                            />
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            size={'large'}
                            color="primary"
                            onClick={() => props.onSubmit(values, uploadFile)}
                        >
                            <Typography>
                                Save
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        </Grid>
    );
};
