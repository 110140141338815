import { ProjectAction } from '../actions';
import { Project, ProjectActionType } from '../constants';
import { ApiErrors } from '../../../helpers/api-errors';

export interface ProjectUpdateState {
    loading: boolean;
    success: boolean;
    error: boolean;
    project: Project;
    errors: ApiErrors;
}

const initialState: ProjectUpdateState = {
    loading: false,
    success: false,
    error: false,
    project: {} as Project,
    errors: {} as ApiErrors,
};

export const updateReducer = (state: ProjectUpdateState = initialState, action: ProjectAction) => {
    switch (action.type) {
        case ProjectActionType.PROJECTS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                errors: {},
            };
        case ProjectActionType.PROJECTS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                project: action.project,
                errors: {},
            };
        case ProjectActionType.PROJECTS_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errors: action.errors,
            };
        case ProjectActionType.PROJECTS_UPDATE_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
