import React, { useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import Checkbox from '@material-ui/core/Checkbox';
import { AppState } from '../../../modules';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RespondentInnerComponentProps } from '../index';
import { useSelector } from 'react-redux';
import { createGetCheckBoxProps } from '../../../helpers/formBuilder';

const useStyles = makeStyles(() =>
    createStyles({
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
    })
);


export const Medical: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const diseases = useSelector((state: AppState) => selectDictionaryGroup(state, 'medical'));

    const [values, setValues] = useState(respondent);

    const getCheckBoxProps = createGetCheckBoxProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } direction={ 'row' }>
            {
                diseases.map(
                    (item: Dictionary, index: number) =>
                        (
                            <Grid key={ index } item={ true } xs={ 12 } className={ classes.grid }>
                                <FormControlLabel
                                    { ...getCheckBoxProps('diseases', diseases, index) }
                                    control={ <Checkbox color="primary" /> }
                                />
                            </Grid>
                        )
                )
            }
        </Grid>
    );
};
