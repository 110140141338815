import axios from 'axios';
import { put } from 'redux-saga/effects';
import { fileUploadSuccess, fileUploadFailure } from './actions';
import { FileUploadRequestAction } from './constants';
import { filterValuesSet } from '../filterValue';
import { useHistory } from 'react-router-dom';
import history from '../../history';

export default function* uploadFile(action: FileUploadRequestAction) {
    try {
        const formData = new FormData();

        formData.append('file', action.file);

        const response = yield axios.post(process.env.REACT_APP_API_URL + action.url, formData);

        yield put(fileUploadSuccess(response.data.filter));
        yield put(filterValuesSet(response.data.filter, response.data.fromFile));

        history.push('/respondents/list');
    } catch (error) {
        yield put(fileUploadFailure(error.message))
    }
}
