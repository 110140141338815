import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetPasswordRequest, changePasswordRequest } from '../../modules/resetPassword';
import { LoginPageWrapper } from '../../components/LoginPageWrapper';
import { ResetPasswordComponent } from '../../components/ResetPasswordComponent/ResetPassword';
import { ChangePasswordComponent } from '../../components/ResetPasswordComponent/ChangePassword';

interface ResetPasswordParams {
    token: string;
    email: string;
}

export const ResetPasswordPage: React.FC = () => {
    const dispatch = useDispatch();
    const { token, email } = useParams<ResetPasswordParams>();
    const title = token ? 'Change password' : 'Reset your password';

    const onPasswordResetSubmit = (email: string) => {
        dispatch(resetPasswordRequest(email));
    };

    const onPasswordChangeSubmit = (token: string, password: string, passwordConfirmation: string) => {
        dispatch(changePasswordRequest(token, password, passwordConfirmation, email))
    };

    return (
        <LoginPageWrapper title={title}>
            {!token ? (
                <ResetPasswordComponent
                    onSubmit={onPasswordResetSubmit}
                />
            ) : (
                <ChangePasswordComponent
                    onSubmit={onPasswordChangeSubmit}
                    token={token}
                />
            )}
        </LoginPageWrapper>
    )
};
