import React from 'react';

import { createStyles,  Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
    })
);

export const Political : React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    const partyAffiliations = useSelector((state: AppState) => selectDictionaryGroup(state, 'party_affiliations'));

    return (
        <Grid container={true} spacing={3} justify={'center'}>
            <Grid xs={12} item={true}>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Party Affiliations
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Grid item={ true } xs={ 12 }>
                                <Checkbox
                                    disabled={ true }
                                    name={ ''}
                                    color={ 'primary' }
                                    checked={ respondent.democrat }
                                />
                                Democrat
                            </Grid>
                            <Grid item={ true } xs={ 12 }>
                                <Checkbox
                                    disabled={ true }
                                    name={ ''}
                                    color={ 'primary' }
                                    checked={ respondent.republican }
                                />
                                Republican
                            </Grid>
                            <Grid item={ true } xs={ 12 }>
                                <Checkbox
                                    disabled={ true }
                                    name={ ''}
                                    color={ 'primary' }
                                    checked={ respondent.independant }
                                />
                                Independent
                            </Grid>
                            <Grid item={ true } xs={ 12 }>
                                <Checkbox
                                    disabled={ true }
                                    name={ ''}
                                    color={ 'primary' }
                                    checked={ respondent.tea_party }
                                />
                                Tea Party
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Active Voter?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.active_voter }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Registered to Vote?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.voter_status }
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
