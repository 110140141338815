import { Respondent, RespondentActionType } from './constants';
import { FilterSet } from '../../containers/RespondentsSearchContainer';
import { ListComponentHeader } from '../../interfaces';
import { RespondentsList } from '../respondentsList';

export interface RespondentsListFailure {
    type: typeof RespondentActionType.RESPONDENTS_LIST_FAILURE;
}

export interface RespondentsListRequest {
    type: typeof RespondentActionType.RESPONDENTS_LIST_REQUEST;
    filter: FilterSet;
    skip?: number;
    orderBy?: string;
    orderDirection?: string;
    fromFile?: Boolean;
}

export interface RespondentsListSuccess {
    type: typeof RespondentActionType.RESPONDENTS_LIST_SUCCESS;
    data: string[];
    total: number;
    headers: ListComponentHeader[];
}

export interface RespondentsListResetState {
    type: typeof RespondentActionType.RESPONDENTS_LIST_RESET_STATE;
}

export type RespondentsListAction =
    RespondentsListFailure |
    RespondentsListRequest |
    RespondentsListSuccess |
    RespondentsListResetState;

export const respondentsListFailure = (): RespondentsListFailure => ({
    type: RespondentActionType.RESPONDENTS_LIST_FAILURE,
});

export const respondentsListRequest = (params: FilterSet, skip?: number, orderBy?: string, orderDirection?: string, fromFile?: Boolean): RespondentsListRequest => ({
    type: RespondentActionType.RESPONDENTS_LIST_REQUEST,
    filter: params,
    skip,
    orderBy,
    orderDirection,
    fromFile,
});

export const respondentsListSuccess = (data: string[], total: number, headers: ListComponentHeader[]): RespondentsListSuccess => ({
    type: RespondentActionType.RESPONDENTS_LIST_SUCCESS,
    data,
    total,
    headers,
});

export const respondentsListResetState = (): RespondentsListResetState => ({
    type: RespondentActionType.RESPONDENTS_LIST_RESET_STATE,
});


export interface RespondentsShowFailure {
    type: typeof RespondentActionType.RESPONDENTS_SHOW_FAILURE;
}

export interface RespondentsShowRequest {
    type: typeof RespondentActionType.RESPONDENTS_SHOW_REQUEST;
    _id: string;
}

export interface RespondentsShowSuccess {
    type: typeof RespondentActionType.RESPONDENTS_SHOW_SUCCESS;
    data: any[];
}

export type RespondentsShowAction = RespondentsShowFailure | RespondentsShowRequest | RespondentsShowSuccess;

export const respondentsShowFailure = (): RespondentsShowFailure => ({
    type: RespondentActionType.RESPONDENTS_SHOW_FAILURE,
});

export const respondentsShowRequest = (_id: string): RespondentsShowRequest => ({
    type: RespondentActionType.RESPONDENTS_SHOW_REQUEST,
    _id,
});

export const respondentsShowSuccess = (data: any[]): RespondentsShowSuccess => ({
    type: RespondentActionType.RESPONDENTS_SHOW_SUCCESS,
    data,
});

export interface RespondentDuplicateSearchFailure {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_FAILURE;
}

export interface RespondentDuplicateSearchRequest {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_REQUEST;
    phone: string;
}

export interface RespondentDuplicateSearchSuccess {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_SUCCESS;
    duplicates: Respondent[];
    headers: ListComponentHeader[];
}

export interface RespondentDuplicateResetState {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_RESET_STATE;
}

export const respondentsDuplicateResetState = (): RespondentDuplicateResetState => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_RESET_STATE,
});

export type RespondentDuplicateSearchAction =
    RespondentDuplicateSearchFailure |
    RespondentDuplicateSearchRequest |
    RespondentDuplicateResetState |
    RespondentDuplicateSearchSuccess;

export const respondentDuplicateSearchFailure = (): RespondentDuplicateSearchFailure => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_FAILURE,
});

export const respondentDuplicateSearchRequest = (phone: string): RespondentDuplicateSearchRequest => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_REQUEST,
    phone,
});

export const respondentDuplicateSearchSuccess = (duplicates: Respondent[], headers: ListComponentHeader[]): RespondentDuplicateSearchSuccess => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_SEARCH_SUCCESS,
    duplicates,
    headers,
});

export interface RespondentDuplicateCreateFailure {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_CREATE_FAILURE;
}

export interface RespondentDuplicateCreateRequest {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_CREATE_REQUEST;
    id: string;
    replace: boolean;
}

export interface RespondentDuplicateCreateSuccess {
    type: typeof RespondentActionType.RESPONDENT_DUPLICATE_CREATE_SUCCESS;
}

export type RespondentDuplicateCreateAction =
    RespondentDuplicateCreateFailure |
    RespondentDuplicateCreateRequest |
    RespondentDuplicateCreateSuccess;

export const respondentDuplicateCreateFailure = (): RespondentDuplicateCreateFailure => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_CREATE_FAILURE,
});

export const respondentDuplicateCreateRequest = (id: string, replace: boolean = false): RespondentDuplicateCreateRequest => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_CREATE_REQUEST,
    id,
    replace,
});

export const respondentDuplicateCreateSuccess = (): RespondentDuplicateCreateSuccess => ({
    type: RespondentActionType.RESPONDENT_DUPLICATE_CREATE_SUCCESS,
});

export interface RespondentsCreateFailure {
    type: typeof RespondentActionType.RESPONDENTS_CREATE_FAILURE;
}

export interface RespondentsCreateRequest {
    type: typeof RespondentActionType.RESPONDENTS_CREATE_REQUEST;
    respondent: Respondent;
}

export interface RespondentsCreateSuccess {
    type: typeof RespondentActionType.RESPONDENTS_CREATE_SUCCESS;
    respondent: Respondent;
}

export const respondentsCreateFailure = (): RespondentsCreateFailure => ({
    type: RespondentActionType.RESPONDENTS_CREATE_FAILURE,
});

export const respondentsCreateRequest = (respondent: Respondent): RespondentsCreateRequest => ({
    type: RespondentActionType.RESPONDENTS_CREATE_REQUEST,
    respondent,
});

export const respondentsCreateSuccess = (respondent: Respondent): RespondentsCreateSuccess => ({
    type: RespondentActionType.RESPONDENTS_CREATE_SUCCESS,
    respondent,
});

export interface RespondentUpdateFailure {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_FAILURE;
}

export interface RespondentUpdateRequest {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_REQUEST;
    id: string;
    respondent: Respondent;
}

export interface RespondentUpdateSuccess {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_SUCCESS;
    respondent: Respondent;
}

export type RespondentUpdateAction = RespondentUpdateFailure | RespondentUpdateRequest | RespondentUpdateSuccess;

export const respondentUpdateFailure = (): RespondentUpdateFailure => ({
    type: RespondentActionType.RESPONDENT_UPDATE_FAILURE,
});

export const respondentUpdateRequest = (id: string, respondent: Respondent): RespondentUpdateRequest => ({
    type: RespondentActionType.RESPONDENT_UPDATE_REQUEST,
    id,
    respondent,
});

export const respondentUpdateSuccess = (respondent: Respondent): RespondentUpdateSuccess => ({
    type: RespondentActionType.RESPONDENT_UPDATE_SUCCESS,
    respondent,
});

export interface RespondentUploadIdRequest {
    type: typeof RespondentActionType.RESPONDENT_UPLOAD_ID_REQUEST;
    id: string;
    files?: File[];
}

export interface RespondentUploadIdSuccess {
    type: typeof RespondentActionType.RESPONDENT_UPLOAD_ID_SUCCESS;
}

export interface RespondentUploadIdFailure {
    type: typeof RespondentActionType.RESPONDENT_UPLOAD_ID_FAILURE;
}

export type RespondentUploadIdAction = RespondentUploadIdRequest | RespondentUploadIdSuccess | RespondentUploadIdFailure;

export const respondentUploadIdRequest = (id: string, files?: File[]): RespondentUploadIdRequest => ({
    type: RespondentActionType.RESPONDENT_UPLOAD_ID_REQUEST,
    id,
    files,
});

export const respondentUploadIdSuccess = (): RespondentUploadIdSuccess => ({
    type: RespondentActionType.RESPONDENT_UPLOAD_ID_SUCCESS,
});

export const respondentUploadIdFailure = (): RespondentUploadIdFailure => ({
    type: RespondentActionType.RESPONDENT_UPLOAD_ID_FAILURE,
});

export interface RespondentUpdateRlFieldsFailure {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_FAILURE;
}

export interface RespondentUpdateRlFieldsRequest {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_REQUEST;
    id: string;
    column: any;
    listId: string;
    skip: number;
}

export interface RespondentUpdateRlFieldsSuccess {
    type: typeof RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_SUCCESS;
    respondentsList: RespondentsList;
    bookedText: string;
}

export type RespondentUpdateRlFieldsAction = RespondentUpdateRlFieldsFailure | RespondentUpdateRlFieldsRequest | RespondentUpdateRlFieldsSuccess;

export const respondentUpdateRlFieldsFailure = (): RespondentUpdateRlFieldsFailure => ({
    type: RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_FAILURE,
});

export const respondentUpdateRlFieldsRequest = (id: string, column: any, listId: string, skip: number): RespondentUpdateRlFieldsRequest => ({
    type: RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_REQUEST,
    id,
    column,
    listId,
    skip,
});

export const respondentUpdateRlFieldsSuccess = (respondentsList: RespondentsList, bookedText: string): RespondentUpdateRlFieldsSuccess => ({
    type: RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_SUCCESS,
    respondentsList,
    bookedText,
});

export interface RespondentsDeleteFailure {
    type: typeof RespondentActionType.RESPONDENTS_DELETE_FAILURE;
}

export interface RespondentsDeleteRequest {
    type: typeof RespondentActionType.RESPONDENTS_DELETE_REQUEST;
    _id?: string;
}

export interface RespondentsDeleteSuccess {
    type: typeof RespondentActionType.RESPONDENTS_DELETE_SUCCESS;
}

export type RespondentsDeleteAction = RespondentsDeleteFailure | RespondentsDeleteRequest | RespondentsDeleteSuccess;

export const respondentsDeleteFailure = (): RespondentsDeleteFailure => ({
    type: RespondentActionType.RESPONDENTS_DELETE_FAILURE,
});

export const respondentsDeleteRequest = (_id?: string): RespondentsDeleteRequest => ({
    type: RespondentActionType.RESPONDENTS_DELETE_REQUEST,
    _id,
});

export const respondentsDeleteSuccess = (): RespondentsDeleteSuccess => ({
    type: RespondentActionType.RESPONDENTS_DELETE_SUCCESS,
});

export interface RespondentsBulkDeleteFailure {
    type: typeof RespondentActionType.RESPONDENTS_BULK_DELETE_FAILURE;
}

export interface RespondentsBulkDeleteRequest {
    type: typeof RespondentActionType.RESPONDENTS_BULK_DELETE_REQUEST;
    ids: string[];
}

export interface RespondentsBulkDeleteSuccess {
    type: typeof RespondentActionType.RESPONDENTS_BULK_DELETE_SUCCESS;
}

export type RespondentsBulkDeleteAction = RespondentsBulkDeleteFailure | RespondentsBulkDeleteRequest | RespondentsBulkDeleteSuccess;

export const respondentsBulkDeleteFailure = (): RespondentsBulkDeleteFailure => ({
    type: RespondentActionType.RESPONDENTS_BULK_DELETE_FAILURE,
});

export const respondentsBulkDeleteRequest = (ids: string[]): RespondentsBulkDeleteRequest => ({
    type: RespondentActionType.RESPONDENTS_BULK_DELETE_REQUEST,
    ids,
});

export const respondentsBulkDeleteSuccess = (): RespondentsBulkDeleteSuccess => ({
    type: RespondentActionType.RESPONDENTS_BULK_DELETE_SUCCESS,
});

export type RespondentAction =
    | RespondentsCreateRequest
    | RespondentsCreateFailure
    | RespondentsCreateSuccess
    | RespondentUpdateRequest
    | RespondentUploadIdRequest
    | RespondentUpdateFailure
    | RespondentUpdateSuccess
    | RespondentUploadIdSuccess;

export interface RespondentJoinProjectRequest {
    type: typeof RespondentActionType.RESPONDENT_JOIN_PROJECT_REQUEST;
    project: string;
    status: string;
    id?: string;
}

export interface RespondentJoinProjectSuccess {
    type: typeof RespondentActionType.RESPONDENT_JOIN_PROJECT_SUCCESS;
    message: string;
    rlRevision: any;
}

export interface RespondentJoinProjectFailure {
    type: typeof RespondentActionType.RESPONDENT_JOIN_PROJECT_FAILURE;
    message: string;
}

export interface RespondentJoinProjectResetState {
    type: typeof RespondentActionType.RESPONDENT_JOIN_PROJECT_RESET_STATE;
}

export const respondentJoinProjectRequest = (project: string, status: string, id?: string): RespondentJoinProjectRequest => ({
    type: RespondentActionType.RESPONDENT_JOIN_PROJECT_REQUEST,
    project,
    status,
    id,
});

export const respondentJoinProjectSuccess = (message: string, rlRevision: any): RespondentJoinProjectSuccess => ({
    type: RespondentActionType.RESPONDENT_JOIN_PROJECT_SUCCESS,
    message,
    rlRevision,
});

export const respondentJoinProjectFailure = (message: string): RespondentJoinProjectFailure => ({
    type: RespondentActionType.RESPONDENT_JOIN_PROJECT_FAILURE,
    message,
});

export const respondentsJoinProjectResetState = (): RespondentJoinProjectResetState => ({
    type: RespondentActionType.RESPONDENT_JOIN_PROJECT_RESET_STATE,
});

export type RespondentJoinProjectAction =
    RespondentJoinProjectFailure |
    RespondentJoinProjectRequest |
    RespondentJoinProjectSuccess |
    RespondentJoinProjectResetState;
