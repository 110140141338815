import axios, { Method } from 'axios';
import history from '../history';

axios.defaults.withCredentials = true;

// Add a response interceptor for check response status.
axios.interceptors.response.use(function (response) {
    return response;
}, (error) => {
    if (error.response && error.response.status === 422) {
        return Promise.reject(error.response.data.errors);
    }

    if (error.response && [401, 419].includes(error.response.status)) {
        history.push('/login');
    }
    return Promise.reject(error);
});

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';

export const requestBuilder = (path: string, method: Method, data: object) => axios.request({
    baseURL: '/',
    method,
    [method === METHOD_GET ? 'params' : 'data']: data,
    responseType: 'json',
    url: process.env.REACT_APP_API_URL + path,
});
