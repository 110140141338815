import React, { useState, ChangeEvent } from 'react';
import { Project } from '../../../../modules/projects';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, createStyles, Divider, Grid, makeStyles, TextField, Theme, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        font: {
            fontWeight: 600,
            fontSize: '13px',
        },
        select: {
            height: 48,
        },
        projectChips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    })
);

const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
        minWidth: '100px',
        maxHeight: '30px',
        margin: theme.spacing(2),
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
            borderLeftColor: '#d5d5d5',
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(theme => ({
    root: {
        color: '#4f4f4f',
        backgroundColor: '#f2f2f2',
        '&$selected': {
            backgroundColor: '#d8e2fe',
            borderColor: '#4664ba',
            color: '#4664ba',
            '&:hover': {
                backgroundColor: '#d8e2fe',
            },
        },
    },
    selected: {},
}))(ToggleButton);

interface SelectProjectsFieldProps {
    projects: Project[];
    selected?: string[];
    setSelected: (selected: string[]) => void;
}

export const SelectProjectsField = (props: SelectProjectsFieldProps) => {
    const {projects, setSelected, selected = []} = props;

    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Project[]>(projects);
    const [sortDirection, setSortDirection] = useState<boolean>(true);
    const [projectIds, setProjectsIds] = useState<string[]>(JSON.parse(JSON.stringify(selected)));
    const [projectNames, setProjectNames] = useState(() => {
            const names: string[] = [];
            projects.map((project: Project) => projectIds.includes(project._id) && names.push(project.project_name));

            return names;
        }
    );

    const isAllSelected =
        options.length > 0 && selected.length === options.length;

    const sortFilter = (direction: boolean) => {
        const sorted = [...options].sort((a: Project, b: Project) =>
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            direction ? ((b.project_name?.toLocaleLowerCase() < a.project_name?.toLocaleLowerCase()) ? 1 : -1) : ((b.project_name?.toLocaleLowerCase() > a.project_name?.toLocaleLowerCase()) ? 1 : -1));
        setOptions(sorted);
    };

    const filterProjects = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setOptions(projects);
        } else {
            // tslint:disable-next-line:arrow-return-shorthand
            const newOptions = options.filter((option: Project) => {
                // @ts-ignore
                return option.project_name?.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) !== -1;
            });
            setOptions(newOptions);
        }
    };

    const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'select-all') {
            const checked = event.target.checked;
            setSelected(checked ? options.map((option: Project) => option._id) : []);
            return;
        }
    };

    const handleInputChange = (id: string) => {
        const uncheckedProject = selected.findIndex((project: string) => project === id);
        if (uncheckedProject !== -1){
            selected.splice(uncheckedProject, 1)
        } else {
            const selectedProjectIndex = options.findIndex((option: Project) =>
                option._id === id);
            selected.push(options[selectedProjectIndex]._id)
        }
        setSelected(selected)
    };

    const handleSortDirection = (event: React.MouseEvent<HTMLElement>, newAlignment: boolean) => {
        setSortDirection(newAlignment);
        sortFilter(newAlignment);
    };

    const handleClose = () => {
        setOpen(false);
        setSelected(projectIds);
        setOptions(projects);
        setSortDirection(true);
    };

    const handleApply = () => {
        setOpen(false);
        const names: string[] = [];
        projects.map((project: Project) => selected.includes(project._id) && names.push(project.project_name));

        setProjectNames(names);
        setSelected(selected);
        setProjectsIds(selected);
        setOptions(projects);
        setSortDirection(true);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl size={'small'} variant="filled">
            <InputLabel id="user-add-projects-label">Select projects</InputLabel>
            <Select
                labelId="user-add-projects-label"
                id="user-add-projects"
                open={open}
                value={projectNames}
                renderValue={(value: any) =>
                    (
                        <div className={classes.projectChips}>
                            {(
                                projectNames.map((name: string | undefined, index: number) => (
                                <Chip
                                    style={{marginRight: 8}}
                                    key={index}
                                    label={name}
                                />
                              ))
                            )}
                        </div>
                    )}
                onOpen={handleOpen}
                multiple={true}
                IconComponent={KeyboardArrowDownIcon}
            >
                <Grid item={true} xs={12}>
                    <Box display={'flex'} justifyContent={'flex-end'} mr={4}>
                        <IconButton edge={'end'} size={'small'} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box margin={2}>
                        <Box display={'flex'}>
                            <TextField
                                style={{backgroundColor: '#fff'}}
                                type={'search'}
                                variant={'outlined'}
                                onChange={filterProjects}
                            />
                            <Box mx={2}>
                                <Button
                                    size={'large'}
                                    variant="contained"
                                    onClick={handleApply}
                                    color={'primary'}
                                >
                                    <Typography color={'inherit'}>
                                        Save
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>

                        <Box width={1} display={'flex'} justifyContent={'space-between'}>
                            <Box display={'flex'}>
                                <Checkbox
                                    name="select-all"
                                    onChange={handleSelectAllChange}
                                    indeterminate={
                                        selected.length > 0 && selected.length < options.length
                                    }
                                    checked={isAllSelected}
                                    color={'secondary'}
                                />

                                <Box display={'flex'} flexDirection={'column'}
                                     justifyContent={'center'}>
                                    <Typography component={'span'}>All</Typography>
                                </Box>
                            </Box>

                            <div>
                                <Typography
                                    className={classes.font}
                                    variant={'caption'}
                                >
                                    Sort by
                                </Typography>
                                <StyledToggleButtonGroup
                                    value={sortDirection}
                                    exclusive={true}
                                    onChange={handleSortDirection}
                                    aria-label="direction sort"
                                >
                                    <StyledToggleButton value={true} aria-label="direction asc">
                                        <Typography variant={'button'} className={classes.font}>
                                            A &gt; Z
                                        </Typography>
                                    </StyledToggleButton>
                                    <StyledToggleButton value={false} aria-label="direction desc">
                                        <Typography variant={'button'} className={classes.font}>
                                            Z &gt; A
                                        </Typography>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </div>
                        </Box>

                        <Box mx={-6} my={2} height={'2px'}><Divider style={{height: '2px'}}/></Box>
                        {
                            options.map(
                                (item: Project, index: number) =>
                                    (
                                        <Grid key={index} item={true} xs={12}>
                                            <Checkbox
                                                key={index}
                                                id={item.project_name}
                                                name={item.project_name}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    handleInputChange(item._id)
                                                }}
                                                checked={selected.includes(item._id) ?? false}
                                                color={'secondary'}
                                            />
                                            <label htmlFor={item.project_name}>{item.display_name}</label>
                                        </Grid>
                                    ))}
                    </Box>
                </Grid>
            </Select>
        </FormControl>
    );
};
