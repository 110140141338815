import React from 'react';
import { LoginComponent } from '../../components/LoginComponent';
import { useDispatch } from 'react-redux';
import { loginRequest } from '../../modules/auth';

export const LoginContainer: React.FC = () => {
    const dispatch = useDispatch();

    const onSubmit = (email: string, password: string) => {
        dispatch(loginRequest({email, password}))
    };

    return (
        <LoginComponent
            onSubmit={onSubmit}
        />
    )
};
