import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_POST, METHOD_PUT, requestBuilder } from '../../../helpers';
import { RespondentActionType } from '../constants';
import {
    respondentsCreateFailure,
    RespondentsCreateRequest,
    respondentsCreateSuccess,
    respondentUpdateFailure,
    RespondentUpdateRequest,
    RespondentUploadIdRequest,
    respondentUpdateSuccess,
    respondentUploadIdSuccess,
    respondentUploadIdFailure
} from '../actions';
import { toggleSnackbarOpen } from '../../notifications';
import { ImageType } from 'react-images-uploading';

export function* createRespondent(action: RespondentsCreateRequest) {
    try {
        const {respondent} = action;
        const {data} = yield call(
            requestBuilder,
            'api/respondents',
            METHOD_POST,
            {
                'respondent': respondent,
            }
        );

        yield put(respondentsCreateSuccess(data));
    } catch (error) {
        yield put(respondentsCreateFailure());
    }
}

function* updateRespondent(action: RespondentUpdateRequest) {
    try {
        const {id, respondent} = action

        const {data} = yield call(
            requestBuilder,
            `api/respondents/${id}`,
            METHOD_PUT,
            {
                'respondent': respondent,
            }
        );
        //    yield fork(uploadRespondentId, id, respondent.file)
        yield put(respondentUpdateSuccess(data));
        yield put(toggleSnackbarOpen('Record Saved', 'success'));

    } catch (error) {
        yield put(respondentUpdateFailure());
    }
}

function* uploadRespondentId(action: RespondentUploadIdRequest) {
    try {
        const {id, files} = action

        const formData = new FormData();

        if (files) {
            files.map((image: ImageType, key: number) => {
                formData.append(`files[${key}]`, image.file as File)
                formData.append(`ids[${key}]`, image._id)
            })
        }

        yield call(
            requestBuilder,
            `api/respondents/upload-id/${id}`,
            METHOD_POST,
            formData
        );
        yield put(respondentUploadIdSuccess());
        yield put(toggleSnackbarOpen('Record Saved', 'success'));
    } catch (error) {
        yield put(respondentUploadIdFailure());
    }
}

export function* rootUpdateRespondentsSaga() {
    yield all([
        takeLatest(RespondentActionType.RESPONDENT_UPDATE_REQUEST, updateRespondent),
        takeLatest(RespondentActionType.RESPONDENT_UPLOAD_ID_REQUEST, uploadRespondentId),
    ]);
}
