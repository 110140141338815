import React from 'react';
import { Grid } from '@material-ui/core';
import { Dictionary } from '../../../../modules/dictionaries';
import Checkbox from '@material-ui/core/Checkbox';

interface CheckBoxGroupFieldProps {
    value: any;
    dictionary: any;
    name?: string;
}

export const CheckBoxGroupField: React.FC<CheckBoxGroupFieldProps> = (props: CheckBoxGroupFieldProps) => {
    const {value, dictionary, name} = props;

    return (
        <>
            {
                (value && value.length && dictionary.length && dictionary.map(
                    (item: Dictionary, index: number) => value.includes(item.value) ?
                        (
                            <Grid key={ index } item={ true } xs={ 12 }>
                                <Checkbox
                                    disabled={ true }
                                    key={ index }
                                    name={ item.group + '_' + item.value }
                                    color={ 'primary' }
                                    checked={ true }
                                />
                                { item.text }
                            </Grid>
                        ) : ('')
                )) || (`Field : ${ name } not set`)
            }
        </>
    );
};
