import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import FormControl from '@material-ui/core/FormControl';
import { createGetInputProps } from '../../../helpers/formBuilder';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveButton from '@material-ui/icons/Remove';
import AddButton from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
        grid: {
            flex: '1 0 15%',
        },
        gridCheckbox: {
            flex: '1 1 100px',
        },
        dropdownStyle: {
            padding: '14px 12px',
        },
    })
);

interface CarFieldSet {
    [make: string]: string | number | null | undefined,
    model: string,
    year: string,
}

const CarField: CarFieldSet = {
    make: '',
    model: '',
    year: '',
};

export const Vehicle: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const carMake = useSelector((state: AppState) => selectDictionaryGroup(state, 'car_make'));
    const [values, setValues] = useState(respondent);
    const [cars, setCars] = useState<CarFieldSet[]>(values.cars?.length ?
        JSON.parse(JSON.stringify([...values.cars])) : [{...CarField}]);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);

    const handleChangeInput = (index: number, event: React.ChangeEvent<{}>, customEvent?: any) => {
        const target = customEvent ?? event.target as HTMLInputElement;
        const i = cars.findIndex((value: CarFieldSet, key) => key === index);

        cars[i][target.name] = target.value;
        setCars([...cars]);
        setValues({...values, 'cars' : cars})
        setFilterValues('cars', cars);
    };

    const handleAddFields = () => {
        setCars([...cars, {...CarField}]);
    };

    const handleRemoveFields = (index: number) => {
        const i = cars.findIndex((value: CarFieldSet, key) => key === index);

        cars.splice(i, 1);
        setValues({...values, 'cars' : cars})
        setFilterValues('cars', cars);
        setCars(cars.length ? [...cars] : [CarField]);
    };

    return (
        <Grid container={true} spacing={3} justify={'center'}>
            <Grid xs={12} item={true}>
                <Box my={2}>
                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Own ATV
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('own_atv')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Own Boat
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('own_boat')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={4}>
                            <Typography style={{width: '100%'}} className={classes.typographyHeader}>
                                Own Motorcycle
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <RadioGroupEditField
                                inputProps = {getInputProps('own_motorcycle')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.fieldItem} container={true} spacing={1}>
                        {
                            cars.map(
                                (car: CarFieldSet, key: number) =>
                                    (
                                        <div key={key}>
                                            <Typography style={{width: '100%'}}
                                                        className={classes.typographyHeader}>
                                                {'Car ' + (key + 1)}
                                            </Typography>
                                            <Grid spacing={3} container={true}>
                                                <Grid item={true} xs={4}>
                                                    <FormControl>
                                                        <Autocomplete
                                                            id={'car_' + car.id + '_make_label'}
                                                            options={carMake}
                                                            value={carMake.find((option : Dictionary) => option.value === car.make) || null}
                                                            defaultValue={carMake.find((option : Dictionary) => option.value === car.make) || null}
                                                            getOptionLabel={((option: Dictionary) => option.text)}
                                                            getOptionSelected={(option: Dictionary, value: Dictionary) => option.text === value.text}
                                                            onChange={ (event: any, newValue: any) =>
                                                                handleChangeInput(
                                                                    key,
                                                                    event,
                                                                    { name: 'make', value: newValue ? newValue.value : null }
                                                                )
                                                            }
                                                            renderInput={params =>
                                                                (
                                                                    <TextField name={'make'} label={'Car ' + (key + 1) + ' Make'} {...params} />
                                                                )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item={true} xs={3}>
                                                    <TextField
                                                        id={'car_' + (key + 1) + '_model'}
                                                        label={'Car ' + (key + 1) + ' Model'}
                                                        name={'model'}
                                                        fullWidth={true}
                                                        value={car.model || ''}
                                                        onChange={
                                                            (event: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleChangeInput(key, event)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={3}>
                                                    <TextField
                                                        id={'car_' + (key + 1) + '_year'}
                                                        name={'year'}
                                                        label={'Car ' + (key + 1) + ' Year'}
                                                        fullWidth={true}
                                                        value={car.year || ''}
                                                        onChange={
                                                            (event: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleChangeInput(key, event)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={2}>
                                                    <Box display={'flex'} justifyContent={'center'}>
                                                        <IconButton onClick={() => handleRemoveFields(key)}>
                                                            <RemoveButton/>
                                                        </IconButton>
                                                        <IconButton onClick={() => handleAddFields()}>
                                                            <AddButton/>
                                                        </IconButton>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                            )
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
