import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Project,
    projectsShowRequest, projectsShowResetState,
    projectsUpdateRequest, projectsUpdateResetState,
    selectProject,
    selectProjectShowLoading, selectProjectsUpdateFailure, selectProjectUpdateLoading, selectProjectUpdateSuccess
} from '../../modules/projects';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Comments } from '../../components/Comments';
import {
    Comment,
    commentsCreateRequest,
    commentsDeleteRequest,
    commentsListRequest,
    commentsUpdateRequest,
    selectCommentPostRequested,
    selectComments,
    selectCommentsCount,
    selectCommentsRequested
} from '../../modules/comments';
import { ProjectRespondentListComponent } from '../../components/Project/ProjectRespondentListComponent';
import {
    respondentsListCreateRequest,
    respondentsListUpdateRequest,
    selectRespondentsList,
    RespondentsList
} from '../../modules/respondentsList';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toggleSnackbarClose, toggleSnackbarOpen } from '../../modules/notifications';
import { ProjectForm } from '../../components/Project/ProjectForm';
import history from '../../history';

const useStyles = makeStyles(theme =>
    ({
        paper: {
            padding: theme.spacing(5),
            marginBottom: theme.spacing(4),
        },
        heading: {
            color: '#a9130f',
            fontSize: 30,
        },
        typography: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(27.8),
            fontWeight: 600,
        },
    }));


export const ProjectEditContainer: React.FC = () => {
    const project = useSelector(selectProject);
    const projectLoading = useSelector(selectProjectShowLoading);
    const projectUpdateLoading = useSelector(selectProjectUpdateLoading);
    const projectUpdateSuccess = useSelector(selectProjectUpdateSuccess);
    const comments = useSelector(selectComments);
    const commentsLoading = useSelector(selectCommentsRequested);
    const postCommentLoading = useSelector(selectCommentPostRequested);
    const commentsCount = useSelector(selectCommentsCount);
    const respondentsList = useSelector(selectRespondentsList);
    const updateProjectError = useSelector(selectProjectsUpdateFailure);

    const dispatch = useDispatch();
    const classes = useStyles();

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        if (projectUpdateSuccess) {
            dispatch(toggleSnackbarOpen('Record Saved', 'success'));
        }
    },        [projectUpdateSuccess]);

    useEffect(() => {
        if (Object.keys(updateProjectError).length) {
            let errorMessage = '';
            Object.keys(updateProjectError).forEach((message: string) => errorMessage += updateProjectError[message]);
            dispatch(toggleSnackbarOpen(errorMessage, 'error'));
        }
    },        [updateProjectError]);

    useEffect(
        () => {
            dispatch(projectsShowResetState());
            dispatch(projectsUpdateResetState());
            dispatch(toggleSnackbarClose());
            dispatch(projectsShowRequest(id));
        },
        [id]
    );

    const postComment = (comment: string) => {
        dispatch(commentsCreateRequest(comment, id));
    };

    const onSubmitRespondentList = (values: RespondentsList) => {
        values.respondents_list_project = project._id;
        if (values._id) {
            dispatch(respondentsListUpdateRequest(values));
        } else {
            dispatch(respondentsListCreateRequest(values));
        }
        // history.push('/projects/');
    };

    const onSubmitProjectUpdate = (values: Project) => {
        dispatch(projectsUpdateRequest(values));
    };

    const onHandleExpandMore = () => {
        dispatch(commentsListRequest(id))
    };

    const onHandleDeleteComment = (id: string) => {
        dispatch(commentsDeleteRequest(id))
    };

    const onHandleCommentTextChange = (comment: Comment) => {
        dispatch(commentsUpdateRequest(comment))
    };

    return (
        <>
            {
                projectLoading ? <LinearProgress/> : (
                    <>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Typography className={classes.heading}>
                                {project.project_name}
                            </Typography>
                        </Box>
                        <Paper className={classes.paper} variant="outlined">
                            <ProjectForm
                                project={project}
                                loading={projectUpdateLoading}
                                onSubmit={onSubmitProjectUpdate}
                                apiErrors={projectUpdateSuccess ? projectUpdateSuccess : updateProjectError }
                            />
                        </Paper>

                        <Typography className={classes.typography}>Project Activity</Typography>
                        <Paper className={classes.paper} variant="outlined">
                            <Comments
                                comments={comments}
                                commentsCount={commentsCount}
                                loading={commentsLoading}
                                postCommentLoading={postCommentLoading}
                                postComment={postComment}
                                onHandleExpandMore={onHandleExpandMore}
                                onHandleDeleteComment={onHandleDeleteComment}
                                onHandleCommentTextChange={onHandleCommentTextChange}
                            />
                        </Paper>

                        <ProjectRespondentListComponent
                            list={respondentsList}
                            projectCreated={true}
                            onSubmit={onSubmitRespondentList}
                        />
                    </>
                )
            }
        </>
    )
};
