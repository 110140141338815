import { AppState } from '..';
import { Dictionary } from './constants';
import { ListComponentHeader } from '../../interfaces';

export const selectDictionaries = (state: AppState): Dictionary[] => state.dictionaries.all.data;
export const selectDictionaryGroup = (state: AppState, group: string, orderByValue: boolean = false): Dictionary[] =>
{
    const values = state.dictionaries.all.data.filter((item: Dictionary) => item.group === group);

    if (orderByValue) {
        return values.sort((a: Dictionary, b: Dictionary) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    }

    return values;
}

export const selectDictionariesListData = (state: AppState): any[] => state.dictionaries.list.data;
export const selectDictionariesListTotal = (state: AppState): number => state.dictionaries.list.total;
export const selectDictionariesListLoading = (state: AppState): boolean => state.dictionaries.list.loading;
export const selectDictionariesListHeadings = (state: AppState): ListComponentHeader[] => state.dictionaries.list.headers;

export const selectDictionaryCreateLoading = (state: AppState): boolean => state.dictionaries.dictionary.loading;
export const selectDictionaryCreateErrorMessage = (state: AppState): string => state.dictionaries.dictionary.message;
export const selectDictionaryCreateSuccess = (state: AppState): boolean => state.dictionaries.dictionary.success;
