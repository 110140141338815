import { call, put } from 'redux-saga/effects';
import { METHOD_GET, requestBuilder } from '../../../helpers';
import { respondentsShowFailure, RespondentsShowRequest, respondentsShowSuccess } from '../actions';

export default function* ShowRequestSaga(action: RespondentsShowRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/respondents/${action._id}`,
            METHOD_GET,
            {}
        );

        yield put(respondentsShowSuccess(data));

    } catch (error) {
        yield put(respondentsShowFailure());
    }
}
