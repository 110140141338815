import { AppState, Respondent } from '..';
import { ListComponentHeader } from '../../interfaces';

export const selectRespondents = (state: AppState): any[] => state.respondents.list.data;
export const selectRespondentsRequested = (state: AppState): boolean => state.respondents.list.loading;
export const selectTotal = (state: AppState): number => state.respondents.list.total;
export const selectHeaders = (state: AppState): ListComponentHeader[] => state.respondents.list.headers;

export const selectRespondent = (state: AppState): Respondent => state.respondents.show.data;
export const selectRespondentRequested = (state: AppState): boolean => state.respondents.show.loading;
export const selectRespondentFailed = (state: AppState): boolean => state.respondents.show.error;

export const selectDuplicates = (state: AppState): Respondent[] => state.respondents.duplicates.duplicates;
export const selectDuplicatesHeaders = (state: AppState): ListComponentHeader[] => state.respondents.duplicates.headers;
export const selectDuplicatesSearchLoading = (state: AppState): boolean => state.respondents.duplicates.loading;
export const selectDuplicatesError = (state: AppState): boolean => state.respondents.duplicates.error;

// Delete
export const selectRespondentDeleteLoading = (state: AppState): boolean => state.respondents.delete.loading;
export const selectRespondentDeleteSuccess = (state: AppState): boolean => state.respondents.delete.success;

// Update
export const selectRespondentUpdateLoading = (state: AppState): boolean => state.respondents.update.loading;

// update RL Fields
export const selectBookedText = (state: AppState): string => state.respondents.updateRlFields.bookedText;

// bulk delete
export const selectRespondentBulkDeleteLoading = (state: AppState): boolean => state.respondents.bulkDelete.loading;
export const selectRespondentBulkDeleteSuccess = (state: AppState): boolean => state.respondents.bulkDelete.success;

// join project
export const selectRespondentJoinProjectError = (state: AppState): boolean => state.respondents.joinProject.error;
export const selectRespondentJoinProjectSuccess = (state: AppState): boolean => state.respondents.joinProject.success;
export const selectRespondentJoinProjectLoading = (state: AppState): boolean => state.respondents.joinProject.loading;
export const selectRespondentJoinProjectMessage = (state: AppState): string => state.respondents.joinProject.message;
export const selectRespondentJoinProjectRlRevision = (state: AppState): any => state.respondents.joinProject.rlRevision;
