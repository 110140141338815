import React from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Project } from '../../../modules/projects';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        paper: {
            padding: `${theme.spacing(3)}px ${theme.spacing(12)}px`,
        },
        chip: {
            backgroundColor: '#f2f2f2',
            borderRadius: 4,
            color: '#4f4f4f',
            marginRight: theme.spacing(1),
        },
    })
);

export interface ProjectAddShowProps {
    project: Project;
}

export const ProjectAddShowComponent: React.FC<ProjectAddShowProps> = (props: ProjectAddShowProps) => {
    const classes = useStyles();

    const {project} = props;

    return (
        <Paper className={classes.paper} variant="outlined">
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                    <Grid container={true} spacing={3}>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <Typography variant={'h6'}>Project #</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={9}>
                            <Typography>{project.project_number}</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <Typography variant={'h6'}>Project Name</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={9}>
                            <Typography>{project.project_name}</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <Typography variant={'h6'}>Contact Name</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={9}>
                            <Typography>{project.contact}</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <Typography variant={'h6'}>Company Name</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={9}>
                            <Typography>{project.project_company_name}</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <Typography variant={'h6'}>Topic</Typography>
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={9}>
                            <Typography>{project.topic}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={6}>
                    <Grid className={classes.label} item={true} xs={12}>
                        <Box>
                            <Typography variant={'h6'}>Key words</Typography>
                            {project.project_key_words && project.project_key_words.map((keyword: string, key: number) =>
                                (
                                    <>
                                        <Chip
                                            key={key}
                                            size="small"
                                            label={keyword}
                                            className={classes.chip}
                                        />
                                    </>
                                ))}
                        </Box>
                    </Grid>
                    <Grid className={classes.label} item={true} xs={12}>
                        <Box>
                            <Typography variant={'h6'}>Client Internal</Typography>
                            {project.project_client_internal && project.project_client_internal.map((keyword: string, key: number) =>
                                (
                                    <>
                                        <Chip
                                            key={key}
                                            size="small"
                                            label={keyword}
                                            className={classes.chip}
                                        />
                                    </>
                                ))}
                        </Box>
                    </Grid>
                    <Grid className={classes.label} item={true} xs={12}>
                        <Box>
                            <Typography variant={'h6'}>Project Geographical Area</Typography>
                            {project.project_geographical_area && project.project_geographical_area.map((keyword: string, key: number) =>
                                (
                                    <>
                                        <Chip
                                            key={key}
                                            size="small"
                                            label={keyword}
                                            className={classes.chip}
                                        />
                                    </>
                                ))}
                        </Box>
                    </Grid>
                    <Grid className={classes.label} item={true} xs={12} md={3}>
                        <Typography variant={'h6'}>Fielding Dates</Typography>
                    </Grid>
                    <Grid className={classes.label} item={true} xs={12} md={9}>
                        <Typography>
                            {project.fielding_date_text}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
