import React, { useEffect } from 'react';
import { User } from '../../../modules/user/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from '../../../modules/roles/selectors';
import {
    selectUser,
    selectUserCreateError,
    selectUserCreateErrors,
    selectUserCreateLoading,
    selectUserCreateSuccess,
    userCreateRequest
} from '../../../modules/user';
import { toggleSnackbarOpen } from '../../../modules/notifications';
import { UserForm } from '../../../pages/User/UserForm';

export const UserAddContainer: React.FC = () => {
    const roles = useSelector(selectRoles);
    const user = useSelector(selectUser);
    const createUserLoading = useSelector(selectUserCreateLoading);
    const createUserSuccess = useSelector(selectUserCreateSuccess);
    const createUserError = useSelector(selectUserCreateError);
    const createUserErrors = useSelector(selectUserCreateErrors);
    const me: User = useSelector(selectUser);

    const dispatch = useDispatch();

    useEffect(() => {
        if (createUserSuccess) {
            dispatch(toggleSnackbarOpen('Record Saved', 'success'));
        }
    }, [createUserSuccess]);

    useEffect(() => {
        if (createUserError) {
            let errorMessage = '';
            Object.keys(createUserErrors).forEach((message: string) => errorMessage += createUserErrors[message]);
            dispatch(toggleSnackbarOpen(errorMessage, 'error'));
        }
    }, [createUserError]);

    const onSubmitForm = (values: User) => {
        dispatch(userCreateRequest(values));
    };

    return (
        <>
            <UserForm
                user={{} as User}
                me={me}
                projects={me.projects}
                updateLoading={createUserLoading}
                onSubmit={onSubmitForm}
                roles={roles}
                archivedUser={user.deleted_at && true}
            />
        </>
    )
};
