import React from 'react';
import { Provider } from 'react-redux';
import {
    Router,
    Route,
    Switch,
} from 'react-router-dom';
import './App.css';
import { store } from './modules';
import {
    RespondentPage,
    LoginPage,
    DashboardPage,
    ResetPasswordPage,
    ProjectsPage,
    ProjectsSearchPage,
    RespondentsListPage,
    ProjectsListPage,
    ProjectShowPage,
    RespondentsSearchPage,
    ProjectsAddPage,
    RespondentsAddPage,
    RespondentEditPage,
    ProjectEditPage,
    AnalyticsPage,
} from './pages';
import { Layout } from './components/common/Layout';
import history from './history';
import { UserContextProvider } from './contexts/UserContext';
import { prcTheme } from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { UserAddPage, UserPage, UserRolesPage } from './pages/User';
import { UserEditPage } from './pages/User/UserEditPage';
import { RespondentsImportPage } from './pages/RespondentsImportPage';
import { UserShowPage } from './pages/User/UserShowPage';
import { DictionariesPage } from './pages/DictionatiesPage';
import { DatabaseComparePage } from './pages/DatabaseComparePage';

const App: React.FC = () => (
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/login" exact={true} render={() => (<LoginPage/>)}/>
                <Route
                    path={['/password_reset', '/password_reset/:email?/:token?']}
                    exact={true}
                    render={() => (<ResetPasswordPage/>)}
                />
                <ThemeProvider theme={prcTheme}>
                    <UserContextProvider>
                        <Layout>
                            <Route path="/dashboard" exact={true} render={() => (<DashboardPage/>)}/>
                            <Route path="/search" exact={true} render={() => (<RespondentsSearchPage/>)}/>
                            <Route path="/respondents/list" exact={true} render={() => (<RespondentsListPage/>)}/>
                            <Route path="/respondents/show/:id" exact={true} render={() => (<RespondentPage/>)}/>
                            <Route path="/respondents/edit/:id" exact={true} render={() => (<RespondentEditPage/>)}/>
                            <Route path="/respondents/add" exact={true} render={() => (<RespondentsAddPage/>)}/>
                            <Route path="/respondents/import" exact={true} render={() => (<RespondentsImportPage/>)}/>
                            <Route path="/database/compare" exact={true} render={() => (<DatabaseComparePage/>)}/>
                            <Route path="/projects/" exact={true} render={() => (<ProjectsPage/>)}/>
                            <Route path="/projects/add" exact={true} render={() => (<ProjectsAddPage/>)}/>
                            <Route path="/projects/search" exact={true} render={() => (<ProjectsSearchPage/>)}/>
                            <Route path="/projects/list" exact={true} render={() => (<ProjectsListPage/>)}/>
                            <Route path="/projects/show/:id" exact={true} render={() => (<ProjectShowPage/>)}/>
                            <Route path="/projects/edit/:id" exact={true} render={() => (<ProjectEditPage/>)}/>
                            <Route path="/users" exact={true} render={() => (<UserPage/>)}/>
                            <Route path="/users/add" exact={true} render={() => (<UserAddPage/>)}/>
                            <Route path="/users/edit/:id" exact={true} render={() => (<UserEditPage/>)}/>
                            <Route path="/users/show/:id" exact={true} render={() => (<UserShowPage/>)}/>
                            <Route path="/users/roles" exact={true} render={() => (<UserRolesPage/>)}/>
                            <Route path="/analytics" exact={true} render={() => (<AnalyticsPage/>)}/>
                            <Route path="/dictionaries" exact={true} render={() => (<DictionariesPage/>)}/>
                        </Layout>
                    </UserContextProvider>
                </ThemeProvider>
            </Switch>
        </Router>
    </Provider>
);

export default App;
