export enum ExportToFileDoneType {
    EXPORT_TO_FILE_DONE_FAILURE = 'EXPORT/TO/FILE/DONE/FAILURE',
    EXPORT_TO_FILE_DONE_REQUEST = 'EXPORT/TO/FILE/DONE/REQUEST',
    EXPORT_TO_FILE_DONE_SUCCESS = 'EXPORT/TO/FILE/DONE/SUCCESS',

    EXPORT_TO_FILE_CHECK_DONE_START = 'EXPORT/TO/FILE/CHECK/DONE/START',
    EXPORT_TO_FILE_CHECK_DONE_STOP = 'EXPORT/TO/FILE/CHECK/DONE/STOP',
}


