import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectsSearchComponent, Values } from '../../components';
import Grid from '@material-ui/core/Grid';
import { filterValuesSet } from '../../modules/filterValue';
import { clearEmptyValues } from '../../helpers/formHelper';

export const ProjectsSearchContainer: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (values: Values) => {
        values = clearEmptyValues(values);

        dispatch(filterValuesSet(values));
        history.push('/projects/list');
    };

    return (
        <>
            <Grid spacing={3} container={true}>
                <Grid item={true} xs={12}>
                    <ProjectsSearchComponent
                        onSubmit={onSubmit}
                    />
                </Grid>
            </Grid>
        </>
    );
};
