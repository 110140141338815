export enum FileUploadActionType {
    FILE_UPLOAD_REQUEST = 'FILE/UPLOAD/REQUEST',
    FILE_UPLOAD_SUCCESS = 'FILE/UPLOAD/SUCCESS',
    FILE_UPLOAD_FAILURE = 'FILE/UPLOAD/FAILURE',

    FILE_UPLOAD_DATA_RESET_STATE = 'FILE/UPLOAD/DATA/RESET/STATE',
}

export interface FileUploadRequestPayload {
    file: File;
    url: string;
}

export interface FileUploadSuccessPayload {
    filter: any
}

export interface FileUploadFailurePayload {
    error: string
}

export type FileUploadRequestAction =
    ActionWithPayload<FileUploadActionType.FILE_UPLOAD_REQUEST, FileUploadRequestPayload>;

export type FileUploadSuccessAction =
    ActionWithPayload<FileUploadActionType.FILE_UPLOAD_SUCCESS, FileUploadSuccessPayload>;

export type FileUploadFailureAction =
    ActionWithPayload<FileUploadActionType.FILE_UPLOAD_FAILURE, FileUploadFailurePayload>;

export type FileUploadDataResetAction =
    ActionWithPayload<FileUploadActionType.FILE_UPLOAD_DATA_RESET_STATE, {}>;

export type FileUploadAction =
    FileUploadRequestAction |
    FileUploadSuccessAction |
    FileUploadFailureAction |
    FileUploadDataResetAction;
