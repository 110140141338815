import { ProjectAction } from '../actions';
import { Project, ProjectActionType } from '../constants';
import { ListComponentHeader } from '../../../interfaces';

export interface ProjectsAllState {
    loading: boolean;
    success: boolean;
    error: boolean;
    active: any[];
    archived: any[];
    headers: ListComponentHeader[];
    projects: Project[];
    activeTotal: number;
    archivedTotal: number;
}

const initialState: ProjectsAllState = {
    loading: false,
    success: false,
    error: false,
    active: [],
    archived: [],
    headers: [],
    projects: [],
    activeTotal: 0,
    archivedTotal: 0,
};

export const projectsAllReducer = (state: ProjectsAllState = initialState, action: ProjectAction) => {
    switch (action.type) {
        case ProjectActionType.PROJECTS_REQUEST:
        case ProjectActionType.PROJECTS_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: true,
                activeTotal: 0,
                archivedTotal: 0,
            };
        case ProjectActionType.PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                active: action.active,
                archived: action.archived,
                headers: action.headers,
                activeTotal: action.activeTotal,
                archivedTotal: action.archivedTotal,
            };
        case ProjectActionType.PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case ProjectActionType.PROJECTS_DASHBOARD_SUCCESS:
            return {
                ...state,
                projects: action.projects,
                loading: false,
                success: true,

            };
        case ProjectActionType.PROJECTS_DASHBOARD_RESET_STATE:
            return {
                ...state,
                projects: [],
            };
        default:
            return state;
    }
};
