import { User } from '../reducers/user'
import { UserActionType } from '../constants';
import { UserAction } from '../actions';

export interface UserShowState {
    loading: boolean;
    success: boolean;
    error: boolean;
    user: User;
}

const initialState: UserShowState = {
    loading: false,
    success: false,
    error: false,
    user: {} as User,
};

export const showReducer = (state: UserShowState = initialState, action: UserAction) => {
    switch (action.type) {
        case UserActionType.USER_SHOW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UserActionType.USER_SHOW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                user: action.user,
            };
        case UserActionType.USER_SHOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UserActionType.USER_SHOW_RESET_STATE:
            return {
                ...state,
                user: {},
            };
        default:
            return state;
    }
};
