import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectNotificationLevel,
    selectNotificationMessage,
    selectNotificationShow,
    toggleSnackbarClose
} from '../../../modules/notifications';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

export const Notification: React.FC = () => {
    const dispatch = useDispatch();

    const show = useSelector(selectNotificationShow);
    const message = useSelector(selectNotificationMessage);
    const level = useSelector(selectNotificationLevel);

    const handleClose = () => {
        dispatch(toggleSnackbarClose(level))
    };

    return (
        <div>
            <Snackbar open={ show } anchorOrigin={ {horizontal: 'center', vertical: 'top'} } onClose={ handleClose }>
                <Alert onClose={ handleClose } severity={level}>
                    { message }
                </Alert>
            </Snackbar>
        </div>
    );
}
