import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardForm } from '../../components/Forms/DashboardForm';
import { ProjectsList } from '../../components/ProjectsList';
import { makeStyles, Paper } from '@material-ui/core';
import { FilterSet, FilterValue } from '../RespondentsSearchContainer';
import Box from '@material-ui/core/Box';
import { projectsDashboardRequest, selectProjectsDashboard, selectProjectsRequested } from '../../modules/projects';
import { filterValuesSet } from '../../modules/filterValue';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme =>
    ({
        paper: {
            padding: theme.spacing(5),
        },
    }));

export const DashboardContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const projects = useSelector(selectProjectsDashboard);
    const loading = useSelector(selectProjectsRequested);
    const [dashboardFilter, setDashboardFilter] = useState<FilterSet>({});

    const onSubmit = () => {
        dispatch(filterValuesSet(dashboardFilter));
        history.push('/respondents/list');
    };

    useEffect(() => {
        dispatch(projectsDashboardRequest());
    }, []);

    const setFilterValue = (filterSetName: string, filterValue: FilterValue[]) => {
        if (dashboardFilter.hasOwnProperty(filterSetName)) {
            delete dashboardFilter[filterSetName];
        }

        dashboardFilter[filterSetName] = filterValue;

        setDashboardFilter(dashboardFilter);
    };

    const setInnerDashboardFilter = (filterValue: any) => {
        setFilterValue('demographic', filterValue)
    };

    return (
        <>
            <Paper variant="outlined" className={classes.paper}>
                <DashboardForm
                    onSubmit={onSubmit}
                    setFilter={setInnerDashboardFilter}
                />
            </Paper>

            <Box mt={4}>
                {loading && <LinearProgress/>}
                <ProjectsList
                    projects={projects}
                />
            </Box>
        </>
    )
};
