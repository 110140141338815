import { RespondentsListAction } from '../actions';
import { RespondentsList, RespondentsListActionType } from '../constants';

export interface RespondentsListCreateState {
    loading: boolean;
    success: boolean;
    error: boolean;
    list: RespondentsList;
}

const initialState: RespondentsListCreateState = {
    loading: false,
    success: false,
    error: false,
    list: {} as RespondentsList,
};

export const createReducer = (state: RespondentsListCreateState = initialState, action: RespondentsListAction) => {
    switch (action.type) {
        case RespondentsListActionType.RESPONDENTS_LIST_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                list: action.list,
            };
        case RespondentsListActionType.RESPONDENTS_LIST_CREATE_FAILURE:
            return {
                ...state,
                success: false,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
