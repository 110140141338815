export enum RespondentActionType {
    RESPONDENTS_LIST_FAILURE = 'RESPONDENTS/LIST/FAILURE',
    RESPONDENTS_LIST_REQUEST = 'RESPONDENTS/LIST/REQUEST',
    RESPONDENTS_LIST_SUCCESS = 'RESPONDENTS/LIST/SUCCESS',
    RESPONDENTS_LIST_RESET_STATE= 'RESPONDENTS/LIST/RESET/STATE',

    RESPONDENTS_SHOW_FAILURE = 'RESPONDENTS/SHOW/FAILURE',
    RESPONDENTS_SHOW_REQUEST = 'RESPONDENTS/SHOW/REQUEST',
    RESPONDENTS_SHOW_SUCCESS = 'RESPONDENTS/SHOW/SUCCESS',

    RESPONDENTS_CREATE_FAILURE = 'RESPONDENTS/CREATE/FAILURE',
    RESPONDENTS_CREATE_REQUEST = 'RESPONDENTS/CREATE/REQUEST',
    RESPONDENTS_CREATE_SUCCESS = 'RESPONDENTS/CREATE/SUCCESS',

    RESPONDENT_UPDATE_FAILURE = 'RESPONDENT/UPDATE/FAILURE',
    RESPONDENT_UPDATE_REQUEST = 'RESPONDENT/UPDATE/REQUEST',
    RESPONDENT_UPDATE_SUCCESS = 'RESPONDENT/UPDATE/SUCCESS',

    RESPONDENT_UPLOAD_ID_REQUEST = 'RESPONDENT/UPLOAD/ID/REQUEST',
    RESPONDENT_UPLOAD_ID_SUCCESS = 'RESPONDENT/UPLOAD/ID/SUCCESS',
    RESPONDENT_UPLOAD_ID_FAILURE = 'RESPONDENT/UPLOAD/ID/FAILURE',

    RESPONDENT_UPDATE_RL_FIELDS_FAILURE = 'RESPONDENT/UPDATE/RL/FIELDS/FAILURE',
    RESPONDENT_UPDATE_RL_FIELDS_REQUEST = 'RESPONDENT/UPDATE/RL/FIELDS/REQUEST',
    RESPONDENT_UPDATE_RL_FIELDS_SUCCESS = 'RESPONDENT/UPDATE/RL/FIELDS/SUCCESS',

    RESPONDENTS_DELETE_FAILURE = 'RESPONDENTS/DELETE/FAILURE',
    RESPONDENTS_DELETE_REQUEST = 'RESPONDENTS/DELETE/REQUEST',
    RESPONDENTS_DELETE_SUCCESS = 'RESPONDENTS/DELETE/SUCCESS',

    RESPONDENTS_BULK_DELETE_FAILURE = 'RESPONDENTS/BULK/DELETE/FAILURE',
    RESPONDENTS_BULK_DELETE_REQUEST = 'RESPONDENTS/BULK/DELETE/REQUEST',
    RESPONDENTS_BULK_DELETE_SUCCESS = 'RESPONDENTS/BULK/DELETE/SUCCESS',

    RESPONDENT_DUPLICATE_SEARCH_FAILURE = 'RESPONDENT/DUPLICATE/SEARCH/FAILURE',
    RESPONDENT_DUPLICATE_SEARCH_REQUEST = 'RESPONDENT/DUPLICATE/SEARCH/REQUEST',
    RESPONDENT_DUPLICATE_SEARCH_SUCCESS = 'RESPONDENT/DUPLICATE/SEARCH/SUCCESS',

    RESPONDENT_DUPLICATE_CREATE_FAILURE = 'RESPONDENT/DUPLICATE/CREATE/FAILURE',
    RESPONDENT_DUPLICATE_CREATE_REQUEST = 'RESPONDENT/DUPLICATE/CREATE/REQUEST',
    RESPONDENT_DUPLICATE_CREATE_SUCCESS = 'RESPONDENT/DUPLICATE/CREATE/SUCCESS',

    RESPONDENT_DUPLICATE_RESET_STATE = 'RESPONDENT/DUPLICATE/RESET/STATE',

    RESPONDENT_JOIN_PROJECT_REQUEST = 'RESPONDENT/JOIN/PROJECT/REQUEST',
    RESPONDENT_JOIN_PROJECT_SUCCESS = 'RESPONDENT/JOIN/PROJECT/SUCCESS',
    RESPONDENT_JOIN_PROJECT_FAILURE = 'RESPONDENT/JOIN/PROJECT/FAILURE',
    RESPONDENT_JOIN_PROJECT_RESET_STATE = 'RESPONDENT/JOIN/PROJECT/RESET/STATE',
}

export interface Respondent {
    /**
     * Demographic
     */
    _id?: string,
    last_name?: string,
    first_name?: string,
    home_phone?: string,
    business_phone?: string,
    street_address?: string,
    city?: string,
    state?: string,
    zip_code?: number,
    dob?: string,
    gender?: string,
    income?: string,
    marital_status?: string,
    cellphone?: string,
    county?: string,
    occupation?: string,
    role_in_company?: string,
    employment_status?: string,
    ethnicity?: number[],
    age_from?: number,
    age_to?: number,
    age?: string,
    rural_code?: string,
    /**
     * Education
     */
    education_level?: string,
    state_attended_college?: string,
    college_attended?: string,
    college_major?: string,

    /**
     * Electronics
     */
    cable_provider?: string,
    video_game_system?: string,

    /**
     * Employment
     */
    company?: string,
    industry?: string,
    occupation_other?: string,
    revenue_level?: number[],
    number_employees?: number,

    /**
     * Financial
     */
    checking_account?: boolean,
    checking_account_provider?: string,
    savings_account?: boolean,
    savings_account_provider?: string,
    credit_card_ow?: boolean,
    investment_account?: boolean,
    ammount_in_liquid_assets?: string,

    /**
     * Household
     */
    number_in_household?: string,
    children?: any[],
    hh_internet_provider?: string,
    home_ownership?: boolean,
    pool?: boolean,
    pool_type?: string,
    solar_power?: boolean,
    spa?: boolean,
    type_of_flooring?: string,
    type_of_home?: string,

    /**
     * Lifestyle
     */
    has_children?: boolean,
    alcohol?: string,
    cigarette?: string,
    length_of_cigarettes?: string,
    type_of_cigarettes?: string
    gym_membership?: boolean,
    smoker?: boolean,
    tobaco_deep_users?: boolean,
    tobaco_usage?: boolean,

    /**
     * Medical
     */
    diseases?: number[],

    /**
     * Military
     */
    current_military_branch?: number[],
    current_military_service?: boolean,
    past_military_branch?: number[],
    past_military_service?: boolean,

    /**
     * Personal
     */
    car_insurance?: boolean,
    car_insurance_provider?: number,
    car_give_for_anyone?: boolean,
    health_insurance?: boolean,
    health_insurance_provider?: number[],
    health_insurance_provider_other?: string,
    home_insurance?: boolean,
    home_insurance_provider?: number[],
    languages_spoken_in_hh?: number[],
    languages_spoken_outside_hh?: number[],
    other_language_spoken_in_home?: string,
    life_insurance?: boolean,
    life_insurance_provider?: number[],
    personal_income?: number[],
    place_of_birth?: string,
    primary_language?: number[],
    speak_spanish?: boolean,
    sexual_orientation?: number[],
    household_income?: number,

    /**
     * Political
     */
    democrat?: boolean,
    republican?: boolean,
    independant?: boolean,
    tea_party?: boolean,
    active_voter?: boolean,
    voter_status?: boolean,

    /**
     * Religion
     */
    religion?: number[],

    /**
     * Vehicle
     */
    own_atv?: boolean,
    own_boat?: boolean,
    own_motorcycle?: boolean,
    cars?: any[],

    /**
     * Pets
     */
    pets?: number[],

    /**
     * Covid
     */
    has_covid?: boolean,
    vaccinated?: boolean,
    vaccine_type?: string,
    vaccine_date?: string,
    covid_antibodies?: boolean,


    phone?: string,
    email?: string,
    comments?: string,
    sex_at_birth?: string,
    last_date_atten?: string,
    book_status?: boolean,
    do_not_use?: boolean,
    type?: string,
    rl?: any,
    history?: any,
    last_participation?: any,
    created_at?: string,
    updated_at?: string,
    files?: File[],
    upload_ids?: any,

    created_after?: string,
    created_before?: string,

    npi?: string,
}

export const respondentInitialValues: Respondent = {
    phone: '',
    first_name : '',
    last_name : '',
    age : '',
    email : '',
    street_address : '',
    city : '',
    state : '',
    county : '',
    rural_code : '',
    sex_at_birth : '',
    gender : '',
    ethnicity : [],
    marital_status : '',
    employment_status : '',
    income : '',
    occupation : '',
    role_in_company : '',
    last_date_atten : '',
    home_phone : '',
    business_phone : '',
    dob : '',
    vaccinated : false,
    vaccine_type : '',
    vaccine_date : '',
    covid_antibodies : false,
    has_covid : false,
    book_status : false,
    diseases : [],
    education_level: '',
    history: [],
    democrat: false,
    republican: false,
    independant: false,
    tea_party: false,
}
