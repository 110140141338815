import { RespondentsListActionType } from './constants';
import { Respondent } from '../respondents';
import { ListComponentHeader } from '../../interfaces';
import { RespondentsList } from './constants';

export interface RespondentsListCreateFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_CREATE_FAILURE;
}

export interface RespondentsListCreateRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_CREATE_REQUEST;
    respondentsList: RespondentsList;
}

export interface RespondentsListCreateSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_CREATE_SUCCESS;
    list: RespondentsList[];
}

export const respondentsListCreateFailure = (): RespondentsListCreateFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_CREATE_FAILURE,
});

export const respondentsListCreateRequest = (respondentsList: RespondentsList): RespondentsListCreateRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_CREATE_REQUEST,
    respondentsList,
});

export const respondentsListCreateSuccess = (list: RespondentsList[]): RespondentsListCreateSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_CREATE_SUCCESS,
    list,
});

export interface RespondentsListDeleteFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_DELETE_FAILURE;
}

export interface RespondentsListDeleteRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_DELETE_REQUEST;
    id: string;
}

export interface RespondentsListDeleteSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_DELETE_SUCCESS;
    list: RespondentsList[];
}

export type RespondentsListDeleteAction = RespondentsListDeleteFailure | RespondentsListDeleteRequest | RespondentsListDeleteSuccess;

export const respondentsListDeleteFailure = (): RespondentsListDeleteFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_DELETE_FAILURE,
});

export const respondentsListDeleteRequest = (id: string): RespondentsListDeleteRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_DELETE_REQUEST,
    id,
});

export const respondentsListDeleteSuccess = (list: RespondentsList[]): RespondentsListDeleteSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_DELETE_SUCCESS,
    list,
});

export interface RespondentsListRespondentsShowFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_FAILURE;
}

export interface RespondentsListRespondentsShowRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_REQUEST;
    _id: string;
    skip: number;
}

export interface RespondentsListRespondentsShowSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_SUCCESS;
    respondents: Respondent[];
    total: number,
    headers: ListComponentHeader[],
}

export const respondentsListRespondentsShowFailure = (): RespondentsListRespondentsShowFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_FAILURE,
});

export const respondentsListRespondentsShowRequest = (_id: string, skip: number): RespondentsListRespondentsShowRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_REQUEST,
    _id,
    skip,
});

export const respondentsListRespondentsShowSuccess = (respondents: Respondent[], total: number, headers: ListComponentHeader[]): RespondentsListRespondentsShowSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_SUCCESS,
    respondents,
    total,
    headers,
});

export interface RespondentsListShowFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_SHOW_FAILURE;
}

export interface RespondentsListShowRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_SHOW_REQUEST;
    id: string;
}

export interface RespondentsListShowSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_SHOW_SUCCESS;
    respondentsList: RespondentsList
}

export const respondentsListShowFailure = (): RespondentsListShowFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_SHOW_FAILURE,
});

export const respondentsListShowRequest = (id: string): RespondentsListShowRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_SHOW_REQUEST,
    id,
});

export const respondentsListShowSuccess = (respondentsList: RespondentsList): RespondentsListShowSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_SHOW_SUCCESS,
    respondentsList,
});

export interface RespondentsListUpdateFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_UPDATE_FAILURE;
}

export interface RespondentsListUpdateRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_UPDATE_REQUEST;
    respondentsList: RespondentsList;
}

export interface RespondentsListUpdateSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_UPDATE_SUCCESS;
    respondentsList: RespondentsList
}

export type RespondentsListUpdateAction = RespondentsListUpdateFailure | RespondentsListUpdateRequest | RespondentsListUpdateSuccess;

export const respondentsListUpdateFailure = (): RespondentsListUpdateFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_UPDATE_FAILURE,
});

export const respondentsListUpdateRequest = (respondentsList: RespondentsList): RespondentsListUpdateRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_UPDATE_REQUEST,
    respondentsList,
});

export const respondentsListUpdateSuccess = (respondentsList: RespondentsList): RespondentsListUpdateSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_UPDATE_SUCCESS,
    respondentsList,
});

export interface RespondentsListListFailure {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_LIST_FAILURE;
}

export interface RespondentsListListRequest {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_LIST_REQUEST;
}

export interface RespondentsListListSuccess {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_LIST_SUCCESS;
    list: RespondentsList[];
}

export interface RespondentsListListResetState {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_LIST_RESET_STATE;
}

export const respondentsListListFailure = (): RespondentsListListFailure => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_LIST_FAILURE,
});

export const respondentsListListRequest = (): RespondentsListListRequest => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_LIST_REQUEST,
});

export const respondentsListListSuccess = (list: RespondentsList[]): RespondentsListListSuccess => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_LIST_SUCCESS,
    list,
});

export const respondentsListListResetState = (): RespondentsListListResetState => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_LIST_RESET_STATE,
});

export interface RespondentsListShowClearState {
    type: typeof RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_RESET_STATE;
}

export const respondentsListRespondentsShowClearState = (): RespondentsListShowClearState => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_RESPONDENTS_SHOW_RESET_STATE,
});

export interface RespondentsListShowAwait {
    type: RespondentsListActionType.RESPONDENTS_LIST_SHOW_AWAIT;
}

export const respondentsListShowAwait = (): RespondentsListShowAwait => ({
    type: RespondentsListActionType.RESPONDENTS_LIST_SHOW_AWAIT,
});

export type RespondentsListAction =
    | RespondentsListShowFailure
    | RespondentsListShowRequest
    | RespondentsListShowSuccess
    | RespondentsListRespondentsShowFailure
    | RespondentsListRespondentsShowRequest
    | RespondentsListRespondentsShowSuccess
    | RespondentsListListFailure
    | RespondentsListListRequest
    | RespondentsListListSuccess
    | RespondentsListListResetState
    | RespondentsListCreateRequest
    | RespondentsListCreateFailure
    | RespondentsListCreateSuccess
    | RespondentsListShowClearState;
