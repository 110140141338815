import { UserActionType, UserChangePasswordAction } from '../constants';

export interface UserChangePasswordState {
    loading: boolean;
    error: string;
    message: string;
}

export const initialState: UserChangePasswordState = {
    loading: false,
    error: '',
    message: '',
};


export const userChangePasswordReducer = (
    state: UserChangePasswordState = initialState, action: UserChangePasswordAction
) => {
    switch (action.type) {
        case UserActionType.USER_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UserActionType.USER_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                error: '',
            };
        case UserActionType.USER_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: '',
            };
        default:
            return state;
    }
};
