import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    selectUserShow,
    selectUserShowLoading,
    userShowRequest,
} from '../../../modules/user';
import { Link, useParams } from 'react-router-dom';
import { User } from '../../../modules/user/reducers/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Can } from '../../../components/common/Can';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        font: {
            fontWeight: 600,
            fontSize: '13px',
        },
        select: {
            height: 48,
        },
        button: {
            margin: 5,
            padding: 3,
            backgroundColor: '#dfe7fe',
            borderRadius: '15%',
            '&:hover': {
                borderRadius: '15%',
                padding: 3,
            },
        },
        typography: {
            color: '#82a1fb',
            fontSize: 13,
        },
    })
);

export const UserShowContainer: React.FC = () => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const user: User = useSelector(selectUserShow);
    const loading = useSelector(selectUserShowLoading);

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(userShowRequest(id))
    }, []);

    return (
        <>
            {loading ? (
                <LinearProgress/>
            ) : (
                <Paper className={classes.paper} variant="outlined">
                    <Can to={'user.update'}>
                        <Box mt={-4} width={1} display="flex" justifyContent="flex-end">
                            <Link to={`/users/edit/${user._id}`}>
                                <Box display="flex" alignItems={'center'} flexDirection={'column'}>
                                    <IconButton
                                        className={classes.button}
                                        color={'primary'}
                                        aria-label="edit">
                                        <CreateOutlinedIcon/>
                                    </IconButton>
                                    <Typography className={classes.typography}>
                                        Edit
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Can>
                    <Grid container={true} spacing={3} justify={'center'}>
                        <Grid item={true} xs={9}>
                            <Grid container={true} spacing={2}>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-add-first-name-label">First Name</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <TextField
                                        fullWidth={true}
                                        aria-readonly={true}
                                        hiddenLabel={true}
                                        name={'first_name'}
                                        value={user.first_name}
                                    />
                                </Grid>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-add-last-name-label">Last Name</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <TextField
                                        fullWidth={true}
                                        aria-readonly={true}
                                        hiddenLabel={true}
                                        name={'last_name'}
                                        value={user.last_name}
                                    />
                                </Grid>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-edit-roles-label">User Roles</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <TextField
                                        aria-readonly={true}
                                        hiddenLabel={true}
                                        value={user.roles?.join()}
                                        placeholder="Roles"
                                    />
                                </Grid>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-add-email-label">Email Address</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <TextField
                                        id="user-add-email"
                                        margin="none"
                                        aria-readonly={true}
                                        hiddenLabel={true}
                                        fullWidth={true}
                                        name={'email'}
                                        value={user.email}
                                    />
                                </Grid>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-add-email-label">Select projects</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <TextField
                                        aria-readonly={true}
                                        hiddenLabel={true}
                                        value={user.projects_name}
                                        placeholder="Roles"
                                        multiline={true}
                                    />
                                </Grid>
                                {user.deleted_at && (
                                    <>
                                        <Grid className={classes.label} item={true} xs={12} md={3}>
                                            <InputLabel id="user-edit-archived-label">Archived</InputLabel>
                                        </Grid>
                                        <Grid item={true} xs={12} md={9}>
                                            <FormControlLabel
                                                name="user-edit-archived"
                                                control={<Checkbox color="secondary"/>}
                                                disabled={true}
                                                label="Archived"
                                                checked={true}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    );
};
