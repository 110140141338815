import {
    EXPORT_TO_FILE_DOWNLOAD,
    EXPORT_TO_FILE_FAILURE,
    EXPORT_TO_FILE_REQUEST,
    EXPORT_TO_FILE_RESET_STATE,
    EXPORT_TO_FILE_SUCCESS
} from './constants';
import { FilterSet } from '../../containers/RespondentsSearchContainer';

export interface ExportToFileFailure {
    type: typeof EXPORT_TO_FILE_FAILURE;
}

export interface ExportToFileRequest {
    type: typeof EXPORT_TO_FILE_REQUEST;
    filter: FilterSet;
    entity: string;
    fromFile: boolean | undefined;
}

export interface ExportToFileSuccess {
    type: typeof EXPORT_TO_FILE_SUCCESS,
    id: string;
}

export interface ExportToFileResetState {
    type: typeof EXPORT_TO_FILE_RESET_STATE,
}

export interface ExportToFileDownload {
    type: typeof EXPORT_TO_FILE_DOWNLOAD,
    link: string,
}

export type ExportToFileAction = ExportToFileFailure | ExportToFileRequest | ExportToFileSuccess | ExportToFileResetState | ExportToFileDownload;

export const exportToFileFailure = (): ExportToFileFailure => ({
    type: EXPORT_TO_FILE_FAILURE,
});

export const exportToFileRequest = (filter: FilterSet, entity: string, fromFile?: boolean): ExportToFileRequest => ({
    type: EXPORT_TO_FILE_REQUEST,
    filter,
    entity,
    fromFile,
});

export const exportToFileSuccess = (id: string): ExportToFileSuccess => ({
    type: EXPORT_TO_FILE_SUCCESS,
    id,
});

export const exportToFileResetState = (): ExportToFileResetState => ({
    type: EXPORT_TO_FILE_RESET_STATE,
});

export const exportToFileDownload = (link: string): ExportToFileDownload => ({
    type: EXPORT_TO_FILE_DOWNLOAD,
    link,
});


