import { User } from '../reducers/user'
import { UserActionType } from '../constants';
import { UserAction } from '../actions';
import { ApiErrors } from '../../../helpers/api-errors';

export interface UserUpdateState {
    loading: boolean;
    success: boolean;
    error: boolean;
    user: User;
    errors: ApiErrors;
}

const initialState: UserUpdateState = {
    loading: false,
    success: false,
    error: false,
    user: {} as User,
    errors: {} as ApiErrors,
};

export const updateReducer = (state: UserUpdateState = initialState, action: UserAction) => {
    switch (action.type) {
        case UserActionType.USER_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errors: {},
            };
        case UserActionType.USER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                user: action.user,
            };
        case UserActionType.USER_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errors: action.errors,
            };
        case UserActionType.USER_UPDATE_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
