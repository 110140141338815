import React from 'react';
import { NavLink } from 'react-router-dom';
import { createStyles, Divider, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { AccordionContainer } from '../common/AccordionContainer';
import { Medical } from './Medical';
import { Demographic } from './Demographic';
import { Education } from './Education';
import { Electronics } from './Electronics';
import { Employment } from './Employment';
import { Household } from './Household';
import { Personal } from './Personal';
import { Religion } from './Religion';
import { Vehicle } from './Vehicle';
import { Financial } from './Financial';
import { Lifestyle } from './Lifestyle';
import { Military } from './Military';
import { Political } from './Political';
import { Pets } from './Pets';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Respondent, selectUser } from '../../modules';
import { RespondentIdCopyToClipboardComponent } from '../Respondent/RespondentIdCopyToClipboardComponent';
import { RespondentDeleteComponent } from '../Respondent/RespondentDeleteComponent';
import moment from 'moment';
import history from '../../history';
import { User } from '../../modules/user/reducers/user';
import { useSelector } from 'react-redux';
import { RespondentAttendanceComponent } from '../Respondent/RespondentAttendanceComponent';
import {PhotoId} from "./PhotoID";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
        },
        typographyHeader: {
            fontWeight: 'bold',
        },
        typography: {},
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        label: {
            color: '#5680F9',
        },
        headerInfo: {
            backgroundColor: '#ffffff',
            boxShadow: 'inset 0px 0px 2px 0px #ccc',
            padding: '10px 12px',
            borderRadius: '5px',
            minWidth: '85px',
            height: '38px',
        },
        dnu: {
            color: '#f00',
        },
    })
);

export interface RespondentComponentProps {
    respondent: Respondent;
    dictionaries: any;
}

export interface RespondentInnerComponentProps {
    respondent: Respondent;
}


export const RespondentComponent: React.FC<RespondentComponentProps> = (props: RespondentComponentProps) => {
    const {respondent, dictionaries} = props;
    const classes = useStyles();
    const me: User = useSelector(selectUser);

    return (
        <Grid container={true} spacing={3} style={{marginBottom: 40}}>
            <Grid item={true} xs={12}>
                <AccordionContainer
                    title={'Participation History'}
                >
                    <RespondentAttendanceComponent
                        projects={me.projects}
                        respondent={respondent}
                    />
                </AccordionContainer>
            </Grid>

            <Grid item={true} xs={12}>

                <Box display={'flex'} justifyContent={history.location.hash !== '#view' ? 'space-between' : 'flex-end'} mb={2}>

                    {
                        history.location.hash !== '#view' && (
                            <NavLink to="/respondents/list" color="primary">
                                Return to list
                            </NavLink>
                        )
                    }

                    <Grid container={true} spacing={2} style={{justifyContent: 'end'}}>
                        <Grid item={true}>
                            <TextField
                                inputProps={{'aria-readonly': true, style: {color: '#4f4f4f'}}}
                                value={respondent.npi}
                                label="NPI #"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item={true}>
                            <RespondentIdCopyToClipboardComponent
                                id={props.respondent._id}
                            />
                        </Grid>
                    </Grid>

                </Box>

                <Divider />

                <Box display={'flex'} justifyContent={'flex-end'} mt={2}>

                    <FormControl fullWidth={false} style={{marginRight: '20px'}}>
                        <FormLabel className={classes.label}>Date created</FormLabel>
                        <FormLabel
                            className={classes.headerInfo}>{respondent.created_at ? moment(respondent.created_at).format('MM/DD/YYYY') : ''}</FormLabel>
                    </FormControl>

                    <FormControl fullWidth={false} style={{marginRight: '20px'}}>
                        <FormLabel className={classes.label}>Date modified</FormLabel>
                        <FormLabel
                            className={classes.headerInfo}>{respondent.updated_at ? moment(respondent.updated_at).format('MM/DD/YYYY') : ''}</FormLabel>
                    </FormControl>

                    <FormControl fullWidth={false} style={{marginRight: '20px'}}>
                        <FormLabel className={classes.label}>Last participation date</FormLabel>
                        <FormLabel
                            className={classes.headerInfo}>{respondent.last_participation ? moment(respondent.last_participation).format('MM/DD/YYYY') : ''}</FormLabel>
                    </FormControl>
                    <FormControl fullWidth={false} style={{marginRight: '20px'}}>
                        <FormLabel className={classes.label}>Respondent type</FormLabel>
                        <FormLabel
                            className={classes.headerInfo}>{respondent.type ? 'Healthcare Professional' : 'Consumer'}</FormLabel>
                    </FormControl>
                    <FormControl fullWidth={false}>
                        <FormLabel className={classes.label}>Status</FormLabel>
                        <FormLabel
                            className={`${classes.headerInfo} ${classes.dnu}`}>{respondent.do_not_use ? 'DNU' : 'Active'}</FormLabel>
                    </FormControl>
                </Box>
                <Demographic
                    respondent={respondent}
                />
            </Grid>

            <Grid item={true} xs={12}>
                <AccordionContainer
                    title={'Photo Id'}
                >
                    <PhotoId
                        respondentPhotoId={respondent.upload_ids}
                    />
                </AccordionContainer>
            </Grid>

            <Grid item={true} xs={12}>
                <AccordionContainer
                    title={'Medical'}
                >
                    <Medical
                        respondent={respondent}
                        dictionaries={dictionaries}
                    />
                </AccordionContainer>
            </Grid>

            <Box display={'flex'} flexGrow={1} mt={2} mx={1.5}>
                <Grid spacing={3} container={true}>
                    <Grid item={true} xs={12} md={6}>
                        <Grid spacing={3} container={true}>
                            <AccordionContainer
                                title={'Education'}
                            >
                                <Education
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Employment'}
                            >
                                <Employment
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Household'}
                            >
                                <Household
                                    respondent={respondent}
                                />

                            </AccordionContainer>

                            <AccordionContainer
                                title={'Personal'}
                            >
                                <Personal
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Religion'}
                            >
                                <Religion
                                    respondent={respondent}
                                />

                            </AccordionContainer>

                            <AccordionContainer
                                title={'Vehicle Ownership'}
                            >
                                <Vehicle
                                    respondent={respondent}
                                />
                            </AccordionContainer>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <Grid spacing={3} container={true}>
                            <AccordionContainer
                                title={'Electronics'}
                            >
                                <Electronics
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Financial'}
                            >
                                <Financial
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Lifestyle'}
                            >
                                <Lifestyle
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Military'}
                            >
                                <Military
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Political'}
                            >
                                <Political
                                    respondent={respondent}
                                />
                            </AccordionContainer>

                            <AccordionContainer
                                title={'Pets'}
                            >
                                <Pets
                                    respondent={respondent}
                                />
                            </AccordionContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2} width={1} display={'flex'} justifyContent={'flex-end'}>
                <RespondentDeleteComponent
                    id={props.respondent._id}
                />
            </Box>

        </Grid>
    );
};
