import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography, TextField, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import {
    resetPasswordStatus,
    resetPasswordStatusMessage,
    changePasswordRequesting
} from '../../../modules/resetPassword';


export interface ChangePasswordProps {
    onSubmit: (token: string, password: string, passwordConfirmation: string) => void;
    token?: string;
}

const useStyles = makeStyles(theme =>
    ({
        button: {
            backgroundColor: '#0000fe',
            margin: theme.spacing(3, 0, 2),
            textTransform: 'none',
            minWidth: 150,
        },
    }));

export const ChangePasswordComponent: React.FC<ChangePasswordProps> = (props: ChangePasswordProps) => {
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const token = props.token || '';
    const onSubmit = () => props.onSubmit(token, password, passwordConfirmation);
    const classes = useStyles();
    const changeRequesting = useSelector(changePasswordRequesting);
    const resetSuccess = useSelector(resetPasswordStatus);
    const resetSuccessMessage = useSelector(resetPasswordStatusMessage);
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >

                {
                    resetSuccess && (
                        <Alert severity={'success'}>
                            {resetSuccessMessage}
                        </Alert>
                    )}
                <>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required={true}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        defaultValue={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}

                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required={true}
                        name="password_confirmation"
                        label="Confirm Password"
                        type="password"
                        id="password_confirmation"
                        defaultValue={passwordConfirmation}
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(event.target.value)
                        }
                    />
                </>

                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={onSubmit}
                    className={classes.button}
                    disabled={changeRequesting}
                >
                    {changeRequesting && <CircularProgress size={24}/>}
                    {!changeRequesting && (
                        <Typography variant={'button'}>
                            Change Password
                        </Typography>
                    )}
                </Fab>
            </Box>
        </>
    );
};
