import React, { useContext } from 'react';
import { DictionaryContainer } from '../../containers';
import { Alert } from '@material-ui/lab';
import { PermissionContext } from '../../contexts/PermissionContext';

export const DictionariesPage: React.FC = () => {

    const {isAllowedTo} = useContext(PermissionContext);

    return (isAllowedTo('dictionary.view') ? (
        <DictionaryContainer/>
    ) : (
        <Alert severity="error">you are not authorized to view this page</Alert>
    ))
}


