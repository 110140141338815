import React, { useState } from 'react';
import { User } from '../../../modules/user/reducers/user';
import { useFormik } from 'formik';
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Role } from '../../../modules/roles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Project } from '../../../modules/projects';
import { SelectProjectsField } from '../../../components/common/Form/SelectProjectsField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        font: {
            fontWeight: 600,
            fontSize: '13px',
        },
        select: {
            height: 48,
        },
    })
);


interface UserFormProps {
    me: User;
    user: User;
    projects: Project[];
    updateLoading: boolean;
    onSubmit: (values: User) => void;
    roles: Role[];
    archivedUser?: boolean;
}

export const UserForm: React.FC<UserFormProps> = (props: UserFormProps) => {
    const {user, me, projects, updateLoading, onSubmit, roles, archivedUser = false} = props;
    const classes = useStyles();
    const [archived, setArchived] = useState(true);

    const onSubmitForm = (values: User) => {
        onSubmit(values);
    };

    const setSelected = (selected: string[]) => {
        user.project_ids = user.not_my_selected_projects_ids ? selected.concat(user.not_my_selected_projects_ids) : selected;
        formik.setFieldValue('project_ids', user.project_ids);
    };

    const handleArchiveChange = (checked: boolean) => {
        setArchived(checked);
        if (!checked) {
            delete formik.values.deleted_at;
        } else {
            formik.values.deleted_at = new Date();
        }
    };

    const formik = useFormik({
        initialValues: user,
        onSubmit: onSubmitForm,
    });

    return (
        <Paper className={classes.paper} variant="outlined">
            <Grid container={true} spacing={3} justify={'center'}>
                <Grid item={true} xs={9}>
                    <Grid container={true} spacing={2}>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <InputLabel id="user-add-first-name-label">First Name</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="First Name"
                                fullWidth={true}
                                {...formik.getFieldProps('first_name')}
                                helperText={formik.errors.first_name}
                            />
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <InputLabel id="user-add-last-name-label">Last Name</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="Last Name"
                                fullWidth={true}
                                {...formik.getFieldProps('last_name')}
                                helperText={formik.errors.last_name}
                            />
                        </Grid>
                        {me._id !== user._id && (
                            <>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-edit-roles-label">User Roles</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <FormControl size={'small'} variant="filled">
                                        <Autocomplete
                                            options={roles}
                                            getOptionLabel={(option: any) => option.name}
                                            popupIcon={<KeyboardArrowDownIcon/>}
                                            getOptionSelected={(option: any, value: any) => option.name === value.name}
                                            filterOptions={(options: any, state: any) => options}
                                            value={roles.find((option: Role) => formik.getFieldProps('role_ids')?.value?.[0] === (option._id)) || null}
                                            renderInput={params =>
                                                (
                                                    <TextField
                                                        label={'User Roles'}
                                                        {...params}
                                                        onKeyDown={(event: any) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                        }}

                                                    />
                                                )}
                                            onChange={(event: any, newValue: any) => {
                                                formik.setFieldValue('role_ids', [newValue?._id ?? null])
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <InputLabel id="user-add-email-label">Email Address</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <TextField
                                label="Email Address"
                                fullWidth={true}
                                {...formik.getFieldProps('email')}
                                helperText={formik.errors.name}
                            />
                        </Grid>
                        <Grid className={classes.label} item={true} xs={12} md={3}>
                            <InputLabel id="user-add-email-label">Select projects</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                            <SelectProjectsField
                                projects={projects}
                                setSelected={setSelected}
                                selected={user.project_ids || []}
                            />
                        </Grid>
                        {archivedUser && (
                            <>
                                <Grid className={classes.label} item={true} xs={12} md={3}>
                                    <InputLabel id="user-edit-archived-label">Archived</InputLabel>
                                </Grid>
                                <Grid item={true} xs={12} md={9}>
                                    <FormControlLabel
                                        name="user-edit-archived"
                                        control={<Checkbox color="secondary"/>}
                                        onChange={(e: any) => {
                                            handleArchiveChange(e.target.checked)
                                        }}
                                        label="Archived"
                                        checked={archived}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Box width={1} mt={3} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            type="submit"
                            variant="contained"
                            size={'large'}
                            color="primary"
                            disabled={updateLoading}
                            onClick={() => formik.handleSubmit()}
                        >
                            {updateLoading ? (
                                <CircularProgress size={24}/>
                            ) : (
                                <Typography>
                                    Save
                                </Typography>
                            )}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};
