import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { resetPasswordFailure, ChangePasswordRequest, resetPasswordSuccess } from '../actions';
import { handleApiErrors } from '../../../helpers/api-errors';
import history from '../../../history';

export default function* ChangePasswordSaga(action: ChangePasswordRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            'api/reset-password',
            METHOD_POST,
            {
                token: action.token,
                password: action.password,
                password_confirmation: action.passwordConfirmation,
                email: action.email,
            }
        );
        yield put(resetPasswordSuccess(data.status));

        history.push('/login');
    } catch (error) {
        yield put(resetPasswordFailure(handleApiErrors(error)))
    }
}
