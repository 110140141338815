import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import Box from '@material-ui/core/Box';
import { Project } from '../../modules/projects';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

interface ProjectListProps {
    projects: Project[],
}

const useStyles = makeStyles(theme =>
    ({
        paper: {
            padding: theme.spacing(5),
        },
        projectName: {
            color: '#4f4f4f',
            fontSize: 18,
            fontWeight: 700,
        },
    }));

export const ProjectsList: React.FC<ProjectListProps> = (props: ProjectListProps) => {
    const projects = props.projects;
    const classes = useStyles();

    return (
        <>
            <Grid container={true} spacing={4}>
                {projects && projects.map((project: Project, index) => (
                    <>
                        <Grid key={project._id} xs={6} item={true}>
                            <Paper className={classes.paper} variant="outlined">
                                <Box mb={4} display={'flex'} justifyContent={'space-between'}>
                                    <Link
                                        to={`projects/show/${project._id}`}
                                    >
                                        <Typography className={classes.projectName}>{project.project_name}</Typography>
                                    </Link>

                                    <Link
                                        to={`projects/show/${project._id}`}
                                    >
                                        <Badge badgeContent={project.unread_comments || 0} color="secondary">
                                            <ChatOutlined style={{color: '#4f4f4f'}} />
                                        </Badge>
                                    </Link>
                                </Box>

                                <Grid container={true} spacing={2} justify={'center'}>
                                    <Grid xs={12} sm={12} item={true}>
                                        <TextField
                                            margin="none"
                                            value={project.project_number}
                                            fullWidth={true}
                                            aria-readonly={true}
                                            hiddenLabel={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} item={true}>
                                        <TextField
                                            margin="none"
                                            value={project.contact}
                                            fullWidth={true}
                                            aria-readonly={true}
                                            hiddenLabel={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} item={true}>
                                        <TextField
                                            margin="none"
                                            value={project.booked_text}
                                            fullWidth={true}
                                            aria-readonly={true}
                                            hiddenLabel={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} item={true}>
                                        <TextField
                                            margin="none"
                                            value={project.fielding_date_text}
                                            fullWidth={true}
                                            aria-readonly={true}
                                            hiddenLabel={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    )
};
