export enum ResetPasswordActionType {
    RESET_PASSWORD_REQUESTING = 'RESET_PASSWORD_REQUESTING',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
    CHANGE_PASSWORD_REQUESTING = 'CHANGE_PASSWORD_REQUESTING',
}

export interface ResetPasswordRequest {
    type: typeof ResetPasswordActionType.RESET_PASSWORD_REQUESTING;
    email: string;
}

export interface ChangePasswordRequest {
    type: typeof ResetPasswordActionType.CHANGE_PASSWORD_REQUESTING;
    token: string;
    password: string;
    passwordConfirmation: string;
    email: string;
}

export interface ResetPasswordSuccess {
    type: typeof ResetPasswordActionType.RESET_PASSWORD_SUCCESS,
    status: string;
}

export interface ResetPasswordFailure {
    type: typeof ResetPasswordActionType.RESET_PASSWORD_ERROR;
    error: string;
}

export type ResetPasswordAction =
    ResetPasswordRequest | ResetPasswordSuccess | ResetPasswordFailure | ChangePasswordRequest;

export const resetPasswordRequest = (email: string): ResetPasswordRequest => ({
    type: ResetPasswordActionType.RESET_PASSWORD_REQUESTING,
    email,
});

export const changePasswordRequest =
    (token: string, password: string, passwordConfirmation: string, email: string): ChangePasswordRequest => (
        {
            type: ResetPasswordActionType.CHANGE_PASSWORD_REQUESTING,
            token,
            password,
            passwordConfirmation,
            email,
        });

export const resetPasswordSuccess = (status: string): ResetPasswordSuccess => ({
    type: ResetPasswordActionType.RESET_PASSWORD_SUCCESS,
    status,
});

export const resetPasswordFailure = (error: string): ResetPasswordFailure => ({
    type: ResetPasswordActionType.RESET_PASSWORD_ERROR,
    error,
});


