import { RespondentsList } from '../respondentsList';
import { Comment } from '../comments';

export enum ProjectActionType {
    PROJECTS_FAILURE = 'PROJECTS/FAILURE',
    PROJECTS_REQUEST = 'PROJECTS/REQUEST',
    PROJECTS_SUCCESS = 'PROJECTS/SUCCESS',

    PROJECTS_UPDATE_FAILURE = 'PROJECTS/UPDATE/FAILURE',
    PROJECTS_UPDATE_REQUEST = 'PROJECTS/UPDATE/REQUEST',
    PROJECTS_UPDATE_SUCCESS = 'PROJECTS/UPDATE/SUCCESS',
    PROJECTS_UPDATE_RESET_STATE = 'PROJECTS/UPDATE/RESET/STATE',

    PROJECTS_CREATE_FAILURE = 'PROJECTS/CREATE/FAILURE',
    PROJECTS_CREATE_REQUEST = 'PROJECTS/CREATE/REQUEST',
    PROJECTS_CREATE_SUCCESS = 'PROJECTS/CREATE/SUCCESS',
    PROJECTS_CREATE_RESET_STATE = 'PROJECTS/CREATE/RESET/STATE',

    PROJECTS_LIST_FAILURE = 'PROJECTS/LIST/FAILURE',
    PROJECTS_LIST_REQUEST = 'PROJECTS/LIST/REQUEST',
    PROJECTS_LIST_SUCCESS = 'PROJECTS/LIST/SUCCESS',

    PROJECTS_SHOW_FAILURE = 'PROJECTS/SHOW/FAILURE',
    PROJECTS_SHOW_REQUEST = 'PROJECTS/SHOW/REQUEST',
    PROJECTS_SHOW_SUCCESS = 'PROJECTS/SHOW/SUCCESS',
    PROJECTS_SHOW_RESET_STATE = 'PROJECTS/SHOW/RESET/STATE',

    PROJECTS_DASHBOARD_FAILURE = 'PROJECTS/DASHBOARD/FAILURE',
    PROJECTS_DASHBOARD_REQUEST = 'PROJECTS/DASHBOARD/REQUEST',
    PROJECTS_DASHBOARD_SUCCESS = 'PROJECTS/DASHBOARD/SUCCESS',
    PROJECTS_DASHBOARD_RESET_STATE = 'PROJECTS/DASHBOARD/RESET/STATE',

    PROJECTS_REFRESH = 'PROJECTS/REFRESH',
}

export interface Project {
    _id: string,
    id?: string,
    state?: number,
    contact?: string,
    project_number?: string,
    comments?: Comment[],
    unread_comments?: number,
    comments_count?: number,
    respondents_list?: RespondentsList[]
    project_name: string,
    display_name: string,
    project_company_name?: string,
    topic?: string,
    project_key_words?: string[],
    project_client_internal?: string[],
    project_geographical_area?: string[],
    project_description?: string,
    project_incentives?: string,
    checked?: boolean,
    target_to_book?: string,
    booked?: string,
    booked_text: string,
    fielding_date_at: any,
    fielding_date_to: any,
    fielding_date_text: string,
}
