import { combineReducers } from 'redux';
import { RespondentsListShowState, showReducer } from './show';
import { RespondentsListRespondentsState, respondentsListRespondentsReducer } from './respondentsList';
import { createReducer, RespondentsListCreateState } from './create';
import { deleteReducer, RespondentsListDeleteState } from './delete';
import { RespondentsListUpdateState, updateReducer } from './update';

// Respondents List state interface
export interface RespondentsListState {
    show: RespondentsListShowState;
    create: RespondentsListCreateState;
    delete: RespondentsListDeleteState;
    update: RespondentsListUpdateState;
    respondentsListRespondents: RespondentsListRespondentsState;
}

// preparing app respondentsListReducer
export const respondentsListReducer = combineReducers({
    show: showReducer,
    create: createReducer,
    delete: deleteReducer,
    update: updateReducer,
    respondentsListRespondents: respondentsListRespondentsReducer,
});
