import React, { ChangeEvent, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Dictionary } from '../../modules/dictionaries';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

interface Props {
    open: boolean;
    handleClose: () => void;
    handleAddDictionary: () => void;
    setValues: (dictionary: Dictionary) => void;
    groups: Dictionary[];
    dictionary: Dictionary;
    loading: boolean;
    errorMessage: string;
    createSuccess: boolean;
    edit: boolean;
    group: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            color: '#5680f9',
            fontSize: 25,
            fontWeight: 'bolder',
        },
        dialogActions: {
            '& button:hover span': {
                color: '#fff',
            },
            '& button': {
                maxHeight: 40,
            },
            marginTop: 40,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modifyButton: {
            '& span': {
                color: '#295ce4',
                fontWeight: 500,
            },
            '&.Mui-disabled span': {
                color: '#fff',
            },
            backgroundColor: '#f3f6ff',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })
);

export const DictionaryAddDialogComponent: React.FC<Props> = (props: Props) => {
    const {
        open,
        handleClose,
        handleAddDictionary,
        setValues,
        groups,
        dictionary,
        loading,
        errorMessage,
        createSuccess,
        edit = false,
    } = props;
    const classes = useStyles();

    const setDictionaryValues = (name: string, value: string) => {
        const dict = {...dictionary}
        dict[name] = value;
        setValues(dict);
    };

    return (
        <Dialog
            open={open}
            maxWidth={'xl'}
            onClose={handleClose}
            aria-labelledby="duplicates-dialog-title"
            aria-describedby="duplicates-dialog-description"
        >
            <DialogTitle id="duplicates-dialog-title">
                <Typography
                    align="center"
                    className={classes.dialogTitle}
                >{edit ? 'Edit' : 'Add'} Dictionary</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {errorMessage && (
                    <Box mb={3}>
                        <Alert severity="error">
                            {errorMessage}
                        </Alert>
                    </Box>
                )}
                {createSuccess && (
                    <Box mb={3}>
                        <Alert severity="success">
                            Success!
                        </Alert>
                    </Box>
                )}
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={4}>
                        <FormControl size={'small'} variant="filled">
                            <InputLabel id="dictionary-group-label">Group</InputLabel>
                            <Select
                                labelId="dictionary-group-label"
                                id="dictionary-group"
                                value={dictionary.group || props.group}
                                disabled={edit}
                                IconComponent={KeyboardArrowDownIcon}
                                defaultValue={''}
                                name={'group'}
                                onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
                                    setDictionaryValues(e.target.name as string, e.target.value as string)
                                }}
                            >
                                {
                                    groups && groups.map(
                                        (group: Dictionary, key: number) =>
                                            (
                                                <MenuItem key={key}
                                                          value={group.value}>{group.text}</MenuItem>
                                            )
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <TextField
                            name={'text'}
                            label={'Text'}
                            value={dictionary.text}
                            onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
                                setDictionaryValues(e.target.name as string, e.target.value as string)
                            }}
                        />
                    </Grid>
                    <Grid item={true} xs={4}>
                        <TextField
                            label="Order"
                            type="number"
                            name={'order'}
                            value={dictionary.order}
                            onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
                                setDictionaryValues(e.target.name as string, e.target.value as string)
                            }}
                            inputProps={{min: 0}}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="contained"
                    size={'large'}
                    disabled={loading}
                    color="primary"
                    onClick={() => handleAddDictionary()}
                >
                    {loading ? (
                        <CircularProgress style={{color: '#fff'}} size={24}/>
                    ) : (
                        <Typography>
                            {edit ? 'Edit' : 'Add'} Dictionary
                        </Typography>
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
