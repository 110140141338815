import { call, put } from 'redux-saga/effects';
import { METHOD_GET, requestBuilder } from '../../helpers';
import {
    chartsFailure,
    ChartsRequest,
    chartsSuccess
} from './actions';

export function* ChartsRequestSaga(action: ChartsRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            'api/charts',
            METHOD_GET,
            {
                field: action.payload
            }
        );

        yield put(chartsSuccess(data.chart, data.all));
    } catch (error) {
        yield put(chartsFailure());
    }
}
