import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import FormControl from '@material-ui/core/FormControl';
import { createGetAutocompleteProps } from '../../../helpers/formBuilder';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
    })
);

export const Education: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const stateDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'state'));
    const collegeAttendedDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'college_attended'));
    const collegeMajorDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'college_major'));

    const [values, setValues] = useState(respondent);

    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'State'} {...params} />
                                        )}
                                    {...getAutocompleteProps('state_attended_college', stateDictionary)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'College Attended'} {...params} />
                                        )}
                                    {...getAutocompleteProps('college_attended', collegeAttendedDictionary)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={ 12 } item={ true }>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'College Major'} {...params} />
                                        )}
                                    {...getAutocompleteProps('college_major', collegeMajorDictionary)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
