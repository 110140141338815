import React from 'react';

import { createStyles,  Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import { selectDictionaryGroup } from '../../../modules/dictionaries';

interface CarFields {
    make: string,
    model: string,
    year: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
    })
);

export const Vehicle : React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();
    const carMakesDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'car_make'));

    return (
        <Grid container={true} spacing={3} justify={'center'}>
            <Grid xs={12} item={true}>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Own ATV
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.own_atv }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Own Boat
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.own_boat }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Own Motorcycle
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.own_motorcycle }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Car
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            {
                                respondent?.cars?.length && respondent.cars.map(
                                    (item: CarFields, index: number) =>
                                        (
                                            <Typography key={ index } style={ {width: '100%'} } className={ classes.typography }>
                                                { carMakesDictionary.find(make => make.value === item.make)?.text.concat(' : ') }
                                                { item.model && item.year ? item.model.concat(' : ') : item.model }
                                                { item.year }
                                            </Typography>
                                        )
                                )
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
