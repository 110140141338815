import {
    createStyles,
    makeStyles,
    withStyles,
    Theme,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    TablePagination,
    TableFooter, TableContainerProps, TableSortLabel,
    LinearProgress,
    CircularProgress, Checkbox
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FilterSet } from '../../containers';
import Paper from '@material-ui/core/Paper';
import { ArchiveOutlined, DeleteOutlined } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ListComponentHeader } from '../../interfaces';
import { Project } from '../../modules/projects';
import { responseFormatters } from '../../helpers/responseFormatter';
import { Can } from '../common/Can';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { selectRespondentBulkDeleteSuccess, selectUser } from '../../modules';
import { ConfirmDialog } from '../common/ConfirmDialog';
import history from '../../history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            check_label: {
                fontSize: '20px',
                display: 'block',
                paddingLeft: 7,
                '& span': {
                    display: 'flex',
                    alignSelf: 'center',
                    position: 'relative',
                    height: '20px',
                    width: '20px',
                    border: '2px solid rgba(0, 0, 0, 0.54)',
                    borderRadius: '2px',
                    cursor: 'pointer',
                },
                '& span:hover': {
                    boxShadow: ' 0px 0px 4px 0px #999',
                },
                '& .checked': {
                    backgroundColor: '#ef7311',
                    border: '2px solid #ef7311',
                    boxShadow: ' 0px 0px 4px 0px #999',
                },
                '& .checked:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    left: '5px',
                    width: '7px',
                    height: '12px',
                    border: 'solid #fff',
                    borderWidth: '0 2px 2px 0',
                    transform: 'rotate(45deg)',
                },
            },
            nav: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
            },
            table: {
                minWidth: 700,
                '&.bordered': {
                    '& tbody': {
                        '& td': {
                            paddingLeft: 10,
                            borderRight: '1px solid #E0E0E0',
                        },
                    },
                },
                '& tbody': {
                    '& td': {
                        paddingLeft: 10,
                        fontSize: 14,
                        height: 40,
                        maxWidth: 300,
                        '&:first-child': {
                            borderLeft: '1px solid #E0E0E0',
                        },
                        '&:last-child': {
                            padding: 0,
                        },
                        '&:nth-last-child(-n+2)': {
                            borderRight: '1px solid #E0E0E0',
                        },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
            },
            actionLink: {
                color: '#BDBDBD',
                fontSize: '10px',
                cursor: 'pointer',
            },
            actionButton: {
                display: 'block',
                color: '#BDBDBD',
                cursor: 'pointer',
            },
            actionIcon: {
                color: '#BDBDBD',
                fontSize: '1.42rem',
                display: 'block',
            },
            doNotUse: {
                color: '#dc5656',
                fontSize: '1.42rem',
                display: 'block',
            },
            exportButton: {
                backgroundColor: '#fff',
                border: '2px solid #5680f9',
                '&:hover': {
                    border: '2px solid #5680f9',
                    backgroundColor: '#fff',
                },
                '& span': {
                    color: '#5680f9',
                    fontWeight: 600,
                    fontSize: 'larger',
                },
            },
            statusSelect: {
                '& div': {
                    padding: 7,
                },
            },
            visuallyHidden: {
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: -1,
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                top: 20,
                width: 1,
                opacity: 1,
            },
            displayInline: {
                    display: 'inline-block',
            },
            displayInlineM: {
                display: 'inline-block',
                marginLeft: '8px',
            },
            tdTextCenter: {
                textAlign: 'center',
            },
        }
    )
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            paddingLeft: 10,
        },
        body: {
            fontSize: 14,
            paddingLeft: 10,
        },
    })
)(TableCell);

interface EnhancedTableProps {
    checkboxSelection: boolean;
    headBackgroundColor: string;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    numSelected: number;
    rowCount: number;
    onSortClick: (property: string) => (event: React.MouseEvent<unknown>) => void;
    headers: ListComponentHeader[];
    hideHeadings?: string[];
    sortable?: boolean;
    headTextColor: string;
    classes: ReturnType<typeof useStyles>;
    orderBy: string;
    orderDirection: Order;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        checkboxSelection,
        headBackgroundColor,
        onSelectAllClick,
        numSelected,
        rowCount,
        onSortClick,
        sortable,
        headTextColor,
        classes,
        orderBy,
        orderDirection,
    } = props;

    return (
        <TableHead style={{height: 45, backgroundColor: headBackgroundColor}}>
            <TableRow>
                {checkboxSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                        />
                    </TableCell>
                )}
                {
                    props.headers.map(header =>
                            (sortable && header.sortable ?
                                    (
                                        <StyledTableCell key={header.name}
                                                         style={{color: headTextColor}}>
                                            <TableSortLabel
                                                active={orderBy === header.name}
                                                direction={orderBy === header.name ? orderDirection : 'asc'}
                                                onClick={onSortClick(header.name)}
                                            >
                                                {header.label || header.name}

                                                {orderBy === header.name ? (
                                                    <span className={classes.visuallyHidden}>
                                                                          {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                        </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    )
                                    :
                                    (
                                        <StyledTableCell key={header.name}
                                                         style={{color: headTextColor}}>
                                            {header.label || header.name}
                                        </StyledTableCell>
                                    )
                            )
                    )
                }
            </TableRow>
        </TableHead>
    );
}

interface ListComponentProps {
    rows: any[];
    listOf?: string;
    exportAbilities?: boolean;
    handleExport?: () => void;
    exportLoading?: boolean;
    bulkDeleteLoading?: boolean;
    loading?: boolean;
    query: FilterSet;
    total: number;
    pageChange: (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => void;
    headers: ListComponentHeader[];
    returnLink?: string;
    headBackgroundColor?: string,
    headTextColor?: string,
    actionArchive?: boolean
    actionDelete?: boolean
    onHandleArchive?: (row: any) => void;
    onHandleDelete?: (row: any) => void;
    onHandleBulkDelete?: (ids: string[], skip: number, orderBy?: string, orderDirection?: string) => void;
    rowsPerPage?: number;
    sortable?: boolean;
    hideHeadings?: string[];
    showActions?: boolean;
    showPagination?: boolean;
    showTotal?: boolean;
    rowChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, id: string, respondent: any, page: number) => void;
    showEdit?: boolean;
    serverSide?: boolean;
    exportDoneLoading?: boolean;
    exportDoneSuccess?: boolean;
    targetBlank?: boolean;
    class?: string;
    dictActions?: boolean;
    editDict?: (row: any) => void;
}

interface ArchiveDialogProps {
    open: boolean,
    itemName: string,
    handleClose: () => void,
    handleArchive: () => void;
}

type Order = 'asc' | 'desc';

const AlertDialog: React.FC<ArchiveDialogProps> = (props: ArchiveDialogProps) =>
    (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>Are You sure you want to archive this {props.itemName}?</Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        size={'large'}
                        color="primary"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={props.handleArchive}
                        variant="contained"
                        size={'large'}
                        color="primary"
                        autoFocus={true}>
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

export const ListComponent: React.FC<ListComponentProps> = (props: ListComponentProps) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('');
    const [orderDirection, setOrderDirection] = useState<Order>('desc');
    const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<Project>({} as Project);
    const [selected, setSelected] = useState<string[]>([]);
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const bulkDeleteSuccess = useSelector(selectRespondentBulkDeleteSuccess);
    const user = useSelector(selectUser);

    const {
        rows = [],
        headBackgroundColor = '#ddf6d9',
        headTextColor = '#005001',
        actionArchive = false,
        actionDelete = false,
        rowsPerPage = 500,
        exportAbilities = false,
        listOf = 'Project',
        showActions = true,
        showPagination = true,
        showTotal = true,
        showEdit = false,
        serverSide = true,
        targetBlank = false,
        dictActions = false,
    } = props;

    const linkName = listOf.charAt(0).toLowerCase() + listOf.slice(1);
    const linkNameUrl = linkName + 's';
    const headers = props.headers.filter(header => !header.hidden && !props.hideHeadings?.includes(header.label));

    useEffect(
        () => {
            if (bulkDeleteSuccess) {
                setSelected([])
            }
        },
        [bulkDeleteSuccess]
    );

    const getLink = (action: string, id: string) =>
        `/${linkNameUrl}/${action}/${id}${targetBlank ? '#view' : ''}`;

    const linkTo = (e: any) => {
        e.stopPropagation();

        if (!targetBlank){
            e.preventDefault();
            history.push(e.target.parentNode.getAttribute('href'));
        }
    };

    const handleClickArchive = (row: Project) => {
        setActiveRow(row);
        setOpenArchiveDialog(true);
    };

    const handleClickDelete = (row: Project) => {
        setActiveRow(row);
        setOpenDeleteDialog(true);
    };

    const handleCloseArchiveDialog = () => {
        setOpenArchiveDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const onArchiveClick = () => {
        if (props.onHandleArchive) {
            props.onHandleArchive(activeRow);
        }
        handleCloseArchiveDialog();
    };

    const onDeleteClick = () => {
        if (props.onHandleDelete) {
            props.onHandleDelete(activeRow);
        }
        handleCloseDeleteDialog();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        if (serverSide) {
            const skip = newPage * rowsPerPage;

            props.pageChange(props.query, skip, orderBy, orderDirection);
        }

        if (selected.length) {
            setSelected([]);
        }
    };

    const handleSortClick = (property: string) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && orderDirection === 'asc';

        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);

        if (serverSide) {
            props.pageChange(props.query, 0, property, isAsc ? 'desc' : 'asc');
        }

        if (selected.length) {
            setSelected([]);
        }
    };

    const getTableSelect = (header: ListComponentHeader, name: string, id: string, respondent: any) => {

        const selectProps: any = {
            'name': name,
            'value': name === 'Status' ? respondent.rl_status : respondent.rl_contact,
        };

        return (
            <Select
                {...selectProps}
                className={classes.statusSelect}
                IconComponent={KeyboardArrowDownIcon}
                name={name}
                onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => props.rowChange && props.rowChange(e, id, respondent, page)}
            >
                {
                    header.options.map(
                        (item: any) =>
                            (
                                <MenuItem key={item.value}
                                          value={item.value}>{item.text}</MenuItem>
                            )
                    )
                }
            </Select>
        )
    };


    const getTableCellValue = (row: any, header: ListComponentHeader): string | JSX.Element => {

        if (header.join) {
            return row[header.name] ? row[header.name].join(', ') : '';
        }

        if (header.name === 'do_not_use') {
            return row[header.name] ? (
                <span className={`material-icons-outlined MuiIcon-root ${classes.doNotUse}`}>
                    error
                </span>
            ) : '';
        }

        let value = row[header.name + '_text'] ? row[header.name + '_text'] : row[header.name];

        if (value) {
            if (header.capitalize) {
                value = value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
            }

            if (header.pascalCase) {
                value = value.toLowerCase().replace(/\w+/g, (w: string) => w[0].toUpperCase() + w.slice(1));
            }

            if (header.lowercase) {
                value = value.toLowerCase();
            }

            if (header.formatter) {
                header.formatter.split(',').forEach(formatter =>
                    value = responseFormatters[formatter as keyof string](value)
                );
            }
        }

        return (value || typeof value === 'number') ? value : '';
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: any) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        if (!checkboxSelection) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleBulkDelete = () => {
        if (props.onHandleBulkDelete) {
            const skip = page * rowsPerPage;
            props.onHandleBulkDelete(selected, skip, orderBy, orderDirection);
        }
    }

    function getComparator(order: any, orderBy: any) {
        return order === 'desc'
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a: any, b: any, orderBy: any) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array: any, comparator: any) {
        const stabilizedThis = array.map((el: any, index: any) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el: any) => el[0]);
    }

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const tableRows = () => {
        if (serverSide) {
            return props.rows;
        } else {
            return stableSort(props.rows, getComparator(orderDirection, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
    }

    return (
        <>
            <Box mb={2} display={'flex'} width={1} justifyContent={'space-between'}>
                {showTotal && (
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                        <Typography style={{color: '#979797'}} variant={'caption'}>
                            {Intl.NumberFormat().format(props.total)} records found</Typography>
                    </Box>
                )}
                <Box display={'flex'} justifyContent={'flex-end'}>
                    {showEdit && (
                        <Can to={'respondent.delete'}>
                            <Button
                                variant="contained"
                                color={'primary'}
                                style={{marginRight: 16}}
                                onClick={() => setCheckboxSelection(!checkboxSelection)}
                                aria-label="delete abilities"
                                disabled={props.loading}
                            >
                                <Typography>
                                    Edit
                                </Typography>
                            </Button>
                        </Can>
                    )}
                    {exportAbilities && (
                        <Can to={'respondent.export'}>
                            <Button
                                variant="contained"
                                color={'primary'}
                                className={classes.exportButton}
                                onClick={props.handleExport}
                                aria-label="move all right"
                                disabled={
                                    props.exportLoading ||
                                    props.loading ||
                                    (props.exportDoneLoading && !props.exportDoneSuccess)
                                }
                            >
                                {props.exportLoading && <CircularProgress size={24}/>}
                                {
                                    !props.exportLoading && (
                                        <Typography>
                                            Export
                                        </Typography>
                                    )
                                }
                            </Button>
                        </Can>
                    )}
                </Box>
            </Box>

            {
                props.loading ? <LinearProgress/> : (
                    <TableContainer style={{overflowX: 'unset'}}
                                    component={(props: TableContainerProps) => (<Paper {...props} elevation={0}/>)}>
                        <Table aria-label="customized table" className={classes.table + (props.class ? ' ' + props.class : '')}>
                            <EnhancedTableHead
                                checkboxSelection={checkboxSelection}
                                headBackgroundColor={headBackgroundColor}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={rows.length}
                                numSelected={selected.length}
                                onSortClick={handleSortClick}
                                hideHeadings={props.hideHeadings}
                                headers={headers}
                                sortable={props.sortable}
                                headTextColor={headTextColor}
                                classes={classes}
                                orderBy={orderBy}
                                orderDirection={orderDirection}
                            />

                            <TableBody>
                                {
                                    tableRows().map((row: Project, index: number) => {
                                            const isItemSelected = isSelected(row._id);

                                            return (
                                                <TableRow
                                                    role="checkbox"
                                                    onClick={event => handleClick(event, row._id)}
                                                    key={`TableRow-${row._id}`}
                                                    hover={true}
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                >
                                                    {checkboxSelection && (
                                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingCheckbox">
                                                            <label className={classes.check_label}>
                                                                <span className={isItemSelected ? 'checked' : ''}/>
                                                            </label>
                                                        </td>
                                                    )}

                                                    {
                                                        headers.map(header =>
                                                            (
                                                                <td className="MuiTableCell-root MuiTableCell-body"  key={`${row._id}-${header.name}`}>
                                                                    {
                                                                        header.select ? (
                                                                            getTableSelect(header, header.label, row._id, row)
                                                                        ) : (
                                                                            getTableCellValue(row, header)
                                                                        )
                                                                    }
                                                                </td>
                                                            )
                                                        )
                                                    }
                                                    {dictActions && (
                                                        <td
                                                            key={`action-${row._id}`}
                                                            className={`MuiTableCell-root MuiTableCell-body ${classes.tdTextCenter}`}
                                                        >
                                                            <Can
                                                                to={linkName + '.update'}>
                                                                <div
                                                                    className={`${classes.displayInlineM}`}
                                                                >
                                                                    <a onClick={() => props.editDict && props.editDict(row)}>
                                                                        <span
                                                                            className={`material-icons-outlined MuiIcon-root ${classes.actionIcon}`}
                                                                        >mode_edit</span>
                                                                        <span
                                                                            className={`MuiTypography-root MuiTypography-caption ${classes.actionButton}`}>
                                                                            Edit
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </Can>
                                                        </td>
                                                    )}
                                                    {showActions && (
                                                        <td
                                                            key={`action-${row._id}`}
                                                            className={`MuiTableCell-root MuiTableCell-body ${classes.tdTextCenter}`}
                                                        >
                                                            <div
                                                                className={`${classes.displayInline}`}
                                                            >
                                                                <a
                                                                    href={ getLink('show', row.id || row._id) }
                                                                    target={targetBlank ? '_blank' : ''}
                                                                    onClick={e => linkTo(e)}
                                                                >
                                                                    <span
                                                                        className={`material-icons-outlined MuiIcon-root ${classes.actionIcon}`}
                                                                    >visibility</span>
                                                                    <span
                                                                        className={`MuiTypography-root MuiTypography-caption ${classes.actionButton}`}
                                                                    >
                                                                        View
                                                                    </span>
                                                                </a>
                                                            </div>

                                                            <Can
                                                                to={linkName + '.update'}>
                                                                <div
                                                                    className={`${classes.displayInlineM}`}
                                                                >
                                                                    <a
                                                                        href={ getLink('edit', row.id || row._id) }
                                                                        target={targetBlank ? '_blank' : ''}
                                                                        onClick={e => linkTo(e)}
                                                                    >
                                                                        <span
                                                                            className={`material-icons-outlined MuiIcon-root ${classes.actionIcon}`}
                                                                        >mode_edit</span>
                                                                        <span
                                                                            className={`MuiTypography-root MuiTypography-caption ${classes.actionButton}`}>
                                                                            Edit
                                                                        </span>
                                                                    </a>
                                                                </div>

                                                                {actionArchive && row.state !== 1 && user._id !== row._id && (
                                                                    <div
                                                                        className={`${classes.displayInlineM}`}
                                                                    >
                                                                        <ArchiveOutlined
                                                                            className={classes.actionButton}
                                                                            fontSize={'small'}
                                                                            onClick={() => handleClickArchive(row)}
                                                                            aria-label={`view-${row._id}`}
                                                                            style={{marginLeft: 10}}
                                                                        />
                                                                        <Typography
                                                                            onClick={() => handleClickArchive(row)}
                                                                            variant={'caption'}
                                                                            className={classes.actionButton}
                                                                        >
                                                                            Archive
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </Can>

                                                            {actionDelete && user._id !== row._id && (
                                                                <Can
                                                                    to={linkName + '.delete'}>
                                                                    <Box
                                                                        display={'inline-block'}
                                                                        ml={1}
                                                                    >
                                                                        <DeleteOutlined
                                                                            className={classes.actionButton}
                                                                            fontSize={'small'}
                                                                            onClick={() => handleClickDelete(row)}
                                                                            aria-label={`view-${row._id}`}
                                                                            style={{marginLeft: 10}}
                                                                        />
                                                                        <Typography
                                                                            onClick={() => handleClickDelete(row)}
                                                                            variant={'caption'}
                                                                            className={classes.actionButton}
                                                                        >
                                                                            Delete
                                                                        </Typography>
                                                                    </Box>
                                                                </Can>
                                                            )}
                                                        </td>
                                                    )}

                                                </TableRow>
                                            )
                                        }
                                    )
                                }
                            </TableBody>

                            {showPagination && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10]}
                                            count={props.total}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </TableContainer>
                )}
            {checkboxSelection && (
                <Box mt={2} width={1} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                        variant="contained"
                        color={'primary'}
                        disabled={!selected.length || props.bulkDeleteLoading}
                        onClick={() => handleBulkDelete()}
                        style={{backgroundColor: '#f00'}}
                        aria-label="delete abilities"
                    >
                        {props.bulkDeleteLoading ? (
                            <CircularProgress size={24}/>
                        ) : (
                            <Typography>
                                Delete
                            </Typography>
                        )}
                    </Button>
                </Box>
            )}
            {actionArchive && (
                <AlertDialog
                    open={openArchiveDialog}
                    itemName={listOf}
                    handleArchive={onArchiveClick}
                    handleClose={handleCloseArchiveDialog}
                />
            )}
            {actionDelete && (
                <ConfirmDialog
                    open={openDeleteDialog}
                    confirmMessage={
                        'Are you sure you want to erase this user?\n' +
                        'This action will remove them from your database.'
                    }
                    handleDelete={onDeleteClick}
                    handleClose={handleCloseDeleteDialog}
                />
            )}
        </>
    );
};
