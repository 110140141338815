import { User } from './reducers/user';
import { ListComponentHeader } from '../../interfaces';

export enum UserActionType {
    USER_REQUEST = 'USER/REQUEST',
    USER_SUCCESS = 'USER/SUCCESS',
    USER_FAILURE = 'USER/FAILURE',
    USER_CREATE_REQUEST = 'USER/CREATE/REQUEST',
    USER_CREATE_SUCCESS = 'USER/CREATE/SUCCESS',
    USER_CREATE_FAILURE = 'USER/CREATE/FAILURE',

    USER_UPDATE_REQUEST = 'USER/UPDATE/REQUEST',
    USER_UPDATE_SUCCESS = 'USER/UPDATE/SUCCESS',
    USER_UPDATE_FAILURE = 'USER/UPDATE/FAILURE',
    USER_UPDATE_RESET_STATE = 'USER/UPDATE/RESET/STATE',

    USER_DELETE_REQUEST = 'USER/DELETE/REQUEST',
    USER_DELETE_SUCCESS = 'USER/DELETE/SUCCESS',
    USER_DELETE_FAILURE = 'USER/DELETE/FAILURE',

    USER_SHOW_REQUEST = 'USER/SHOW/REQUEST',
    USER_SHOW_SUCCESS = 'USER/SHOW/SUCCESS',
    USER_SHOW_FAILURE = 'USER/SHOW/FAILURE',
    USER_SHOW_RESET_STATE = 'USER/SHOW/RESET/STATE',
    USERS_REQUEST = 'USERS/REQUEST',
    USERS_SUCCESS = 'USERS/SUCCESS',
    USERS_FAILURE = 'USERS/FAILURE',
    USER_CHANGE_PASSWORD_REQUEST = 'USER/CHANGE_PASSWORD/REQUEST',
    USER_CHANGE_PASSWORD_SUCCESS = 'USER/CHANGE_PASSWORD/SUCCESS',
    USER_CHANGE_PASSWORD_FAILURE = 'USER/CHANGE_PASSWORD/FAILURE',

    USERS_REFRESH = 'USERS/REFRESH',
}

// Users
export interface UsersRequestPayload {
    skip?: number;
    orderBy?: string;
    orderDirection?: string;
}

export interface UsersSuccessPayload {
    active: User[];
    archived: User[];
    total: number;
    archivedTotal: number;
    headers: ListComponentHeader[];
}

export interface UsersFailurePayload {
    error: string
}

export type UsersRequestAction =
    ActionWithPayload<UserActionType.USERS_REQUEST, UsersRequestPayload>;

export type UsersSuccessAction =
    ActionWithPayload<UserActionType.USERS_SUCCESS, UsersSuccessPayload>;

export type UsersFailureAction =
    ActionWithPayload<UserActionType.USERS_FAILURE, UsersFailurePayload>;

export type UsersAction =
    UsersRequestAction |
    UsersSuccessAction |
    UsersFailureAction;

// Change Password
export interface UserChangePasswordRequestPayload {
    password: string,
    newPassword: string,
    password_confirmation: string,
}

export interface UserChangePasswordSuccessPayload {
    message: string
}

export interface UserChangePasswordFailurePayload {
    error: string
}

export type UserChangePasswordRequestAction =
    ActionWithPayload<UserActionType.USER_CHANGE_PASSWORD_REQUEST, UserChangePasswordRequestPayload>;

export type UserChangePasswordSuccessAction =
    ActionWithPayload<UserActionType.USER_CHANGE_PASSWORD_SUCCESS, UserChangePasswordSuccessPayload>;

export type UserChangePasswordFailureAction =
    ActionWithPayload<UserActionType.USER_CHANGE_PASSWORD_FAILURE, UserChangePasswordFailurePayload>;

export type UserChangePasswordAction =
    UserChangePasswordRequestAction |
    UserChangePasswordSuccessAction |
    UserChangePasswordFailureAction;
