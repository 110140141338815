import { all, call, put, takeLatest } from 'redux-saga/effects';
import { METHOD_GET, METHOD_POST, METHOD_PUT, requestBuilder } from '../../../helpers';
import {
    projectsCreateFailure,
    ProjectsCreateRequest,
    projectsCreateSuccess,
    projectsDashboardFailure,
    ProjectsDashboardRequest,
    projectsDashboardSuccess,
    projectsFailure,
    ProjectsRequest,
    projectsShowSuccess,
    projectsSuccess,
    projectsUpdateFailure,
    ProjectsUpdateRequest,
    projectsUpdateSuccess
} from '../actions';
import { ProjectActionType } from '../constants';
import { toggleSnackbarOpen } from '../../notifications';

export function* ProjectsAllRequest(action: ProjectsRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            'api/projects',
            METHOD_GET,
            {
                skipActive: action.skipActive,
                skipArchived: action.skipArchived,
                activeOrderBy: action.activeOrderBy,
                activeOrderDirection: action.activeOrderDirection,
                archivedOrderBy: action.archivedOrderBy,
                archivedOrderDirection: action.archivedOrderDirection,
            }
        );

        yield put(projectsSuccess(data.active, data.archived, data.headers, data.activeTotal, data.archivedTotal));
    } catch (error) {
        yield put(projectsFailure());
    }
}

function* createProject(action: ProjectsCreateRequest) {
    try {
        const {project} = action;
        const {data, Error} = yield call(
            requestBuilder,
            'api/projects',
            METHOD_POST,
            {
                'project': project,
            }
        );

        if (Error) {
            yield put(projectsCreateFailure(Error));
        } else {
            yield put(projectsCreateSuccess(data.project));
            yield put(projectsShowSuccess(data.project));
        }
    } catch (error) {
        yield put(projectsCreateFailure(error));
    }
}

function* updateProject(action: ProjectsUpdateRequest) {
    try {
        const {project} = action;
        const {data , Error} = yield call(
            requestBuilder,
            `api/projects/${project._id}`,
            METHOD_PUT,
            {
                'project': project,
            }
        );

        if (Error) {
            yield put(projectsCreateFailure(Error));
        } else {
            yield put(projectsUpdateSuccess(data));
            yield put(projectsShowSuccess(data));
        }
    } catch (error) {
        yield put(projectsUpdateFailure(error));
        if (Object.keys(error).length) {
            let errorMessage = '';
            Object.keys(error).forEach((message: string) => errorMessage += error[message]);
            yield put(toggleSnackbarOpen(errorMessage, 'error'));
        }
    }
}

function* dashboardProject(action: ProjectsDashboardRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            'api/projects/dashboard',
            METHOD_GET,
            {}
        );

        yield put(projectsDashboardSuccess(data.projects));
    } catch (error) {
        yield put(projectsDashboardFailure());
    }
}

export function* rootProjectsSaga() {
    yield all([
        takeLatest(ProjectActionType.PROJECTS_UPDATE_REQUEST, updateProject),
        takeLatest(ProjectActionType.PROJECTS_CREATE_REQUEST, createProject),
        takeLatest(ProjectActionType.PROJECTS_DASHBOARD_REQUEST, dashboardProject),
    ]);
}
