import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Can } from '../Can';
import { useDispatch } from 'react-redux';
import { projectsCreateResetState } from '../../../modules/projects';
import {
    respondentsListListResetState,
    respondentsListRespondentsShowClearState
} from '../../../modules/respondentsList';
import { respondentsDuplicateResetState, respondentsListResetState } from '../../../modules/respondents';
import { filterValuesResetState } from '../../../modules/filterValue';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0, 0, 5),
    },
    button: {
        fontSize: 'large',
        fontWeight: 500,
        fontsize: 19,
        padding: '7px 14px',
    },
    activeDashboard: {
        '& button': {
            border: '2px solid #002ca3',
        },
    },
    activeSearch: {
        '& button': {
            border: '2px solid #c06e21',
            // boxShadow: 'none',
        },
    },
    activeProjectSearch: {
        '& button': {
            border: '2px solid #791275',
        },
    },
    activeProjects: {
        '& button': {
            border: '2px solid #a9130f',
        },
    },
    activeProjectNew: {
        '& button': {
            border: '2px solid #005001',
        },
    },
    activeRespondentNew: {
        '& button': {
            border: '2px solid #19007d',
        },
    },
    buttonDashboard: {
        backgroundColor: '#d7e6fe',
        '& span': {
            color: '#002ca3',
        },
        '&:hover': {
            backgroundColor: '#d7e6ee',
        },
    },
    buttonSearch: {
        '& span': {
            color: '#b85c08',
        },
        backgroundColor: '#fdebd4',
        '&:hover': {
            backgroundColor: '#fdebe4',
        },
    },
    buttonProjectSearch: {
        '& span': {
            color: '#791275',
        },
        backgroundColor: '#fde4fc',
        '&:hover': {
            backgroundColor: '#faebff',
        },
    },
    buttonProjects: {
        '& span': {
            color: '#a9130f',
        },
        backgroundColor: '#ffe2e1',
        '&:hover': {
            backgroundColor: '#fff2f7',
        },
        minWidth: 'auto',
        '@media (min-width:1600px)': {
            minWidth: 200,
        },
    },
    buttonProjectNew: {
        '& span': {
            color: '#005001',
        },
        backgroundColor: '#ddf6d9',
        '&:hover': {
            backgroundColor: '#DDECE9',
        },
    },
    buttonRespondent: {
        '& span': {
            color: '#19007d',
        },
        backgroundColor: '#e2dbfd',
        '&:hover': {
            backgroundColor: '#e2dbed',
        },
    },
}));

export const AppNav: React.FC = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const resetStates = () => {
        dispatch(projectsCreateResetState());
        dispatch(respondentsListListResetState());
        dispatch(respondentsListRespondentsShowClearState());
        dispatch(respondentsListResetState());
        dispatch(filterValuesResetState());
        dispatch(respondentsDuplicateResetState());
    };

    return (
        <div className={classes.root}>
            <Grid spacing={2} justify={'space-between'} container={true}>
                <Grid item={true}>
                    <NavLink
                        to="/dashboard"
                        activeClassName={classes.activeDashboard}
                    >
                        <Button
                            className={`${classes.button} ${classes.buttonDashboard}`}
                            disableElevation={true}
                            fullWidth={true}
                            variant="contained">
                            Dashboard
                        </Button>
                    </NavLink>
                </Grid>
                <Grid item={true}>
                    <NavLink
                        to="/search"
                        activeClassName={classes.activeSearch}
                        onClick={() => resetStates()}
                    >
                        <Button
                            className={`${classes.button} ${classes.buttonSearch}`}
                            disableElevation={true}
                            fullWidth={true}
                            variant="contained">
                            Respondent Search
                        </Button>
                    </NavLink>
                </Grid>
                <Grid item={true}>
                    <NavLink
                        to="/projects/search"
                        exact={true}
                        activeClassName={classes.activeProjectSearch}
                        onClick={() => resetStates()}
                    >
                        <Button
                            className={`${classes.button} ${classes.buttonProjectSearch}`}
                            disableElevation={true}
                            fullWidth={true}
                            variant="contained">
                            Project Search
                        </Button>
                    </NavLink>
                </Grid>
                <Grid item={true}>
                    <NavLink
                        to="/projects/"
                        exact={true}
                        activeClassName={classes.activeProjects}
                    >
                        <Button
                            className={`${classes.button} ${classes.buttonProjects}`}
                            disableElevation={true}
                            fullWidth={true}
                            variant="contained">
                            Projects
                        </Button>
                    </NavLink>
                </Grid>
                <Can to={'project.create'}>
                    <Grid item={true}>
                        <NavLink
                            to="/projects/add"
                            exact={true}
                            activeClassName={classes.activeProjectNew}
                            onClick={() => resetStates()}
                        >
                            <Button
                                className={`${classes.button} ${classes.buttonProjectNew}`}
                                disableElevation={true}
                                fullWidth={true}
                                variant="contained">
                                Create New Project
                            </Button>
                        </NavLink>
                    </Grid>
                </Can>
                <Grid item={true}>
                    <NavLink
                        to="/respondents/add"
                        exact={true}
                        activeClassName={classes.activeRespondentNew}
                        onClick={() => resetStates()}
                    >
                        <Button
                            className={`${classes.button} ${classes.buttonRespondent}`}
                            disableElevation={true}
                            fullWidth={true}
                            variant="contained">
                            Add New Respondent
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        </div>
    );
};
