import { combineReducers } from 'redux';
import { DictionariesAllState, allReducer } from './all';
import { dictionariesListReducer, DictionariesListState } from './list';
import { dictionaryReducer, DictionaryState } from './dictionary';

// app state interface
export interface DictionariesState {
    all: DictionariesAllState;
    list: DictionariesListState;
    dictionary: DictionaryState;
}

export const dictionariesReducer = combineReducers({
    all: allReducer,
    list: dictionariesListReducer,
    dictionary: dictionaryReducer,
});
