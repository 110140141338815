import { combineReducers } from 'redux';
import { listReducer, ProjectsListState } from './list';
import { showReducer, ProjectsShowState } from './show';
import { createReducer, ProjectsCreateState } from './create';
import { projectsAllReducer, ProjectsAllState } from './projects';
import { ProjectUpdateState, updateReducer } from './update';

// app state interface
export interface ProjectsState {
    list: ProjectsListState;
    show: ProjectsShowState;
    create: ProjectsCreateState;
    update: ProjectUpdateState;
    projects: ProjectsAllState;
}

export const projectsReducer = combineReducers({
    list: listReducer,
    show: showReducer,
    projects: projectsAllReducer,
    create: createReducer,
    update: updateReducer,
});
