import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { createGetCheckBoxProps, createGetInputProps } from '../../../helpers/formBuilder';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Political: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const partyAffiliations = useSelector((state: AppState) => selectDictionaryGroup(state, 'party_affiliations'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getCheckBoxProps = createGetCheckBoxProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Party Affiliations
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Grid item={ true } xs={ 12 } className={ classes.grid }>
                                <FormControlLabel
                                    key={ `field-party-affiliations-democrat` }
                                    label={ 'Democrat' }
                                    name={ `democrat` }
                                    control={ <Checkbox color="primary" /> }
                                    checked={ respondent.democrat }
                                    onChange={ (e: any, checked) => {
                                        respondent.republican = checked;
                                        setValues({...values, 'democrat' : checked})
                                        setFilterValues('democrat', checked)
                                    } }
                                />
                            </Grid>
                            <Grid item={ true } xs={ 12 } className={ classes.grid }>
                                <FormControlLabel
                                    key={ `field-party-affiliations-republican` }
                                    label={ 'Republican' }
                                    name={ `republican` }
                                    control={ <Checkbox color="primary" /> }
                                    checked={ respondent.republican }
                                    onChange={ (e: any, checked) => {
                                        respondent.republican = checked;
                                        setValues({...values, 'republican' : checked})
                                        setFilterValues('republican', checked)
                                    } }
                                />
                            </Grid>
                            <Grid item={ true } xs={ 12 } className={ classes.grid }>
                                <FormControlLabel
                                    key={ `field-party-affiliations-independant` }
                                    label={ 'Independent' }
                                    name={ `independant` }
                                    control={ <Checkbox color="primary" /> }
                                    checked={ respondent.independant }
                                    onChange={ (e: any, checked) => {
                                        respondent.independant = checked;
                                        setValues({...values, 'independant' : checked})
                                        setFilterValues('independant', checked)
                                    } }
                                />
                            </Grid>
                            <Grid item={ true } xs={ 12 } className={ classes.grid }>
                                <FormControlLabel
                                    key={ `field-party-affiliations-tea_party` }
                                    label={ 'Tea Party' }
                                    name={ `tea_party` }
                                    control={ <Checkbox color="primary" /> }
                                    checked={ respondent.tea_party }
                                    onChange={ (e: any, checked) => {
                                        respondent.tea_party = checked;
                                        setValues({...values, 'tea_party' : checked})
                                        setFilterValues('tea_party', checked)
                                    } }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Active Voter?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('active_voter')}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Registered to Vote?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupEditField
                                inputProps = {getInputProps('voter_status')}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
