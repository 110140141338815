import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    selectUser,
    selectUserShow,
    selectUserShowLoading, selectUserUpdateError, selectUserUpdateErrors,
    selectUserUpdateLoading, selectUserUpdateSuccess,
    userShowRequest,
    userUpdateRequest
} from '../../../modules/user';
import { useParams } from 'react-router-dom';
import { UserForm } from '../../../pages/User/UserForm';
import { User } from '../../../modules/user/reducers/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import { selectRoles } from '../../../modules/roles/selectors';
import { toggleSnackbarOpen } from '../../../modules/notifications';

export const UserEditContainer: React.FC = () => {
    const dispatch = useDispatch();

    const user: User = useSelector(selectUserShow);
    const me: User = useSelector(selectUser);
    const loading = useSelector(selectUserShowLoading);
    const roles = useSelector(selectRoles);
    const updateLoading = useSelector(selectUserUpdateLoading);
    const updateSuccess = useSelector(selectUserUpdateSuccess);
    const updateUserError = useSelector(selectUserUpdateError);
    const updateUserErrors = useSelector(selectUserUpdateErrors);

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        if (updateSuccess) {
            dispatch(toggleSnackbarOpen('Record Saved', 'success'));
        }
    }, [updateSuccess]);

    useEffect(() => {
        dispatch(userShowRequest(id))
    }, []);

    useEffect(() => {
        if (updateUserError) {
            let errorMessage = '';
            Object.keys(updateUserErrors).forEach((message: string) => errorMessage += updateUserErrors[message]);
            dispatch(toggleSnackbarOpen(errorMessage, 'error'));
        }
    }, [updateUserError]);

    const onSubmit = (user: User) => {
        dispatch(userUpdateRequest(user, true))
    };

    return (
        <>
            {loading ? (
                <LinearProgress/>
            ) : (
                <UserForm
                    user={user}
                    me={me}
                    projects={me.projects}
                    updateLoading={updateLoading}
                    onSubmit={onSubmit}
                    roles={roles}
                    archivedUser={user.deleted_at && true}
                />
            )}
        </>
    );
};
