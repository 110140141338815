import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/prc_logo.jpg';
import { Copyright } from '../../components/common/Copyright';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: '70px 30px',
        backgroundPosition: 'center',
    },
    login: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface LoginPageProps {
    children?: React.ReactNode;
    title: string;
}

export const LoginPageWrapper: React.FC<LoginPageProps> = (props: LoginPageProps) => {
    const {children, title} = props;
    const classes = useStyles();

    return (
        <Grid container={true} component="main" className={classes.root}>
            <Grid item={true} xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item={true} xs={12} sm={8} md={5} className={classes.login} component={Paper} elevation={2}>

                <Typography component="h1" variant="h5">
                    {title}
                </Typography>

                {children}

                <Copyright/>

            </Grid>
        </Grid>
    )
};
