import React, { ChangeEvent, useEffect, useState } from 'react';
import { UploadFileComponent } from '../../components/UploadFileComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadDataReset, fileUploadRequest } from '../../modules/fileUpload';
import {
    selectFileUploadFailure, selectFileUploadFailureMessage,
    selectImportLoading,
    selectImportResponseData,
    selectImportTableTitle
} from '../../modules/fileUpload/selectors';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ListComponent } from '../../components';
import { FilterSet } from '../RespondentsSearchContainer';
import { toggleSnackbarOpen } from '../../modules/notifications';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
    })
);

export const DatabaseCompareContainer: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File>({} as File);
    const loading = useSelector(selectImportLoading);
    const fileUploadFailure = useSelector(selectFileUploadFailure);
    const fileUploadFailureMessage = useSelector(selectFileUploadFailureMessage);
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleCapture = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        // @ts-ignore
        setSelectedFile(element.files[0]);
    };

    useEffect(() => {
        dispatch(fileUploadDataReset())
    }, []);

    useEffect(() => {
        if (fileUploadFailure) {
            dispatch(toggleSnackbarOpen(fileUploadFailureMessage, 'error'));
        }
    }, [fileUploadFailure]);

    const handleSubmit = () => {
        dispatch(fileUploadRequest(selectedFile, 'api/database/compare'));
    };

    // tslint:disable-next-line:no-empty
    const pageChange = (query: FilterSet, skip: number) => {
    };

    return (
        <>
            <Box mt={3.5} mb={1}>
                <Typography className={classes.heading}>Database Compare</Typography>
            </Box>

            <UploadFileComponent
                handleSubmit={handleSubmit}
                handleCapture={handleCapture}
                selectedFileName={selectedFile ? selectedFile.name : ''}
                loading={loading}
                title={'Select xlsx. File to Compare Database. The number of lines in the file must not exceed 250,000'}
            />
        </>
    );
};
