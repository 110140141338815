import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
    NavLink,
} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Container from '@material-ui/core/Container';

import Logo from '../../../assets/prc_logo.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserSigned } from '../../../modules/user';
import { logoutRequest } from '../../../modules/auth';
import { Can } from '../Can';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#fff',
        color: '#0000fe',
    },
    container: {
        maxWidth: '1680px',
    },
    inner: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logoBox: {
        flexGrow: 1,

    },
    logo: {
        minWidth: '70px',
        minHeight: '30px',
        backgroundImage: `url(${Logo})`,
        backgroundSize: '70px 30px',
        backgroundRepeat: 'no-repeat',
        display: 'inline-flex',
    },
});

export const Header: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const signed = useSelector(selectUserSigned);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logoutRequest());
    };

    return (
        <AppBar elevation={1} position="fixed" className={classes.root}>
            <Container className={classes.container}>
                <Toolbar disableGutters={true}>
                    <Grid container={true} className={classes.inner}>
                        <Grid item={true} className={classes.logoBox}>
                            <NavLink className={classes.logo} to="/dashboard"/>
                        </Grid>
                        {signed && (
                            <>
                                <Grid item={true}>
                                    <Typography variant="h6">
                                        {user.first_name} {user.last_name}
                                    </Typography>
                                </Grid>
                                <Grid item={true}>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle fontSize={'large'}/>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted={true}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleClose}>
                                            <NavLink
                                                to="/users"
                                            >
                                                Account Settings
                                            </NavLink>
                                        </MenuItem>
                                        <Can to={'user.list'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/users/roles"
                                                >
                                                    User Roles
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <Can to={'user.create'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/users/add"
                                                >
                                                    Create User
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <Can to={'respondent.import'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/respondents/import"
                                                >
                                                    Import Respondents
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <Can to={'database.compare'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/database/compare"
                                                >
                                                    Database Compare
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <Can to={'analytics.view'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/analytics"
                                                >
                                                    Analytics
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <Can to={'dictionary.update'}>
                                            <MenuItem onClick={handleClose}>
                                                <NavLink
                                                    to="/dictionaries"
                                                >
                                                    Dictionaries
                                                </NavLink>
                                            </MenuItem>
                                        </Can>
                                        <MenuItem onClick={handleLogout}>Log out</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
