import { call, put } from 'redux-saga/effects';
import { METHOD_PUT, requestBuilder } from '../../../helpers';
import {
    respondentUpdateFailure,
    RespondentUpdateRlFieldsRequest, respondentUpdateRlFieldsSuccess,
    respondentUpdateSuccess,
} from '../actions';
import { respondentsListRespondentsShowRequest } from '../../respondentsList';

export default function* updateRespondentRlFieldsSaga(action: RespondentUpdateRlFieldsRequest) {
    try {
        const {id, column, listId, skip} = action;
        const {data} = yield call(
            requestBuilder,
            `api/respondents-list/${listId}/respondent-rl-column`,
            METHOD_PUT,
            {
                id,
                'column': column,
            }
        );

        // yield put(respondentUpdateSuccess(data));
        yield put(respondentUpdateRlFieldsSuccess(data.respondentsList, data.booked_text));
        yield put(respondentsListRespondentsShowRequest(listId, skip));
    } catch (error) {
        yield put(respondentUpdateFailure());
    }
}
