import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

interface InputPhoneMaskProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    value: any;
    setPhone: (phone: any) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const InputPhoneMask: React.FC<InputPhoneMaskProps> = (props: InputPhoneMaskProps) => {
    const {name, label} = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let value = event.target.value.replace(/[^\d]+/ig, '');
        if (value.length > 10) {
            value = event.target.value.replace(/[^\dx]+/ig, '');
        }

        props.setPhone(value);
    };

    return (
        <>
            <InputMask
                mask={ '(999) 999-9999' }
                disabled={ false }
                value={ props.value }
                onChange={ handleChange }
            >
                {/* tslint:disable-next-line:jsx-wrap-multiline */ }
                { () => <TextField
                    label={ label }
                    id={ name }
                    onKeyPress={ props.onKeyPress }
                /> }
            </InputMask>
        </>
    );
};
