import React, { useEffect } from 'react';
import { UserEditContainer } from '../../../containers/User/UserEditContainer';
import { useDispatch } from 'react-redux';
import { userShowResetState, userUpdateResetState } from '../../../modules/user';

export const UserEditPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userShowResetState());
        dispatch(userUpdateResetState());
    }, []);

    return (
        <>
            <UserEditContainer/>
        </>
    );
};
