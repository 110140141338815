import { DictionariesListAction } from '../actions';
import { DictionariesActionType } from '../constants';
import { ListComponentHeader } from '../../../interfaces';

export interface DictionariesListState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: any[];
    total: number;
    headers: ListComponentHeader[];
}

const initialState: DictionariesListState = {
    loading: false,
    success: false,
    error: false,
    data: [],
    total: 0,
    headers: [],
};

export const dictionariesListReducer = (state: DictionariesListState = initialState, action: DictionariesListAction) => {
    switch (action.type) {
        case DictionariesActionType.DICTIONARIES_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                total: 0,
            };
        case DictionariesActionType.DICTIONARIES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
                total: action.total,
                headers: action.headers,
            };
        case DictionariesActionType.DICTIONARIES_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
            };
        case DictionariesActionType.DICTIONARIES_LIST_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
