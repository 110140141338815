import { DictionariesActionType, Dictionary } from './constants';
import { FilterSet } from '../../containers';
import { ListComponentHeader } from '../../interfaces';

export interface DictionariesRequest {
    type: typeof DictionariesActionType.DICTIONARIES_REQUEST;
}

export interface DictionariesFailure {
    type: typeof DictionariesActionType.DICTIONARIES_FAILURE;
}

export interface DictionariesSuccess {
    type: typeof DictionariesActionType.DICTIONARIES_SUCCESS;
    data: Dictionary[];
}

export type DictionariesAction = DictionariesFailure | DictionariesRequest | DictionariesSuccess;

export const dictionariesFailure = (): DictionariesFailure => ({
    type: DictionariesActionType.DICTIONARIES_FAILURE,
});

export const dictionariesRequest = (): DictionariesRequest => ({
    type: DictionariesActionType.DICTIONARIES_REQUEST,
});

export const dictionariesSuccess = (data: Dictionary[]): DictionariesSuccess => ({
    type: DictionariesActionType.DICTIONARIES_SUCCESS,
    data,
});

export interface DictionariesListRequest {
    type: typeof DictionariesActionType.DICTIONARIES_LIST_REQUEST;
    filter: FilterSet;
    skip?: number;
    orderBy?: string;
    orderDirection?: string;
}

export interface DictionariesListFailure {
    type: typeof DictionariesActionType.DICTIONARIES_LIST_FAILURE;
}

export interface DictionariesListSuccess {
    type: typeof DictionariesActionType.DICTIONARIES_LIST_SUCCESS;
    data: string[];
    total: number;
    headers: ListComponentHeader[];
}

export interface DictionariesListResetState {
    type: typeof DictionariesActionType.DICTIONARIES_LIST_RESET_STATE;
}

export const dictionariesListResetState = (): DictionariesListResetState => ({
    type: DictionariesActionType.DICTIONARIES_LIST_RESET_STATE,
});

export type DictionariesListAction =
    DictionariesListFailure
    | DictionariesListRequest
    | DictionariesListSuccess
    | DictionariesListResetState;

export const dictionariesListFailure = (): DictionariesListFailure => ({
    type: DictionariesActionType.DICTIONARIES_LIST_FAILURE,
});

export const dictionariesListRequest = (filter: FilterSet, skip?: number, orderBy?: string, orderDirection?: string): DictionariesListRequest => ({
    type: DictionariesActionType.DICTIONARIES_LIST_REQUEST,
    filter,
    skip,
    orderBy,
    orderDirection,
});

export const dictionariesListSuccess = (data: string[], total: number, headers: ListComponentHeader[]): DictionariesListSuccess => ({
    type: DictionariesActionType.DICTIONARIES_LIST_SUCCESS,
    data,
    total,
    headers,
});

export interface DictionaryCreateRequest {
    type: typeof DictionariesActionType.DICTIONARY_CREATE_REQUEST;
    dictionary: Dictionary;
}

export interface DictionaryCreateFailure {
    type: typeof DictionariesActionType.DICTIONARY_CREATE_FAILURE;
    error: any;
}

export interface DictionaryCreateSuccess {
    type: typeof DictionariesActionType.DICTIONARY_CREATE_SUCCESS;
}

export const dictionaryCreateFailure = (error: any): DictionaryCreateFailure => ({
    type: DictionariesActionType.DICTIONARY_CREATE_FAILURE,
    error,
});

export const dictionaryCreateRequest = (dictionary: Dictionary): DictionaryCreateRequest => ({
    type: DictionariesActionType.DICTIONARY_CREATE_REQUEST,
    dictionary,
});

export const dictionaryCreateSuccess = (): DictionaryCreateSuccess => ({
    type: DictionariesActionType.DICTIONARY_CREATE_SUCCESS,
});

export interface DictionaryUpdateRequest {
    type: typeof DictionariesActionType.DICTIONARY_UPDATE_REQUEST;
    dictionary: Dictionary;
}

export interface DictionaryUpdateFailure {
    type: typeof DictionariesActionType.DICTIONARY_UPDATE_FAILURE;
    error: any;
}

export interface DictionaryUpdateSuccess {
    type: typeof DictionariesActionType.DICTIONARY_UPDATE_SUCCESS;
}

export const dictionaryUpdateFailure = (error: any): DictionaryUpdateFailure => ({
    type: DictionariesActionType.DICTIONARY_UPDATE_FAILURE,
    error,
});

export const dictionaryUpdateRequest = (dictionary: Dictionary): DictionaryUpdateRequest => ({
    type: DictionariesActionType.DICTIONARY_UPDATE_REQUEST,
    dictionary,
});

export const dictionaryUpdateSuccess = (): DictionaryUpdateSuccess => ({
    type: DictionariesActionType.DICTIONARY_UPDATE_SUCCESS,
});

export interface DictionaryResetState {
    type: typeof DictionariesActionType.DICTIONARY_RESET_STATE;
}

export const dictionaryResetState = (): DictionaryResetState => ({
    type: DictionariesActionType.DICTIONARY_RESET_STATE,
});

export type DictionaryAction =
    DictionaryUpdateFailure |
    DictionaryUpdateRequest |
    DictionaryUpdateSuccess |
    DictionaryCreateFailure |
    DictionaryCreateRequest |
    DictionaryCreateSuccess |
    DictionaryResetState;
