import { take, put, call, delay, race, cancelled } from 'redux-saga/effects'
import { METHOD_GET, requestBuilder } from '../../helpers';
import {
    exportToFileCheckDoneStart,
    exportToFileCheckDoneStop,
    exportToFileDoneRequest,
    exportToFileDoneSuccess
} from './actions';
import { ExportToFileDoneType } from './constants';
import { EXPORT_TO_FILE_SUCCESS } from '../export';

function* checkDone(id: string) {
    try {
        while (true) {
            yield put(exportToFileDoneRequest());

            const response = yield call(
                requestBuilder,
                `api/respondents/export/${id}`,
                METHOD_GET,
                {}
            );

            if (response.data.done) {
                yield put(exportToFileDoneSuccess(response.data.link));
                yield put(exportToFileCheckDoneStop());
            }

            yield delay(5000);
        }
    } finally {
        if (yield cancelled()) {
            yield put(exportToFileCheckDoneStop());
        }
    }
}

export function* watchExportDone() {
    const {id} = yield take(EXPORT_TO_FILE_SUCCESS);

    yield put(exportToFileCheckDoneStart());

    yield race([
        call(checkDone, id),
        take(ExportToFileDoneType.EXPORT_TO_FILE_CHECK_DONE_STOP),
    ]);
}
