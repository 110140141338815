import { Comment, CommentsActionType } from './constants';

export interface CommentsListFailure {
    type: typeof CommentsActionType.COMMENTS_LIST_FAILURE;
}

export interface CommentsListRequest {
    type: typeof CommentsActionType.COMMENTS_LIST_REQUEST;
    projectId: string;
}

export interface CommentsListSuccess {
    type: typeof CommentsActionType.COMMENTS_LIST_SUCCESS;
    comments: Comment[];
    commentsCount: number;
}

export const commentsListFailure = (): CommentsListFailure => ({
    type: CommentsActionType.COMMENTS_LIST_FAILURE,
});

export const commentsListRequest = (projectId: string): CommentsListRequest => ({
    type: CommentsActionType.COMMENTS_LIST_REQUEST,
    projectId,
});

export const commentsListSuccess = (comments: Comment[], commentsCount: number): CommentsListSuccess => ({
    type: CommentsActionType.COMMENTS_LIST_SUCCESS,
    comments,
    commentsCount,
});

export interface CommentsCreateFailure {
    type: typeof CommentsActionType.COMMENTS_CREATE_FAILURE;
}

export interface CommentsCreateRequest {
    type: typeof CommentsActionType.COMMENTS_CREATE_REQUEST;
    comment: string;
    project: string;
}

export interface CommentsCreateSuccess {
    type: typeof CommentsActionType.COMMENTS_CREATE_SUCCESS;
    comments: Comment[];
    commentsCount: number;
}

export const commentsCreateFailure = (): CommentsCreateFailure => ({
    type: CommentsActionType.COMMENTS_CREATE_FAILURE,
});

export const commentsCreateRequest = (comment: string, project: string): CommentsCreateRequest => ({
    type: CommentsActionType.COMMENTS_CREATE_REQUEST,
    comment,
    project,
});

export const commentsCreateSuccess = (comments: Comment[], commentsCount: number): CommentsCreateSuccess => ({
    type: CommentsActionType.COMMENTS_CREATE_SUCCESS,
    comments,
    commentsCount,
});

export interface CommentsDeleteRequest {
    type: typeof CommentsActionType.COMMENTS_DELETE_REQUEST;
    comment: string;
}

export interface CommentsDeleteSuccess {
    type: typeof CommentsActionType.COMMENTS_DELETE_SUCCESS;
    comments: Comment[];
    commentsCount: number;
}

export interface CommentsDeleteFailure {
    type: typeof CommentsActionType.COMMENTS_DELETE_FAILURE;
}

export const commentsDeleteFailure = (): CommentsDeleteFailure => ({
    type: CommentsActionType.COMMENTS_DELETE_FAILURE,
});

export const commentsDeleteRequest = (comment: string): CommentsDeleteRequest => ({
    type: CommentsActionType.COMMENTS_DELETE_REQUEST,
    comment,
});

export const commentsDeleteSuccess = (comments: Comment[], commentsCount: number): CommentsDeleteSuccess => ({
    type: CommentsActionType.COMMENTS_DELETE_SUCCESS,
    comments,
    commentsCount,
});

export interface CommentsUpdateFailure {
    type: typeof CommentsActionType.COMMENTS_UPDATE_FAILURE;
}

export interface CommentsUpdateRequest {
    type: typeof CommentsActionType.COMMENTS_UPDATE_REQUEST;
    comment: Comment;
}

export interface CommentsUpdateSuccess {
    type: typeof CommentsActionType.COMMENTS_UPDATE_SUCCESS;
}

export const commentsUpdateFailure = (): CommentsUpdateFailure => ({
    type: CommentsActionType.COMMENTS_UPDATE_FAILURE,
});

export const commentsUpdateRequest = (comment: Comment): CommentsUpdateRequest => ({
    type: CommentsActionType.COMMENTS_UPDATE_REQUEST,
    comment,
});

export const commentsUpdateSuccess = (): CommentsUpdateSuccess => ({
    type: CommentsActionType.COMMENTS_UPDATE_SUCCESS,
});

export type CommentAction =
    CommentsListFailure
    | CommentsListRequest
    | CommentsListSuccess
    | CommentsCreateRequest
    | CommentsCreateFailure
    | CommentsCreateSuccess
    | CommentsDeleteRequest
    | CommentsDeleteFailure
    | CommentsDeleteSuccess
    | CommentsUpdateRequest
    | CommentsUpdateFailure
    | CommentsUpdateSuccess;
