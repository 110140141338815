import { ResetPasswordAction, ResetPasswordActionType } from './actions';
import { ApiErrors } from '../../helpers/api-errors';

export interface ResetPasswordState {
    requesting: boolean;
    changeRequesting: boolean;
    success: boolean;
    error: boolean;
    errors: ApiErrors;
    statusMessage: string;
}

const initialState: ResetPasswordState = {
    requesting: false,
    changeRequesting: false,
    success: false,
    error: false,
    errors: {},
    statusMessage: '',
};

export const resetPasswordReducer = (state: ResetPasswordState = initialState, action: ResetPasswordAction) => {
    switch (action.type) {
        case ResetPasswordActionType.RESET_PASSWORD_REQUESTING:
            return {
                ...state,
                requesting: true,
                email: action.email,
            };
        case ResetPasswordActionType.CHANGE_PASSWORD_REQUESTING:
            return {
                ...state,
                requesting: true,
                token: action.token,
                password: action.password,
                passwordConfirmation: action.passwordConfirmation,
                email: action.email,
            };
        case ResetPasswordActionType.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                statusMessage: action.status,
                error: false,
                errors: {},
            };
        case ResetPasswordActionType.RESET_PASSWORD_ERROR:
            return {
                ...state,
                requesting: false,
                error: true,
                errors: action.error,
            };
        default:
            return state;
    }
};
