import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Zoom from '@material-ui/core/Zoom';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { UserChangePasswordRequestPayload, userUpdateRequest } from '../../../modules/user';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { User } from '../../../modules/user/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    resetPasswordError, resetPasswordErrorMessage,
    resetPasswordRequesting,
    resetPasswordStatus,
    resetPasswordStatusMessage
} from '../../../modules';
import { ExportFields } from '../../ExportFields';
import { selectDictionaryGroup } from '../../../modules/dictionaries';
import { toggleSnackbarOpen } from '../../../modules/notifications';

interface UserComponentProps {
    user: User,
    signed: boolean,
    loading: boolean,
    message: string,
    error: string,
    show: boolean,
    onSubmit: (payload: UserChangePasswordRequestPayload) => void;
    onResetPasswordSubmit: (email: string) => void;
}

interface Values {
    password: string,
    newPassword: string,
    password_confirmation: string,
}

interface ErrorValues {
    password?: string,
    newPassword?: string
    password_confirmation?: string,
}

const useStyles = makeStyles(theme =>
    ({
        paper: {
            padding: theme.spacing(5),
        },
        typography: {
            color: '#124c95',
        },
        margin: {
            margin: '12px 0',
        },
        marginY6: {
            margin: '6px 0',
        },
        passwordTip: {
            fontSize: '12px',
            color: '#828282',
            paddingTop: '5px',
        },
    }));

export const UserComponent: React.FC<UserComponentProps> = (props: UserComponentProps) => {
    const initialValues: Values = {
        password: '',
        newPassword: '',
        password_confirmation: '',
    };

    const dispatch = useDispatch();

    const initialErrorValues: ErrorValues = {};
    const [open, setOpen] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState<boolean>(false);

    const resetRequesting = useSelector(resetPasswordRequesting);
    const resetSuccess = useSelector(resetPasswordStatus);
    const resetSuccessMessage = useSelector(resetPasswordStatusMessage);
    const resetError = useSelector(resetPasswordError);
    const resetErrorMessage = useSelector(resetPasswordErrorMessage);
    const exportFields = useSelector((state: AppState) => selectDictionaryGroup(state, 'export_fields'))

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickShowCurrentPassword = () => {
        setShowCurrentPassword(show => !show);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(show => !show);
    };

    const handleClickShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(show => !show);
    };

    const handleClickShowResetPasswordForm = () => {
        setShowResetPasswordForm(value => value = !value);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleUpdateExportFields = (fields: string[]) => {
        user.settings.export_fields = fields;
        dispatch(userUpdateRequest(user, false))
        dispatch(toggleSnackbarOpen('Record Saved', 'success'))
    };

    const classes = useStyles();
    const {user, signed, loading, message, error, show, onSubmit, onResetPasswordSubmit} = props;

    const validate = (values: Values) => {
        const errors: ErrorValues = {...initialErrorValues};
        if (values.password_confirmation !== values.newPassword) {
            errors.password_confirmation = 'The password confirmation does not match'
        }
        if (values.password.length < 6) {
            errors.password = 'Password must be at least 6 characters'
        }

        if (!values.password) {
            errors.password = 'Required'
        }

        return errors;
    };

    const onSubmitForm = (values: Values) => onSubmit(values);

    const formik = useFormik({
        isInitialValid: false,
        initialValues,
        validate,
        onSubmit: onSubmitForm,
    });

    return (
        <>
            {!signed && <CircularProgress size={24}/>}
            {
                signed && (
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={5}>
                                <Box p={3}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={3}>
                                            <Typography className={classes.typography}>First Name </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={9}>
                                            <Typography>{user.first_name}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={3}>
                                            <Typography className={classes.typography}>Last Name</Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={9}>
                                            <Typography>{user.last_name}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={3}>
                                            <Typography className={classes.typography}>Email</Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={9}>
                                            <Typography>{user.email}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={3}>
                                            <Typography className={classes.typography}>User Role</Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={9}>
                                            <Typography>{user.role_names.join(', ')}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={3}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                size={'large'}
                                                color="primary"
                                                onClick={() => setOpen(true)}
                                            >
                                                <Typography>
                                                    Select Export Fields
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                            <Hidden xsDown={true}>
                                <Divider style={{marginRight: -2}} orientation="vertical" flexItem={true}/>
                            </Hidden>
                            <Grid item={true} xs={12} sm={7}>
                                <Grid container={true}>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Box justifyContent={'flex-end'} display={'flex'} style={{flexGrow: 1}}>
                                            <List dense={true}>
                                                <ListItem>
                                                    <ListItemText
                                                        style={{textAlign: 'right'}}
                                                        primary={
                                                            (
                                                                <>
                                                                    {(resetSuccess && (
                                                                        <Alert severity={'success'}
                                                                               style={{marginBottom: 10}}>
                                                                            {resetSuccessMessage}
                                                                        </Alert>
                                                                    ))}
                                                                    {(resetError && (
                                                                        <Alert severity={'error'}
                                                                               style={{marginBottom: 10}}>
                                                                            {resetErrorMessage}
                                                                        </Alert>
                                                                    ))}
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        size={'large'}
                                                                        color="primary"
                                                                        onClick={() => onResetPasswordSubmit(user.email)}
                                                                        disabled={resetRequesting || resetSuccess}
                                                                    >
                                                                        {resetRequesting && <CircularProgress size={24}/>}
                                                                        <Typography>
                                                                            Reset Password
                                                                        </Typography>
                                                                    </Button>
                                                                </>
                                                            )
                                                        }
                                                        secondary={(
                                                            <Typography className={classes.passwordTip}>
                                                                If you've forgotten your current password, this will <br/>
                                                                allow you to reset it through your email
                                                            </Typography>
                                                        )}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        style={{textAlign: 'right'}}
                                                        primary={
                                                            (
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    size={'large'}
                                                                    color="primary"
                                                                    onClick={handleClickShowResetPasswordForm}
                                                                >
                                                                    {loading ?
                                                                        (
                                                                            <CircularProgress size={24}/>
                                                                        )
                                                                        : (
                                                                            <Typography>
                                                                                Change Password
                                                                            </Typography>
                                                                        )}
                                                                </Button>
                                                            )
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} hidden={!showResetPasswordForm}>
                                        <Grid container={true}>
                                            <Box display={'flex'} style={{flexGrow: 1}} mx={2}>
                                                <Grid item={true} xs={12} sm={12}>
                                                    {message && show && (
                                                        <Zoom in={show}>
                                                            <Alert icon={<CheckIcon fontSize="inherit"/>}
                                                                   severity="success">
                                                                {message}
                                                            </Alert>
                                                        </Zoom>
                                                    )}
                                                    {error && show && (
                                                        <Zoom in={show}>
                                                            <Alert severity="warning">
                                                                {error}
                                                            </Alert>
                                                        </Zoom>
                                                    )}
                                                </Grid>
                                            </Box>
                                            <Box display={'flex'} style={{flexGrow: 1}} mx={2}>
                                                <Grid item={true} xs={12} sm={5}>
                                                    <List dense={true}>
                                                        <ListItem>
                                                            <ListItemText
                                                                className={classes.margin}
                                                                primary={
                                                                    (
                                                                        <Typography
                                                                            className={classes.typography}
                                                                        >Current Password </Typography>
                                                                    )
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                className={classes.margin}
                                                                primary={
                                                                    (
                                                                        <Typography
                                                                            className={classes.typography}
                                                                        >New Password </Typography>
                                                                    )
                                                                }
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                className={classes.margin}
                                                                primary={
                                                                    (
                                                                        <Typography
                                                                            className={classes.typography}
                                                                        >Confirm Password</Typography>
                                                                    )
                                                                }
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                                <Grid item={true} xs={12} sm={7}>
                                                    <form>
                                                        <TextField
                                                            id="current-password"
                                                            label="Current Password"
                                                            type={showCurrentPassword ? 'text' : 'password'}
                                                            {...formik.getFieldProps('password')}
                                                            error={
                                                                formik.touched.password &&
                                                                Boolean(formik.errors.password)
                                                            }
                                                            helperText={formik.errors.password}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowCurrentPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showCurrentPassword ? <Visibility/> :
                                                                                <VisibilityOff/>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <TextField
                                                            className={classes.marginY6}
                                                            id="new_password"
                                                            label="New Password"
                                                            type={showNewPassword ? 'text' : 'password'}
                                                            {...formik.getFieldProps('newPassword')}
                                                            error={
                                                                formik.touched.newPassword &&
                                                                Boolean(formik.errors.newPassword)
                                                            }
                                                            helperText={formik.errors.newPassword}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowNewPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showNewPassword ? <Visibility/> :
                                                                                <VisibilityOff/>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <TextField
                                                            className={classes.marginY6}
                                                            id="confirm_password"
                                                            label="Confirm Password"
                                                            type={showConfirmNewPassword ? 'text' : 'password'}
                                                            {...formik.getFieldProps('password_confirmation')}
                                                            error={
                                                                formik.touched.password_confirmation &&
                                                                Boolean(formik.errors.password_confirmation)
                                                            }
                                                            helperText={formik.errors.password_confirmation}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowConfirmNewPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showConfirmNewPassword ? <Visibility/> :
                                                                                <VisibilityOff/>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            size={'large'}
                                                            color="primary"
                                                            disabled={!formik.isValid || loading}
                                                            onClick={() => formik.handleSubmit()}
                                                        >
                                                            {loading ?
                                                                (
                                                                    <CircularProgress size={24}/>
                                                                )
                                                                : (
                                                                    <Typography>
                                                                        Save
                                                                    </Typography>
                                                                )}
                                                        </Button>
                                                    </form>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ExportFields
                            open={open}
                            fields={exportFields}
                            exportFields={user.settings?.export_fields}
                            handleClose={handleClose}
                            updateExportFields={handleUpdateExportFields}
                        />
                    </Paper>
                )
            }
        </>
    );
};
