import {
    UserActionType,
    UserChangePasswordAction,
    UserChangePasswordRequestPayload, UsersAction
} from './constants';
import { User, UserState } from './reducers/user';
import { ListComponentHeader } from '../../interfaces';
import { ApiErrors } from '../../helpers/api-errors';

export interface UserRequest {
    type: typeof UserActionType.USER_REQUEST;
}

export interface UserSuccess {
    type: typeof UserActionType.USER_SUCCESS;
    user: UserState;
    signed: boolean;
}

export interface UserFailure {
    type: typeof UserActionType.USER_FAILURE;
}

export const userRequest = (): UserRequest => ({
    type: UserActionType.USER_REQUEST,
});

export const userSuccess = (user: UserState, signed: boolean) => ({
    type: UserActionType.USER_SUCCESS,
    user,
    signed,
});

export const userFailure = (): UserFailure => ({
    type: UserActionType.USER_FAILURE,
});

export const usersRequest = (skip?: number, orderBy?: string, orderDirection?: string): UsersAction =>
    ({
        type: UserActionType.USERS_REQUEST,
        skip,
        orderBy,
        orderDirection,
    });

export const usersSuccess = (
    active: User[],
    archived: User[],
    total: number,
    archivedTotal: number,
    headers: ListComponentHeader[]
): UsersAction =>
    ({
        type: UserActionType.USERS_SUCCESS,
        active,
        archived,
        total,
        archivedTotal,
        headers,
    });

export const usersFailure = (error: string): UsersAction =>
    ({
        type: UserActionType.USERS_FAILURE,
        error,
    });

export const userChangePasswordRequest = (
    {password, newPassword, password_confirmation}: UserChangePasswordRequestPayload
): UserChangePasswordAction =>
    ({
        type: UserActionType.USER_CHANGE_PASSWORD_REQUEST,
        password,
        newPassword,
        password_confirmation,
    });

export const userChangePasswordSuccess = (message: string): UserChangePasswordAction =>
    ({
        type: UserActionType.USER_CHANGE_PASSWORD_SUCCESS,
        message,
    });

export const userChangePasswordFailure = (error: string): UserChangePasswordAction =>
    ({
        type: UserActionType.USER_CHANGE_PASSWORD_FAILURE,
        error,
    });


export interface UserCreateFailure {
    type: typeof UserActionType.USER_CREATE_FAILURE;
    errors: ApiErrors;
}

export interface UserCreateRequest {
    type: typeof UserActionType.USER_CREATE_REQUEST;
    user: User;
}

export interface UserCreateSuccess {
    type: typeof UserActionType.USER_CREATE_SUCCESS;
    user: User;
}

export const userCreateFailure = (errors: ApiErrors): UserCreateFailure => ({
    type: UserActionType.USER_CREATE_FAILURE,
    errors,
});

export const userCreateRequest = (user: User): UserCreateRequest => ({
    type: UserActionType.USER_CREATE_REQUEST,
    user,
});

export const userCreateSuccess = (user: User): UserCreateSuccess => ({
    type: UserActionType.USER_CREATE_SUCCESS,
    user,
});

export interface UserDeleteFailure {
    type: typeof UserActionType.USER_DELETE_FAILURE;
    errors: ApiErrors;
}

export interface UserDeleteRequest {
    type: typeof UserActionType.USER_DELETE_REQUEST;
    user: User;
}

export interface UserDeleteSuccess {
    type: typeof UserActionType.USER_DELETE_SUCCESS;
    user: User;
}

export const userDeleteFailure = (errors: ApiErrors): UserDeleteFailure => ({
    type: UserActionType.USER_DELETE_FAILURE,
    errors,
});

export const userDeleteRequest = (user: User): UserDeleteRequest => ({
    type: UserActionType.USER_DELETE_REQUEST,
    user,
});

export const userDeleteSuccess = (user: User): UserDeleteSuccess => ({
    type: UserActionType.USER_DELETE_SUCCESS,
    user,
});

export interface UserUpdateFailure {
    type: typeof UserActionType.USER_UPDATE_FAILURE;
    errors: ApiErrors;
}

export interface UserUpdateRequest {
    type: typeof UserActionType.USER_UPDATE_REQUEST;
    user: User;
    redirect: boolean;
}

export interface UserUpdateSuccess {
    type: typeof UserActionType.USER_UPDATE_SUCCESS;
    user: User;
}

export interface UserUpdateResetState {
    type: typeof UserActionType.USER_UPDATE_RESET_STATE;
}

export const userUpdateFailure = (errors: ApiErrors): UserUpdateFailure => ({
    type: UserActionType.USER_UPDATE_FAILURE,
    errors,
});

export const userUpdateRequest = (user: User, redirect: boolean): UserUpdateRequest => ({
    type: UserActionType.USER_UPDATE_REQUEST,
    user,
    redirect,
});

export const userUpdateSuccess = (user: User): UserUpdateSuccess => ({
    type: UserActionType.USER_UPDATE_SUCCESS,
    user,
});

export const userUpdateResetState = (): UserUpdateResetState => ({
    type: UserActionType.USER_UPDATE_RESET_STATE,
});

export interface UserShowFailure {
    type: typeof UserActionType.USER_SHOW_FAILURE;
}

export interface UserShowRequest {
    type: typeof UserActionType.USER_SHOW_REQUEST;
    id: string;
}

export interface UserShowSuccess {
    type: typeof UserActionType.USER_SHOW_SUCCESS;
    user: User;
}

export interface UserShowResetState {
    type: typeof UserActionType.USER_SHOW_RESET_STATE;
}

export const userShowFailure = (): UserShowFailure => ({
    type: UserActionType.USER_SHOW_FAILURE,
});

export const userShowRequest = (id: string): UserShowRequest => ({
    type: UserActionType.USER_SHOW_REQUEST,
    id,
});

export const userShowSuccess = (user: User): UserShowSuccess => ({
    type: UserActionType.USER_SHOW_SUCCESS,
    user,
});

export const userShowResetState = (): UserShowResetState => ({
    type: UserActionType.USER_SHOW_RESET_STATE,
});

// Refresh
export interface UsersRefresh {
    type: UserActionType.USERS_REFRESH;
}

export const usersRefresh = (): UsersRefresh => ({
    type: UserActionType.USERS_REFRESH,
});

export type UserAction =
    UserSuccess |
    UserFailure |
    UserRequest |
    UserShowSuccess |
    UserShowFailure |
    UserShowRequest |
    UserUpdateSuccess |
    UserUpdateFailure |
    UserUpdateRequest |
    UserUpdateResetState |
    UserShowResetState |
    UserCreateRequest |
    UserCreateSuccess |
    UserCreateFailure |
    UsersRefresh;
