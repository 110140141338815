import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
    Respondent, respondentDuplicateCreateRequest,
    respondentDuplicateSearchRequest, respondentsCreateRequest, respondentUpdateRequest,
    selectDuplicates,
    selectDuplicatesError, selectDuplicatesHeaders, selectDuplicatesSearchLoading
} from '../../modules';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogContent,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableRow, withStyles
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputPhoneMask } from '../../components/common/Form/InputPhoneMask';
import { useHistory } from 'react-router-dom';
import { toggleSnackbarOpen } from '../../modules/notifications';
import { RespondentEditComponent } from '../../components/RespondentEditComponent';
import { ListComponentHeader } from '../../interfaces';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ConfirmDialog } from '../../components/common/ConfirmDialog';
import { clearEmptyArraysValues } from '../../helpers/formHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            color: '#5680f9',
            fontSize: 25,
            fontWeight: 'bolder',
        },
        dialogActions: {
            '& button:hover span': {
                color: '#fff',
            },
            '& button': {
                maxHeight: 40,
            },
            marginTop: 40,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modifyButton: {
            '& span': {
                color: '#295ce4',
                fontWeight: 500,
            },
            '&.Mui-disabled span': {
                color: '#fff',
            },
            backgroundColor: '#f3f6ff',
        },
        createDuplicateButton: {
            border: '2px solid #295ce4',
            backgroundColor: '#fff',
            '& span': {
                color: '#295ce4',
                fontWeight: 500,
            },
            '&.Mui-disabled': {
                border: 'none',
            },
            '&.Mui-disabled span': {
                color: '#fff',
            },
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        paper: {
            padding: theme.spacing(6),
            marginBottom: theme.spacing(5),
        },
        doNotUse: {
            color: '#dc5656',
            fontSize: '1.42rem',
            display: 'block',
        },
        table: {
            minWidth: 900,
            '& thead': {
                '& th': {
                    padding: 10,
                    textAlign: 'center',
                },
            },
            '& tbody': {
                '& td': {
                    height: 40,
                    maxWidth: 300,
                    textAlign: 'center',
                    padding: '0 10px',
                    borderLeft: '1px solid #E0E0E0',
                    '&:nth-last-child(-n+1)': {
                        borderRight: '1px solid #E0E0E0',
                    },
                    '&:nth-first-child': {
                        padding: 0,
                    },
                },
            },
        },
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            color: '#19007d',
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const hiddenHeaders = ['dob', 'comments', 'status', 'contact', 'state', 'city', 'ethnicity'];

export const RespondentsAddContainer: React.FC = () => {
    const dispatch = useDispatch();

    const [phone, setPhone] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [modifyMode, setModifyMode] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>('');
    const [selected, setSelected] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<Respondent>({phone, type: 'false'});

    const duplicates: Respondent[] = useSelector(selectDuplicates);
    const headers: ListComponentHeader[] = useSelector(selectDuplicatesHeaders);
    const duplicatesError: boolean = useSelector(selectDuplicatesError);
    const duplicatesSearchLoading: boolean = useSelector(selectDuplicatesSearchLoading);

    const classes = useStyles();
    const history = useHistory();

    const handleChangePhone = (value: string | null) => {
        if (value === '') {
            value = null;
        }

        setPhone(value);
    };

    useEffect(() => {
        if (duplicates.length) {
            setOpen(true);
        } else {
            setModifyMode(false);
            setOpen(false);
            setSelected(false);
            setSelectedId('');
            // setPhone(null);
        }
    }, [duplicates]);

    const handleDuplicateSearch = () => {
        setInitialValues({phone});
        dispatch(respondentDuplicateSearchRequest(phone || ''))
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmReplaceClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleModify = () => {
        setOpen(false);
        const respondent: Respondent | undefined = duplicates.find((duplicate: Respondent) =>
            duplicate._id === selectedId);
        if (respondent) {
            setInitialValues(respondent);
            setModifyMode(true);
        }
    };

    const handleCreateDuplicate = (replace: boolean = false) => {
        setOpen(false);
        dispatch(respondentDuplicateCreateRequest(selectedId, replace));
        setSelectedId('');
        setSelected(false);
    };

    const isSelected = (id?: string) => {
        if (!id) return;
        return selectedId.indexOf(id) !== -1;
    };

    const handleClick = (event: React.MouseEvent<unknown>, id?: string) => {
        if (!id) return;

        if (id === selectedId) {
            setSelectedId('');
            setSelected(false);
        } else {
            setSelected(true);
            setSelectedId(id);
        }
    };

    const replaceRespondent = () => {
        handleCreateDuplicate(true);
        handleClose();
        handleConfirmReplaceClose();
    };

    const onSubmit = (values: Respondent) => {
        values = clearEmptyArraysValues(values);
        dispatch(respondentUpdateRequest(selectedId, values));
        history.push('/respondents/list');
        dispatch(toggleSnackbarOpen('Record Saved', 'success'))
    };
    const onSubmitNew = (values: Respondent) => {
        values = clearEmptyArraysValues(values);
        dispatch(respondentsCreateRequest(values));
        history.push('/respondents/list');
        dispatch(toggleSnackbarOpen('Record Saved', 'success'))
    };

    return (
        <>
            {!modifyMode && (
                <Paper variant={'outlined'} className={classes.paper}>
                    <InputPhoneMask
                        name={'business_phone'}
                        label="Phone"
                        value={phone}
                        setPhone={handleChangePhone}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                handleDuplicateSearch();
                            }
                        }}
                    />
                    <Box mt={4} width={1} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            type="submit"
                            variant="contained"
                            size={'large'}
                            color="primary"
                            disabled={duplicatesSearchLoading}
                            onClick={() => handleDuplicateSearch()}
                        >
                            {duplicatesSearchLoading && <CircularProgress size={24}/>}
                            {!duplicatesSearchLoading && (
                                <Typography variant={'button'}>
                                    Search
                                </Typography>
                            )}
                        </Button>
                    </Box>
                </Paper>
            )}

            {(!duplicates.length && duplicatesError || modifyMode) && (
                <>
                    <RespondentEditComponent
                        mode={'add'}
                        hideSearchByKeyword={true}
                        respondent={modifyMode ? initialValues : {
                            'cellphone': initialValues.phone,
                            type: 'false',
                            do_not_use: false,
                        }}
                        onSubmit={modifyMode ? onSubmit : onSubmitNew}/>
                </>
            )}

            <Dialog
                open={open}
                maxWidth={'xl'}
                onClose={handleClose}
                aria-labelledby="duplicates-dialog-title"
                aria-describedby="duplicates-dialog-description"
            >
                <DialogTitle id="duplicates-dialog-title">
                    <Typography
                        align="center"
                        className={classes.dialogTitle}
                    >Duplicates found</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TableContainer style={{overflowX: 'unset'}}
                                    component={(props: TableContainerProps) => (<Paper {...props} elevation={0}/>)}>
                        <Table aria-label="customized table" className={classes.table}>
                            <TableHead style={{backgroundColor: '#e2dbfd'}}>
                                <TableRow>
                                    <StyledTableCell/>
                                    {
                                        headers.map(
                                            (header: ListComponentHeader) => {
                                                if (hiddenHeaders.indexOf(header.name) !== -1) return;

                                                return (
                                                    <StyledTableCell key={header.name}>
                                                        {header.label}
                                                    </StyledTableCell>
                                                )
                                            }
                                        )
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {duplicates && duplicates.map((row: Respondent) => {
                                        const isItemSelected = isSelected(row._id);
                                        const labelId = `enhanced-table-checkbox-${row._id}`;
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                key={row._id}
                                                tabIndex={-1}
                                                onClick={event => handleClick(event, row._id)}
                                                selected={isItemSelected}
                                            >
                                                <StyledTableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.first_name}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div>{row.last_name}</div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div>{row.cellphone}</div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div>{row.email}</div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div>{row.age}</div>
                                                </StyledTableCell>
                                                {row.last_participation ? (
                                                    <>
                                                        <StyledTableCell>
                                                            <div>{row.last_participation.updated_at && (
                                                                moment(row.last_participation.updated_at).format('YYYY-MM-DD')
                                                            )}</div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <div>{row.last_participation.project && (
                                                                row.last_participation.project.project_name
                                                            )}</div>
                                                        </StyledTableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTableCell/>
                                                        <StyledTableCell/>
                                                    </>
                                                )}
                                                <StyledTableCell>
                                                    {row.do_not_use && (
                                                        <span
                                                            className={`material-icons-outlined MuiIcon-root ${classes.doNotUse}`}>
                                                            error
                                                        </span>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        )
                                    }
                                )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.modifyButton}
                        variant="contained"
                        size={'large'}
                        color="primary"
                        disabled={!selected}
                        onClick={handleModify}
                    >
                        Modify
                    </Button>
                    <Button
                        className={classes.createDuplicateButton}
                        variant="contained"
                        size={'large'}
                        color="primary"
                        disabled={!selected}
                        onClick={() => handleCreateDuplicate(false)}
                    >
                        Create duplicate
                    </Button>
                    <Button
                        variant="contained"
                        size={'large'}
                        color="primary"
                        disabled={!selected}
                        onClick={() => setConfirmDialogOpen(true)}
                    >
                        Replace record
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={confirmDialogOpen}
                confirmMessage={
                    'Are You sure you want to replace record?' +
                    ' The selected respondent will be removed'
                }
                handleDelete={replaceRespondent}
                handleClose={handleConfirmReplaceClose}
                confirmButtonText={'Replace'}
            />
        </>
    );
};
