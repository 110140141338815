import { combineReducers } from 'redux';
import { userReducer, UserState } from './user';
import { userChangePasswordReducer, UserChangePasswordState } from './changePassword';
import { usersListReducer, UsersListState } from './users';
import { showReducer, UserShowState } from './show';
import { updateReducer, UserUpdateState } from './update';
import { createReducer, UserCreateState } from './create';

export interface UsersState {
    user: UserState;
    users: UsersListState;
    show: UserShowState;
    update: UserUpdateState;
    create: UserCreateState;
    changePassword: UserChangePasswordState;
}

export const usersReducer = combineReducers({
    user: userReducer,
    users: usersListReducer,
    show: showReducer,
    update: updateReducer,
    create: createReducer,
    changePassword: userChangePasswordReducer,
});
