import { AppState } from '..';
import { Project } from './constants';
import { ListComponentHeader } from '../../interfaces';
import { ApiErrors } from '../../helpers/api-errors';

export const selectActiveProjects = (state: AppState): any[] => state.projects.projects.active;
export const selectArchivedProjects = (state: AppState): any[] => state.projects.projects.archived;

export const selectProjectsRequested = (state: AppState): boolean => state.projects.projects.loading;
export const selectProjectsDashboard = (state: AppState): Project[] => state.projects.projects.projects;
export const selectProjectsPageHeaders = (state: AppState): ListComponentHeader[] => state.projects.projects.headers;
export const selectProjectsList = (state: AppState): any[] => state.projects.list.data;
export const selectProjectsListLoading = (state: AppState): boolean => state.projects.list.loading;
export const selectProjectsTotal = (state: AppState): number => state.projects.list.total;
export const selectProjectsListHeaders = (state: AppState): ListComponentHeader[] => state.projects.list.headers;

export const selectProjectsActiveTotal = (state: AppState): number => state.projects.projects.activeTotal;
export const selectProjectsArchivedTotal = (state: AppState): number => state.projects.projects.archivedTotal;

export const selectProject = (state: AppState): Project => state.projects.show.project;
export const selectProjectShowLoading = (state: AppState): boolean => state.projects.show.loading;

export const selectProjectUpdateLoading = (state: AppState): boolean => state.projects.update.loading;
export const selectProjectUpdateSuccess = (state: AppState): boolean => state.projects.update.success;
export const selectProjectsUpdateFailure = (state: AppState): ApiErrors => state.projects.update.errors;

export const selectCreatedProject = (state: AppState): Project => state.projects.create.project;
export const selectProjectCreateSuccess = (state: AppState): boolean => state.projects.create.success;
export const selectProjectsCreateRequested = (state: AppState): boolean => state.projects.create.loading;
export const selectCreatedProjectsFailure = (state: AppState): ApiErrors => state.projects.create.errors;
