export enum RolesActionType {
    ROLES_REQUEST = 'ROLES/REQUEST',
    ROLES_SUCCESS = 'ROLES/SUCCESS',
    ROLES_FAILURE = 'ROLES/FAILURE',
}

export interface Role {
    _id: string,
    name: string
}

export interface RolesSuccessPayload {
    roles: Role[]
}

export interface RolesFailurePayload {
    error: string
}

export type RolesRequestAction =
    ActionWithPayload<RolesActionType.ROLES_REQUEST, {}>;

export type RolesSuccessAction =
    ActionWithPayload<RolesActionType.ROLES_SUCCESS, RolesSuccessPayload>;

export type RolesFailureAction =
    ActionWithPayload<RolesActionType.ROLES_FAILURE, RolesFailurePayload>;

export type RolesAction =
    RolesRequestAction |
    RolesSuccessAction |
    RolesFailureAction;
