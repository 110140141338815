import { Project, ProjectActionType } from './constants';
import { FilterSet } from '../../containers/RespondentsSearchContainer';
import { ListComponentHeader } from '../../interfaces';
import { ApiErrors } from '../../helpers/api-errors';

export interface ProjectsListFailure {
    type: typeof ProjectActionType.PROJECTS_LIST_FAILURE;
}

export interface ProjectsListRequest {
    type: typeof ProjectActionType.PROJECTS_LIST_REQUEST;
    filter: FilterSet;
    skip?: number;
    orderBy?: string;
    orderDirection?: string;
}

export interface ProjectsListSuccess {
    type: typeof ProjectActionType.PROJECTS_LIST_SUCCESS;
    data: string[];
    total: number;
    headers: ListComponentHeader[];
}

export const projectsListFailure = (): ProjectsListFailure => ({
    type: ProjectActionType.PROJECTS_LIST_FAILURE,
});

export const projectsListRequest = (params: FilterSet, skip?: number, orderBy?: string, orderDirection?: string): ProjectsListRequest => ({
    type: ProjectActionType.PROJECTS_LIST_REQUEST,
    filter: params,
    skip,
    orderBy,
    orderDirection,
});

export const projectsListSuccess = (data: string[], total: number, headers: ListComponentHeader[]): ProjectsListSuccess => ({
    type: ProjectActionType.PROJECTS_LIST_SUCCESS,
    data,
    total,
    headers,
});

/**
 * PROJECTS PAGE (ACTIVE + ARCHIVED)
 */

export interface ProjectsFailure {
    type: typeof ProjectActionType.PROJECTS_FAILURE;
}

export interface ProjectsRequest {
    type: typeof ProjectActionType.PROJECTS_REQUEST;
    skipActive?: number;
    skipArchived?: number;
    activeOrderBy?: string;
    activeOrderDirection?: string;
    archivedOrderBy?: string;
    archivedOrderDirection?: string;
}

export interface ProjectsSuccess {
    type: typeof ProjectActionType.PROJECTS_SUCCESS;
    active: string[];
    archived: string[];
    headers: ListComponentHeader[];
    activeTotal: number;
    archivedTotal: number;
}

export const projectsFailure = (): ProjectsFailure => ({
    type: ProjectActionType.PROJECTS_FAILURE,
});

export const projectsRequest = (
    activeOrderBy?: string,
    activeOrderDirection?: string,
    archivedOrderBy?: string,
    archivedOrderDirection?: string,
    skipActive?: number,
    skipArchived?: number
): ProjectsRequest =>
    ({
        type: ProjectActionType.PROJECTS_REQUEST,
        skipActive,
        skipArchived,
        activeOrderBy,
        activeOrderDirection,
        archivedOrderBy,
        archivedOrderDirection,
    });

export const projectsSuccess = (
    active: string[],
    archived: string[],
    headers: ListComponentHeader[],
    activeTotal: number,
    archivedTotal: number
): ProjectsSuccess => ({
    type: ProjectActionType.PROJECTS_SUCCESS,
    active,
    archived,
    headers,
    activeTotal,
    archivedTotal,
});

/**
 * CREATE PROJECT PAGE
 */

export interface ProjectsCreateFailure {
    type: typeof ProjectActionType.PROJECTS_CREATE_FAILURE;
    errors: ApiErrors;
}

export interface ProjectsCreateRequest {
    type: typeof ProjectActionType.PROJECTS_CREATE_REQUEST;
    project: Project;
}

export interface ProjectsCreateSuccess {
    type: typeof ProjectActionType.PROJECTS_CREATE_SUCCESS;
    project: Project;
}

export const projectsCreateFailure = (errors: ApiErrors): ProjectsCreateFailure => ({
    type: ProjectActionType.PROJECTS_CREATE_FAILURE,
    errors,
});

export const projectsCreateRequest = (project: Project): ProjectsCreateRequest => ({
    type: ProjectActionType.PROJECTS_CREATE_REQUEST,
    project,
});

export const projectsCreateSuccess = (project: Project): ProjectsCreateSuccess => ({
    type: ProjectActionType.PROJECTS_CREATE_SUCCESS,
    project,
});

export interface ProjectsUpdateFailure {
    type: typeof ProjectActionType.PROJECTS_UPDATE_FAILURE;
    errors: ApiErrors;
}

export interface ProjectsUpdateRequest {
    type: typeof ProjectActionType.PROJECTS_UPDATE_REQUEST;
    project: Project;
}

export interface ProjectsUpdateSuccess {
    type: typeof ProjectActionType.PROJECTS_UPDATE_SUCCESS;
    project: Project;
}

export const projectsUpdateFailure = (errors: ApiErrors): ProjectsUpdateFailure => ({
    type: ProjectActionType.PROJECTS_UPDATE_FAILURE,
    errors,
});

export const projectsUpdateRequest = (project: Project): ProjectsUpdateRequest => ({
    type: ProjectActionType.PROJECTS_UPDATE_REQUEST,
    project,
});

export const projectsUpdateSuccess = (project: Project): ProjectsUpdateSuccess => ({
    type: ProjectActionType.PROJECTS_UPDATE_SUCCESS,
    project,
});

export interface ProjectsShowFailure {
    type: typeof ProjectActionType.PROJECTS_SHOW_FAILURE;
}

export interface ProjectsShowRequest {
    type: typeof ProjectActionType.PROJECTS_SHOW_REQUEST;
    _id: string;
}

export interface ProjectsShowSuccess {
    type: typeof ProjectActionType.PROJECTS_SHOW_SUCCESS;
    project: Project;
}

export interface ProjectsShowResetState {
    type: typeof ProjectActionType.PROJECTS_SHOW_RESET_STATE;
}

export const projectsShowFailure = (): ProjectsShowFailure => ({
    type: ProjectActionType.PROJECTS_SHOW_FAILURE,
});

export const projectsShowRequest = (_id: string): ProjectsShowRequest => ({
    type: ProjectActionType.PROJECTS_SHOW_REQUEST,
    _id,
});

export const projectsShowSuccess = (project: Project): ProjectsShowSuccess => ({
    type: ProjectActionType.PROJECTS_SHOW_SUCCESS,
    project,
});

export const projectsShowResetState = (): ProjectsShowResetState => ({
    type: ProjectActionType.PROJECTS_SHOW_RESET_STATE,
});

export interface ProjectsDashboardFailure {
    type: typeof ProjectActionType.PROJECTS_DASHBOARD_FAILURE;
}

export interface ProjectsDashboardRequest {
    type: typeof ProjectActionType.PROJECTS_DASHBOARD_REQUEST;
}

export interface ProjectsDashboardSuccess {
    type: typeof ProjectActionType.PROJECTS_DASHBOARD_SUCCESS;
    projects: Project[];
}

export const projectsDashboardFailure = (): ProjectsDashboardFailure => ({
    type: ProjectActionType.PROJECTS_DASHBOARD_FAILURE,
});

export const projectsDashboardRequest = (): ProjectsDashboardRequest => ({
    type: ProjectActionType.PROJECTS_DASHBOARD_REQUEST,
});

export const projectsDashboardSuccess = (projects: Project[]): ProjectsDashboardSuccess => ({
    type: ProjectActionType.PROJECTS_DASHBOARD_SUCCESS,
    projects,
});

export interface ProjectsDashboardResetState {
    type: typeof ProjectActionType.PROJECTS_DASHBOARD_RESET_STATE;
}

export const projectsDashboardResetState = (): ProjectsDashboardResetState => ({
    type: ProjectActionType.PROJECTS_DASHBOARD_RESET_STATE,
});

export interface ProjectsCreateResetState {
    type: typeof ProjectActionType.PROJECTS_CREATE_RESET_STATE;
}

export const projectsCreateResetState = (): ProjectsCreateResetState => ({
    type: ProjectActionType.PROJECTS_CREATE_RESET_STATE,
});

export interface ProjectsUpdateResetState {
    type: typeof ProjectActionType.PROJECTS_UPDATE_RESET_STATE;
}

export const projectsUpdateResetState = (): ProjectsUpdateResetState => ({
    type: ProjectActionType.PROJECTS_UPDATE_RESET_STATE,
});

export interface ProjectsRefresh {
    type: typeof ProjectActionType.PROJECTS_REFRESH;
    skipActive?: number;
    skipArchived?: number;
    activeOrderBy?: string;
    activeOrderDirection?: string;
    archivedOrderBy?: string;
    archivedOrderDirection?: string;
}

export const projectsRefresh = (
    activeOrderBy?: string,
    activeOrderDirection?: string,
    archivedOrderBy?: string,
    archivedOrderDirection?: string,
    skipActive?: number,
    skipArchived?: number
): ProjectsRefresh =>
    ({
        type: ProjectActionType.PROJECTS_REFRESH,
        activeOrderBy,
        activeOrderDirection,
        archivedOrderBy,
        archivedOrderDirection,
        skipActive,
        skipArchived,
    });


export type ProjectAction =
    ProjectsUpdateResetState
    | ProjectsCreateResetState
    | ProjectsListFailure
    | ProjectsListRequest
    | ProjectsListSuccess
    | ProjectsFailure
    | ProjectsRequest
    | ProjectsSuccess
    | ProjectsCreateRequest
    | ProjectsCreateFailure
    | ProjectsCreateSuccess
    | ProjectsUpdateRequest
    | ProjectsUpdateFailure
    | ProjectsUpdateSuccess
    | ProjectsShowFailure
    | ProjectsShowRequest
    | ProjectsShowSuccess
    | ProjectsShowResetState
    | ProjectsDashboardFailure
    | ProjectsDashboardRequest
    | ProjectsDashboardSuccess
    | ProjectsRefresh
    | ProjectsDashboardResetState;
