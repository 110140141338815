import { call, put } from 'redux-saga/effects';
import { METHOD_GET, requestBuilder } from '../../helpers';
import { rolesSuccess, rolesFailure } from './actions';
import { RolesRequestAction } from './constants';

function* RequestSaga(action: RolesRequestAction) {
    try {
        const { data } = yield call(
            requestBuilder,
            'api/roles',
            METHOD_GET,
            {}
        );
        yield put(rolesSuccess(data));
    } catch (error) {
        yield put(rolesFailure(error))
    }
}

export const RolesSaga = RequestSaga;
