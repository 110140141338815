import { RespondentsShowAction } from '../actions';
import { Respondent, RespondentActionType, respondentInitialValues } from '../constants';

export interface RespondentsShowState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: Respondent;
}

const initialState: RespondentsShowState = {
    loading: false,
    success: false,
    error: false,
    data: respondentInitialValues,
};

export const showReducer = (state: RespondentsShowState = initialState, action: RespondentsShowAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENTS_SHOW_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
            };
        case RespondentActionType.RESPONDENTS_SHOW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
            };
        case RespondentActionType.RESPONDENTS_SHOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                data: respondentInitialValues,
                success: false,
            };
        default:
            return state;
    }
};
