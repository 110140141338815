import { FilterValueAction } from './actions';
import { FILTER_VALUE_RESET_STATE, FILTER_VALUE_SET } from './constants';
import { FilterSet } from '../../containers/RespondentsSearchContainer';

export interface FilterValuesState {
    filter: FilterSet;
    fromFile?: boolean;
}

const initialState: FilterValuesState = {
    filter: [{f_id: (0 | Math.random() * 9e6).toString(36)}],
    fromFile: false,
};

export const filterValuesReducer = (state: FilterValuesState = initialState, action: FilterValueAction) => {
    switch (action.type) {
        case FILTER_VALUE_SET:
            return {
                ...state,
                filter: action.filter,
                fromFile: action.fromFile,
            };
        case FILTER_VALUE_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;

    }
};
