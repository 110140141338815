export enum DictionariesActionType {
    DICTIONARIES_REQUEST = 'DICTIONARIES_REQUEST',
    DICTIONARIES_FAILURE = 'DICTIONARIES_FAILURE',
    DICTIONARIES_SUCCESS = 'DICTIONARIES_SUCCESS',

    DICTIONARIES_LIST_REQUEST = 'DICTIONARIES_LIST_REQUEST',
    DICTIONARIES_LIST_FAILURE = 'DICTIONARIES_LIST_FAILURE',
    DICTIONARIES_LIST_SUCCESS = 'DICTIONARIES_LIST_SUCCESS',

    DICTIONARIES_LIST_RESET_STATE = 'DICTIONARIES_LIST_RESET_STATE',

    DICTIONARY_CREATE_REQUEST = 'DICTIONARY_CREATE_REQUEST',
    DICTIONARY_CREATE_FAILURE = 'DICTIONARY_CREATE_FAILURE',
    DICTIONARY_CREATE_SUCCESS = 'DICTIONARY_CREATE_SUCCESS',

    DICTIONARY_UPDATE_REQUEST = 'DICTIONARY_UPDATE_REQUEST',
    DICTIONARY_UPDATE_FAILURE = 'DICTIONARY_UPDATE_FAILURE',
    DICTIONARY_UPDATE_SUCCESS = 'DICTIONARY_UPDATE_SUCCESS',

    DICTIONARY_RESET_STATE = 'DICTIONARY_RESET_STATE',
}

export interface Dictionary extends Record<string, any>{
    _id: string,
    group: string,
    value: string,
    text: string,
    checked?: boolean
}
