import React from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getPropTextValue } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';

interface CarFields {
    type: string,
    year: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Personal: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Car Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.car_insurance }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Car Insurance Provider
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'car_insurance_provider') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Caregiver for Anyone?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.car_give_for_anyone }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Health Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.health_insurance }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Health Insurance Provider
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'health_insurance_provider') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Health Insurance Provider (other)
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'health_insurance_provider_other') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Life Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.life_insurance }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Life Insurance Provider
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.life_insurance_provider }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Household Income
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'household_income') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Home Insurance
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.home_insurance }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Home Insurance Provider
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'home_insurance_provider') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Personal Income
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'personal_income') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Place of Birth
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'place_of_birth') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Primary Language
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'primary_language') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Sexual Orientation
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'sexual_orientation') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Speak Spanish?
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.speak_spanish }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Languages Spoken in Household (check all that apply)
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'languages_spoken_in_hh') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Languages Spoken Outside of Household (check all that apply)
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'languages_spoken_outside_hh') }
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
