import React, { ChangeEvent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import {
    createStyles,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Theme,
    Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState, Respondent } from '../../../../modules';
import { selectDictionaryGroup } from '../../../../modules/dictionaries';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import Box from '@material-ui/core/Box';
import RemoveButton from '@material-ui/icons/Remove';
import AddButton from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';

interface Props {
    values?: Respondent;
    setValues: (values: Respondent) => void;
    setFilterValues: (prop: string, value: any) => void;
    mode?: string;
}

interface ChildFieldTemplate {
    name?: string;
    gender?: string;
    ethnicity?: string;
    dob: Date | null;
    age_from?: string;
    age_to?: string;
}

const childField: ChildFieldTemplate = {
    name: '',
    gender: undefined,
    ethnicity: undefined,
    dob: null,
};

const sexOptions = [
    {
        text: 'Male',
        value: '0',
    },
    {
        text: 'Female',
        value: '1',
    },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
            margin: '0 4px',
        },
        age: {
            display: 'flex',
            alignContent: 'stretch',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: '#4f4f4f',
        },
    })
);

export const ChildSearchField: React.FC<Props> = (props: Props) => {
    const {values, setValues, setFilterValues} = props;
    const classes = useStyles();
    const ethnicityOptions = useSelector((state: AppState) => selectDictionaryGroup(state, 'ethnicity'));
    const genderOptions = useSelector((state: AppState) => selectDictionaryGroup(state, 'gender'));
    const [children, setChildren] = useState<ChildFieldTemplate[]>(values?.children?.length ?
                                                        JSON.parse(JSON.stringify([...values.children])) : [{...childField}]);

    const handleAddFields = () => {
        setChildren([...children, {...childField}]);
    };

    const handleRemoveFields = (index: number) => {
        const i = children.findIndex((value: ChildFieldTemplate, key) => key === index);

        children.splice(i, 1);
        setValues({...values, 'children' : children.length ? children : undefined})
        setFilterValues('children', children);
        setChildren(children.length ? [...children] : [childField]);
    };

    return (
        <>
            { children.map((child: ChildFieldTemplate, key: number) => (
                <div key={ key }>
                    <Typography style={ {margin: '0 4px'} }>Child { key + 1 }</Typography>
                    <Paper className={ `${ classes.paper } ${ classes.root }` } variant={ 'outlined' }>
                        <Grid spacing={ 3 } container={ true }>
                            <Grid xs={ 12 } md={ 6 } item={ true }>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    value={ child?.name }
                                    label="Name"
                                    onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                                        child.name = event.target.value;
                                        setChildren([...children]);
                                        setValues({...values, 'children' : children})
                                        setFilterValues('children', children);
                                    } }
                                    fullWidth={ true }
                                />
                            </Grid>
                            <Grid xs={ 12 } md={ 6 } item={ true }>
                                {props.mode === 'search' && (
                                    <Grid justify={'space-between'} container={true}>
                                        <Grid className={classes.age} item={true} xs={2}>
                                            <Typography style={{color: '#0000008a'}}>
                                                Age Range
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                type="number"
                                                label="From"
                                                id="age_from"
                                                defaultValue={ '' }
                                                onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                                                    child.age_from = event.target.value;
                                                    setFilterValues('children', children);
                                                } }
                                                inputProps={{min: 0}}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                type="number"
                                                label="To"
                                                id="age_to"
                                                defaultValue={ '' }
                                                onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                                                    child.age_to = event.target.value;
                                                    setFilterValues('children', children);
                                                } }
                                                inputProps={{min: 0}}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {props.mode !== 'search' && (
                                    <LocalizationProvider dateAdapter={ MomentUtils }>
                                        <DatePicker
                                            clearable={ true }
                                            value={ child.dob }
                                            onChange={ (newValue: Date | null) => {
                                                child.dob = newValue;
                                                setChildren([...children]);
                                                setValues({...values, 'children' : children})
                                                setFilterValues('children', children);
                                            }}
                                            renderInput={ params => (
                                                <>
                                                    <TextField label="Birthday" margin="dense" { ...params }
                                                               variant={ 'filled' }
                                                               helperText={ '' } />
                                                </>
                                            ) }
                                        />
                                    </LocalizationProvider>
                                )}
                            </Grid>
                            <Grid xs={ 12 } md={ 6 } item={ true }>
                                <FormControl>
                                    <Autocomplete
                                        options={ genderOptions }
                                        value={ genderOptions.find((option: any) => option.value === child.gender) || null }
                                        defaultValue={ genderOptions.find((option: any) => option.value === child.gender) || null }
                                        getOptionLabel={ ((option: any) => option.text) }
                                        getOptionSelected={ (option: any, value: any) => option.text === value.text }
                                        onChange={ (event: any, newValue: any) => {
                                            child.gender = newValue ? newValue.value : null;
                                            setChildren([...children]);
                                            setValues({...values, 'children' : children})
                                            setFilterValues('children', children);
                                        } }
                                        renderInput={ params =>
                                            (
                                                <TextField name={ 'gender' } label={ 'Gender' } { ...params } />
                                            ) }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={ 12 } md={ 6 } item={ true }>
                                <FormControl>
                                    <Autocomplete
                                        id={ 'child_' + key + '_ethnicity_label' }
                                        options={ ethnicityOptions }
                                        value={ ethnicityOptions.find((option: any) => option.value === child.ethnicity) || null }
                                        defaultValue={ ethnicityOptions.find((option: any) => option.value === child.ethnicity) || null }
                                        getOptionLabel={ ((option: any) => option.text) }
                                        getOptionSelected={ (option: any, value: any) => option.text === value.text }
                                        onChange={ (event: any, newValue: any) => {
                                            child.ethnicity = newValue ? newValue.value : null;
                                            setChildren([...children]);
                                            setValues({...values, 'children' : children})
                                            setFilterValues('children', children);
                                        } }
                                        renderInput={ params =>
                                            (
                                                <TextField name={ 'ethnicity' } label={ 'Ethnicity' } { ...params } />
                                            ) }
                                    />
                                </FormControl>
                            </Grid>
                            <Box mt={ 2 } width={ 1 } display={ 'flex' } justifyContent={ 'center' }>
                                <IconButton onClick={ () => handleRemoveFields(key) }>
                                    <RemoveButton />
                                </IconButton>
                                <IconButton onClick={ () => handleAddFields() }>
                                    <AddButton />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Paper>
                </div>
            )) }
        </>
    );
};
