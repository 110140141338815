import React from 'react';
import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getPropTextValue } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';
import { RadioGroupField } from '../../common/Form/RadioGroupField';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
            width: '100%',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

interface ChildFieldTemplate {
    id: number;
    name?: string;
    gender?: string;
    ethnicity?: string;
    dob: Date | null;
}

const sexOptions = [
    {
        text: 'Male',
        value: '0',
    },
    {
        text: 'Female',
        value: '1',
    },
];

export const Household: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    const ethnicityOptions = useSelector((state: AppState) => selectDictionaryGroup(state, 'ethnicity'));

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Number in Household
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'number_in_household') }
                            </Typography>
                        </Grid>
                    </Grid>

                    { respondent.children && respondent.children.map((child: ChildFieldTemplate, key: number) => (
                        <div key={ key }>
                            <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                                <Typography style={{margin: '0 4px'}} className={ classes.typographyHeader }>Child {key + 1}</Typography>
                                <Paper style={ {width: '100%'} } variant={'outlined'}>
                                    <Grid style={{margin: '0'}} spacing={2} container={true}>
                                        <Grid  xs={12} md={6} item={ true }>
                                            <Typography className={ classes.typographyHeader }>Name</Typography>
                                            <Typography className={ classes.typography }>{ child.name }</Typography>
                                        </Grid>
                                        <Grid xs={12} md={6} item={ true }>
                                            <Typography className={ classes.typographyHeader }>Birthday</Typography>
                                            <Typography className={ classes.typography }>
                                                { child.dob ? moment(child.dob).format('MM/DD/YYYY') : '' }
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} md={6} item={ true }>
                                            <Typography className={ classes.typographyHeader }>Gender</Typography>
                                            <Typography className={ classes.typography }>
                                                { sexOptions.find((item: any) => item.value === child.gender)?.text }
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} md={6} item={ true }>
                                            <Typography className={ classes.typographyHeader }>Ethnicity</Typography>
                                            <Typography className={ classes.typography }>
                                                { ethnicityOptions.find((item: Dictionary) => item.value === child.ethnicity)?.text }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </div>
                    ))}

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Household Internet Provider
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'hh_internet_provider') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Home
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.home_ownership }
                                values={{'true': 'Own', 'false' : 'Rent'}}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Type of Home
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'type_of_home') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Pool
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.pool }
                            />
                        </Grid>
                    </Grid>

                    { respondent.pool && (
                        <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                            <Grid item={ true } xs={ 12 } sm={ 4 }>
                                <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                    Type of Pool
                                </Typography>
                            </Grid>
                            <Grid item={ true } xs={ 12 } sm={ 8 }>
                                <Typography style={ {width: '100%'} } className={ classes.typography }>
                                    { getPropTextValue(respondent, 'pool_type') }
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Solar Power
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <RadioGroupField
                                value={ respondent.solar_power }
                                values={{'Yes': 'Yes', 'No' : 'No', 'Interest': 'Interest'}}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Type of Flooring
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'type_of_flooring') }
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
