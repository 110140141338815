import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../modules';
import {
    dictionariesListRequest, dictionariesListResetState,
    Dictionary,
    dictionaryCreateRequest, dictionaryResetState, dictionaryUpdateRequest,
    selectDictionariesListData, selectDictionariesListHeadings,
    selectDictionariesListLoading,
    selectDictionariesListTotal, selectDictionaryCreateErrorMessage,
    selectDictionaryCreateLoading, selectDictionaryCreateSuccess,
    selectDictionaryGroup
} from '../../modules/dictionaries';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { ListComponent } from '../../components';
import { FilterSet } from '../RespondentsSearchContainer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AppBar, Grid, makeStyles, Paper, TextField, Toolbar } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { DictionaryAddDialogComponent } from '../../components/DictionaryAddDialogComponent';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme =>
    ({
        paper: {
            padding: theme.spacing(5),
            marginBottom: theme.spacing(4),
        },
        heading: {
            color: '#a9130f',
            fontSize: 30,
        },
        typography: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(27.8),
            fontWeight: 600,
        },
    }));

export const DictionaryContainer: React.FC = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [dict, setDict] = useState<Dictionary>({} as Dictionary);
    const [edit, setEdit] = useState<boolean>(false);

    const [group, setGroup] = useState<string>('');
    const [term, setTerm] = useState<string>('');

    const [query, setQuery] = useState<FilterSet>({});

    const selectEl = useRef<HTMLElement>(null);

    const groups = useSelector((state: AppState) => selectDictionaryGroup(state, 'dictionaries_groups'));
    const currentGroup = useSelector(selectDictionariesListData);
    const listTotal = useSelector(selectDictionariesListTotal);
    const listLoading = useSelector(selectDictionariesListLoading);
    const listHeadings = useSelector(selectDictionariesListHeadings);
    const createLoading = useSelector(selectDictionaryCreateLoading);
    const createMessage = useSelector(selectDictionaryCreateErrorMessage);
    const createSuccess = useSelector(selectDictionaryCreateSuccess);

    useEffect(
        () => {
            dispatch(dictionariesListResetState());
            if (group) {
                setQuery({'group': group});
                setTerm('');
                dispatch(dictionariesListRequest({'group': group}, 0));
            }
        },
        [group]
    );

    useEffect(
        () => {
            if (term.length > 2) {
                setGroup('');
                if (selectEl?.current) {
                    selectEl.current.getElementsByTagName('input')[0].value = '';
                    // selectEl.current.getElementsByTagName('div')[0].innerText = '';
                }
                setQuery({'text': term});
                dispatch(dictionariesListRequest({'text': term}, 0));
            }
        },
        [term]
    );

    const handleClose = () => {
        setEdit(false);
        setOpen(false);
        setDict({} as Dictionary);
        dispatch(dictionaryResetState());
        if (edit) {
            setQuery({'group': group})
            dispatch(dictionariesListRequest({'group': group}, 0));
        }
    }

    const handleAddDictionary = () => {
        if (group && !dict.hasOwnProperty('group')) {
            dict.group = group;
        }
        edit ? dispatch(dictionaryUpdateRequest(dict)) : dispatch(dictionaryCreateRequest(dict));
    }

    const pageChange = (query: FilterSet, skip: number, orderBy?: string, orderDirection?: string) => {
        dispatch(dictionariesListRequest(query, skip, orderBy, orderDirection));
    };

    const setCurrentGroup = (value: string) => {
        setGroup(value);
        if (!dict.hasOwnProperty('_id')) {
            dict.group = value;
        }
    };

    const editDict = (dictionary: any) => {
        setEdit(true)
        setDict(dictionary)
        setOpen(true)
    }

    return (
        <div>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography className={classes.heading}>
                    Dictionaries
                </Typography>
            </Box>
            <AppBar
                position={'static'}
                color={'default'}
                elevation={0}
            >
                <Toolbar>
                    <Grid container={true} spacing={2} alignItems={'center'}>
                        <Grid item={true}>
                            <Search color="inherit" style={{display: 'block'}}/>
                        </Grid>
                        <Grid item={true} xs={10}>
                            <TextField
                                fullWidth={true}
                                placeholder="Search by text"
                                InputProps={{
                                    disableUnderline: true,
                                    style: {fontSize: 'default', backgroundColor: '#f5f5f5'},
                                    endAdornment: (
                                        <IconButton
                                            style={{display: term ? '' : 'none'}}
                                            onClick={() => setTerm('')}><ClearIcon/>
                                        </IconButton>
                                    ),
                                }}
                                value={term}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setTerm(e.target.value as string)}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item={true}>
                            <Button
                                variant="contained"
                                style={{marginRight: 1}}
                                color={'primary'}
                                onClick={() => setOpen(true)}
                                aria-label="add abilities"
                            >
                                <Typography>
                                    Add Dictionary
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Paper variant={'outlined'} className={classes.paper}>
                <FormControl size={'small'} variant="filled">
                    <InputLabel id="dictionary-group-label">Select Dictionary Group</InputLabel>
                    <Select
                        labelId="dictionary-group-label"
                        id="dictionary-group"
                        IconComponent={KeyboardArrowDownIcon}
                        defaultValue={''}
                        ref={selectEl}
                        onChange={(e: ChangeEvent<{ value: unknown; }>) => setCurrentGroup(e.target.value as string)}
                    >
                        {
                            groups && groups.map(
                                (group: Dictionary, key: number) =>
                                    (
                                        <MenuItem key={key}
                                                  value={group.value}>{group.text}</MenuItem>
                                    )
                            )
                        }
                    </Select>
                </FormControl>

                {currentGroup && (
                    <ListComponent
                        loading={listLoading}
                        rows={currentGroup}
                        query={query}
                        total={listTotal}
                        pageChange={pageChange}
                        listOf={'dictionary'}
                        headers={listHeadings}
                        showActions={false}
                        dictActions={true}
                        editDict={editDict}
                        sortable={true}
                    />
                )}

            </Paper>
            <DictionaryAddDialogComponent
                open={open}
                handleClose={handleClose}
                handleAddDictionary={handleAddDictionary}
                groups={groups}
                dictionary={dict}
                setValues={setDict}
                loading={createLoading}
                errorMessage={createMessage}
                createSuccess={createSuccess}
                edit={edit}
                group={group}
            />
        </div>
    );
};
