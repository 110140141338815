import { RespondentUpdateRlFieldsAction } from '../actions';
import { RespondentActionType } from '../constants';

export interface RespondentsUpdateRlFieldsState {
    loading: boolean;
    success: boolean;
    error: boolean;
    bookedText: string;
}

const initialState: RespondentsUpdateRlFieldsState = {
    loading: false,
    success: false,
    error: false,
    bookedText: '0/0 booked',
};

export const updateRlFieldsReducer = (state: RespondentsUpdateRlFieldsState = initialState, action: RespondentUpdateRlFieldsAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                bookedText: action.bookedText,
            };
        case RespondentActionType.RESPONDENT_UPDATE_RL_FIELDS_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
            };
        default:
            return state;
    }
};
