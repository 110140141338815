import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectAddShowComponent } from '../../components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Project,
    projectsCreateRequest,
    selectCreatedProject, selectCreatedProjectsFailure,
    selectProjectCreateSuccess,
    selectProjectsCreateRequested,
} from '../../modules/projects';
import { ProjectRespondentListComponent } from '../../components/Project/ProjectRespondentListComponent';
import {
    RespondentsList,
    respondentsListCreateRequest,
    respondentsListUpdateRequest,
    selectRespondentsList
} from '../../modules/respondentsList';
import { FilterSet } from '../RespondentsSearchContainer';
import { selectFilterValues } from '../../modules/filterValue';
import { toggleSnackbarOpen } from '../../modules/notifications';
import { userRequest } from '../../modules';
import { PermissionContext } from '../../contexts/PermissionContext';
import { ProjectForm } from '../../components/Project/ProjectForm';
import Paper from '@material-ui/core/Paper';
import history from '../../history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            marginBottom: theme.spacing(4),
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        button: {
            backgroundColor: '#5680f9',
            minWidth: 250,
            textTransform: 'none',
        },
        label: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(20),
        },
    })
);

export const ProjectAddContainer: React.FC = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectProjectsCreateRequested);
    const project = useSelector(selectCreatedProject);
    const projectCreated = useSelector(selectProjectCreateSuccess);
    const list = useSelector(selectRespondentsList);
    const filterValues = useSelector(selectFilterValues);
    const {isAllowedTo} = useContext(PermissionContext);
    const createProjectError = useSelector(selectCreatedProjectsFailure);

    const classes = useStyles();

    useEffect(() => {
        if (projectCreated) {

            dispatch(toggleSnackbarOpen('Record Saved', 'success'));

            if (isAllowedTo('user.update')) {
                dispatch(userRequest());
            }
        }
    },        [projectCreated]);

    useEffect(() => {
        if (Object.keys(createProjectError).length) {
            let errorMessage = '';
            Object.keys(createProjectError).forEach((message: string) => errorMessage += createProjectError[message]);
            dispatch(toggleSnackbarOpen(errorMessage, 'error'));
        }
    },        [createProjectError]);

    const onSubmit = (values: Project) => {
        dispatch(projectsCreateRequest(values));
    };

    const onSubmitRespondentList = (values: RespondentsList) => {
        values.respondents_list_project = project._id;
        values.filter = filterValues;
        if (values._id) {
            dispatch(respondentsListUpdateRequest(values));
        } else {
            dispatch(respondentsListCreateRequest(values));
        }
        // history.push('/projects/');
    };

    return (
        <>
            <Box display={'flex'} flexGrow={1} mt={5}>
                <Grid item={true} xs={12}>

                    {projectCreated ? (
                        <>
                            <Typography className={classes.heading}>Project Details</Typography>
                            <ProjectAddShowComponent
                                project={project}
                            />
                        </>
                    ) : (
                        <>
                            <Typography className={classes.heading}>Step 1: Project Details</Typography>
                            <Paper className={classes.paper} variant="outlined">
                                <ProjectForm
                                    project={{} as Project}
                                    loading={loading}
                                    onSubmit={onSubmit}
                                    apiErrors={createProjectError}
                                />
                            </Paper>
                        </>
                    )}

                    <Box my={3} width={1}>
                        <ProjectRespondentListComponent
                            list={list}
                            projectCreated={projectCreated}
                            onSubmit={onSubmitRespondentList}
                        />
                    </Box>
                </Grid>
            </Box>
        </>
    );
};
