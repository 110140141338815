import React from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getPropTextValue } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
    })
);

export const Education: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();

    return (
        <Grid container={true} spacing={3} justify={'center'}>
            <Grid xs={12} item={true}>
                <Box my={ 2 }>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                State
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'state_attended_college') }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                College Attended
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'college_attended') }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                College Major
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'college_major') }
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
