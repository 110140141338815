import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { Checkbox, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import TextField from '@material-ui/core/TextField';
import {
    createGetAutocompleteProps,
    createGetInputProps,
    createMultipleAutocompleteProps
} from '../../../helpers/formBuilder';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldItem: {
            marginBottom: '10px',
        },
    })
);

const filterOptions = createFilterOptions(
    {
        limit: 600,
        ignoreCase: true,
        stringify: (option: Dictionary) => option.text,
    });

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const Employment: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();


    const industry = useSelector((state: AppState) => selectDictionaryGroup(state, 'industry'));
    const revenueLevel = useSelector((state: AppState) => selectDictionaryGroup(state, 'revenue_level'));

    const [values, setValues] = useState(respondent);

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);
    const getMultipleAutocompleteProps = createMultipleAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>


                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={12} item={true}>
                            <TextField
                                label="Company name"
                                { ...getInputProps('company') }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={12} item={true}>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    filterOptions={filterOptions}
                                    renderOption={(option, {selected}) => (
                                        <>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option.text}
                                        </>
                                    )}
                                    renderInput={params =>
                                        (
                                            <TextField label={'Industry'} {...params} />
                                        )}
                                    {...getMultipleAutocompleteProps('industry', industry)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>


                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={12} item={true}>
                            <TextField
                                label=" Occupation (other)"
                                { ...getInputProps('occupation_other') }
                            />
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                            <Grid xs={12} item={true}>
                                <TextField
                                    label=" # of Employees"
                                    { ...getInputProps('number_employees') }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid xs={12} item={true}>
                            <FormControl size={ 'small' } variant="filled">
                                <Autocomplete
                                    renderInput={params =>
                                        (
                                            <TextField label={'Revenue Level'} {...params} />
                                        )}
                                    {...getAutocompleteProps('revenue_level', revenueLevel)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
