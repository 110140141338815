import { UserActionType, UsersAction } from '../constants';
import { User } from './user';
import { ListComponentHeader } from '../../../interfaces';

export interface UsersListState {
    active: User[];
    archived: User[];
    total: number;
    archivedTotal: number;
    headers: ListComponentHeader[];
    loading: boolean,
}

export const initialState: UsersListState = {
    active: [],
    archived: [],
    total: 0,
    archivedTotal: 0,
    headers: [],
    loading: false,
};

export const usersListReducer = (state: UsersListState = initialState, action: UsersAction) => {
    switch (action.type) {
        case UserActionType.USERS_REQUEST:
            return {
                ...state,
                loading: true,
                total: 0,
                archivedTotal: 0,
            };
        case UserActionType.USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                active: action.active,
                archived: action.archived,
                total: action.total,
                archivedTotal: action.archivedTotal,
                headers: action.headers,
            };
        case UserActionType.USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
