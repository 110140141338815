import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import {
    respondentJoinProjectFailure,
    RespondentJoinProjectRequest, respondentJoinProjectSuccess,

} from '../actions';

export default function* respondentJoinProjectSaga(action: RespondentJoinProjectRequest) {
    try {
        const {id, status, project} = action;
        const {data} = yield call(
            requestBuilder,
            `api/respondents/${id}/join-project`,
            METHOD_POST,
            {
                status,
                project,
            }
        );

        yield put(respondentJoinProjectSuccess(data.success.message, data.rlRevision));
    } catch (error) {
        yield put(respondentJoinProjectFailure(error.message));
    }
}
