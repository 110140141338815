import { ChartsAction } from './actions';
import { ChartsType } from './constants';

export interface ChartsState {
    loading: boolean;
    success: boolean;
    error: boolean;
    chart: any;
    all: number;
}

const initialState: ChartsState = {
    loading: false,
    success: false,
    error: false,
    chart: [],
    all: 0,
};

export const chartsReducer = (state: ChartsState = initialState, action: ChartsAction) => {
    switch (action.type) {
        case ChartsType.CHARTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                chart: [],
                all: 0,
            };
        case ChartsType.CHARTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                chart: action.chart,
                all: action.all,
            };
        case ChartsType.CHARTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};
