import { ChartsType } from './constants';

export interface ChartsFailure {
    type: typeof ChartsType.CHARTS_FAILURE;
}

export interface ChartsRequest {
    type: typeof ChartsType.CHARTS_REQUEST;
    payload: string
}

export interface ChartsSuccess {
    type: typeof ChartsType.CHARTS_SUCCESS,
    chart: any,
    all: number,
}

export const chartsFailure = (): ChartsFailure => ({
    type: ChartsType.CHARTS_FAILURE,
});

export const chartsRequest = (payload: string): ChartsRequest => ({
    type: ChartsType.CHARTS_REQUEST,
    payload,
});

export const chartsSuccess = (chart: any, all: number): ChartsSuccess => ({
    type: ChartsType.CHARTS_SUCCESS,
    chart,
    all,
});

export type ChartsAction = ChartsFailure | ChartsRequest | ChartsSuccess;
