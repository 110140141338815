import React from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getPropTextValue } from '../../../helpers/responseFormatter';
import { RespondentInnerComponentProps } from '../index';
// import { RadioGroupField } from '../../common/Form/RadioGroupField';
import { CheckBoxGroupField } from '../../common/Form/CheckBoxGroupField';
import { useSelector } from 'react-redux';
import { AppState } from '../../../modules';
import { selectDictionaryGroup } from '../../../modules/dictionaries';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    })
);

export const Electronics: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent} = props;
    const classes = useStyles();


    // const computerType = useSelector((state: AppState) => selectDictionaryGroup(state, 'computer_type'));
    const videoConsoleType = useSelector((state: AppState) => selectDictionaryGroup(state, 'video_console_type', true));

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>


                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Cable TV provider and/or Streaming Service
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <Typography style={ {width: '100%'} } className={ classes.typography }>
                                { getPropTextValue(respondent, 'cable_provider') }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                        <Grid item={ true } xs={ 12 } sm={ 4 }>
                            <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                Video Game System at Home
                            </Typography>
                        </Grid>
                        <Grid item={ true } xs={ 12 } sm={ 8 }>
                            <CheckBoxGroupField
                                value={ respondent.video_game_system }
                                dictionary={ videoConsoleType }
                                name="video_console_type"
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    );
};
