import { RespondentUpdateAction } from '../actions';
import { RespondentActionType } from '../constants';

export interface RespondentsUpdateState {
    loading: boolean;
    success: boolean;
    error: boolean;
}

const initialState: RespondentsUpdateState = {
    loading: false,
    success: false,
    error: false,
};

export const updateReducer = (state: RespondentsUpdateState = initialState, action: RespondentUpdateAction) => {
    switch (action.type) {
        case RespondentActionType.RESPONDENT_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };
        case RespondentActionType.RESPONDENT_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RespondentActionType.RESPONDENT_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
            };
        default:
            return state;
    }
};
