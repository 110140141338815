import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, Typography, withStyles, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { createGetSelectProps } from '../../../helpers/formBuilder';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
        label: {
            color: '#5680F9',
            margin: '-18px 0px',
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(23),
            fontWeight: 600,
        },
        popperRedText: {
            '& .MuiAutocomplete-listbox': {
                color: '#f00',
            },
        },
    })
);

const StylesSelect = withStyles({
    root: {
        '& .RedText': {
            '& .MuiInputBase-input': {
                color: '#f00',
            },
        },
        '& .MuiTextField-root': {
            '& .MuiAutocomplete-inputRoot': {
                paddingTop: 6,
                paddingBottom: 6,
            },
            '& .MuiSvgIcon-root': {
                color: '#5680f9',
            },
            backgroundColor: '#fff',
            color: '#4F4F4F',
            padding: 0,
            borderRadius: '4px',
            border: '1px solid #f1f1f1',
        },

    },
})(Autocomplete);

export const State: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const [values, setValues] = useState(respondent);

    const getSelectProps = createGetSelectProps(values, setValues, setFilterValues);

    return (
        <>
            <Grid container={true} spacing={3} justify={'space-between'}>
                <Box mx={1.5} mt={3.5} mb={1}>
                    <Typography className={classes.heading}>Demographic</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mx={1.5} mt={1.5} mb={3}>

                    <FormControl fullWidth={false} style={{minWidth: '250px', marginRight: '20px'}}>
                        <InputLabel
                            className={classes.label}
                            shrink={true}
                            htmlFor="respondent-type-label">
                            Respondent Type
                        </InputLabel>
                        {
                            (props.mode !== 'search') && (
                                <>
                                    <StylesSelect
                                        disableClearable={true}
                                        PopperComponent={ props => (
                                            <Popper {...props} style={ {maxWidth: 'fit-content'}} placement="bottom-end" />
                                        )}
                                        popupIcon={ <KeyboardArrowDownIcon /> }
                                        renderInput={ params =>
                                            (
                                                <TextField
                                                    {...params}
                                                    onKeyDown={(event: any) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )}
                                        {...getSelectProps('type', [
                                            {text: 'Consumer', value: 'false'},
                                            {text: 'Healthcare Professionals', value: 'true'},
                                        ])}
                                    />
                                </>
                            )
                        }
                        {
                            (props.mode === 'search') && (
                                <>
                                    <StylesSelect
                                        disableClearable={true}
                                        PopperComponent={ props => (
                                            <Popper {...props} style={ {maxWidth: 'fit-content'}} placement="bottom-end" />
                                        )}
                                        popupIcon={ <KeyboardArrowDownIcon /> }
                                        renderInput={ params =>
                                            (
                                                <TextField
                                                    {...params}
                                                    onKeyDown={(event: any) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )}
                                        {...getSelectProps('type', [
                                            {text: 'Consumer', value: 'false'},
                                            {text: 'Include Healthcare Professional', value: 'all'},
                                            {text: 'Only Healthcare Professionals', value: 'true'},
                                        ])}
                                    />
                                </>
                            )
                        }
                    </FormControl>
                    <FormControl fullWidth={false} style={{minWidth: '250px'}}>
                        <InputLabel className={classes.label} shrink={true} htmlFor="do_not_use">Profile
                            Status</InputLabel>
                        {
                            (props.mode !== 'search') && (
                                <>
                                    <StylesSelect
                                        disableClearable={true}
                                        PopperComponent={ props => (
                                            <Popper
                                                {...props}
                                                className={classes.popperRedText}
                                                style={ {maxWidth: 'fit-content', minWidth: '250px'}}
                                                placement="bottom-end"
                                            />
                                        )}
                                        popupIcon={ <KeyboardArrowDownIcon /> }
                                        renderInput={ params =>
                                            (
                                                <TextField
                                                    {...params}
                                                    className={'RedText'}
                                                    onKeyDown={(event: any) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )}
                                        {...getSelectProps('do_not_use', [
                                            {text: 'Active', value: 'false'},
                                            {text: 'DNU', value: 'true'},
                                        ])}
                                    />
                                </>
                            )
                        }
                        {
                            props.mode === 'search' && (
                                <>
                                    <StylesSelect
                                        disableClearable={true}
                                        PopperComponent={ props => (
                                            <Popper
                                                {...props}
                                                style={ {maxWidth: 'fit-content', minWidth: '250px'}}
                                                placement="bottom-end"
                                            />
                                        )}
                                        popupIcon={ <KeyboardArrowDownIcon /> }
                                        renderInput={ params =>
                                            (
                                                <TextField
                                                    {...params}
                                                    onKeyDown={(event: any) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )}
                                        {...getSelectProps('do_not_use', [
                                            {text: 'All', value: ' '},
                                            {text: 'Exclude Booked', value: 'booked'},
                                            {text: 'Exclude DNU, Attended Last 3 Months and Anyone marked Booked', value: 'dnu_attended_3_month_booked'},
                                            {text: 'Exclude DNU, Attended Last 6 Months and Anyone marked Booked', value: 'dnu_attended_6_month_booked'},
                                            {text: 'Exclude DNU', value: 'dnu'},
                                            {text: 'Exclude DNU & Booked', value: 'dnu_booked'},
                                            {text: 'Exclude Attended', value: 'attended'},
                                        ])}
                                    />
                                </>
                            )
                        }
                    </FormControl>
                </Box>
            </Grid>


        </>

    );
};
