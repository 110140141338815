import { combineReducers } from 'redux';
import { listReducer, RespondentsListState } from './list';
import { showReducer, RespondentsShowState } from './show';
import { duplicatesReducer, RespondentDuplicateSearchState } from './duplicate';
import { deleteReducer, RespondentsDeleteState } from './delete';
import { RespondentsUpdateState, updateReducer } from './update';
import { RespondentsUpdateRlFieldsState, updateRlFieldsReducer } from './update_rl_fields';
import { bulkDeleteReducer, RespondentsBulkDeleteState } from './bulk_delete';
import { joinProjectReducer, RespondentsJoinProjectState } from './join_project';

// app state interface
export interface RespondentsState {
    list: RespondentsListState;
    show: RespondentsShowState;
    delete: RespondentsDeleteState;
    bulkDelete: RespondentsBulkDeleteState;
    update: RespondentsUpdateState;
    duplicates: RespondentDuplicateSearchState;
    updateRlFields: RespondentsUpdateRlFieldsState;
    joinProject: RespondentsJoinProjectState;
}

// preparing app respondentsReducer
export const respondentsReducer = combineReducers({
    list: listReducer,
    show: showReducer,
    delete: deleteReducer,
    bulkDelete: bulkDeleteReducer,
    update: updateReducer,
    duplicates: duplicatesReducer,
    updateRlFields: updateRlFieldsReducer,
    joinProject: joinProjectReducer,
});
