import { call, put } from 'redux-saga/effects';
import { METHOD_GET, requestBuilder } from '../../../helpers';
import { dictionariesFailure, DictionariesRequest, dictionariesSuccess } from '../actions';

function* RequestSaga(action: DictionariesRequest) {
    try {
        const { data } = yield call(
            requestBuilder,
            'api/dictionaries',
            METHOD_GET,
            {}
        );

        yield put(dictionariesSuccess(data));
    } catch (error) {
        yield put(dictionariesFailure());
    }
}

export const DictionariesRequestSaga = RequestSaga;
