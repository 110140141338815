import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import {
    AppState,
} from '../../../modules';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RespondentInnerComponentProps } from '../index';
import { createGetAutocompleteProps, createGetCheckBoxProps } from '../../../helpers/formBuilder';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(5),
            position: 'relative',
        },
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        typography: {
            color: '#565656',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        grid: {
            flex: '1 0 15%',
        },
        root: {},
        gridCheckbox: {
            flex: '1 1 100px',
        },
    })
);

export const Religion: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {
    const {respondent, setFilterValues} = props;
    const classes = useStyles();

    const religions = useSelector((state: AppState) => selectDictionaryGroup(state, 'religions'));

    const [values, setValues] = useState(respondent);

    const getCheckBoxProps = createGetCheckBoxProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);

    return (
        <Grid container={ true } spacing={ 3 } justify={ 'center' }>
            <Grid xs={ 12 } item={ true }>
                <Box my={ 2 }>
                    {
                        props.mode === 'search' && (
                            <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                                <Grid item={ true } xs={ 12 } sm={ 4 }>
                                    <Typography style={ {width: '100%'} } className={ classes.typographyHeader }>
                                        Religious Affiliations (check all that apply)
                                    </Typography>
                                </Grid>
                                <Grid  item={ true } xs={ 12 } sm={ 8 }>
                                    {
                                        religions.map(
                                            (item: Dictionary, index: number) =>
                                                (
                                                    <Grid key={ index } item={ true } xs={ 12 } className={ classes.grid }>
                                                        <FormControlLabel
                                                            { ...getCheckBoxProps('religion', religions, index) }
                                                            control={ <Checkbox color="primary" /> }
                                                        />
                                                    </Grid>
                                                )
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        props.mode !== 'search' && (
                            <Grid className={ classes.fieldItem } container={ true } spacing={ 1 }>
                                <Grid xs={ 12 } item={ true }>
                                    <FormControl size={ 'small' } variant="filled">
                                        <Autocomplete
                                            renderInput={params =>
                                                (
                                                    <TextField label={'Religious Affiliations'} {...params} />
                                                )}
                                            {...getAutocompleteProps('religion', religions)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                    }
                </Box>
            </Grid>
        </Grid>
    );
};
