import { call, put } from 'redux-saga/effects';
import { METHOD_GET, requestBuilder } from '../../../helpers';
import { projectsShowFailure, ProjectsShowRequest, projectsShowSuccess } from '../actions';
import { commentsListSuccess } from '../../comments';
import { respondentsListListSuccess } from '../../respondentsList';

export default function* ShowRequestSaga(action: ProjectsShowRequest) {
    try {
        const {data} = yield call(
            requestBuilder,
            `api/projects/${action._id}`,
            METHOD_GET,
            {}
        );

        yield put(projectsShowSuccess(data));
        yield put(respondentsListListSuccess(data.respondents_list));
        yield put(commentsListSuccess(data.comments, data.comments_count));

    } catch (error) {
        yield put(projectsShowFailure());
    }
}
