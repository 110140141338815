import { FileUploadAction, FileUploadActionType } from './constants';
import { FILTER_VALUE_SET } from '../filterValue';
import { FilterSet } from '../../containers';

export interface FileUploadState {
    loading: boolean;
    success: boolean;
    error: boolean;
    data: any;
    filter: FilterSet;
    title: string;
    errorMessage: string;
}

const initialState: FileUploadState = {
    loading: false,
    success: false,
    error: false,
    data: [],
    filter: [],
    title: 'Value',
    errorMessage: '',
};

export const fileUploadReducer = (state: FileUploadState = initialState, action: FileUploadAction) => {
    switch (action.type) {
        case FileUploadActionType.FILE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case FileUploadActionType.FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                filter: action.filter,
            };
        case FileUploadActionType.FILE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.error,
            };
        case FileUploadActionType.FILE_UPLOAD_DATA_RESET_STATE:
            return {
                ...state,
                data: [],
                title: 'Value',
            };
        default:
            return state;
    }
};
