import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST, METHOD_PUT,
    requestBuilder,
} from '../../../helpers';
import {
    usersSuccess,
    usersFailure,
    userCreateSuccess,
    UserCreateRequest,
    userCreateFailure,
    UserUpdateRequest,
    userUpdateSuccess,
    userUpdateFailure,
    UserShowRequest,
    userShowSuccess,
    userShowFailure,
    userUpdateResetState,
    userDeleteSuccess, userDeleteFailure,
} from '../actions';
import { UserActionType, UsersRequestAction } from '../constants';
import history from '../../../history';
import { toggleSnackbarOpen } from '../../notifications';

function* usersRequestSaga(action: UsersRequestAction) {
    try {
        const {data, Error} = yield call(
            requestBuilder,
            'api/users/list',
            METHOD_POST,
            {
                skip: action.skip,
                orderBy: action.orderBy,
                orderDirection: action.orderDirection,
            }
        );

        if (Error) {
            yield put(usersFailure(Error));
        } else {
            yield put(usersSuccess(data.active, data.archived, data.total, data.archivedTotal, data.headers));
            yield put(userUpdateResetState());
        }

    } catch (error) {
        yield put(usersFailure('error'));
    }
}

function* createUser(action: UserCreateRequest) {
    try {
        const {user} = action;
        const {data} = yield call(
            requestBuilder,
            'api/users',
            METHOD_POST,
            {
                'user': user,
            }
        );

        yield put(userCreateSuccess(data.user));

        history.push('/users/roles');
    } catch (error) {
        yield put(userCreateFailure(error));
    }
}

function* updateUser(action: UserUpdateRequest) {
    try {
        const {user, redirect} = action;
        const {data} = yield call(
            requestBuilder,
            `api/users/${user._id}`,
            METHOD_PUT,
            {
                'user': user,
            }
        );

        yield put(userUpdateSuccess(data));
        if (redirect) {
            history.push('/users/roles');
        }
    } catch (error) {
        yield put(userUpdateFailure(error));
    }
}

function* deleteUser(action: UserUpdateRequest) {
    try {
        const {user} = action;
        const {data} = yield call(
            requestBuilder,
            `api/users/${user._id}`,
            METHOD_DELETE,
            {}
        );

        yield put(userDeleteSuccess(data));
        yield put(toggleSnackbarOpen('Record Deleted', 'success'));
    } catch (error) {
        yield put(userDeleteFailure(error));
    }
}

function* showUser(action: UserShowRequest) {
    try {
        const {id} = action;
        const {data} = yield call(
            requestBuilder,
            `api/users/${id}`,
            METHOD_GET,
            {}
        );

        yield put(userShowSuccess(data.user));
    } catch (error) {
        yield put(userShowFailure());
    }
}

export default function* rootUsersSaga() {
    yield all([
        takeLatest(UserActionType.USERS_REQUEST, usersRequestSaga),
        takeLatest(UserActionType.USER_CREATE_REQUEST, createUser),
        takeLatest(UserActionType.USER_UPDATE_REQUEST, updateUser),
        takeLatest(UserActionType.USER_SHOW_REQUEST, showUser),
        takeLatest(UserActionType.USER_DELETE_REQUEST, deleteUser),
    ]);
}
