import { ProjectAction } from '../actions';
import { Project, ProjectActionType } from '../constants';

export interface ProjectsShowState {
    loading: boolean;
    success: boolean;
    error: boolean;
    project: Project;
}

const initialState: ProjectsShowState = {
    loading: false,
    success: false,
    error: false,
    project: {} as Project,
};

export const showReducer = (state: ProjectsShowState = initialState, action: ProjectAction) => {
    switch (action.type) {
        case ProjectActionType.PROJECTS_SHOW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ProjectActionType.PROJECTS_SHOW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                project: action.project,
            };
        case ProjectActionType.PROJECTS_SHOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case ProjectActionType.PROJECTS_SHOW_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
