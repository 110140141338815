import { CommentAction } from '../actions';
import { Comment, CommentsActionType } from '../constants';

export interface CommentsState {
    loading: boolean;
    postLoading: boolean;
    success: boolean;
    error: boolean;
    comments: Comment[];
    commentsCount: number;
}

const initialState: CommentsState = {
    loading: false,
    postLoading: false,
    success: false,
    error: false,
    comments: [],
    commentsCount: 0,
};

export const commentsReducer =
    (state: CommentsState = initialState, action: CommentAction) => {
    switch (action.type) {
        case CommentsActionType.COMMENTS_CREATE_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case CommentsActionType.COMMENTS_CREATE_SUCCESS:
            return {
                ...state,
                postLoading: false,
                success: true,
                comments: action.comments,
                commentsCount: action.commentsCount,
            };
        case CommentsActionType.COMMENTS_CREATE_FAILURE:
            return {
                ...state,
                postLoading: false,
                error: true,
            };
        case CommentsActionType.COMMENTS_DELETE_SUCCESS:
            return {
                ...state,
                comments: action.comments,
                commentsCount: action.commentsCount,
            };
        case CommentsActionType.COMMENTS_DELETE_FAILURE:
            return {
                ...state,
                error: true,
            };
        case CommentsActionType.COMMENTS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CommentsActionType.COMMENTS_LIST_SUCCESS:
            return {
                ...state,
                comments: action.comments,
                commentsCount: action.commentsCount,
                loading: false,
            };
        case CommentsActionType.COMMENTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
