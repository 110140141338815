import React from 'react';
import { UserRolesContainer } from '../../../containers/User/UserRolesContainer';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Can } from '../../../components/common/Can';

export const UserRolesPage: React.FC = () =>
    (
        <>
            <Can to={'user.create'}>
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button
                        variant="contained"
                        size={'large'}
                        color="primary"
                    >
                        <Link
                            to="/users/add"
                            style={{color: '#fff', minWidth: 280}}
                        >
                            <Typography>
                                Create New User
                            </Typography>
                        </Link>
                    </Button>
                </Box>
            </Can>
            <UserRolesContainer/>
        </>
    );
