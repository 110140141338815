import { Respondent } from '../modules';

export const getPropTextValue = (object: any, property: keyof Respondent, capitalize: boolean = false): string => {
    const value = object[property + '_text'] ? object[property + '_text'] : object[property];
    return value && capitalize ? value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1) : value || '';
};

export interface ResponseFormattersInterface {
    [key: string]: (object: any) => any;
}

export const responseFormatters: ResponseFormattersInterface = {
    phone: (phoneNumber: string): string => {
        if (!phoneNumber) {
            return '';
        }

        const preparedString = phoneNumber.replace(/[^\d]/g, '');
        const replaceTo = preparedString.length > 10 ? '($1) $2-$3 x$4' : '($1) $2-$3';

        return preparedString.replace(/([\d]{3})([\d]{3})([\d]{1,4})([\d]{4})*/, replaceTo);
    },
}
