import { call, put } from 'redux-saga/effects';
import { METHOD_DELETE, requestBuilder } from '../../../helpers';
import {
    respondentsDeleteFailure,
    RespondentsDeleteRequest, respondentsDeleteSuccess,
} from '../actions';
import { toggleSnackbarOpen } from '../../notifications';
import history from '../../../history';

export default function* DeleteRespondentSaga(action: RespondentsDeleteRequest) {
    try {
        yield call(
            requestBuilder,
            `api/respondents/${action._id}`,
            METHOD_DELETE,
            {}
        );

        yield put(respondentsDeleteSuccess());
        history.push('/search');
        yield put(toggleSnackbarOpen('Record Deleted', 'success'));

    } catch (error) {
        yield put(respondentsDeleteFailure());
    }
}
