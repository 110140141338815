import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterValuesSet, Respondent } from '../../modules';
import { useHistory } from 'react-router-dom';
import { RespondentsSearchComponent } from '../../components';
import { Grid } from '@material-ui/core';
import { clearEmptyValues } from '../../helpers/formHelper';

export interface FilterValue {
    name: string,
    value: any,
}

export interface FilterSet {
    [key: string]: any;
}

export const RespondentsSearchContainer: React.FC = () => {
    const [formData, setFormData] = useState<any>([{type: false}]);
    const dispatch = useDispatch();
    const history = useHistory();

    const setFilterValuesAll = (values: Respondent, index: number) => {
        values = clearEmptyValues(values);
        formData[index] = values;
    };

    const addForm = () => {
        const newForm = [...formData];
        newForm.push([{}]);
        setFormData(newForm)
    };

    const showFormValues = () => {
        if (formData[0] && /[a-z0-9]{24}/.test(formData[0].keywords)) {
            history.push('/respondents/show/' + formData[0].keywords.replace(/.*?([a-z0-9]{24}).*/, '$1'));
        } else {
            dispatch(filterValuesSet(formData));
            history.push('/respondents/list');
        }
    };

    return (
        <>
            {
                formData.map((form: any, index: number) => (
                    <Grid container={true} key={index} style={{marginBottom: 40}}>
                        <RespondentsSearchComponent
                            formData={formData}
                            index={index}
                            addFrom={addForm}
                            onSubmit={() => showFormValues()}
                            setFilterValues={(values: Respondent) => setFilterValuesAll(values, index)}
                        />
                    </Grid>
                ))
            }
        </>
    );
};
