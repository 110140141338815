import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    usersRequest,
    selectUsersActive,
    selectUsersLoading,
    selectUsersHeaders,
    selectUsersTotal,
    selectUsersArchived,
    selectUsersArchivedTotal,
    userUpdateRequest,
    usersRefresh, userDeleteRequest,
} from '../../../modules/user';
import { UserRolesComponent } from '../../../components/User';
import { User } from '../../../modules/user/reducers/user';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { ListComponentHeader } from '../../../interfaces';

const useStyles = makeStyles(theme =>
    ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
        },
        heading: {
            color: '#5680F9',
            fontSize: theme.typography.pxToRem(28),
            fontWeight: 600,
        },
    }));

export const UserRolesContainer: React.FC = () => {
    const dispatch = useDispatch();
    const active: User[] = useSelector(selectUsersActive);
    const archived: User[] = useSelector(selectUsersArchived);
    const headers: ListComponentHeader[] = useSelector(selectUsersHeaders);
    const total: number = useSelector(selectUsersTotal);
    const archivedTotal: number = useSelector(selectUsersArchivedTotal);
    const loading = useSelector(selectUsersLoading);

    const classes = useStyles();

    useEffect(() => {
        dispatch(usersRequest())
    }, []);

    const handleArchive = (row: User) => {
        row.deleted_at = new Date();
        dispatch(userUpdateRequest(row, true));
        dispatch(usersRefresh());
    };

    const handleDelete = (row: User) => {
        dispatch(userDeleteRequest(row));
        dispatch(usersRefresh());
    };

    const pageChange = (skip: number, orderBy?: string, orderDirection?: string) => {
        dispatch(usersRequest(skip, orderBy, orderDirection));
    };

    return (
        <div className={classes.root}>

            <Typography className={classes.heading}>Current Users</Typography>

            <Paper className={classes.paper} variant="outlined">
                <UserRolesComponent
                    users={active}
                    total={total}
                    headers={headers}
                    loading={loading}
                    pageChange={pageChange}
                    handleArchive={handleArchive}
                    handleDelete={handleDelete}
                    actionArchive={true}
                    serverSide={false}
                />
            </Paper>

            <Typography className={classes.heading}>Archived Users</Typography>

            <Paper className={classes.paper} variant="outlined">
                <UserRolesComponent
                    users={archived}
                    total={archivedTotal}
                    headers={headers}
                    loading={loading}
                    pageChange={pageChange}
                    handleArchive={handleArchive}
                    handleDelete={handleDelete}
                    serverSide={false}
                />
            </Paper>
        </div>
    );
};
