import { call, put } from 'redux-saga/effects';
import { METHOD_POST, requestBuilder } from '../../../helpers';
import { projectsListFailure, ProjectsListRequest, projectsListSuccess } from '../actions';

export default function* ListRequestSaga(action: ProjectsListRequest) {
    try {
        const { data } = yield call(
            requestBuilder,
            'api/projects/search',
            METHOD_POST,
            {
                filter: action.filter,
                skip: action.skip,
                orderBy: action.orderBy,
                orderDirection: action.orderDirection,
            }
        );

        yield put(projectsListSuccess(data.data, data.total, data.headers));
    } catch (error) {
        yield put(projectsListFailure());
    }
}
