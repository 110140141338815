import React, { MutableRefObject, useRef, useState } from 'react';
import { Grid, makeStyles, Typography, Button, Divider, Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Respondent, AppState, selectRespondentUpdateLoading } from '../../../modules';
import { useSelector } from 'react-redux';
import { Dictionary, selectDictionaryGroup } from '../../../modules/dictionaries';
import { InputPhoneMask } from '../../common/Form/InputPhoneMask';
import {
    createGetAutocompleteProps,
    createGetInputProps,
    createMultipleAutocompleteProps
} from '../../../helpers/formBuilder';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { RadioGroupEditField } from '../../common/Form/RadioGroupEditField';
import { DateRange, DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';

interface RespondentInnerComponentProps {
    respondent: Respondent;
    setFilterValues: (prop: string, value: any) => void;
    onSubmit: () => void;
    mode?: string;
    searchBtnDisabled?: boolean;
}

const filterOptions = createFilterOptions(
    {
        limit: 600,
        ignoreCase: true,
        stringify: (option: Dictionary) => option.text,
    });

const useStyles = makeStyles(theme =>
    ({
        typographyHeader: {
            fontWeight: 'bold',
            color: '#4f4f4f',
        },
        fieldItem: {
            marginBottom: '10px',
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5),
        },
        age: {
            display: 'flex',
            alignContent: 'stretch',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: '#4f4f4f',
        },

        formControl: {
            color: '#5680F9',
            top: theme.typography.pxToRem(-8),
        },
    }));

export const Demographic: React.FC<RespondentInnerComponentProps> = (props: RespondentInnerComponentProps) => {

    const {respondent, setFilterValues} = props;
    const classes = useStyles();
    const [creationDate, setCreationDate] = useState<DateRange<Date>>([null, null]);

    const stateDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'state'));
    const incomeDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'income'));
    const maritalStatusDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'marital_status'));
    const ethnicityDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'ethnicity'));
    const educationDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'education'));
    const employmentStatusDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'employment_status'));
    const genderDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'gender'));
    const roleInCompanyDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'role_in_company'));
    const occupationDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'occupation'));
    const countyDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'county'));
    const cityDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'city'));
    const ruralCodeDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'rural_code'));
    const respondentUpdateLoading = useSelector(selectRespondentUpdateLoading);
    const sexDictionary = useSelector((state: AppState) => selectDictionaryGroup(state, 'sex_at_birth'));

    const [values, setValues] = useState(respondent);

    const toRef = useRef<null | HTMLInputElement>(null);
    const fromRef = useRef<null | HTMLInputElement>(null);
    const ageRef = useRef<null | HTMLInputElement>(null);

    const handleScroll = (ref: MutableRefObject<any>) =>
        ref.current?.getElementsByTagName('input')[0].blur()

    const getInputProps = createGetInputProps(values, setValues, setFilterValues);
    const getAutocompleteProps = createGetAutocompleteProps(values, setValues, setFilterValues);
    const getMultipleAutocompleteProps = createMultipleAutocompleteProps(values, setValues, setFilterValues);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const renderMultipleOption = (option: Dictionary, selected: boolean) => (
        <>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{marginRight: 8}}
                checked={selected}
            />
            {option.text}
        </>
    )

    const dateChange = (newValue: DateRange<Date>) => {
        if (newValue[0]) {
            const after = moment(newValue[0]);
            if (after.toISOString() !== null) {
                setFilterValues('created_after', after.toISOString());
            }
        } else {
            setFilterValues('created_after', '');
        }

        if (newValue[1]) {
            const before = moment(newValue[1]);
            if (before.toISOString() !== null) {
                setFilterValues('created_before', before.toISOString());
            }
        } else {
            setFilterValues('created_before', '');
        }

        setCreationDate(newValue);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} variant="outlined">

                <Grid container={true} spacing={3} justify={'center'}>
                    <Grid xs={12} sm={6} item={true}>
                        <TextField
                            label="First Name"
                            {...getInputProps('first_name')}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} item={true}>
                        {props.mode === 'search' && (
                            <InputPhoneMask
                                label="Phone"
                                name={'phone'}
                                value={values.phone}
                                setPhone={(value: any) => {
                                    setFilterValues('phone', value);
                                    setValues({
                                        ...values, 'phone':
                                        value,
                                    })
                                }}
                            />
                        )}
                        {props.mode !== 'search' && (
                            <InputPhoneMask
                                label="Home Phone"
                                name={'home_phone'}
                                value={values.home_phone}
                                setPhone={(value: any) => {
                                    setFilterValues('home_phone', value);
                                    setValues({
                                        ...values, 'home_phone':
                                        value,
                                    })
                                }}
                            />
                        )}
                    </Grid>
                    <Grid xs={12} sm={6} item={true}>
                        <TextField
                            label="Last Name"
                            {...getInputProps('last_name')}
                        />
                    </Grid>
                    {props.mode !== 'search' && (
                        <Grid xs={12} sm={6} item={true}>
                            <InputPhoneMask
                                label="Cell Phone"
                                name={'cellphone'}
                                value={values.cellphone}
                                setPhone={(value: any) => {
                                    setFilterValues('cellphone', value);
                                    setValues({
                                        ...values, 'cellphone':
                                        value,
                                    })
                                }}
                            />
                        </Grid>
                    )}
                    <Grid xs={12} sm={6} item={true}>
                        <TextField
                            label="Email"
                            {...getInputProps('email')}
                            autoComplete="email"
                        />
                    </Grid>
                    {props.mode !== 'search' && (
                        <Grid xs={12} sm={6} item={true}>
                            <InputPhoneMask
                                label="Business Phone"
                                name={'business_phone'}
                                value={values.business_phone}
                                setPhone={(value: any) => {
                                    setFilterValues('business_phone', value);
                                    setValues({
                                        ...values, 'business_phone':
                                        value,
                                    })
                                }}
                            />
                        </Grid>
                    )}
                    <Box mt={8} width={0.5} px={1.5}>
                        <Grid container={true} spacing={3}>
                            <Grid xs={12} item={true}>
                                <TextField
                                    {...getInputProps('street_address')}
                                    label="Home Address"
                                />
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        renderInput={params =>
                                            (
                                                <TextField label={'City'} {...params} />
                                            )}
                                        {...getAutocompleteProps('city', cityDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={8} width={0.5} px={1.5}>
                        <Grid container={true} spacing={3}>
                            <Grid xs={6} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'State'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('state', stateDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={6} item={true}>
                                <TextField
                                    label="Zip"
                                    {...getInputProps('zip_code')}
                                />
                            </Grid>
                            <Grid xs={6} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'County'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('county', countyDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={6} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        renderInput={params =>
                                            (
                                                <TextField label={'Rural Code'} {...params} />
                                            )}
                                        {...getAutocompleteProps('rural_code', ruralCodeDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={8} width={0.5} px={1.5}>
                        <Grid container={true} spacing={3}>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        renderInput={params =>
                                            (
                                                <TextField label={'Sex at Birth'} {...params} />
                                            )}
                                        {...getAutocompleteProps('sex_at_birth', sexDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Ethnicity'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('ethnicity', ethnicityDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Marital Status'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('marital_status', maritalStatusDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Employment Status'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('employment_status', employmentStatusDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Occupation'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('occupation', occupationDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            {props.mode === 'search' && (
                                <Grid xs={12} item={true}>
                                    <FormControl size={'small'} variant="filled">
                                        <LocalizationProvider dateAdapter={MomentUtils}>
                                            <DateRangePicker
                                                startText="Created After"
                                                endText="Created Before"
                                                clearable={true}
                                                showTabs={true}
                                                value={creationDate}
                                                onChange={(newValue: DateRange<Date>) => dateChange(newValue)}
                                                renderInput={(startProps, endProps) => (
                                                    <>
                                                        <TextField variant={'filled'} {...startProps} helperText={''}/>
                                                        <DateRangeDelimiter> - </DateRangeDelimiter>
                                                        <TextField variant={'filled'} {...endProps} helperText={''}/>
                                                    </>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Box mt={8} width={0.5} px={1.5}>
                        <Grid container={true} spacing={3}>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Gender Identity'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('gender', genderDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            {props.mode === 'search' && (
                                <Grid item={true} xs={12}>
                                    <Grid justify={'space-between'} container={true}>
                                        <Grid className={classes.age} item={true} xs={2}>
                                            <Typography style={{color: '#0000008a'}}>
                                                Age Range
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                ref={toRef}
                                                onWheel={() => handleScroll(toRef)}
                                                type="number"
                                                label="From"
                                                {...getInputProps('age_from')}
                                                inputProps={{min: 0}}
                                            />
                                        </Grid>
                                        <Grid className={classes.age} item={true} xs={1}>
                                            <Typography style={{color: '#0000008a'}}>
                                                To
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                ref={fromRef}
                                                onWheel={() => handleScroll(fromRef)}
                                                label="To"
                                                type="number"
                                                {...getInputProps('age_to')}
                                                inputProps={{min: 0}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {props.mode !== 'search' && (
                                <Grid item={true} xs={6}>
                                    <TextField
                                        label="Date of Birth"
                                        type={'date'}
                                        {...getInputProps('dob')}
                                    />
                                </Grid>
                            )}
                            {props.mode !== 'search' && (
                                <Grid xs={6} item={true}>
                                    <TextField
                                        label="Age"
                                        ref={ageRef}
                                        onWheel={() => handleScroll(ageRef)}
                                        type="number"
                                        inputProps={{min: 0}}
                                        {...getInputProps('age')}
                                    />
                                </Grid>
                            )}
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Education'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('education_level', educationDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Income'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('income', incomeDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormControl size={'small'} variant="filled">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        renderOption={(option, {selected}) => renderMultipleOption(option, selected)}
                                        renderInput={params =>
                                            (
                                                <TextField label={'Role in Company'} {...params} />
                                            )}
                                        {...getMultipleAutocompleteProps('role_in_company', roleInCompanyDictionary)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>

                    {props.mode !== 'search' && (
                        <>
                            <Divider style={{width: '100%', marginTop: '30px'}}/>
                            <Box mt={8} width={0.5} px={1.5}>
                                <Grid container={true} spacing={3}>
                                    <Grid xs={12} item={true}>
                                        <Grid container={true}>
                                            <Grid item={true} xs={12} sm={4}>
                                                <Typography style={{width: '100%'}}
                                                            className={classes.typographyHeader}>
                                                    Vaccinated?
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={8}>
                                                <RadioGroupEditField
                                                    inputProps={getInputProps('vaccinated')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item={true}>
                                        <Grid container={true}>
                                            <Grid item={true} xs={12} sm={4}>
                                                <Typography style={{width: '100%'}}
                                                            className={classes.typographyHeader}>
                                                    Vaccine type?
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={8}>
                                                <RadioGroupEditField
                                                    inputProps={getInputProps('vaccine_type')}
                                                    values={{'Moderna': 'Moderna', 'J&J': 'J&J', 'Pfizer': 'Pfizer'}}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={8} width={0.5} px={1.5}>
                                <Grid container={true} spacing={3}>
                                    <Grid xs={12} sm={6} item={true}>
                                        <TextField
                                            label="DATE of that Vaccination"
                                            {...getInputProps('vaccine_date')}
                                        />
                                    </Grid>
                                    <Grid xs={12} item={true}>
                                        <Grid container={true}>
                                            <Grid item={true} xs={12} sm={4}>
                                                <Typography style={{width: '100%'}}
                                                            className={classes.typographyHeader}>
                                                    Has confirmed antibodies
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={8}>
                                                <RadioGroupEditField
                                                    inputProps={getInputProps('covid_antibodies')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box width={1} my={3}>
                                <Divider/>
                            </Box>

                            <Grid container={true}>
                                <Grid item={true} xs={12}>
                                    <TextField
                                        label="Write a comment"
                                        {...getInputProps('comments')}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Box width={1} display="flex" justifyContent="flex-end" mt={3} mb={2} mr={1.5}
                         bgcolor="background.paper">
                        <Button
                            type="submit"
                            variant="contained"
                            size={'large'}
                            color="primary"
                            disabled={props.searchBtnDisabled}
                            onClick={() => props.onSubmit()}
                        >
                            {respondentUpdateLoading ?
                                (
                                    <CircularProgress style={{color: '#fff'}} size={24}/>
                                )
                                : (
                                    <Typography>
                                        {props.mode !== 'search' ? 'Save' : 'Search'}
                                    </Typography>
                                )}
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </div>
    )
};
