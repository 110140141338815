import ListRequestSaga from './list';
import ShowRequestSaga from './show';
import rootDuplicateSaga from './duplicate';
import DeleteRespondentSaga from './delete';
import updateRespondentRlFieldsSaga from './update_rl_fields';
import BulkDeleteRespondentSaga from './bulk_delete';
export const RespondentsListRequestSaga = ListRequestSaga;
export const RespondentsShowRequestSaga = ShowRequestSaga;
export const RespondentsDeleteRequestSaga = DeleteRespondentSaga;
export const RespondentsBulkDeleteRequestSaga = BulkDeleteRespondentSaga;
export const RespondentsDuplicateRequestSaga = rootDuplicateSaga;
export const UpdateRespondentRlFieldsSaga = updateRespondentRlFieldsSaga;
