import { UserAction } from '../actions';
import { UserActionType } from '../constants';
import { Role } from '../../roles';
import { Project } from '../../projects';

export interface User {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    roles: string[],
    projects: Project[],
    projects_name?: string[],
    selected_projects_ids: string[],
    not_my_selected_projects_ids: string[],
    project_ids: string[],
    role_ids: string[],
    deleted_at?: Date,
    _id: string;
    permission_names: string[];
    role_names: string[];
    settings: any;
}

export interface UserState {
    user: User;
    signed: boolean,
    error: boolean,
}

export const initialState: UserState = {
    user: {
        name: '',
        first_name: '',
        projects: [],
        projects_name: [],
        project_ids: [],
        selected_projects_ids: [],
        not_my_selected_projects_ids: [],
        last_name: '',
        email: '',
        _id: '',
        roles: [],
        role_ids: [],
        permission_names: [],
        role_names: [],
        settings: [],
    },
    signed: false,
    error: false,
};


export const userReducer = (state: UserState = initialState, action: UserAction) => {
    switch (action.type) {
        case UserActionType.USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UserActionType.USER_SUCCESS:
            return {
                ...state,
                user: action.user,
                signed: action.signed,
            };
        case UserActionType.USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
