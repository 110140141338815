import React, { useContext } from 'react';
import { PermissionContext } from '../../../contexts/PermissionContext';

interface CanProps {
    to: string;
    children?: React.ReactNode;
};

export const Can: React.FC<CanProps> = (props: CanProps) => {
    const {to, children} = props;

    const {isAllowedTo} = useContext(PermissionContext);

    if (isAllowedTo(to)) {
        return <>{children}</>;
    }

    return null;
};
