import { DictionaryAction } from '../actions';
import { DictionariesActionType } from '../constants';

export interface DictionaryState {
    loading: boolean;
    success: boolean;
    error: boolean;
    message: string;
}

const initialState: DictionaryState = {
    loading: false,
    success: false,
    error: false,
    message: '',
};

export const dictionaryReducer = (state: DictionaryState = initialState, action: DictionaryAction) => {
    switch (action.type) {
        case DictionariesActionType.DICTIONARY_CREATE_REQUEST:
        case DictionariesActionType.DICTIONARY_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                message: '',
                success: false,
            };
        case DictionariesActionType.DICTIONARY_CREATE_SUCCESS:
        case DictionariesActionType.DICTIONARY_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: '',
            };
        case DictionariesActionType.DICTIONARY_CREATE_FAILURE:
        case DictionariesActionType.DICTIONARY_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.error,
                success: false,
            };
        case DictionariesActionType.DICTIONARY_RESET_STATE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
