import React, { useEffect } from 'react';
import { RespondentsAddContainer } from '../../containers';
import { useDispatch } from 'react-redux';
import { respondentsDuplicateResetState } from '../../modules/respondents';

export const RespondentsAddPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(respondentsDuplicateResetState());
    }, []);

    return (
        <>
            <RespondentsAddContainer/>
        </>
    );
};
