import React, { useEffect } from 'react';
import { selectUser, selectUserError, selectUserSigned, userRequest } from '../../modules/user';
import { initialState, UserState } from '../../modules/user/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { rolesRequest } from '../../modules/roles';
import { LoadingPage } from '../../pages/LoadingPage';
import { dictionariesRequest } from '../../modules/dictionaries';
import { PermissionContextProvider } from '../PermissionContext';

const UserContext = React.createContext<UserState>(initialState);

interface UserContextProviderProps {
    children?: React.ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = (props: UserContextProviderProps) => {
    const user = useSelector(selectUser);
    const signed = useSelector(selectUserSigned);
    const error = useSelector(selectUserError);
    const dispatch = useDispatch();
    const {children} = props;

    useEffect(
        () => {
            dispatch(userRequest());
            dispatch(rolesRequest());
            dispatch(dictionariesRequest());
        },
        []
    );

    return (
        <>
            {!signed ? (
                <LoadingPage/>
            ) : (
                <UserContext.Provider
                    value={{user, signed, error}}>
                    <PermissionContextProvider
                        permissions={user.permission_names}
                    >
                        {children}
                    </PermissionContextProvider>
                </UserContext.Provider>
            )}
        </>
    );
};
